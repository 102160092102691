import { Car } from '../../../../utils/models';
import { FEATURE_APP_NAME } from '../../../../utils/utils';
import { TrackingEvent, transformPayload } from '../../common';
import { DataLayerEvent } from './reference';

function createPayload({ carlineName: longName = '' }: Car) {
  const dataLayerEvent: DataLayerEvent = {
    eventInfo: {
      eventAction: 'impression',
      eventName: 'visualizer - impression',
    },
    attributes: {
      componentName: FEATURE_APP_NAME,
      label: '',
      targetURL: '',
      clickID: '',
      elementName: '',
      value: longName,
      pos: '',
    },
  };

  return transformPayload(dataLayerEvent);
}

export type Payload = ReturnType<typeof createPayload>;

export class Event extends TrackingEvent<Payload> {
  private _registerd = false; // track status to only register once

  register(element: Element, car: Car) {
    if (this._registerd) return;

    this._registerd = true;

    this._registerComponent();
    this._trackingService.registerImpressionTracking(element, () => {
      const payload = createPayload(car);
      this._log(payload);
      this._registerComponent();
      return payload;
    });
  }
}
