import React, { FC } from 'react';

export const CancelIcon: FC = () => (
  <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_6000_30320" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2807 12.2298L7.46677 17.0454L6.75953 16.3385L11.5734 11.5228L6.75781 6.70895L7.46479 6.00172L12.2804 10.8156L17.0943 6L17.8015 6.70698L12.9876 11.5226L17.8033 16.3365L17.0963 17.0437L12.2807 12.2298Z"
      />
    </mask>
    <path
      d="M7.46677 17.0454L6.75979 17.7527L7.46702 18.4597L8.174 17.7524L7.46677 17.0454ZM12.2807 12.2298L12.9876 11.5226L12.2804 10.8156L11.5734 11.5228L12.2807 12.2298ZM6.75953 16.3385L6.0523 15.6315L5.34532 16.3387L6.05255 17.0457L6.75953 16.3385ZM11.5734 11.5228L12.2807 12.2298L12.9876 11.5226L12.2804 10.8156L11.5734 11.5228ZM6.75781 6.70895L6.05058 6.00197L5.3436 6.70921L6.05083 7.41619L6.75781 6.70895ZM7.46479 6.00172L8.17177 5.29449L7.46454 4.58751L6.75756 5.29474L7.46479 6.00172ZM12.2804 10.8156L11.5734 11.5228L12.2807 12.2298L12.9876 11.5226L12.2804 10.8156ZM17.0943 6L17.8013 5.29277L17.094 4.58579L16.3871 5.29302L17.0943 6ZM17.8015 6.70698L18.5088 7.41396L19.2157 6.70673L18.5085 5.99975L17.8015 6.70698ZM12.9876 11.5226L12.2804 10.8156L11.5734 11.5228L12.2807 12.2298L12.9876 11.5226ZM17.8033 16.3365L18.5105 17.0435L19.2175 16.3362L18.5102 15.6293L17.8033 16.3365ZM17.0963 17.0437L16.3893 17.751L17.0965 18.4579L17.8035 17.7507L17.0963 17.0437ZM8.174 17.7524L12.9879 12.9368L11.5734 11.5228L6.75953 16.3385L8.174 17.7524ZM6.05255 17.0457L6.75979 17.7527L8.17375 16.3382L7.46651 15.6312L6.05255 17.0457ZM10.8662 10.8159L6.0523 15.6315L7.46677 17.0454L12.2807 12.2298L10.8662 10.8159ZM6.05083 7.41619L10.8664 12.2301L12.2804 10.8156L7.46479 6.00172L6.05083 7.41619ZM6.75756 5.29474L6.05058 6.00197L7.46505 7.41593L8.17203 6.7087L6.75756 5.29474ZM12.9874 10.1084L8.17177 5.29449L6.75781 6.70895L11.5734 11.5228L12.9874 10.1084ZM16.3871 5.29302L11.5732 10.1086L12.9876 11.5226L17.8015 6.70698L16.3871 5.29302ZM18.5085 5.99975L17.8013 5.29277L16.3873 6.70723L17.0946 7.41421L18.5085 5.99975ZM13.6949 12.2296L18.5088 7.41396L17.0943 6L12.2804 10.8156L13.6949 12.2296ZM18.5102 15.6293L13.6946 10.8154L12.2807 12.2298L17.0963 17.0437L18.5102 15.6293ZM17.8035 17.7507L18.5105 17.0435L17.096 15.6295L16.389 16.3367L17.8035 17.7507ZM11.5737 12.9371L16.3893 17.751L17.8033 16.3365L12.9876 11.5226L11.5737 12.9371Z"
      fill="white"
      mask="url(#path-1-inside-1_6000_30320)"
    />
  </svg>
);
