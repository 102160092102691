import { Car, OptionType } from '../models';
import { amendExclusiveAve } from './ave';

export function selectOption(optionId: string, car: Car, type: OptionType): Car {
  const { availableOptions, selectedOptions } = car;
  const { [type]: availableOptionsToChange } = availableOptions;

  if (!availableOptionsToChange) return car;

  const selectedOptionToChange = [...availableOptionsToChange].find(({ id }) => id === optionId);
  if (!selectedOptionToChange) return car;

  const result = {
    ...car,
    selectedOptions: {
      ...selectedOptions,
      [type]: selectedOptionToChange,
    },
  };

  return amendExclusiveAve(result);
}
