import React from 'react';

/**
 * Custom hooks that defines an effect that triggers only triggers on updates, not on initial mount.
 * @param effect
 * @param dependencies
 */
export function useUpdateEffect(effect: () => void, dependencies: any[]) {
  const mounted = React.useRef(false);

  React.useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;

      return;
    }

    effect();
  }, dependencies);
}
