import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Button, ButtonProps, Text } from '@audi/audi-ui-react';
import { BackIcon } from './Icons/BackIcon';

interface TextButtonProps {
  onClick?: () => void;
  testId?: string;
  children?: ReactNode;
}

export const TextButton: FC<TextButtonProps> = ({ children, onClick, testId, ...restProps }) => {
  const buttonText = (
    <Text as="span" variant="copy2">
      {children}
    </Text>
  );

  const props: ButtonProps = {
    ...restProps,
    onClick,
    variant: 'text',
    'data-testid': testId,
  };

  return (
    <StyledButton {...props} icon={<BackIcon />}>
      {buttonText}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  margin-left: -6px;

  & > span {
    display: block;
    /* removes space between icon and text */
    margin: 0;
    /* centers icon and text horizontally */
    margin-bottom: 2px;
  }
`;
