import React, { FC } from 'react';

export const ApplyIcon: FC = () => (
  <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75977 12.5041L7.45097 11.8111L11.3127 15.5518L18.9878 6L19.7598 6.60275L11.4042 17L6.75977 12.5041Z"
      fill="white"
    />
  </svg>
);
