import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { Logger } from '@feature-hub/core';
import { Event as FeatureAppReady } from './events/core/feature-app-ready/event';
import { Event as Impression } from './events/module/impression/event';
import { Event as InteractingWithTabNavigation } from './events/module/interacting-with-tab-navigation/event';
import { Event as ClickToChangeCarPart } from './events/module/click-to-change-car-part/event';
import { Event as GoBackToOverview } from './events/module/go-back-to-overview/event';
import { Event as ClickOnTheMainCta } from './events/module/click-on-the-main-cta/event';
import { Event as ChangeCarPartCallback } from './events/module/change-car-part-callback/event';

export function createTrackingInstances(trackingService: TrackingServiceV2, logger: Logger) {
  return {
    changeCarPartCallback: new ChangeCarPartCallback(trackingService, logger),
    clickOnTheMainCta: new ClickOnTheMainCta(trackingService, logger),
    clickToChangeCarPart: new ClickToChangeCarPart(trackingService, logger),
    featureAppReady: new FeatureAppReady(trackingService, logger),
    goBackToOverview: new GoBackToOverview(trackingService, logger),
    impression: new Impression(trackingService, logger),
    interactingWithTabNavigation: new InteractingWithTabNavigation(trackingService, logger),
  };
}

export type TrackingInstances = ReturnType<typeof createTrackingInstances>;
