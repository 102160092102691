import { I18NServiceV1 } from '@volkswagen-onehub/audi-i18n-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { useAppState } from '../contexts/app-context';

export const i18nScope = 'fa.visualizer';

export interface UseI18nArgs {
  id: string;
  defaultMessage: string;
}

export const i18nKeys = {
  backToOverview: {
    defaultMessage: 'Back to Overview',
    id: 'fa.visualizer.backToOverview',
  },
  exteriorColor: {
    defaultMessage: 'Exterior Color',
    id: 'fa.visualizer.exteriorColor',
  },
  exteriorColorExclusive: {
    defaultMessage: 'Custom Paint',
    id: 'fa.visualizer.exteriorColorExclusive',
  },
  exteriorColorCategory: {
    defaultMessage: 'Custom Paint Category',
    id: 'fa.visualizer.exteriorColorCategory',
  },
  rim: {
    defaultMessage: 'Rim',
    id: 'fa.visualizer.rim',
  },
  interior: {
    defaultMessage: 'Interiors',
    id: 'fa.visualizer.interior',
  },
  interiorBaseColor: {
    defaultMessage: 'Interior Base Colors',
    id: 'fa.visualizer.interiorBaseColor',
  },
  interiorContrastColor: {
    defaultMessage: 'Interior Contrast Colors',
    id: 'fa.visualizer.interiorContrastColor',
  },
  interiorSeatScheme: {
    defaultMessage: 'Interior Seat Scheme',
    id: 'fa.visualizer.interiorSeatScheme',
  },
  interiorStitchingColor: {
    defaultMessage: 'Interior Stitching Colors',
    id: 'fa.visualizer.interiorStitchingColor',
  },
  change: {
    exteriorColor: {
      defaultMessage: 'Change Exterior Color',
      id: 'fa.visualizer.change.exteriorColor',
    },
    exteriorColorExclusive: {
      defaultMessage: 'Change Custom Paint',
      id: 'fa.visualizer.change.exteriorColorExclusive',
    },
    rim: {
      defaultMessage: 'Change Rims',
      id: 'fa.visualizer.change.rim',
    },
    interior: {
      defaultMessage: 'Change Interior',
      id: 'fa.visualizer.change.interior',
    },
    interiorBaseColor: {
      defaultMessage: 'Change Interior Base Color',
      id: 'fa.visualizer.change.interiorBaseColor',
    },
    interiorContrastColor: {
      defaultMessage: 'Change Interior Contrast Color',
      id: 'fa.visualizer.change.interiorContrastColor',
    },
    interiorStitchingColor: {
      defaultMessage: 'Change Interior Stitching Color',
      id: 'fa.visualizer.change.interiorStitchingColor',
    },
  },
  exclusiveColorSubCategory: {
    CRYSTAL_EFFECT: {
      defaultMessage: 'Crystal Effect',
      id: 'fa.visualizer.exclusiveColorSubCategory.crystalEffect',
    },
    MATTE: {
      defaultMessage: 'Matt',
      id: 'fa.visualizer.exclusiveColorSubCategory.matte',
    },
    METALLIC: {
      defaultMessage: 'Metallic',
      id: 'fa.visualizer.exclusiveColorSubCategory.metallic',
    },
    PEARL_EFFECT: {
      defaultMessage: 'Pearl Effect',
      id: 'fa.visualizer.exclusiveColorSubCategory.pearlEffect',
    },
    UNI: {
      defaultMessage: 'Uni',
      id: 'fa.visualizer.exclusiveColorSubCategory.uni',
    },
  },
  conflict: {
    apply: {
      defaultMessage: 'apply',
      id: 'fa.visualizer.conflict.apply',
    },
    cancel: {
      defaultMessage: 'cancel',
      id: 'fa.visualizer.conflict.cancel',
    },
    buildabilityConflict: {
      defaultMessage: 'Buildability Conflict',
      id: 'fa.visualizer.conflict.buildabilityConflict',
    },
    notice: {
      generic: {
        defaultMessage:
          'This option is not combinable with the current configuration. When applying this color, the rest of the configuration is reset.',
        id: 'fa.visualizer.conflict.notice.generic',
      },
      exteriorColor: {
        defaultMessage: 'When applying this color, the rest of the configuration is reset.',
        id: 'fa.visualizer.conflict.notice.exteriorColor',
      },
      rim: {
        defaultMessage: 'When applying this rim, the rest of the configuration is reset.',
        id: 'fa.visualizer.conflict.notice.rim',
      },
      interior: {
        defaultMessage: 'When applying this interior, the rest of the configuration is reset.',
        id: 'fa.visualizer.conflict.notice.interior',
      },
    },
  },
  plural: {
    exteriorColor: {
      defaultMessage: 'Exterior Colors',
      id: 'fa.visualizer.plural.exteriorColor',
    },
    exteriorColorExclusive: {
      defaultMessage: 'Custom Paints',
      id: 'fa.visualizer.plural.exteriorColorExclusive',
    },
    rim: {
      defaultMessage: 'Rims',
      id: 'fa.visualizer.plural.rim',
    },
    interior: {
      defaultMessage: 'Interiors',
      id: 'fa.visualizer.plural.interior',
    },
    interiorBaseColor: {
      defaultMessage: 'Interior Base Colors',
      id: 'fa.visualizer.plural.interiorBaseColor',
    },
    interiorContrastColor: {
      defaultMessage: 'Interior Contrast Colors',
      id: 'fa.visualizer.plural.interiorContrastColor',
    },
    interiorStitchingColor: {
      defaultMessage: 'Interior Stitching Colors',
      id: 'fa.visualizer.plural.interiorStitchingColor',
    },
  },
  printHeader: {
    defaultMessage: 'Audi Exclusive Visualization',
    id: 'fa.visualizer.printHeader',
  },
  printChosenOptions: {
    defaultMessage: 'Your selected options',
    id: 'fa.visualizer.printChosenOptions',
  },
  printCreatedDate: {
    defaultMessage: 'Created on',
    id: 'fa.visualizer.printCreatedDate',
  },
  cta: {
    defaultMessage: 'Configure model',
    id: 'fa.visualizer.cta',
  },
  ctaPrint: {
    defaultMessage: 'Print configuration',
    id: 'fa.visualizer.ctaPrint',
  },
};

export type Placeholder = Record<string, number | string>;

function isDebugMode() {
  const urlParamString = typeof window !== 'undefined' ? window.location.search : '';
  const urlParams = new URLSearchParams(urlParamString);

  return urlParams.get('d_text') === 'true';
}

export function useI18n({ id, defaultMessage }: UseI18nArgs, placeholder?: Placeholder): string {
  const { translations } = useAppState();

  const translation = translations[id];

  if (isDebugMode()) {
    return `[${id}]${translation ?? '-'}[END]`;
  }

  if (placeholder) {
    const message = [...Object.entries(placeholder)].reduce(
      (replacedMessage, [currentPlaceholder, currentValue]) =>
        replacedMessage?.replace(`{{${currentPlaceholder}}}`, `${currentValue}`),
      translation ?? defaultMessage,
    );

    return message ?? `missing translation: ${id}`;
  }

  return translation ?? defaultMessage ?? `missing translation: ${id}`;
}

export type Translations = Record<string, string>;

export async function fetchTranslations(
  localeService: GfaLocaleServiceV1,
  i18nService: I18NServiceV1,
): Promise<Translations> {
  return new Promise((resolve, reject) => {
    try {
      i18nService.setScopes([i18nScope]);
      i18nService.registerCallback((messages) => {
        const res = Object.entries(messages[localeService.language]).reduce(
          (acc: Translations, [key, value]) => {
            // TODO remove this when i18nService honors the configured scope (i18nScope)
            // Filter i18n keys relevant for the given scope.
            if (key.includes(i18nScope)) {
              acc[key] = value;
            }

            return acc;
          },
          {},
        );

        resolve(res);
      });
    } catch (err) {
      reject(err);
    }
  });
}
