import React, { FC, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { Text } from '@audi/audi-ui-react';
import { Tile, TileImage, TileText } from '../components/Tile';
import { useAppDispatch, useAppState } from '../contexts/app-context';
import { i18nKeys, useI18n } from '../i18n/i18n';
import { OptionTypeExcludingSeatScheme } from '../utils/models';
import { useCorrectExteriorColorTranslationKey } from '../hooks/use-correct-exterior-color-translation';
import { formatHTML } from '../utils/utils';
import { BackIcon } from '../components/Icons/BackIcon';

interface SummaryItemProps {
  category: OptionTypeExcludingSeatScheme;
}

export const SummaryItem: FC<SummaryItemProps> = ({ category }) => {
  const { car, enabledCategories, tracking } = useAppState();

  const { selectedOptions } = car;
  const { interiorSeatScheme } = selectedOptions;

  const categoryLabel = useI18n(i18nKeys.change[useCorrectExteriorColorTranslationKey(category)]);

  const dispatch = useAppDispatch();
  const setCategory = useCallback(() => {
    dispatch({
      type: 'SET_CATEGORY',
      payload: category,
    });
    tracking?.interactingWithTabNavigation.trigger(category, categoryLabel);
  }, [category, categoryLabel, dispatch, tracking?.interactingWithTabNavigation]);

  const selectedOption = selectedOptions[category];

  const optionLabel = useMemo(() => {
    if (!selectedOption) return null;

    if (category === 'interiorContrastColor' && interiorSeatScheme) {
      return [interiorSeatScheme.name, selectedOption.name].join(': ');
    }

    return selectedOption.name;
  }, [category, interiorSeatScheme, selectedOption]);

  if (!selectedOption || !optionLabel) return null;

  const {
    tile: { url, width, height, mimeType },
  } = selectedOption;

  return (
    <SummaryItemWrapper
      amountTiles={enabledCategories.length}
      role="listbox"
      aria-label={categoryLabel}
      onClick={() => setCategory()}
    >
      <CategoryWrapper>
        <Text as="span" variant="copy2" data-testid={category}>
          {categoryLabel}
        </Text>
        <BackIcon direction="right" />
      </CategoryWrapper>
      <Tile
        text={formatHTML(optionLabel)}
        src={url}
        category={category === 'rim' ? 'rim' : 'color'}
        width={width}
        height={height}
        type={mimeType}
        usage="overview"
        isTruncated
      >
        <TileImage />
        <TileText />
      </Tile>
    </SummaryItemWrapper>
  );
};

const SummaryItemWrapper = styled.div<{ amountTiles: number }>`
  display: grid;
  gap: var(${({ theme }) => theme.responsive.spacing.xxs});
  grid-auto-rows: 1fr auto;
  justify-items: center;
  display: flex;
  flex-direction: column;
`;

const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

   & span {
    margin-right: 12px;
`;
