/**
 * This is the entry point for Feature Hub App integration
 */

import { OneGraphServiceV1 } from '@oneaudi/onegraph-service';
import { OneGraphQueryServiceV1 } from '@oneaudi/onegraph-query-service';
import React from 'react';
import { FeatureAppDefinition, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import { pubSubFeatureService, PubSubFeatureServiceV1 } from '@oneaudi/pubsub-service';
import { FootnoteContextProvider } from '@oneaudi/feature-app-utils';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { I18NServiceV1 } from '@volkswagen-onehub/audi-i18n-service';
import { ThemeProvider } from '@audi/audi-ui-react';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { VisualizerContent } from './editor.d';
import { State, createInitialState } from './contexts/reducers/app-reducer';
import { AppFeatureServices, AppProvider } from './contexts/app-context';
import { Visualizer } from './views/Visualizer';
import { createTrackingInstances } from './tracking/main';

export { FEATURE_APP_NAME } from './utils/utils';

export interface Dependencies extends FeatureServices {
  readonly 'gfa:locale-service': GfaLocaleServiceV1;
  readonly 'pubsub-service': PubSubFeatureServiceV1;
  readonly 'audi-tracking-service'?: TrackingServiceV2;
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
  readonly 's2:logger'?: Logger;
  readonly 's2:serialized-state-manager'?: SerializedStateManagerV1;
  readonly 'audi-content-service': ContentServiceV1;
  readonly 'dbad:audi-vue-formatter-service': VueFormatterServiceInterfaceV1;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly 'dbad:audi-i18n-service': I18NServiceV1;
  readonly 'onegraph-service': OneGraphServiceV1;
  readonly 'onegraph-query-service': OneGraphQueryServiceV1;
}

export type FeatureAppConfig = Record<string, never>;

const featureApp: FeatureAppDefinition<ReactFeatureApp, Dependencies, FeatureAppConfig> = {
  dependencies: {
    featureServices: {
      'audi-content-service': '^1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
      'dbad:audi-i18n-service': '^1.0.0',
      'dbad:audi-vue-formatter-service': '^1.0.0',
      'gfa:locale-service': '^1.0.0',
      'pubsub-service': '^1.0.0',
    },
    externals: {
      '@audi/audi-ui-react': '^3.4.1',
      '@feature-hub/react': '^2.7.0',
      '@oneaudi/onegraph-query-service': '*',
      '@oneaudi/onegraph-service': '*',
      'react-dom': '^16.13.1 || ^17.0.2',
      'styled-components': '^5.1.1',
      react: '^16.13.1 || ^17.0.2',
    },
  },

  ownFeatureServiceDefinitions: [pubSubFeatureService],

  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      'audi-tracking-service': '^2.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
    },
  },

  create: ({ featureAppId, featureServices }) => {
    const {
      'audi-tracking-service': trackingService,
      'gfa:locale-service': localeService,
      'dbad:audi-i18n-service': i18nService,
      's2:async-ssr-manager': asyncSsrManager,
      's2:logger': s2logger,
      's2:serialized-state-manager': serializedStateManager,
      'audi-content-service': contentService,
      'dbad:audi-vue-formatter-service': vueFormatterService,
      'pubsub-service': pubSubService,
      'audi-footnote-reference-service': footnoteReferenceServiceScopeManager,
    } = featureServices;

    const logger = s2logger || console;

    const content = contentService.getContent() as VisualizerContent | undefined;

    if (!content) {
      throw new Error('The feature app content not properly configured');
    }

    let initialState: State | undefined;
    let loadingPromise = Promise.resolve();

    /**
     *
     * Creates initial state and saves it the upper scope. This way when asyncSsrManager re-renders the FA
     * the initialState is available in the feature app render method.
     */
    async function setupInitialState(_content: VisualizerContent) {
      initialState = await createInitialState(
        _content,
        localeService,
        vueFormatterService,
        i18nService,
        logger,
        featureAppId,
        trackingService,
      );

      return initialState;
    }

    if (asyncSsrManager) {
      // Server Side Rendering (SSR)

      // setup and serialize initial state
      const createInitialStatePromise = setupInitialState(content).then((_initialState) => {
        // serialize initial state to make available during CSR
        serializedStateManager?.register(() => JSON.stringify(_initialState));
      });

      // schedule a feature app re-render after the initial state is ready to be used.
      asyncSsrManager.scheduleRerender(
        Promise.all([createInitialStatePromise, i18nService.setLocale()]),
      );
    } else {
      // Client Side Rendering (CSR)

      // get serialized state created during SSR
      const serializedState = serializedStateManager?.getSerializedState();

      if (serializedState) {
        initialState = JSON.parse(serializedState) as State | undefined;

        if (initialState) {
          if (!initialState.tracking && trackingService) {
            logger.info(
              'Visualizer SSR serialized state: no tracking instances found, recreating from tracking service',
            );
            initialState.tracking = createTrackingInstances(trackingService, logger);
          }
        }

        logger.info('Visualizer SSR serialized state: ', initialState);
      } else {
        logger.warn(
          'The feature app serializedState does not exist. Please make sure the feature app & integrator support SSR.',
        );

        // when the integrator does not support SSR (static-demo-integrator) the state is never created & serialized
        // on the server, thus it does not exist when the FA is loaded on the client either. In this case the
        // initial state has to be created right away before rendering the FA.
        loadingPromise = setupInitialState(content).then();
      }
    }

    const footnoteReferenceService =
      footnoteReferenceServiceScopeManager.getDefaultScopeRefService();

    const mappedFeatureServices: AppFeatureServices = {
      contentService,
      localeService,
      pubSubService,
      asyncSsrManager,
      logger,
      vueFormatterService,
      footnoteReferenceService,
      i18nService,
    };

    return {
      loadingPromise,
      render() {
        return (
          <div data-fefa-custom-id={featureAppId}>
            <ThemeProvider>
              <FootnoteContextProvider footnoteReferenceService={footnoteReferenceService}>
                {initialState ? (
                  <AppProvider featureServices={mappedFeatureServices} initialState={initialState}>
                    <Visualizer />
                  </AppProvider>
                ) : null}
              </FootnoteContextProvider>
            </ThemeProvider>
          </div>
        );
      },
    };
  },
};

export default featureApp;
