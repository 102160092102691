import pkg from '../../../../../../package.json';
import type { AdditionalObjects, DataLayerEvent } from './reference';
import { createCarPartsPayload, TrackingEvent, transformPayload } from '../../common';
import { Car } from '../../../../utils/models';
import { FEATURE_APP_NAME } from '../../../../utils/utils';

const { version } = pkg;

function createPayload(car: Car, isExclusive: boolean) {
  const { carlineId = '', carlineName: longName = '', price = '0', selectedOptions } = car;

  const dataLayerEvent: DataLayerEvent = {
    eventInfo: {
      eventAction: 'feature_app_ready',
      eventName: 'feature app ready',
    },
    attributes: {
      componentName: FEATURE_APP_NAME,
      label: '',
      targetURL: '',
      clickID: '',
      elementName: '',
      value: '',
      pos: '',
    },
  };

  const additionalObjects: AdditionalObjects = {
    componentInfo: {
      implementer: 2,
      version,
      variant: isExclusive ? 'exclusive' : 'standard',
    },
    products: {
      configuredVehicle: [
        {
          productInfo: {
            productID: carlineId,
            productName: longName,
            manufacturer: 'Audi',
          },
          price: {
            totalPrice: price,
          },
          category: {
            primaryCategory: '',
            subCategory1: carlineId,
            productType: 'configured vehicle',
          },
          carParts: createCarPartsPayload(selectedOptions),
        },
      ],
    },
  };

  return transformPayload(dataLayerEvent, additionalObjects);
}

export type Payload = ReturnType<typeof createPayload>;

export class Event extends TrackingEvent<Payload> {
  private _sent = false; // track sent status to only trigger once

  trigger(car: Car, isExclusive: boolean) {
    if (this._sent) return;

    this._sent = true;

    this._trigger(createPayload(car, isExclusive));
  }
}
