import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { Logger } from '@feature-hub/core';
import { ManualConfig } from '../editor.d';
import { AvailableOptions, Car, defaultCategories, Option, OptionTypeDefault } from './models';
import { getCarlineId } from './utils';
import { VehicleType } from '../../../@types/onegraph';

export function createCar(
  config: ManualConfig,
  emission: string,
  consumption: string,
  classFb: string,
  powerConsumption: string,
  fuelConsumptionBatteryDischarged: string,
  classBatteryDischarged: string,
  vehicleType: VehicleType,
): Car {
  const { ave, longName } = config;

  const aveIncludesPrItem = ({ id }: Option) => ave.includes(id);

  const availableOptions = Object.fromEntries(
    defaultCategories.map((category) => {
      const item = config[category].map((items): Option => {
        return {
          id: items.id,
          name: items.name,
          tile: {
            url: items.tile,
          },
        };
      });

      return [category, item] as const;
    }),
  ) as Record<OptionTypeDefault, Option[]>;

  const selectedOptions = Object.fromEntries(
    Object.entries(availableOptions).map(([category, options]) => {
      return [category, options.find(aveIncludesPrItem)] as const;
    }),
  ) as Record<OptionTypeDefault, Option | undefined>;

  defaultCategories.forEach((category) => {
    if (selectedOptions[category]) return;
    if (category === 'interior' && !selectedOptions[category]) return;
    throw new Error(
      `The configured aveString should contain one each of all categories, could not find category "${category}".`,
    );
  });

  return {
    carlineName: longName,
    ave,
    availableOptions,
    selectedOptions,
    emission,
    consumption,
    classFb,
    powerConsumption,
    fuelConsumptionBatteryDischarged,
    classBatteryDischarged,
    vehicleType,
  };
}

export function selectOption(optionId: string, car: Car, type: OptionTypeDefault): Car {
  const currentSelectedOption = car.selectedOptions[type] as Option;

  const availableOptions: Partial<AvailableOptions> = {};
  availableOptions[type] = [];

  const { options, selectedOption: newSelectedOption } = (car.availableOptions[type] || []).reduce(
    (acc: { options: Option[]; selectedOption: Option }, option: Option) => {
      const selected = option.id === optionId;
      const amendedOption = { ...option, selected };

      return {
        options: [...acc.options, amendedOption],
        selectedOption: selected ? amendedOption : acc.selectedOption,
      };
    },
    {
      options: [],
      selectedOption: currentSelectedOption,
    },
  );

  // A new object has to be created to trigger change detection
  const newCar = { ...car };
  newCar.ave = car?.ave?.replace(car.selectedOptions[type]!.id, optionId);
  newCar.selectedOptions[type] = newSelectedOption;
  newCar.availableOptions[type] = options;

  return newCar;
}

export async function fetchCar(
  config: ManualConfig,
  ecFormatterService: VueFormatterServiceInterfaceV1,
  localeService: GfaLocaleServiceV1,
  logger: Logger,
): Promise<Car> {
  const {
    ave,
    emissionFallback,
    consumptionFallback,
    classFallback,
    classBatteryDischargedFallback,
    fuelConsumptionBatteryDischargedFallback,
    powerConsumptionFallback,
    vehicleType,
  } = config;

  const carlineId = getCarlineId(ave);

  let emission = emissionFallback || '';
  let consumption = consumptionFallback || '';
  const classFb = classFallback || '';
  const powerConsumption = powerConsumptionFallback || '';
  const fuelConsumptionBatteryDischarged = fuelConsumptionBatteryDischargedFallback || '';
  const classBatteryDischarged = classBatteryDischargedFallback || '';

  try {
    const res = await ecFormatterService.vueRangeForCarline(
      localeService.countryCode,
      localeService.language,
      carlineId,
    );

    const { formattedEmission, formattedConsumption } = res;
    if (formattedEmission) emission = formattedEmission;
    if (formattedConsumption) consumption = formattedConsumption;
  } catch (err) {
    const msg = `ecFormatterService.vueRangeForCarline request failed for ${carlineId}. Using fallbacks.`;
    logger.warn(msg);
  }

  return createCar(
    config,
    emission,
    consumption,
    classFb,
    powerConsumption,
    fuelConsumptionBatteryDischarged,
    classBatteryDischarged,
    vehicleType,
  );
}
