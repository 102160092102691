import React, { FC } from 'react';
import { OptionTypeAmended } from '../hooks/use-correct-exterior-color-translation';
import { i18nKeys, useI18n } from '../i18n/i18n';
import { Option } from '../utils/models';
import { ImgWithLoadMonitoring, ImgWithLoadMonitoringProps } from './ImgWithLoadMonitoring';

export type Props = Pick<Option, 'name' | 'tile'> & {
  category: OptionTypeAmended;
  onMonitorImgLoad?: ImgWithLoadMonitoringProps['onMonitorLoad'];
};

export const ChosenOption: FC<Props> = ({ category, name, tile, onMonitorImgLoad }) => {
  const categoryLabel = useI18n(i18nKeys[category]);

  return (
    <tr className="ChosenOptionRow">
      <th className="CategoryContainer" data-testid="category-label">
        {categoryLabel}
      </th>
      <td className="OptionTextContainer">
        <div className="OptionText">{name}</div>
      </td>
      <td className="OptionTile">
        <ImgWithLoadMonitoring
          className="TileImage__printView"
          src={tile.url}
          alt={name}
          data-testid="tile-image"
          onMonitorLoad={onMonitorImgLoad}
        />
      </td>
    </tr>
  );
};
