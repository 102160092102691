import { FEATURE_APP_NAME } from '../../../../utils/utils';
import { TrackingEvent, transformPayload } from '../../common';
import { DataLayerEvent } from './reference';

function createPayload(category: string, label: string) {
  const dataLayerEvent: DataLayerEvent = {
    eventInfo: {
      eventAction: 'content',
      eventName: 'visualizer - click on tab',
    },
    attributes: {
      componentName: FEATURE_APP_NAME,
      label,
      targetURL: '',
      clickID: `show-category-${category}`,
      elementName: 'text link',
      value: category,
      pos: '',
    },
  };

  return transformPayload(dataLayerEvent);
}

export type Payload = ReturnType<typeof createPayload>;

export class Event extends TrackingEvent<Payload> {
  trigger(category: string, label: string) {
    this._trigger(createPayload(category, label));
  }
}
