import { Car } from '../../../../utils/models';
import { FEATURE_APP_NAME } from '../../../../utils/utils';
import { sameOrigin, TrackingEvent, transformPayload } from '../../common';
import { DataLayerEventConfiguration, DataLayerEventPrint } from './reference';

function createPayloadConfiguration(
  { carlineName: longName = '' }: Car,
  label: string,
  targetURL: string,
): DataLayerEventConfiguration {
  const eventAction = sameOrigin(targetURL) ? 'internal_link' : 'exit_link';

  return {
    eventInfo: {
      eventAction,
      eventName: 'visualizer - go to configuration',
    },
    attributes: {
      componentName: FEATURE_APP_NAME,
      label,
      targetURL,
      clickID: 'ctaButton__goToConfiguration',
      elementName: 'button',
      value: longName,
      pos: '',
    },
  };
}

function createPayloadPrint(
  { carlineName: longName = '' }: Car,
  label: string,
): DataLayerEventPrint {
  return {
    eventInfo: {
      eventAction: 'print',
      eventName: 'visualizer - click on print',
    },
    attributes: {
      componentName: FEATURE_APP_NAME,
      label,
      targetURL: '',
      clickID: 'ctaButton__clickOnPrint',
      elementName: 'button',
      value: longName,
      pos: '',
    },
  };
}

function createPayload(car: Car, label: string, isPrint: boolean, targetURL?: string) {
  if (!isPrint && targetURL) {
    return transformPayload(createPayloadConfiguration(car, label, targetURL));
  }

  return transformPayload(createPayloadPrint(car, label));
}

export type Payload = ReturnType<typeof createPayload>;
export class Event extends TrackingEvent<Payload> {
  trigger(car: Car, label: string, isPrint: boolean, targetURL?: string) {
    this._trigger(createPayload(car, label, isPrint, targetURL));
  }
}
