// The Overlay consist out of as many OverlayItems as there are Categories
// One Overlay item consists out of
// 1. category headline
// 2. several tiles, one for each available option per category

import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Text } from '@audi/audi-ui-react';
import { Tile, TileImage, TileText } from '../components/Tile';
import { useAppDispatch, useAppState } from '../contexts/app-context';
import { i18nKeys, useI18n } from '../i18n/i18n';
import { OptionTypeExcludingSeatScheme } from '../utils/models';
import { useMutateConfiguration } from '../hooks/use-mutate-configuration';
import { useIsDesktop } from '../hooks/use-is-desktop';
import { SeatSchemeSelect } from './SeatSchemeSelect';
import { useCorrectExteriorColorTranslationKey } from '../hooks/use-correct-exterior-color-translation';
import { ExteriorColorSelect } from './ExteriorColorSelect';
import { components } from '../utils/asset-api-mode/definition.d';
import { NullableCategory } from '../contexts/reducers/app-reducer';
import { BuildabilityConflictWarning } from '../components/BuildabilityConflictWarning';
import { formatHTML } from '../utils/utils';

interface OptionSelectionProps {
  category: OptionTypeExcludingSeatScheme;
}

export type ExclusiveColorTypeFilterValue = components['schemas']['ExclusiveColorTypeFilterValue'];

export const OverlayItem = forwardRef<HTMLDivElement, OptionSelectionProps>(({ category }, ref) => {
  const isDesktop = useIsDesktop();

  const {
    content: { workingMode },
    car,
    selectedCategory,
    tracking,
  } = useAppState();
  const { availableOptions, selectedOptions } = car;

  const isExteriorColorSelectShown =
    workingMode.type === 'assetApi' && workingMode.isExteriorColorSelectShown;

  const dispatch = useAppDispatch();

  const headlineText = useI18n(i18nKeys.plural[useCorrectExteriorColorTranslationKey(category)]);

  const setCategory = useCallback(
    (value: NullableCategory) =>
      dispatch({
        type: 'SET_CATEGORY',
        payload: value,
      }),
    [dispatch],
  );

  const resetSelectedCategory = useCallback(() => {
    dispatch({
      type: 'SET_CATEGORY',
      payload: null,
    });
  }, [dispatch]);

  const mutateConfiguration = useMutateConfiguration();

  const isExlusiveExteriorColor = category === 'exteriorColor' && workingMode.type === 'assetApi';

  const options = useMemo(() => availableOptions[category] || [], [availableOptions, category]);

  const selectedOptionId = useMemo(
    () => selectedOptions[category]?.id,
    [selectedOptions, category],
  );

  const selectedOptionSubCategory = useMemo(
    () => selectedOptions[category]?.subCategory,
    [selectedOptions, category],
  );

  // highlight the clicked option right after clicking and and not waiting for the response of the API
  // because mutateConfiguration is a slow process
  const [clickedOptionId, setClickedOptionId] = useState<string | null>(null);

  // stores the ID of the tile with the open popover
  // null = no popover is shown
  const [idOptionWithPopover, setIdOptionWithPopover] = useState<string | null>(null);

  useEffect(() => {
    setClickedOptionId(null);
  }, [selectedOptionId]);

  useEffect(() => {
    if (!clickedOptionId) return;

    mutateConfiguration(category, clickedOptionId);
    setIdOptionWithPopover(null);

    if (isDesktop) {
      setCategory(category);
      return;
    }

    resetSelectedCategory();
  }, [clickedOptionId]);

  const highlightedOptionId = useMemo(
    () => clickedOptionId || selectedOptionId,
    [clickedOptionId, selectedOptionId],
  );

  const seatScheme =
    selectedCategory === 'interiorContrastColor' ? selectedOptions.interiorSeatScheme?.name : null;

  const changeOptionId = useCallback(
    (id: string, name: string) => {
      setClickedOptionId(id);
      tracking?.clickToChangeCarPart.trigger(category, car, name, seatScheme);
      tracking?.changeCarPartCallback.primeForCallback();
    },
    [car, category, seatScheme, tracking],
  );

  const [selectedExteriorColorCategory, setSelectedExteriorColorCategory] = useState(
    selectedOptionSubCategory,
    // selectedOptionSubCategory as ExclusiveColorTypeFilterValue
  );

  const filteredOptions = useMemo(
    () =>
      isExlusiveExteriorColor && isExteriorColorSelectShown
        ? options.filter(({ subCategory }) => subCategory === selectedExteriorColorCategory)
        : options,
    [isExlusiveExteriorColor, isExteriorColorSelectShown, options, selectedExteriorColorCategory],
  );

  // don't show OverlayItem if there are no or only one option available
  if (options.length <= 1) return null;

  return (
    <OverlayItemWrapper data-category={category}>
      <Headline forwardedAs="h2" variant="order3" spaceStackEnd="s" data-testid="heading-test-id">
        {options.length} {headlineText}
      </Headline>
      {category === 'interiorContrastColor' ? <SeatSchemeSelect /> : null}
      {isExlusiveExteriorColor && isExteriorColorSelectShown ? (
        <ExteriorColorSelect
          selectedCategory={selectedExteriorColorCategory}
          setCategory={setSelectedExteriorColorCategory}
        />
      ) : null}
      <AllOptions role="listbox" aria-label={headlineText}>
        {filteredOptions.map((option, key) => {
          const isHighlighted = option.id === highlightedOptionId;
          const isInConflict = Boolean(option.isInConflict);

          const {
            tile: { url, width, height, mimeType },
          } = option;

          return (
            <BbcWarningPositioningWrapper
              ref={isHighlighted ? ref : undefined}
              key={key}
              role="group"
            >
              <Tile
                category={category === 'rim' ? 'rim' : 'color'}
                isHighlighted={isHighlighted}
                key={key}
                onClick={() =>
                  isInConflict
                    ? setIdOptionWithPopover(option.id)
                    : changeOptionId(option.id, option.name)
                }
                src={url}
                text={formatHTML(option.name)}
                width={width}
                height={height}
                type={mimeType}
                usage="selection"
              >
                <TileImage />
                <TileText />
              </Tile>
              {isInConflict ? (
                <PositionedBuildabilityconflictWarning
                  optionId={option.id}
                  idOptionWithPopover={idOptionWithPopover}
                  setIdOptionWithPopover={setIdOptionWithPopover}
                  handleApplyClick={() => changeOptionId(option.id, option.name)}
                />
              ) : null}
            </BbcWarningPositioningWrapper>
          );
        })}
      </AllOptions>
    </OverlayItemWrapper>
  );
});

const BbcWarningPositioningWrapper = styled.div`
  position: relative;
`;

const PositionedBuildabilityconflictWarning = styled(BuildabilityConflictWarning)`
  /* BBC Warning is positioned absolute in order to be displayed on top of Tile but deliberately has no z-index */
  /* Z-Index would make each BBC Icon be shown on top of BBC modal */
  /* BBC Icon is on top of Tile because its below the Tile in the HTML tree */
  position: absolute;
  top: var(${({ theme }) => theme.responsive.spacing.xs});
  left: 2px;
  pointer-events: none;
`;

const OverlayItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AllOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(${({ theme }) => theme.responsive.spacing.s});

  @media (min-width: ${({ theme }) => theme.breakpoints.l}px) {
    gap: var(${({ theme }) => theme.responsive.spacing.l});
  }
`;

const Headline = styled(Text)`
  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    && {
      font-size: var(${({ theme }) => theme.responsive.typography.order2.fontSize});
    }
  }
`;
