import React, { createContext, Dispatch, ReactNode, useEffect, useReducer, useState } from 'react';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { PubSubFeatureServiceV1 } from '@oneaudi/pubsub-service';
import { I18NServiceV1 } from '@volkswagen-onehub/audi-i18n-service';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { Logger } from '@feature-hub/core';
import { ContentServiceV1 } from '@oneaudi/content-service';
import {
  createOneGraphClientOverrideDeprecation,
  OneGraphProvider,
} from '@oneaudi/onegraph-client';
import { AudiFootnoteReferenceServiceInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { CarsTeaserPubSub, setupCarsTeaserSubscriptions } from '../utils/pubsub-cars-teaser';
import { setupVisaSubscriptions, VisaPubSub } from '../utils/pubsub-visa';
import reducer, { Action, State } from './reducers/app-reducer';
import { useSafeContext } from '../hooks/use-safe-context';
import { fetchCarByWorkingMode } from '../utils/utils';
import { useUpdateEffect } from '../hooks/use-update-effect';

export interface AppPubSub {
  visa?: VisaPubSub;
  carsTeaser?: CarsTeaserPubSub;
}
interface AppServices {
  featureServices: AppFeatureServices;
  pubSub: AppPubSub;
}

export interface AppFeatureServices {
  contentService: ContentServiceV1;
  localeService: GfaLocaleServiceV1;
  pubSubService: PubSubFeatureServiceV1;
  asyncSsrManager?: AsyncSsrManagerV1;
  logger: Logger;
  vueFormatterService: VueFormatterServiceInterfaceV1;
  footnoteReferenceService: AudiFootnoteReferenceServiceInterfaceV3;
  i18nService: I18NServiceV1;
}

export const oneGraphClient = createOneGraphClientOverrideDeprecation({
  clientName: __FEATURE_APP_NAME__,
  clientVersion: __FEATURE_APP_VERSION__,
  endpoint: process.env.ONEGRAPH_ENDPOINT || 'https://onegraph.audi.com/graphql',
});

export const AppStateContext = createContext<State | null>(null);
export const AppDispatchContext = createContext<Dispatch<Action> | null>(null);
export const AppServicesContext = createContext<AppServices | null>(null);

/**
 * The AppProvider setups the application global state and registers to different services to update it (content, pubsub).
 * It also defines contexts that provide AppServices, state and a dispatch function to trigger actions.
 * The provided values can be consumed using the respective hook exported from the current file useAppState, useAppDispatch
 * useFeatureServices & useAppPubSub
 */
export const AppProvider = ({
  children,
  featureServices,
  initialState,
}: {
  children: ReactNode;
  featureServices: AppFeatureServices;
  initialState: State;
}) => {
  const { contentService, pubSubService, localeService, vueFormatterService, logger } =
    featureServices;

  const [state, dispatch] = useReducer(reducer, initialState);
  const { content } = state;

  useUpdateEffect(() => {
    dispatch({ type: 'FETCH_CAR_INIT' });

    async function handleWorkingModeChange() {
      try {
        const carResponse = await fetchCarByWorkingMode(
          content,
          localeService,
          vueFormatterService,
          logger,
        );
        dispatch({
          type: 'FETCH_CAR_SUCCESS',
          payload: carResponse,
        });
      } catch (e) {
        logger.error(e);
        dispatch({ type: 'FETCH_CAR_ERROR', payload: (e as Error).message });
      }
    }

    handleWorkingModeChange();
  }, [content]);

  // setup content service
  useEffect(() => {
    function contentChangeHandler() {
      dispatch({ type: 'SET_CONTENT', payload: contentService.getContent() });
    }

    contentService.onContentChange(contentChangeHandler);

    return () => {
      contentService.removeOnContentChange(contentChangeHandler);
    };
  }, [contentService]);

  // setup pubsub with visa and cars-teaser feature apps
  const [visaPubSub, setVisaPubSub] = useState<VisaPubSub>();
  const [carsTeaserPubSub, setCarsTeaserPubSub] = useState<CarsTeaserPubSub>();

  useEffect(() => {
    setupCarsTeaserSubscriptions(pubSubService).then(setCarsTeaserPubSub);
    setupVisaSubscriptions(pubSubService, state.visa.visaFeatureAppId, dispatch).then(
      setVisaPubSub,
    );
  }, [pubSubService, state.visa.visaFeatureAppId]);

  return (
    <AppServicesContext.Provider
      value={{
        featureServices,
        pubSub: { visa: visaPubSub, carsTeaser: carsTeaserPubSub },
      }}
    >
      <AppStateContext.Provider value={state}>
        <AppDispatchContext.Provider value={dispatch}>
          <OneGraphProvider client={oneGraphClient}>{children}</OneGraphProvider>
        </AppDispatchContext.Provider>
      </AppStateContext.Provider>
    </AppServicesContext.Provider>
  );
};

export function useAppState() {
  return useSafeContext(AppStateContext, 'AppState');
}

export function useAppDispatch() {
  return useSafeContext(AppDispatchContext, 'AppDispatch');
}

export function useAppServices() {
  return useSafeContext(AppServicesContext, 'AppServices');
}

export function useFeatureServices() {
  return useAppServices().featureServices;
}

export function useAppPubSub() {
  return useAppServices().pubSub;
}
