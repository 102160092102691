import React, { FC, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';
import { i18nKeys, useI18n } from '../i18n/i18n';
import { useAppState, useFeatureServices } from '../contexts/app-context';
import { ChosenOption, Props as ChosenOptionProps } from '../components/ChosenOption';
import { OptionTypeExcludingSeatScheme, SEAT_SCHEME_CATEGORY, Option } from '../utils/models';
import { useCorrectExteriorColorTranslationKey } from '../hooks/use-correct-exterior-color-translation';
import { EmissionConsumption } from '../components/EmissionConsumption';
import { AssetAPIConfig } from '../editor.d';
import { ImgLoadState, ImgWithLoadMonitoring } from '../components/ImgWithLoadMonitoring';
// we show three different types of print views:
// 1: one image of VISA-FA
// 2: three images of VISA-FA
// 3: no image (Fallback) of VISA-FA
//  Initially, the print view will be Nr.1 because VISA-FA first renders only two images(front and back) because of the lazy loading.
// As soon as the Slide shows VISA-FA interrupted, other images are rendered. Then Nr.2 will be printed.
interface PrintViewProps {
  printViewIsActive: boolean;
  visaFaRef: RefObject<HTMLDivElement>;
  onAfterPrint: () => void;
}

const PrintViewCategory: FC<
  Pick<Option, 'name' | 'tile'> &
    Pick<ChosenOptionProps, 'onMonitorImgLoad'> & {
      categoryName: OptionTypeExcludingSeatScheme;
    }
> = (props) => {
  const { categoryName } = props;

  const amendedCategory = useCorrectExteriorColorTranslationKey(categoryName);

  return <ChosenOption {...props} category={amendedCategory} />;
};

export const PrintView: FC<PrintViewProps> = ({ printViewIsActive, visaFaRef, onAfterPrint }) => {
  const printViewRef = useRef<HTMLDivElement>(null);

  const {
    car,
    content: {
      cta: { label },
      workingMode,
    },
    enabledCategories,
    tracking,
  } = useAppState();

  const { printViewFootnoteText } = workingMode as AssetAPIConfig;

  const { selectedOptions, emission, consumption } = car;

  const { interiorSeatScheme } = selectedOptions;

  const {
    localeService: { countryCode, language },
  } = useFeatureServices();

  const header = useI18n(i18nKeys.printHeader);
  const selectedOptionsHeader = useI18n(i18nKeys.printChosenOptions);
  const created = useI18n(i18nKeys.printCreatedDate);
  const market = `${countryCode}-${language}`;

  // react-to-print library needs to send CSS (pageStyle) to the iframe for the print view as string.
  // But react-to-print library's technical reason, audi-ui-react library can not be passed.
  // Therefore the global styles are sent to the print view manually.
  const handlePrint = useReactToPrint({
    content: () => printViewRef.current,
    copyStyles: false,
    documentTitle: `${header}`,
    pageStyle: PageStyle,
    removeAfterPrint: true,
    onAfterPrint,
  });

  const loadingImgs = useRef<Record<string, true>>({});
  const [isPrintReady, setIsPrintReady] = useState(false);

  const handleImgLoadMonitoring = (state: ImgLoadState, id: string) => {
    if (state === 'loading') {
      loadingImgs.current[id] = true;
    } else {
      delete loadingImgs.current[id];
    }

    setIsPrintReady(!Object.keys(loadingImgs.current).length);
  };

  useEffect(() => {
    if (!printViewIsActive || !isPrintReady) return;

    // Wait for all images to hopefully paint
    requestAnimationFrame(() => handlePrint());

    tracking?.clickOnTheMainCta.trigger(car, label, true);
  }, [car, handlePrint, label, printViewIsActive, tracking, workingMode, isPrintReady]);

  // All image URLs are retrieved from render images by VISA-FA.
  // Since the images needed for printing are the only first and two interior images, the quiresImgIdx is sorted by certain indexes, 0, 4 and 5.
  // The images are currently hardcoded.We will change them to make the images selectable by the content manager later because the number of images differs by carline.
  const visaFaImages = useMemo(() => {
    if (!printViewIsActive) return null;
    if (!visaFaRef) return null;
    if (!('current' in visaFaRef)) return null;

    if (!visaFaRef.current?.querySelectorAll('img')) return null;

    const requiresImgIdx = [0, 4, 5];
    const imageUrls = [] as string[];
    const visaFaImagesElements = visaFaRef.current.querySelectorAll('img');

    visaFaImagesElements.forEach((el, idx) => {
      const src = el.getAttributeNode('src')?.value;

      if (src && requiresImgIdx.includes(idx)) {
        imageUrls.push(src);
      }
    });

    if (!imageUrls.length) return null;

    return imageUrls.map((url, idx) => (
      <ImgWithLoadMonitoring
        className="VisaImage"
        src={url}
        alt={url}
        key={idx}
        // eslint-disable-next-line react/no-unknown-property
        idx-nr={idx}
        // eslint-disable-next-line react/no-unknown-property
        image-url-length={imageUrls.length}
        onMonitorLoad={(state) => handleImgLoadMonitoring(state, `visa-${idx}`)}
      />
    ));
  }, [printViewIsActive, visaFaRef]);

  const date = new Date();
  const localDate = `${date.toLocaleDateString(market)}`;

  const imageUrlLength = visaFaImages?.length as number;

  const printViewCategories = useMemo(() => {
    return enabledCategories
      .filter(
        (categoryName): categoryName is OptionTypeExcludingSeatScheme =>
          categoryName !== SEAT_SCHEME_CATEGORY,
      )
      .map((category, idx) => {
        const option = selectedOptions[category];
        if (!option) return null;

        const amendedName =
          interiorSeatScheme && category === 'interiorContrastColor'
            ? [interiorSeatScheme.name, option.name].join(': ')
            : option.name;

        return (
          <PrintViewCategory
            categoryName={category}
            {...option}
            name={amendedName}
            key={idx}
            onMonitorImgLoad={(state: ImgLoadState) =>
              handleImgLoadMonitoring(state, `print-view-category-${idx}`)
            }
          />
        );
      });
  }, [enabledCategories, interiorSeatScheme, selectedOptions]);

  if (!printViewIsActive) return null;

  return (
    <StyledPrintView className="StyledPrintView" ref={printViewRef}>
      {/* eslint-disable-next-line react/no-unknown-property */}
      <div className="Wrapper" image-urls={imageUrlLength}>
        {!visaFaImages || imageUrlLength === 1 ? <div className="Headline">{header}</div> : null}
        {visaFaImages ? (
          // eslint-disable-next-line react/no-unknown-property
          <div className="VisaFaImages" image-url-length={imageUrlLength}>
            {/* eslint-disable-next-line react/no-unknown-property */}
            <div className="VisaFaImagesWrapper" image-url-length={imageUrlLength}>
              {imageUrlLength > 1 ? <div className="Headline__threeImgs">{header}</div> : null}
              {visaFaImages}
            </div>
            {visaFaImages ? (
              <div className="StyledHorizontal">
                <EmissionConsumption
                  emission={emission}
                  consumption={consumption}
                  classFb={car.classFb}
                  powerConsumption={car.powerConsumption}
                  fuelConsumptionBatteryDischarged={car.fuelConsumptionBatteryDischarged}
                  classBatteryDischarged={car.classBatteryDischarged}
                  vehicleType={car.vehicleType}
                  textVariant="copy2"
                />
              </div>
            ) : null}
          </div>
        ) : null}
        {/* eslint-disable-next-line react/no-unknown-property */}
        <p className="ChosenOptionsHeadline" image-urls={imageUrlLength}>
          {selectedOptionsHeader}
        </p>
        <table className="ChosenOptionsTable">
          <tbody className="ChosenOptionsBody">{printViewCategories}</tbody>
        </table>
        <div className="Footer">
          <div className="FooterItem">
            {visaFaImages ? null : (
              <EmissionConsumption
                emission={emission}
                consumption={consumption}
                classFb={car.classFb}
                powerConsumption={car.powerConsumption}
                fuelConsumptionBatteryDischarged={car.fuelConsumptionBatteryDischarged}
                classBatteryDischarged={car.classBatteryDischarged}
                vehicleType={car.vehicleType}
                textVariant="copy2"
              />
            )}
          </div>
          <div className="FooterItem">
            {created} {localDate}
          </div>
        </div>
      </div>
      {printViewFootnoteText && printViewFootnoteText.length ? (
        <pre className="FootnoteText">&#42;&#32;{printViewFootnoteText}</pre>
      ) : null}
    </StyledPrintView>
  );
};

const StyledPrintView = styled.div<{ ref: React.ForwardedRef<HTMLDivElement> }>`
  display: none;
  @media print {
    display: block;
  }
`;

// react-to-print library needs to send CSS (pageStyle) to the iframe for the print view as string.
// The print view’s rendered DOM tree is cloned into an iframe and only the inside the print view component are visible for the print.
// audi-ui-react library can not be usable bevause it is page level styling.
// Therefore the global styles are sent to the print view manually.
// GrobalStyles URL: https://github.com/audi/audi-ui-react/blob/main/packages/audi-ui-react/src/components/GlobalStyles/GlobalStyles.tsx
export const GlobalStyles = `
  color: #333333;
    font-weight: 400 700;
    font-family: 'Audi Type Variable';
    font-display: swap;
    font-style: normal;
    font-stretch: 100% 130%;
`;

export const FontFace = `
    font-weight: 400 700;
    font-family: 'Audi Type Variable';
    font-style: normal;
    font-stretch: 100% 130%;
    src: url('https://assets.audi.com/audi-fonts/1/AudiTypeVF.woff2')
      format('woff2-variations');
    font-display: swap;
`;

export const PageStyle = `
      @page {
        size: A4 portrait;
      }

      @font-face {
        ${FontFace}
      }

      @media print {
        :root{
          --color-base-gray-30:#333333;
          --color-base-gray-40:#999999;
          --color-base-gray-60:#666666;
          --color-ui-tertiary:#b3b3b3;
        }

        * {
          ${GlobalStyles}
          margin: 0;
          padding: 0;
        }

        body {
          ${GlobalStyles}
        }

        .StyledPrintView {
          display: block;
        }

        .Wrapper, .Wrapper[image-urls="1"], .Wrapper[image-urls="3"] {
          align-content: center;
          display: grid;
          break-inside: avoid-page;
          break-after: page;
        }

        .Wrapper[image-urls="1"], .Wrapper[image-urls="3"] {
          grid-template-rows: 1fr 5fr 1fr 5fr 1fr;
        }

        .Wrapper  {
          grid-template-rows: 2fr 2fr 2fr 7fr 5fr;
        }

        .Headline, .Headline__threeImgs {
          margin: 0;
          width: 100%;
          width: -moz-available;
          width: -webkit-fill-available;
          font-size: 36px;
          font-stretch: 130%;
          font-style: normal;
          font-weight: 400;
          letter-spacing: -0.66px;
          line-height: 40px;
        }

        .Headline__threeImgs {
          grid-column: 1/4;
          grid-row: 1/3;
        }

        .VisaFaImages[image-url-length="1"], .VisaFaImages[image-url-length="3"] {
          width: 100vw;
          width: -moz-available;
          width: -webkit-fill-available;
        }

        .VisaFaImages[image-url-length="1"] {
          grid-row: 2/3;
        }

        .VisaFaImages[image-url-length="3"] {
          grid-row: 1/3;
        }

        .VisaFaImagesWrapper[image-url-length="1"], .VisaFaImagesWrapper[image-url-length="3"] {
          align-content: first;
          display: grid;
          margin: 0;
        }

        .VisaFaImagesWrapper[image-url-length="1"] {
          grid-template-columns: 1fr;
          grid-template-rows: 1fr;
          height: 350px;
        }

        .VisaFaImagesWrapper[image-url-length="3"] {
          align-content: first;
          display: grid;
          margin: 0;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
          height: 440px;
        }

        .VisaImage[idx-nr="0"][image-url-length="1"] {
          grid-column: 1/6;
          grid-row: 1/7;
          height: 350px;
          width: 100%;
          width: -moz-available;
          width: -webkit-fill-available;
          vertical-align: center;
          object-fit: cover;
        }

        .VisaImage[idx-nr="0"][image-url-length="3"] {
          grid-row: 3/7;
          grid-column: 1/4;
          width: 100%;
          width: -moz-available;
          width: -webkit-fill-available;
          height: 220px;
          position: 55% 100%;
          vertical-align: center;
          object-fit: cover;
        }

        .VisaImage[idx-nr="1"][image-url-length="3"] {
          grid-row: 1/4;
          grid-column: 4/6;
          width: 100%;
          width: -moz-available;
          width: -webkit-fill-available;
          height: 220px;
          position: 30% 100%;
          vertical-align: center;
          object-fit: cover;
        }

        .VisaImage[idx-nr="2"][image-url-length="3"] {
          grid-row: 4/7;
          grid-column: 4/6;
          width: 100%;
          width: -moz-available;
          width: -webkit-fill-available;
          height: 220px;
          position: 30% 100%;
          vertical-align: center;
          object-fit: cover;
        }

        .ChosenOptionsHeadline, .ChosenOptionsHeadline[image-uls="0"] {
          align-self: end;
          grid-row: 3/4;
          font-weight: 700;
          padding: 0 0 28px 0;
        }

        .ChosenOptionsHeadline[image-uls="0"] {
          margin-top: 60px;
        }

        .ChosenOptionsHeadline {
          margin-top: 30px;
        }

        .ChosenOptionsTable {
          grid-row: 4/5;
          table-layout: fixed;
          width: -moz-available;
          width: -webkit-fill-available;
          width: 100%;
        }

        .Footer {
          -webkit-align-items: flex-end;
          -webkit-align-self: flex-end;
          align-items: flex-end;
          align-self: end;
          display: flex;
          grid-row: 5/6;
          justify-content: space-between;
          width: 100%;
          width: -moz-available;
          width: -webkit-fill-available;
        }

        .FooterItem, .EmissionConsumption {
          color: var(--color-ui-tertiary);
          font-size: 12px;
          line-height: 16px;
          align-self: flex-end;
        }

        .EmissionConsumption {
          margin: 0;
          padding: 0;
        }

        .StyledHorizontal {
          margin-top: 20px;
          display: flex;
          gap: 8px;
        }

        .FootnoteText {
          color: var(--color-ui-tertiary);
          font-size: 12px;
          line-height: 16px;
          white-space: pre-wrap;
        }

        .ChosenOptionRow {
          height: 94px;
        }

        .CategoryContainer {
          border-top: 2px solid var(--color-base-gray-30);
          font-weight: normal;
          margin-right: 8px;
          padding-top: 8px;
          text-align: left;
          vertical-align: top;
          width: 33%;
        }

        .OptionTextContainer {
          border-top: 1px solid var(--color-base-gray-40);
          margin-right: 8px;
          padding-top: 8px;
          vertical-align: top;
          width: 50%;
        }

        .OptionText {
          color: var(--color-base-gray-60);
          text-align: left;
          hyphens: auto;
        }

        .OptionTile {
          border-top: 1px solid var(--color-base-gray-40);
          text-align: center;
          vertical-align: center;
          width: auto;
        }

        .TileImage__printView {
          object-fit: cover;
          width: 80px;
          height: 80px;
          padding: 6px;
          box-sizing: border-box;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          margin-top: 6px;
        }

        /* some styling are not supported by Safari. Extra stylings for Safari is added below @media not all and (min-resolution:.001dpcm)...*/
        /* https://stackoverflow.com/questions/16348489/is-there-a-css-hack-for-safari-only-not-chrome */
        @media not all and (min-resolution:.001dpcm) {
          @supports (-webkit-appearance:none) {
            .Wrapper[image-urls="1"], .Wrapper[image-urls="3"] {
              display: inline-table;
            }

            .Wrapper {
              grid-template-rows: 2fr 2fr 2fr 7fr 4fr;
            }


            .ChosenOptionsHeadline, .ChosenOptionsHeadline[image-uls="0"] {
              padding: 30px 0 28px 0;
            }

            .Footer {
              margin-top: 50px;
            }

            .FootnoteText {
              display: inline-table;
            }
          }
        }
      }
    `;
