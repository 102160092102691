import { PubSubFeatureServiceV1 } from '@oneaudi/pubsub-service';

// @todo: Why?
// eslint-disable-next-line no-shadow
export enum CarsTeaserEvents {
  // cars-teaser incoming events (events which cars-teaser is subscribed to)
  IN_CONFIG_CHANGE = 'visualizer.config-change',
}

export const CARS_TEASER_FEATURE_APP_ID = 'audi-feature-app-cars-teaser';

export interface VisualizerConfig {
  carlineId: string;
  selectedExteriorColor: string;
  selectedRim: string;
  /**
   * Determines if this is the initial configuration loaded in the visualizer.
   * User interactions with the feature app (select color, rim, etc) are not considered 'initial'
   */
  initial: boolean;
}

export type Publisher<T> = {
  publish: (event: T) => void;
};

export interface CarsTeaserPubSub {
  configChange: Publisher<VisualizerConfig>;
}

export async function setupCarsTeaserSubscriptions(
  pubSubService: PubSubFeatureServiceV1,
): Promise<CarsTeaserPubSub> {
  const configChange = await pubSubService.awaitSubscription<VisualizerConfig>(
    CARS_TEASER_FEATURE_APP_ID,
    CarsTeaserEvents.IN_CONFIG_CHANGE,
  );

  return { configChange };
}
