import { Button } from '@audi/audi-ui-react';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { configuredCarInputToPrString } from '@audi/onegraph-utils';
import { ClickToAction } from '../editor.d';
import { TrackingInstances } from '../tracking/main';
import { Car } from '../utils/models';
import { substitutePlaceholders } from '../utils/utils';
import { i18nKeys, useI18n } from '../i18n/i18n';
import { useAppState } from '../contexts/app-context';

type StandardProps = {
  car: Car;
  tracking?: TrackingInstances;
};

type PrintProps = {
  onClick: () => void;
};

type ContextProps = ClickToAction & { loading: boolean };

export type CtaButtonProps = (StandardProps | PrintProps) & { isPrint?: boolean };

export const CtaButton: FC<CtaButtonProps> = ({ isPrint = false, ...restProps }) => {
  const {
    content: { cta },
    loading,
  } = useAppState();
  const i18nLabel = useI18n(i18nKeys.cta);
  const { url, newTab } = cta;

  const commonProps = { label: cta.label || i18nLabel, loading, url, newTab };

  if (isPrint) {
    return <PrintCtaButton {...commonProps} {...(restProps as PrintProps)} />;
  }

  return <StandardCtaButton {...commonProps} {...(restProps as StandardProps)} />;
};

const StandardCtaButton: FC<ContextProps & StandardProps> = ({
  label,
  url,
  newTab,
  car,
  loading,
  tracking,
  ...restProps
}) => {
  const ctaUrl = useMemo(() => {
    if (!url) return null;

    return updateUrlWithPlaceholder(url, car);
  }, [car, url]);

  return label && ctaUrl ? (
    <StyledCtaButton
      {...restProps}
      disabled={loading}
      href={ctaUrl}
      variant="primary"
      newWindow={newTab}
      onClick={() => tracking?.clickOnTheMainCta.trigger(car, label, false, ctaUrl)}
    >
      {label}
    </StyledCtaButton>
  ) : null;
};

const PrintCtaButton: FC<ContextProps & PrintProps> = ({
  label,
  loading,
  onClick,
  ...restProps
}) =>
  label ? (
    <StyledCtaButton {...restProps} disabled={loading} variant="primary" onClick={onClick}>
      {label}
    </StyledCtaButton>
  ) : null;

// To make it look like CTA of VISA FA
const StyledCtaButton = styled(Button)`
  &&,
  span {
    font-size: 12px;
    line-height: 20px;
  }
`;
function updateUrlWithPlaceholder(url: string, car: Car) {
  let updatedUrl = url;
  const isEntryLink = /nemo\/entry\.html/.test(url);
  const hasPrStringPlaceholder = /{prString}$/.test(url);
  if (isEntryLink && !hasPrStringPlaceholder) updatedUrl = `${url}?pr={prString}`;

  return car.id
    ? substitutePlaceholders(updatedUrl, [
        { placeholder: '{prString}', value: configuredCarInputToPrString(car.id) },
      ])
    : url;
}
