/* eslint-disable no-shadow */

import type {
  Camera as _Camera,
  LoadingChangeEvent as _LoadingChangeEvent,
  ViewChangeEvent as _ViewChangeEvent,
  VisaConfigChangePayload,
  VisaViewChangePayload,
} from '@volkswagen-onehub/audi-feature-service-automotive-visualization';

import type {
  AuthorConfig as _AuthorConfig,
  Ave3dStreamConfig as _Ave3dStreamConfig,
  EnvironmentsDisplayed as _EnvironmentsDisplayed,
} from '@oneaudi/fa-automotive-visualization/dist/index';

// copied from node_modules/@oneaudi/fa-automotive-visualization/dist/index.d.ts in order to import
export enum Region {
  EU = 'eu-west-1',
  EU_PRELIVE = 'eu-west-1 (prelive)',
  AP = 'ap-southeast-1',
  US_EAST = 'us-east-1',
  US_WEST = 'us-west-2',
}

// copied from node_modules/@oneaudi/fa-automotive-visualization/dist/index.d.ts in order to import
export enum FullscreenMode {
  NONE = 'none',
  FULLSCREEN = 'Fullscreen',
  THEATER_VIEW = 'Theater view',
}

export type ResolutionRatio = '16x5' | '16x9' | '16x5tr' | '8x5' | '16x9tr' | 'default';

export type Camera = _Camera;
export type LoadingChangeEvent = _LoadingChangeEvent;
export type ViewChangeEvent = _ViewChangeEvent;

export type AuthorConfig = _AuthorConfig & {
  // the visa app typing use a simple string here instead
  carousel: { imageAspectRatio: ResolutionRatio };
};
export type Ave3dStreamConfig = _Ave3dStreamConfig;
export type EnvironmentsDisplayed = _EnvironmentsDisplayed;

interface VisaViewChangeEventOut extends ViewChangeEvent {
  type: 'view-change';
}

interface VisaLoadingChangeEventOut extends LoadingChangeEvent {
  type: 'loading-change';
}

interface VisaViewChangeEventIn extends VisaViewChangePayload {
  type: 'view-change';
}
interface VisaConfigChangeEventIn extends VisaConfigChangePayload {
  type: 'config-change';
}

export type VisaEventOutgoing = VisaViewChangeEventOut | VisaLoadingChangeEventOut;

export type VisaEventIncoming = VisaViewChangeEventIn | VisaConfigChangeEventIn;

export type VisaEvent = VisaEventOutgoing | VisaEventIncoming;
