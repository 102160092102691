import type { DataLayerEvent, ProductInformation } from './reference';
import { createCarPartsPayload, TrackingEvent, transformPayload } from '../../common';
import { Car } from '../../../../utils/models';
import { FEATURE_APP_NAME } from '../../../../utils/utils';

function createPayload(car: Car) {
  const { carlineId = '', carlineName: longName = '', price = '0', selectedOptions } = car;

  const dataLayerEvent: DataLayerEvent = {
    eventInfo: {
      eventAction: 'callback',
      eventName: 'visualizer - callback successful',
    },
    attributes: {
      componentName: FEATURE_APP_NAME,
      label: '',
      targetURL: '',
      clickID: '',
      elementName: '',
      value: '',
      pos: '',
    },
  };

  const productInformation: ProductInformation = {
    products: {
      configuredVehicle: [
        {
          productInfo: {
            productID: carlineId,
            productName: longName,
            manufacturer: 'Audi',
          },
          price: {
            totalPrice: price,
          },
          category: {
            primaryCategory: '',
            subCategory1: carlineId,
            productType: 'configured vehicle', // TODO different bet sample and tablelist
          },
          carParts: createCarPartsPayload(selectedOptions),
        },
      ],
    },
  };

  return transformPayload(dataLayerEvent, productInformation);
}

export type Payload = ReturnType<typeof createPayload>;

export class Event extends TrackingEvent<Payload> {
  private _primeForCallback = false;

  primeForCallback() {
    this._primeForCallback = true;
  }

  trigger(car: Car) {
    if (!this._primeForCallback) return;
    this._primeForCallback = false;

    this._trigger(createPayload(car));
  }
}
