import React, { FC, ReactNode, createContext, useContext } from 'react';
import styled, { css } from 'styled-components';
import { Text } from '@audi/audi-ui-react';
import { breakpoints, SizesCategory, imageSizes, Usage } from './sizes';
import { addHeight } from './TileUtils';

export interface TileProps {
  onClick?: () => void;
  src: string;
  text: string;
  /**
   * For the implementation on the Summary or the Overlay, the tiles have different CSS styles.
   */
  usage: Usage;
  /**
   * For different Categories, the tiles have different CSS styles.
   */
  category: SizesCategory;

  height?: number;
  type?: string;
  width?: number;
  /**
   * Will outline the tile in a darker shade. Can be applied for selected tiles.
   */
  isHighlighted?: boolean;
  /**
   * Truncates the TileText after three lines.
   */
  isTruncated?: boolean;
  children?: ReactNode;
}

export interface SizedTileImageProps {
  src: string;
  /**
   * For the implementation on the Summary or the Overlay, the tiles have different CSS styles.
   */
  usage: Usage;
  /**
   * For different Categories, the tiles images have different CSS styles.
   */
  category: SizesCategory;

  height?: number;
  type?: string;
  width?: number;
}

// The content of the tile (TileText and TileImage) are passed in as children wherever the Tile component is used.
// This way the order of the Text and Image can be chosen individually. Or only one of the two is shown.
// Yet, all props are provided via context of the Tile itself and no props are given neither to the Text nor to the image itself.

export const TileContext = createContext(null as unknown as TileProps);

export const TileText: FC = () => {
  const { text, isTruncated = false } = useContext(TileContext);

  return (
    <Text variant="copy2" data-testid="tile-description-text">
      {isTruncated ? <TruncatedText>{text}</TruncatedText> : text}
    </Text>
  );
};

export const TileImage: FC = () => {
  const { src, usage, category, text, type } = useContext(TileContext);
  const [imgWidth] = imageSizes.specifications[category][usage].xs;

  return (
    <SizedTileImage
      src={addHeight(src, 80)}
      srcSet={`${addHeight(src, 80)} 80w, ${addHeight(src, 160)} 160w, ${addHeight(src, 320)} 320w`}
      sizes="80px"
      usage={usage}
      category={category}
      alt={text}
      data-testid="tile-image"
      width={imgWidth}
      height={imgWidth}
      type={type}
      loading="lazy"
    />
  );
};

export const Tile: FC<TileProps> = (props) => {
  // object destructuring so that all props except the children are provided via the context
  const { children, ...rest } = props;
  const { onClick, usage, isHighlighted = false } = rest;

  return (
    <TileContext.Provider value={rest}>
      <TileWrapper
        aria-selected={isHighlighted}
        data-testid="tile"
        isHighlighted={isHighlighted}
        onClick={onClick}
        role="option"
        usage={usage}
      >
        {children}
      </TileWrapper>
    </TileContext.Provider>
  );
};

const TileWrapper = styled.button<{
  isHighlighted: boolean;
  usage: Usage;
}>`
  width: 100%;
  appearance: none;
  background: none;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  cursor: pointer;
  border-color: var(
    ${({ theme, isHighlighted }) => theme.colors.base.grey[isHighlighted ? 100 : 20]}
  );
  padding: var(${({ theme }) => theme.responsive.spacing.xs});
  display: flex;
  flex: 1;
  gap: var(${({ theme }) => theme.responsive.spacing.s});
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
`;

export const SizedTileImage = styled.img<SizedTileImageProps>`
  object-fit: cover;
  box-sizing: border-box;
  width: var(--tile-width);
  height: var(--tile-width);
  flex: 0 0 var(--tile-width);
  padding: var(--tile-padding);
  ${({ category, usage }) => generateBreakpointStyles(category, usage)};
`;

const generateBreakpointStyles = (category: SizesCategory, usage: Usage) => css`
  ${({ theme }) =>
    breakpoints.map((breakpoint) => {
      // padding is only defined for colors in the overview
      const [width, padding = 0] = imageSizes.specifications[category][usage][breakpoint];
      // @media print is for exclusive visualizer
      return `@media (min-width: ${theme.breakpoints[breakpoint]}px) {
            --tile-width: ${width}px;
            --tile-padding: ${padding}px;
          }
          `;
    })}
`;

const TruncatedText = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;
