import { Select } from '@audi/audi-ui-react';
import React, { FC, useCallback, useMemo } from 'react';
import { i18nKeys, useI18n } from '../i18n/i18n';
import { ExclusiveColorTypeFilterValue } from './OverlayItem';
import { Option } from '../utils/models';
import { useAppState } from '../contexts/app-context';

interface ExteriorColorSelectProps {
  selectedCategory: string | undefined;
  setCategory: (category: string) => void;
}

interface SelectOptionProps {
  category: string;
}

const SelectOption = ({ category }: SelectOptionProps) => (
  <option value={category}>
    {useI18n(i18nKeys.exclusiveColorSubCategory[category as ExclusiveColorTypeFilterValue])}
  </option>
);

export const ExteriorColorSelect: FC<ExteriorColorSelectProps> = ({
  selectedCategory,
  setCategory,
}) => {
  const label = useI18n(i18nKeys.exteriorColorCategory);

  const {
    car: { availableOptions },
  } = useAppState();

  // TODO revert hiding options that are in conflict until new conflict ui is coming up..
  // (https://github.com/oneaudi/fa-visualizer/pull/22/commits/b4975970186bd688c2f468c03179bda735d57e83)
  const options = useMemo(
    () => availableOptions?.exteriorColor?.filter(({ isInConflict }) => !isInConflict) || [],
    [availableOptions],
  );

  const nonRedundantExteriorColorCategories = useMemo(
    () => [
      ...new Set(
        options
          .filter((option): option is Option & Required<Pick<Option, 'subCategory'>> =>
            Boolean(option.subCategory),
          )
          .map(({ subCategory }) => subCategory),
      ),
    ],
    [options],
  );

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      event.preventDefault();

      const {
        target: { value },
      } = event;

      if (!value) return;
      // guardes against "windows-bug" so that the select label cannot be selected and break the component
      if (!nonRedundantExteriorColorCategories.includes(value)) return;

      setCategory(value);
    },
    [nonRedundantExteriorColorCategories, setCategory],
  );

  if (!selectedCategory) return null;

  return (
    <Select
      inputId="exteriorColorSelection"
      label={label}
      onChange={onChange}
      required
      spaceStackEnd="l"
      spaceStackStart="l"
      value={selectedCategory}
    >
      {nonRedundantExteriorColorCategories.map((category, index) =>
        category in i18nKeys.exclusiveColorSubCategory ? (
          <SelectOption category={category} key={index} />
        ) : null,
      )}
    </Select>
  );
};
