import { audiLightTheme, BreakpointLabel } from '@audi/audi-ui-react';

export const breakpoints = Object.keys(audiLightTheme.breakpoints) as unknown as BreakpointLabel[];

// [width, height, (optional padding) for each icon in the Tile.tsx component in px]
export const imageSizes = {
  specifications: {
    color: {
      overview: {
        xs: [48, 0],
        s: [80, 10],
        m: [80, 10],
        l: [64, 8],
        xl: [80, 10],
        xxl: [80, 10],
      },
      selection: {
        xs: [80, 10],
        s: [80, 10],
        m: [80, 10],
        l: [64, 8],
        xl: [80, 10],
        xxl: [80, 10],
      },
    },
    rim: {
      overview: {
        xs: [64],
        s: [80],
        m: [80],
        l: [64],
        xl: [80],
        xxl: [80],
      },
      selection: {
        xs: [80],
        s: [80],
        m: [80],
        l: [64],
        xl: [80],
        xxl: [80],
      },
    },
  },
} as const;

type Specifications = typeof imageSizes.specifications;
export type SizesCategory = keyof Specifications;
export type Usage = keyof Specifications[SizesCategory];

// aspect ratios of color selection tile in Tile.tsx
// [width, height]
export const aspectRatios = {
  xs: [1, 1],
  s: [5, 4],
  m: [5, 4],
  l: [5, 4],
  xl: [5, 4],
  xxl: [5, 4],
} as const;

// on desktop the overlay should scroll further up than its initial position
// these are the offset values in px
export const overlayOffset = {
  xs: 0,
  s: 0,
  m: 0,
  l: 108,
  xl: 124,
  xxl: 132,
} as const;
