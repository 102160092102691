import { FeatureAppLoader } from '@feature-hub/react';
import React, { FC, forwardRef, useMemo } from 'react';

import styled, { css } from 'styled-components';
import { Loader } from '@audi/audi-ui-react';
import { AuthorConfig, FullscreenMode, Region, ResolutionRatio } from '../visa.d';
import { useAppState } from '../contexts/app-context';
import { getEnvironmentDisplayed } from '../utils/utils';

// max z-index value used by VISA action's panel on mobile!
const VISA_MAX_Z_INDEX = 40;

const StyledVisaFeatureAppWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  min-height: 220px;
  min-width: 0;
`;

const overlayOpacity0 = css`
  opacity: 0;
`;
const overlayOpacity1 = css`
  opacity: 1;
`;

const StyledLoaderOverlay = styled.div<{ show: boolean }>`
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 300ms ease-out;
  width: 100%;
  z-index: ${VISA_MAX_Z_INDEX};
  ${({ show }) => (show ? overlayOpacity1 : overlayOpacity0)};
`;

const VisaFeatureAppLoader: FC<{
  baseUrl: string;
  bundleUrl: string;
  config: AuthorConfig;
  featureAppId: string;
}> = ({ baseUrl, bundleUrl, config, featureAppId }) => (
  <FeatureAppLoader
    featureAppId={featureAppId}
    // Use major version from FAM
    src={
      bundleUrl?.length
        ? bundleUrl
        : 'https://featureapps.audi.com/fa-automotive-visualization/1/app.js'
    }
    // baseUrl is needed so visa can load bundle chunks dynamically
    baseUrl={
      baseUrl?.length ? baseUrl : 'https://featureapps.audi.com/fa-automotive-visualization/1'
    }
    config={config}
  />
);

/**
 * VisaFeatureAppLoader needs to be rendered only once!
 * Changing the config after a Feature App has been loaded, will have no effect!
 * https://feature-hub.io/docs/guides/integrating-the-feature-hub#feature-app-configs
 *
 * Changes on the Visualizer (like color, rim selection) are sent to Visa through feature-services (pubsub)
 * */
const MemoizedVisaFeatureAppLoader = React.memo(
  VisaFeatureAppLoader,
  // instruct React.memo to never re-render this component
  () => true,
);

export const VisaFeatureApp = forwardRef<HTMLDivElement>((props, ref) => {
  const {
    car,
    loading,
    isShowingExclusiveOptions,
    content: { market: brand, visaOptions },
    visa: { visaFeatureAppId },
  } = useAppState();
  const {
    baseUrl,
    bundleUrl,
    region,
    streaming,
    imageAspectRatio,
    isConvertibleRoofClosed,
    showAnimationSpotsButton,
  } = visaOptions || {};

  /*
    AVP (=> prerendered) images are ONLY used in the exclusive visualizer, thus we don't
    need a face editor option for this anymore!

    To relieably switch avp images off, it must be set in two places of the config:
    - config.isStage
    - config.carousel.useAvpImages
  */
  const config = useMemo<AuthorConfig>(
    () => ({
      button3dModeVisible: streaming,
      // @todo: Check whether this is the right option:
      fullscreenMode: FullscreenMode.FULLSCREEN,
      devMode: false,
      exclusiveOptions: car.exclusiveOptions || '',
      initAveString: car.ave,
      initCarlineName: car.carlineName,
      isStage: isShowingExclusiveOptions,
      showLoader: true,
      isConvertibleRoofClosed,
      // SPACEIN-656 the images for the turntable are no longer produced so this functionality is switched off for good
      showTurntable: false,
      ave3dStream: {
        enabled: streaming,
        environmentsDisplayed: getEnvironmentDisplayed(car),
        region: region as Region,
        showAnimationSpotsButton,
        service: isShowingExclusiveOptions ? 'ae-visualizer' : 'web-visualizer',
      },
      carousel: {
        brand,
        imageAspectRatio: imageAspectRatio as ResolutionRatio,
        useAvpImages: isShowingExclusiveOptions,
        initImage: 'rims',
      },
      stageOptions: {
        showBackground: false,
        showCarline: false,
      },
    }),
    [
      brand,
      car,
      isShowingExclusiveOptions,
      region,
      streaming,
      imageAspectRatio,
      isConvertibleRoofClosed,
      showAnimationSpotsButton,
    ],
  );

  return (
    <StyledVisaFeatureAppWrapper {...props} ref={ref}>
      <MemoizedVisaFeatureAppLoader
        baseUrl={baseUrl}
        bundleUrl={bundleUrl}
        config={config}
        featureAppId={visaFeatureAppId}
      />
      <StyledLoaderOverlay show={loading}>{loading ? <Loader /> : null}</StyledLoaderOverlay>
    </StyledVisaFeatureAppWrapper>
  );
});
