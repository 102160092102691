import type { DataLayerEvent } from './reference';
import { TrackingEvent, transformPayload } from '../../common';
import { FEATURE_APP_NAME } from '../../../../utils/utils';

function createPayload(label: string) {
  const dataLayerEvent: DataLayerEvent = {
    eventInfo: {
      eventAction: 'content',
      eventName: `visualizer -  go back to overview`,
    },
    attributes: {
      componentName: FEATURE_APP_NAME,
      label,
      targetURL: '',
      clickID: 'textButton__backButton',
      elementName: 'text link',
      value: '',
      pos: '',
    },
  };

  return transformPayload(dataLayerEvent);
}

export type Payload = ReturnType<typeof createPayload>;

export class Event extends TrackingEvent<Payload> {
  trigger(label: string) {
    this._trigger(createPayload(label));
  }
}
