import React from 'react';
import { Text, TypographyVariantLabels } from '@audi/audi-ui-react';
import {
  cleanupEmissionConsumptionMarkup,
  renderTextWithFootnotesReferences,
} from '@oneaudi/feature-app-utils';
import styled from 'styled-components';
import { VehicleType } from '../../../@types/onegraph';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledFootnoteText = styled(Text)`
  sup {
    font-size: max(1em, 16px);
    vertical-align: initial;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    font-size: var(${({ theme }) => theme.responsive.typography.copy1.fontSize});
  }
`;

interface Props {
  vehicleType: VehicleType;
  emission: string;
  consumption: string;
  classFb: string;
  powerConsumption: string;
  fuelConsumptionBatteryDischarged: string;
  classBatteryDischarged: string;
  textVariant: TypographyVariantLabels;
}

export const EmissionConsumption = ({
  vehicleType,
  emission,
  consumption,
  classFb,
  powerConsumption,
  fuelConsumptionBatteryDischarged,
  classBatteryDischarged,
  textVariant,
  ...restProps
}: Props) => {
  return (
    <StyledWrapper {...restProps}>
      {vehicleType && vehicleType !== VehicleType.Bev && (
        <StyledFootnoteText
          variant={textVariant}
          data-testid="emission"
          className="EmissionConsumption"
        >
          {renderTextWithFootnotesReferences(cleanupEmissionConsumptionMarkup(consumption ?? ''))}
        </StyledFootnoteText>
      )}
      {vehicleType && vehicleType !== VehicleType.Icev && (
        <StyledFootnoteText
          variant={textVariant}
          data-testid="consumption"
          className="EmissionConsumption"
        >
          {renderTextWithFootnotesReferences(
            cleanupEmissionConsumptionMarkup(powerConsumption ?? ''),
          )}
        </StyledFootnoteText>
      )}
      <StyledFootnoteText
        variant={textVariant}
        data-testid="emission"
        className="EmissionConsumption"
      >
        {renderTextWithFootnotesReferences(cleanupEmissionConsumptionMarkup(emission ?? ''))}
      </StyledFootnoteText>
      <StyledFootnoteText
        variant={textVariant}
        data-testid="emission"
        className="EmissionConsumption"
      >
        {renderTextWithFootnotesReferences(cleanupEmissionConsumptionMarkup(classFb ?? ''))}
      </StyledFootnoteText>
      {vehicleType && vehicleType === VehicleType.Phev && (
        <>
          <StyledFootnoteText
            variant={textVariant}
            data-testid="emission"
            className="EmissionConsumption"
          >
            {renderTextWithFootnotesReferences(
              cleanupEmissionConsumptionMarkup(fuelConsumptionBatteryDischarged ?? ''),
            )}
          </StyledFootnoteText>

          <StyledFootnoteText
            variant={textVariant}
            data-testid="emission"
            className="EmissionConsumption"
          >
            {renderTextWithFootnotesReferences(
              cleanupEmissionConsumptionMarkup(classBatteryDischarged ?? ''),
            )}
          </StyledFootnoteText>
        </>
      )}
    </StyledWrapper>
  );
};
