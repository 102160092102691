import { renderTextWithFootnotesReferences } from '@oneaudi/feature-app-utils';
import React, { FC } from 'react';
import { Layout, Text } from '@audi/audi-ui-react';
import styled from 'styled-components';

export const DEFAULT_FOOTNOTE_REFERENCE_PLACEHOLDER = '{ft_nemo-fn_price_detail}';

const StyledPriceLayout = styled(Layout)`
  flex-shrink: 0;
  align-self: flex-start;
  width: fit-content;
  margin: 0;

  sup {
    font-size: max(1em, 16px);
    vertical-align: initial;
  }
`;

interface Props {
  formattedPrice: string;
  footnoteReferencePlaceholder?: string;
}

export const Price: FC<Props> = ({
  formattedPrice,
  footnoteReferencePlaceholder = DEFAULT_FOOTNOTE_REFERENCE_PLACEHOLDER,
}) => {
  return (
    <StyledPriceLayout direction="column" align={{ m: 'end' }}>
      <TextWithFootnoteReference forwardedAs="h3" variant="order3" data-testid="price">
        {renderTextWithFootnotesReferences(`${formattedPrice}${footnoteReferencePlaceholder}`)}
      </TextWithFootnoteReference>
    </StyledPriceLayout>
  );
};

const TextWithFootnoteReference = styled(Text)`
  sup {
    font-size: max(1em, 16px);
    vertical-align: initial;
  }
`;
