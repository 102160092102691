// create a class function to make

import { Logger } from '@feature-hub/core';
import { TrackingDataV2, TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import {
  OptionType,
  OptionTypeExcludingSeatScheme,
  SEAT_SCHEME_CATEGORY,
  SelectedOptions,
  Option,
} from '../../utils/models';
import { FEATURE_APP_NAME } from '../../utils/utils';
import { AdditionalObjects } from './core/feature-app-ready/reference';
import { ProductInformation } from './module/change-car-part-callback/reference';

export const sameOrigin = (url: string) =>
  typeof window !== 'undefined' ? window.location.hostname === new URL(url).hostname : false;

// transforming the payload with the required object format
export const transformPayload = (
  dataLayerEvent: TrackingDataV2['event'],
  additionalObjects?: TrackingDataV2['componentUpdate'],
): TrackingDataV2 => ({
  event: dataLayerEvent,
  componentUpdate: additionalObjects,
});
export class TrackingEvent<Payload extends TrackingDataV2> {
  protected _logger: Logger;

  protected _trackingService: TrackingServiceV2;

  constructor(trackingService: TrackingServiceV2, logger: Logger) {
    this._logger = logger;
    this._trackingService = trackingService;
  }

  protected _log(payload: Payload) {
    this._logger.info('tracking:', payload.event?.eventInfo?.eventName, payload);
  }

  protected _registerComponent() {
    // this makes sure the feature app is still registered as a tracking component,
    // as the tracking service sometimes (e.g. after a url change when opening a modal)
    // recalculates the registered components and ignores events of unregistered components.
    this._trackingService.featureAppName = FEATURE_APP_NAME;
  }

  protected _trigger(payload: Payload) {
    this._log(payload);
    this._registerComponent();
    this._trackingService.track(payload);
  }
}

function filterForTrackableOptionType(type: OptionType): type is OptionTypeExcludingSeatScheme {
  return type !== SEAT_SCHEME_CATEGORY;
}

function filterForTrackableOption(option?: Option): option is Option {
  return Boolean(option);
}

type FeatureAppReadyCarPart =
  AdditionalObjects['products']['configuredVehicle'][number]['carParts'][number];

type FeatureAppReadyChangeCallback =
  ProductInformation['products']['configuredVehicle'][number]['carParts'][number];

export function createCarPartsPayload(
  selectedOptions: SelectedOptions,
): FeatureAppReadyCarPart[] | FeatureAppReadyChangeCallback[] {
  const carPartsList = Object.entries(selectedOptions)
    .filter(([type]) => filterForTrackableOptionType(type as OptionType))
    .filter(([, option]) => filterForTrackableOption(option as Option | undefined))
    .map(([type, option]) => {
      const { id = '', name = '' } = option as Option;

      const mappedType = type.startsWith('interior') ? 'interior' : type;

      return {
        productInfo: {
          productID: id,
          productName: name,
          manufacturer: 'Audi',
        },
        price: {
          priceWithTax: '', // @todo: fill price once available
        },
        category: {
          // Currently there are only three categorys (exteriorColor, interior,rim) are avaiable
          primaryCategory: mappedType,
          subCategory1: type,
          productType: 'car part',
        },
      };
    });

  return carPartsList;
}
