import { useAppState } from '../contexts/app-context';
import { OptionTypeExcludingSeatScheme } from '../utils/models';

export type OptionTypeAmended = OptionTypeExcludingSeatScheme | 'exteriorColorExclusive';

// in exlusive mode, exterior color should have a more fancy translation than exterior color
// in exlusive mode, it therefor has a different translation than in the other modes

export function useCorrectExteriorColorTranslationKey(
  category: OptionTypeExcludingSeatScheme,
): OptionTypeAmended {
  const {
    content: { workingMode },
  } = useAppState();

  if (workingMode.type !== 'assetApi' || category !== 'exteriorColor') return category;

  return 'exteriorColorExclusive';
}
