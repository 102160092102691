import React, { FC } from 'react';

export const BuildabilityConflictIcon: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 1.5C6.42487 1.5 1.5 6.42487 1.5 12.5C1.5 18.5751 6.42487 23.5 12.5 23.5C18.5751 23.5 23.5 18.5751 23.5 12.5C23.5 6.42487 18.5751 1.5 12.5 1.5Z"
      fill="#FFAA00"
      stroke="#FFAA00"
    />
    <path d="M12.5 7L12.5 14M12.5 16V18" stroke="white" />
  </svg>
);
