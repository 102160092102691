import { Logger } from '@feature-hub/core';
import {
  Camera,
  VisualizationModes,
} from '@volkswagen-onehub/audi-feature-service-automotive-visualization';
// import {} from '@oneaudi/fa-automotive-visualization';
import {
  ConsumptionsAndEmissions,
  VueFormatterServiceInterfaceV1,
} from '@oneaudi/vue-formatter-service';
import { I18NServiceV1 } from '@volkswagen-onehub/audi-i18n-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { VisualizerContent } from '../../editor.d';
// eslint-disable-next-line import/named
import { fetchTranslations, Translations } from '../../i18n/i18n';
import { Car, OptionType } from '../../utils/models';
import { enabledCategories, fetchCarByWorkingMode, replaceShyCharacter } from '../../utils/utils';
import { VisaEventOutgoing } from '../../visa.d';
import { createTrackingInstances, TrackingInstances } from '../../tracking/main';

export interface VisaState {
  camera: Camera;
  loading: boolean;
  visaFeatureAppId: string;
  visualizationMode: VisualizationModes;
}

export interface Env {
  ave3dStream: {
    mmURL: string;
    service: 'feature-app' | 'web';
  };
}

export type NullableCategory = OptionType | null;

export type State = {
  car: Car;
  content: VisualizerContent;
  enabledCategories: OptionType[];
  isShowingPrice: boolean;
  error?: string;
  featureAppId: string;
  isShowingExclusiveOptions: boolean;
  loading: boolean;
  selectedCategory: NullableCategory;
  translations: Translations;
  visa: VisaState;
  tracking?: TrackingInstances;
};

export async function createInitialState(
  content: VisualizerContent,
  localeService: GfaLocaleServiceV1,
  vueFormatterService: VueFormatterServiceInterfaceV1,
  i18nService: I18NServiceV1,
  logger: Logger,
  featureAppId: string,
  trackingService?: TrackingServiceV2,
): Promise<State> {
  const [car, translations] = await Promise.all([
    fetchCarByWorkingMode(content, localeService, vueFormatterService, logger),
    fetchTranslations(localeService, i18nService),
  ]);

  return {
    car: replaceShyCharacter(car),
    content,
    enabledCategories: enabledCategories(content, car),
    isShowingPrice:
      content.workingMode.type === 'api' &&
      (content.workingMode.showPrice === undefined || content.workingMode.showPrice === true),
    featureAppId,
    isShowingExclusiveOptions:
      content.workingMode.type === 'assetApi' && content.workingMode.enableExclusiveMode,
    loading: false,
    selectedCategory: null,
    tracking: trackingService ? createTrackingInstances(trackingService, logger) : undefined,
    translations,
    visa: {
      loading: false,
      camera: 'exterior',
      // @todo: fix undefined VisualizationModes at runtime, tsconfig issue?
      // visualizationMode: VisualizationModes._2D,
      visualizationMode: 0,
      visaFeatureAppId: `${featureAppId}:feature-app-automotive-visualization`,
    },
  };
}

export type Action =
  | { type: 'FETCH_CAR_ERROR'; payload: string }
  | { type: 'FETCH_CAR_INIT' }
  | { type: 'FETCH_CAR_SUCCESS'; payload: Car }
  | { type: 'FETCH_EMISSION_CONSUMPTION_ERROR'; payload: string }
  | { type: 'FETCH_EMISSION_CONSUMPTION_INIT' }
  | {
      type: 'FETCH_EMISSION_CONSUMPTION_SUCCESS';
      payload: ConsumptionsAndEmissions;
    }
  | { type: 'PROCESS_INCOMING_VISA_EVENT'; payload: VisaEventOutgoing }
  | { type: 'SET_CAR'; payload: Car }
  | { type: 'SET_CATEGORY'; payload: NullableCategory }
  | { type: 'SET_CONTENT'; payload: VisualizerContent };

export default function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'FETCH_EMISSION_CONSUMPTION_INIT':
    case 'FETCH_CAR_INIT':
      return {
        ...state,
        loading: true,
      };
    case 'FETCH_EMISSION_CONSUMPTION_SUCCESS':
      return {
        ...state,
        loading: false,
      };
    case 'FETCH_CAR_SUCCESS':
      return {
        ...state,
        loading: false,
        car: replaceShyCharacter(action.payload),
        visa: {
          ...state.visa,
          loading: false,
        },
      };
    case 'FETCH_EMISSION_CONSUMPTION_ERROR':
    case 'FETCH_CAR_ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case 'SET_CAR':
      return {
        ...state,
        car: replaceShyCharacter(action.payload),
      };
    case 'SET_CATEGORY':
      return {
        ...state,
        selectedCategory: action.payload,
      };
    case 'SET_CONTENT':
      return {
        ...state,
        content: action.payload,
        enabledCategories: enabledCategories(action.payload, state.car),
        isShowingExclusiveOptions:
          action.payload.workingMode.type === 'assetApi' &&
          action.payload.workingMode.enableExclusiveMode,
        isShowingPrice:
          action.payload.workingMode.type === 'api' && !!action.payload.workingMode.showPrice,
        loading: false,
      };
    case 'PROCESS_INCOMING_VISA_EVENT':
      return {
        ...state,
        visa: {
          ...state.visa,
          loading:
            action.payload.type === 'loading-change'
              ? action.payload.isLoading
              : state.visa.loading,
          camera: action.payload.type === 'view-change' ? action.payload.camera : state.visa.camera,
          visualizationMode:
            action.payload.type === 'view-change'
              ? action.payload.visualizationMode
              : state.visa.visualizationMode,
        },
      };
    default:
      // eslint-disable-next-line no-console
      console.warn('Unknown action: ', action);
      return state;
  }
}
