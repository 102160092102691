import { useEffect, useState } from 'react';
import { useStyledTheme } from '@audi/audi-ui-react';

export function useIsDesktop() {
  const [isDesktop, setIsDesktop] = useState(false);
  const theme = useStyledTheme();

  useEffect(() => {
    let animationFrame: number;

    function checkMediaQuery() {
      const { matches } = matchMedia(`screen and (min-width: ${theme.breakpoints.l}px)`);
      setIsDesktop(matches);
    }

    function debouncedResize() {
      cancelAnimationFrame(animationFrame);
      animationFrame = requestAnimationFrame(checkMediaQuery);
    }

    checkMediaQuery();
    window.addEventListener('resize', debouncedResize, { passive: true });

    return () => {
      cancelAnimationFrame(animationFrame);
      window.removeEventListener('resize', debouncedResize);
    };
  }, [theme.breakpoints.l]);

  return isDesktop;
}
