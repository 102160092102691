import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Layout } from '@audi/audi-ui-react';
import { CtaButton } from '../components/CtaButton';
import { EmissionConsumption } from '../components/EmissionConsumption';
import { getCarlineId } from '../utils/utils';
import { Headlines } from '../components/Headlines';
import { Overlay } from './Overlay';
import { Summary } from './Summary';
import { useAppPubSub, useAppState } from '../contexts/app-context';
import { useIsDesktop } from '../hooks/use-is-desktop';
import { VisaFeatureApp } from './VisaFeatureApp';
import { PrintView } from './PrintView';

export const Visualizer: FC = () => {
  const isDesktop = useIsDesktop();

  const { carsTeaser, visa } = useAppPubSub();

  const {
    car,
    car: {
      ave,
      exclusiveOptions,
      selectedOptions: { exteriorColor, rim },
      vehicleType,
    },
    isShowingExclusiveOptions,
    selectedCategory,
    visa: { visaFeatureAppId },
    tracking,
  } = useAppState();

  const isPrintBrowser = useMemo(() => {
    if (typeof window === 'undefined') {
      return false;
    }

    const uaString = window.navigator?.userAgent?.toLowerCase();

    // Apple’s mission is to keep Safari as broken as users will tolerate,
    // so as to generate a justification for the Apple AppStore and keep its revenue coming. 💸💸💸
    if (
      uaString.includes('safari') &&
      uaString.includes('applewebkit') &&
      (uaString.includes('iphone') || uaString.includes('ipad'))
    )
      return false;

    // For 10 years, Firefox on Android has not supported `document.print()`,
    // therefore we have to disable the print CTA on Firefox Android.
    if (uaString.includes('firefox') && uaString.includes('android') && uaString.includes('gecko'))
      return false;

    return true;
  }, []);

  const enableExclusiveMode = isShowingExclusiveOptions && isPrintBrowser;

  const isInitialCarConfig = useRef(true);
  const visaFaRef = useRef<HTMLDivElement>(null);

  const [printViewIsActive, setPrintViewIsActive] = useState(false);

  useEffect(() => {
    tracking?.featureAppReady.trigger(car, enableExclusiveMode);
  }, [car, enableExclusiveMode, tracking]);

  useEffect(() => {
    tracking?.changeCarPartCallback.trigger(car);
  }, [car, tracking]);

  const entryRef = useCallback(
    (node: HTMLElement | null) => {
      if (node === null) return;
      tracking?.impression.register(node, car);
    },
    [car, tracking],
  );

  // send event to cars teaser - if it exists on the page
  useEffect(() => {
    if (carsTeaser && exteriorColor && rim) {
      carsTeaser.configChange.publish({
        initial: isInitialCarConfig.current,
        carlineId: getCarlineId(ave),
        selectedExteriorColor: exteriorColor.id,
        selectedRim: rim.id,
      });

      // further car changes triggered by user interactions are not considered inital
      isInitialCarConfig.current = false;
    }
  }, [ave, carsTeaser, exteriorColor, rim]);

  useEffect(() => {
    visa?.configChange.publish({
      aveString: ave,
      exclusiveOptions,
      featureAppId: visaFeatureAppId,
      type: 'config-change',
    });
  }, [car, visa, visaFeatureAppId]);

  const visaView = useMemo(
    () =>
      selectedCategory === null
        ? null
        : (
            {
              exteriorColor: 'exterior',
              interior: 'interior',
              interiorBaseColor: 'interior',
              interiorContrastColor: 'interior',
              interiorSeatScheme: 'interior',
              interiorStitchingColor: 'interior',
              rim: 'rims',
            } as const
          )[selectedCategory],
    [selectedCategory],
  );

  // sends event to VISA to change camera angle
  useEffect(() => {
    if (!visa || !visaView) return;

    visa.viewChange.publish({
      featureAppId: visaFeatureAppId,
      type: 'view-change',
      view: visaView,
    });
  }, [isDesktop, visa, visaFeatureAppId, visaView]);

  const commonCtaProps = { isPrint: enableExclusiveMode };
  const ctaProps = enableExclusiveMode
    ? { ...commonCtaProps, onClick: () => setPrintViewIsActive(true) }
    : { ...commonCtaProps, car, tracking };

  return (
    <>
      <StyledVisualizerWrapper direction="column" ref={entryRef}>
        <Headlines car={car} />
        <LayoutWrapper>
          <VisaFeatureApp ref={visaFaRef} />
          <StyledEmissionConsumption
            vehicleType={vehicleType}
            emission={car.emission}
            consumption={car.consumption}
            classFb={car.classFb}
            powerConsumption={car.powerConsumption}
            fuelConsumptionBatteryDischarged={car.fuelConsumptionBatteryDischarged}
            classBatteryDischarged={car.classBatteryDischarged}
            textVariant="copy2"
            data-testid="emissionConsumption"
          />
          <ConfigurationPositioner>
            <ConfigurationWrapper>
              <SummaryAndOverlayWrapper>
                {selectedCategory ? <Overlay ctaProps={ctaProps} /> : null}
                <Summary />
              </SummaryAndOverlayWrapper>
              <StyledCtaButton {...ctaProps} />
            </ConfigurationWrapper>
          </ConfigurationPositioner>
        </LayoutWrapper>
      </StyledVisualizerWrapper>
      <PrintView
        visaFaRef={visaFaRef}
        printViewIsActive={printViewIsActive}
        onAfterPrint={() => setPrintViewIsActive(false)}
      />
    </>
  );
};

const StyledVisualizerWrapper = styled(Layout)`
  /* TODO: remove feature app margin as soon as AEM is capable of handling them */
  margin: ${({ theme }) => `var(${theme.responsive.spacing.xl})`};
  gap: ${({ theme }) => `var(${theme.responsive.spacing.xs})`};
  @media (min-width: ${({ theme }) => theme.breakpoints.l}px) {
    margin: var(${({ theme }) => theme.responsive.spacing.xxxl});
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.m}px) {
    gap: ${({ theme }) => `var(${theme.responsive.spacing.m})`};
  }
`;

const LayoutWrapper = styled.div`
  // Smaller viewport display the selection overview below the visa feature app. Larger viewport next to it
  display: grid;
  gap: var(${({ theme }) => theme.responsive.spacing.l});

  // On desktop the ratio between visa feature app and selection overview is 7/5
  @media (min-width: ${({ theme }) => theme.breakpoints.l}px) {
    grid-template-columns: 7fr 5fr;
    grid-template-rows: 1fr auto;
    grid-auto-flow: column;
    justify-content: space-between;
  }

  // On larger desktop the ratio between visa feature app and selection overview is 2/1
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    grid-template-columns: 2fr 1fr;
  }
`;

const StyledEmissionConsumption = styled(EmissionConsumption)`
  @media (min-width: ${({ theme }) => theme.breakpoints.l}px) {
    grid-column-end: span 2;
  }
`;

const ConfigurationPositioner = styled.div`
  position: relative;
`;

const ConfigurationWrapper = styled.div`
  display: grid;
  grid-template-rows: minmax(0, 1fr) auto;
  gap: var(${({ theme }) => theme.responsive.spacing.xl});

  // Absolutely positioned to not stretch the entire layout if there are too many items
  @media (min-width: ${({ theme }) => theme.breakpoints.l}px) {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}px) {
    * {
      font-size: var(${({ theme }) => theme.responsive.typography.copy1.fontSize});
    }
  }
`;

const SummaryAndOverlayWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledCtaButton = styled(CtaButton)`
  // Magic number to align it with the CTA of VISA
  margin-bottom: 22px;

  justify-self: flex-start;
`;
