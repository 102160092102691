import {
  ConfiguredCarIdentifier,
  ConfiguredCarModelIdentifier,
  ImageSource,
  MakeOptional,
  VehicleType,
} from '../../../@types/onegraph';

export type Prettify<T> = {
  [P in keyof T]: T[P];
  // eslint-disable-next-line @typescript-eslint/ban-types
} & {};

export interface BaseOption {
  id: string;
  name: string;
}

export interface Option extends BaseOption {
  tile: Partial<ImageSource> & Required<Pick<ImageSource, 'url'>>;
  isInConflict?: boolean;
  subCategory?: string;
  category?: {
    id: string;
    name: string;
  };
}

export type ConfiguredCarModelIdentifierWithOptionalExtensionsPR7 = Prettify<
  MakeOptional<ConfiguredCarModelIdentifier, 'extensionsPR7'>
>;

export type Ids = Prettify<
  Omit<ConfiguredCarIdentifier, 'brand' | 'country' | 'language' | 'model'> & {
    model: ConfiguredCarModelIdentifierWithOptionalExtensionsPR7;
  }
>;

export interface SeatSchemeOption extends BaseOption {
  baseIds: string[];
  prefixIds: Record<OptionTypeExclusiveInteriorOnly, string[]>;
}

export const commonCategories = ['exteriorColor'] as const;

export const standardCategories = ['rim', 'interior'] as const;

export const SEAT_SCHEME_CATEGORY = 'interiorSeatScheme';

export const exclusiveInteriorCategories = [
  'interiorBaseColor',
  SEAT_SCHEME_CATEGORY,
  'interiorContrastColor',
  'interiorStitchingColor',
] as const;

export const defaultCategories = [...commonCategories, ...standardCategories] as const;
export const exclusiveCategories = [...exclusiveInteriorCategories, ...commonCategories] as const;

export type OptionTypeDefault = (typeof defaultCategories)[number];
export type OptionTypeExclusive = (typeof exclusiveCategories)[number];

export type OptionType = OptionTypeDefault | OptionTypeExclusive;
export type OptionTypeExcludingSeatScheme = Exclude<OptionType, typeof SEAT_SCHEME_CATEGORY>;
export type OptionTypeExclusiveInteriorOnly = Exclude<
  (typeof exclusiveInteriorCategories)[number],
  typeof SEAT_SCHEME_CATEGORY
>;

export type AvailableOptions = Partial<
  Record<OptionTypeExcludingSeatScheme, Option[]> & { interiorSeatScheme: SeatSchemeOption[] }
>;

export type SelectedOptions = Partial<
  Record<OptionTypeExcludingSeatScheme, Option> & { interiorSeatScheme: SeatSchemeOption }
>;

export interface Car {
  availableOptions: AvailableOptions;
  ave: string;
  consumption: string;
  emission: string;
  classFb: string;
  powerConsumption: string;
  fuelConsumptionBatteryDischarged: string;
  classBatteryDischarged: string;
  exclusiveOptions?: string;
  id?: Ids;
  carlineName: string;
  selectedOptions: SelectedOptions;
  vehicleType: VehicleType;
  carlineId?: string;
  price?: string;
  prString?: string;
}
