import React from 'react';
import { Layout, Text } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { Car } from '../utils/models';
import { Price } from './Price';
import { useAppState } from '../contexts/app-context';

export interface Props {
  car: Car;
}

const StyledHeadlinesLayout = styled(Layout)`
  gap: ${({ theme }) => `var(${theme.responsive.spacing.s})`};

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    width: min(75vw, 900px);
  }
`;

export const Headlines = ({ car }: Props) => {
  const { isShowingPrice } = useAppState();

  return (
    <StyledHeadlinesLayout direction="column">
      <Text as="h2" variant="display2">
        {car.carlineName}
      </Text>
      {car.price && isShowingPrice ? (
        <Text as="h3" variant="order4">
          <Price formattedPrice={car.price} />
        </Text>
      ) : null}
    </StyledHeadlinesLayout>
  );
};
