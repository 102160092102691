import React, { FC } from 'react';
import styled from 'styled-components';
import { useAppState } from '../contexts/app-context';
import { OptionType, SEAT_SCHEME_CATEGORY } from '../utils/models';
import { SummaryItem } from './SummaryItem';

export const Summary: FC = (props) => {
  const { enabledCategories } = useAppState();
  return (
    <SummaryWrapper {...props}>
      <SummaryItemsWrapper role="group" amountTiles={enabledCategories.length}>
        {enabledCategories.map((category: OptionType) =>
          category === SEAT_SCHEME_CATEGORY ? null : (
            <SummaryItem category={category} key={category} />
          ),
        )}
      </SummaryItemsWrapper>
    </SummaryWrapper>
  );
};

const SummaryWrapper = styled.div`
  overflow-y: auto;
`;

const SummaryItemsWrapper = styled.div<{ amountTiles: number }>`
  display: flex;
  flex-direction: column;
  gap: var(${({ theme }) => theme.responsive.spacing.l});
  overflow-y: auto;
  justify-content: center;
`;
