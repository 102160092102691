import { Select } from '@audi/audi-ui-react';
import React, { FC, useCallback } from 'react';
import { useAppState } from '../contexts/app-context';
import { useMutateConfiguration } from '../hooks/use-mutate-configuration';
import { i18nKeys, useI18n } from '../i18n/i18n';
import { SEAT_SCHEME_CATEGORY } from '../utils/models';

export const SeatSchemeSelect: FC = () => {
  const {
    car: {
      availableOptions: { interiorSeatScheme: availableInteriorSeatSchemes },
      selectedOptions: { interiorSeatScheme: selectedInteriorSeatScheme },
    },
  } = useAppState();

  const mutateConfiguration = useMutateConfiguration();

  const setSeatScheme = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      event.preventDefault();

      const {
        target: { value },
      } = event;

      if (!value) return;
      if (!availableInteriorSeatSchemes?.find(({ id }) => id === value)) return;

      mutateConfiguration(SEAT_SCHEME_CATEGORY, value);
    },
    [availableInteriorSeatSchemes, mutateConfiguration],
  );

  return (
    <Select
      inputId={SEAT_SCHEME_CATEGORY}
      label={useI18n(i18nKeys[SEAT_SCHEME_CATEGORY])}
      onChange={setSeatScheme}
      required
      spaceStackEnd="l"
      spaceStackStart="l"
      value={selectedInteriorSeatScheme?.id}
    >
      {availableInteriorSeatSchemes
        ? availableInteriorSeatSchemes.map(
            // @ts-ignore
            ({ id, name }: any, index: React.Key | null | undefined) => (
              <option value={id} key={index}>
                {name}
              </option>
            ),
          )
        : null}
    </Select>
  );
};
