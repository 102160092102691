import React, { FC, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { i18nKeys, useI18n } from '../i18n/i18n';
import { BBCWarningPopover } from './BuildabilityConflictPopover';
import { BuildabilityConflictIcon } from './Icons/BuildabilityConflictIcon';

export interface SharedProps {
  handleApplyClick: () => void;
  setIdOptionWithPopover: (argument: string | null) => void;
}

interface BBCWarningProps extends SharedProps {
  optionId: string;
  idOptionWithPopover: string | null;
  className?: string;
}

export const BuildabilityConflictWarning: FC<BBCWarningProps> = ({
  className,
  optionId,
  idOptionWithPopover,
  handleApplyClick,
  setIdOptionWithPopover,
}) => {
  const buildablityConflict = useI18n(i18nKeys.conflict.buildabilityConflict);

  // when BBC Icon is hovered, only the info text without the two buttons is shown
  // only by clicking the tile, the two buttons are displayed below the text
  const [isButtonGroupShown, setIsButtonGroupShown] = useState(true);

  // when the BBC icon has been clicked as opposed to hovered, the popover should not...
  // ... close on hovering away from the BBC icon
  const [isPopoverLocked, setIsPopoverLocked] = useState(false);

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      // close popover on second click
      if (idOptionWithPopover && isPopoverLocked) {
        setIdOptionWithPopover(null);
        setIsButtonGroupShown(true);
      }

      setIsPopoverLocked(true);

      // when Popover with buttons is open, don't hide the buttons by clicking on the BBC icon
      if (idOptionWithPopover) return;

      setIdOptionWithPopover(optionId);
      setIsButtonGroupShown(false);
    },
    [idOptionWithPopover, isPopoverLocked, optionId, setIdOptionWithPopover],
  );

  const onMouseEnter = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      // when Popover with buttons is open, don't hide the buttons by hovering on the BBC icon
      if (idOptionWithPopover) return;

      setIdOptionWithPopover(optionId);
      setIsButtonGroupShown(false);
    },
    [idOptionWithPopover, optionId, setIdOptionWithPopover],
  );

  const handleMouseLeave = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      // when Popover with buttons is open, don't hide the buttons by hovering away on the BBC icon
      if (idOptionWithPopover && isButtonGroupShown) return;
      if (isPopoverLocked) return;

      setIdOptionWithPopover(null);
      setIsButtonGroupShown(true);
    },
    [idOptionWithPopover, isButtonGroupShown, isPopoverLocked, setIdOptionWithPopover],
  );

  const iconRef = useRef<HTMLButtonElement>(null);

  return (
    <BBCWarningWrapper className={className} data-testid="bbc-warning">
      <BBCWarningIconButton
        type="button"
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-label={buildablityConflict}
        ref={iconRef}
      >
        <BuildabilityConflictIcon />
      </BBCWarningIconButton>
      {idOptionWithPopover === optionId ? (
        <BBCWarningPopover
          handleApplyClick={handleApplyClick}
          setIdOptionWithPopover={setIdOptionWithPopover}
          isButtonGroupShown={isButtonGroupShown}
          setIsButtonGroupShown={setIsButtonGroupShown}
          setIsPopoverLocked={setIsPopoverLocked}
          iconRef={iconRef.current}
        />
      ) : null}
    </BBCWarningWrapper>
  );
};

const BBCWarningWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-left: -2px;
`;

const BBCWarningIconButton = styled.button`
  border: 0;
  padding: 0;
  background-color: transparent;
  transform: translateX(-50%);
  margin-left: var(${({ theme }) => theme.responsive.spacing.m});
  pointer-events: all;
`;
