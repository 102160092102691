import React, { FC } from 'react';
import styled from 'styled-components';

interface StyledBackIconProps {
  direction?: 'left' | 'right';
}
const StyledBackIcon = styled.svg<StyledBackIconProps>`
  fill: none;
  transform: ${({ direction }) => (direction === 'right' ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
`;

export const BackIcon: FC<StyledBackIconProps> = ({ direction }) => {
  return (
    <StyledBackIcon
      width="8"
      height="13"
      viewBox="0 0 6 11"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden
      direction={direction}
    >
      <path d="M5.3 1.2002L0.75 5.7502L5.3 10.3002" stroke="currentColor" />
    </StyledBackIcon>
  );
};
