import { Car, OptionType } from '../../../../utils/models';
import { FEATURE_APP_NAME } from '../../../../utils/utils';
import { TrackingEvent, transformPayload } from '../../common';
import { DataLayerEvent } from './reference';

function createPayload(category: OptionType, car: Car, label: string, seatScheme?: string | null) {
  const { price = '0', carlineId = '', carlineName: longName = '', selectedOptions } = car;
  const selectedOption = selectedOptions[category];
  const productName = selectedOption?.name || '';

  const mappedType = category.startsWith('interior') ? 'interior' : category;

  const dataLayerEvent: DataLayerEvent = {
    eventInfo: {
      eventAction: 'internal_link',
      eventName: `visualizer - change car part`,
    },
    attributes: {
      componentName: `${FEATURE_APP_NAME}`,
      label: '',
      targetURL: '',
      clickID: `change-car-part-${category}`,
      elementName: 'icon',
      value: seatScheme ? `${category} ${seatScheme} - ${label}` : `${category} - ${label}`,
      pos: '',
      relatedProducts: {
        configuredVehicle: [
          {
            productInfo: {
              productName: longName,
              productID: carlineId,
              manufacturer: 'Audi',
            },
            price: {
              totalPrice: price,
            },
            category: {
              primaryCategory: '',
              subCategory1: carlineId,
              productType: 'configured vehicle',
            },
            carParts: [
              {
                productInfo: {
                  productID: 'exclusive_exterior_LC_PR_AEEXC034',
                  productName,
                  manufacturer: 'Audi',
                },
                price: {
                  priceWithTax: '',
                },
                // Currently there are only three categorys (exteriorColor, interior,rim) are avaiable
                category: {
                  primaryCategory: mappedType,
                  subCategory1: category,
                  productType: 'car part',
                },
              },
            ],
          },
        ],
      },
    },
  };

  return transformPayload(dataLayerEvent);
}

export type Payload = ReturnType<typeof createPayload>;

export class Event extends TrackingEvent<Payload> {
  trigger(category: OptionType, car: Car, label: string, seatScheme?: string | null) {
    this._trigger(createPayload(category, car, label, seatScheme));
  }
}
