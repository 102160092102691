import {
  LoadingChangeEvent,
  ViewChangeEvent,
} from '@volkswagen-onehub/audi-feature-service-automotive-visualization';
import { PubSubFeatureServiceV1 } from '@oneaudi/pubsub-service';
import { Dispatch } from 'react';
import { Action } from '../contexts/reducers/app-reducer';
import { VisaConfigChangeEventIn, VisaViewChangeEventIn } from '../visa.d';
import { Publisher } from './pubsub-cars-teaser';

// @todo: Why?
// eslint-disable-next-line no-shadow
export enum VisaEvents {
  // visa incoming events (events to which visa is subscribed)
  IN_CONFIG_CHANGE = 'visa.config-change',
  IN_VIEW_CHANGE = 'visa.view-change',
  // visa outgoing events (events triggered by visa)
  OUT_LOADING_CHANGE = 'visa.loading-change-event',
  OUT_SELF_VIEW_CHANGE = 'visa.self-view-change',
}

export interface VisaPubSub {
  configChange: Publisher<VisaConfigChangeEventIn>;
  viewChange: Publisher<VisaViewChangeEventIn>;
}

function handleVisaLoadingChange(dispatch: Dispatch<Action>, visaFeatureAppId: string) {
  return (event: LoadingChangeEvent) => {
    if (event.featureAppId !== visaFeatureAppId) return;

    dispatch({
      type: 'PROCESS_INCOMING_VISA_EVENT',
      payload: {
        ...event,
        type: 'loading-change',
      },
    });
  };
}

function handleVisaViewChange(dispatch: Dispatch<Action>, visaFeatureAppId: string) {
  return (event: ViewChangeEvent) => {
    if (event.id !== visaFeatureAppId) return;

    dispatch({
      type: 'PROCESS_INCOMING_VISA_EVENT',
      payload: {
        ...event,
        type: 'view-change',
      },
    });
  };
}

export async function setupVisaSubscriptions(
  pubSubService: PubSubFeatureServiceV1,
  visaFeatureAppId: string,
  dispatch: Dispatch<Action>,
): Promise<VisaPubSub> {
  // incoming events

  pubSubService.subscribe<LoadingChangeEvent>(
    VisaEvents.OUT_LOADING_CHANGE,
    handleVisaLoadingChange(dispatch, visaFeatureAppId),
  );

  pubSubService.subscribe<ViewChangeEvent>(
    VisaEvents.OUT_SELF_VIEW_CHANGE,
    handleVisaViewChange(dispatch, visaFeatureAppId),
  );

  // outgoing events
  const [configChange, viewChange] = await Promise.all([
    pubSubService.awaitSubscription<VisaConfigChangeEventIn>(
      visaFeatureAppId,
      VisaEvents.IN_CONFIG_CHANGE,
    ),
    pubSubService.awaitSubscription<VisaViewChangeEventIn>(
      visaFeatureAppId,
      VisaEvents.IN_VIEW_CHANGE,
    ),
  ]);

  return { configChange, viewChange };
}
