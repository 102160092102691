import React, { ComponentProps, FC } from 'react';

export type ImgLoadState = 'loading' | 'done' | 'error' | 'unmount';

// onLoad and onError are not supposed right now because its overwritten by the functionality of
// this component. Add it if needed.
export type ImgWithLoadMonitoringProps = Omit<ComponentProps<'img'>, 'onLoad' | 'onError'> & {
  onMonitorLoad?: (state: ImgLoadState) => void;
};

export const ImgWithLoadMonitoring: FC<ImgWithLoadMonitoringProps> = ({
  onMonitorLoad,
  alt,
  ...restProps
}) => {
  return (
    <img
      {...restProps}
      alt={alt} // Must be explictly stated so that the linter shuts up
      ref={
        onMonitorLoad
          ? (ref) => {
              if (ref) {
                onMonitorLoad('loading');
              } else {
                onMonitorLoad('unmount');
              }
            }
          : undefined
      }
      onLoad={onMonitorLoad ? () => onMonitorLoad('done') : undefined}
      onError={onMonitorLoad ? () => onMonitorLoad('error') : undefined}
    />
  );
};
