const BASE_URL = 'https://www.audi.de';

export const addHeight = (url: string, height: number) => {
  try {
    const isRelative = url.startsWith('/');
    const base = isRelative ? BASE_URL : undefined;
    const urlObj = new URL(url, base);
    urlObj.searchParams.set('auto', 'webp');
    urlObj.searchParams.set('height', String(height));
    const urlStr = urlObj.toString();
    return isRelative ? urlStr.replace(BASE_URL, '') : urlStr;
  } catch {
    return url;
  }
};
