import { useCallback } from 'react';
import { OptionType, OptionTypeDefault } from '../utils/models';
import { useAppDispatch, useAppState, useFeatureServices } from '../contexts/app-context';
import { selectOption as selectOptionManual } from '../utils/manual-mode';
import { selectOption as selectOptionAssetApi } from '../utils/asset-api-mode/select';
import { changeConfiguredCar } from '../utils/api-mode';

export function useMutateConfiguration() {
  const dispatch = useAppDispatch();

  const {
    content: { workingMode },
    car,
  } = useAppState();
  const { localeService, logger } = useFeatureServices();

  return useCallback(
    async (selectedOptionType: OptionType, optionId: string) => {
      try {
        dispatch({ type: 'FETCH_CAR_INIT' });

        if (workingMode.type === 'manual') {
          dispatch({
            type: 'FETCH_CAR_SUCCESS',
            payload: selectOptionManual(optionId, car, selectedOptionType as OptionTypeDefault),
          });
        } else if (workingMode.type === 'assetApi') {
          dispatch({
            type: 'FETCH_CAR_SUCCESS',
            payload: selectOptionAssetApi(optionId, car, selectedOptionType),
          });
        } else {
          const newCar = await changeConfiguredCar(localeService, optionId, car);
          if (!newCar) throw new Error('no change configurated Car - no data received');

          dispatch({
            type: 'FETCH_CAR_SUCCESS',
            payload: newCar,
          });
        }
      } catch (e) {
        logger.error(e);
        dispatch({ type: 'FETCH_CAR_ERROR', payload: (e as Error).message });
      }
    },
    [car, dispatch, localeService, logger, workingMode],
  );
}
