import { Logger } from '@feature-hub/core';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { VisualizerContent } from '../editor.d';
import { EnvironmentsDisplayed } from '../visa.d';
import { basePrNumbersToItems, fetchCar as fetchCarOneGraph } from './api-mode';
import { fetchCar as fetchCarAssetApi } from './asset-api-mode/main';
import { fetchCar as fetchCarManual } from './manual-mode';
import { Car, defaultCategories, exclusiveCategories, OptionType } from './models';

export const FEATURE_APP_NAME = 'fa-visualizer';

export const MOBILE_OVERLAY_Z_INDEX = 101; // Must be at least 100 because of the Page's headline
export const DESKTOP_BACK_BUTTON_Z_INDEX = 10;
export const MOBILE_BACK_BUTTON_Z_INDEX = DESKTOP_BACK_BUTTON_Z_INDEX + MOBILE_OVERLAY_Z_INDEX;
export const VISA_MAX_Z_INDEX = 40; // max z-index value used by VISA action's panel on mobile!

const shyCharacters = new RegExp('&shy;|<wbr>', 'gi');
const shyUniCode = '\u00AD';

/**
 * Extracts the carline from an ave string
 * @param ave the Automotive Visualization Engine string
 */
export function getCarlineId(ave: string) {
  const [carline] = ave.split(',');

  if (!carline) throw new Error('carline not found');

  return carline;
}

/**
 * Mapping between carlineId and environmets configuration required by Visa
 */
export function getEnvironmentDisplayed({ carlineId, ave }: Car): EnvironmentsDisplayed {
  const defaultEnvironments: EnvironmentsDisplayed = {
    AllianzArena: false,
    Champalimaud_Lissabon_2020: true,
    Champalimaud_Lissabon_Night_2021: true,
    ChicagoNight: true,
    Convention_Centre_Dubai_2021: true,
    LAVista: false,
    Metro_Dubai_2021: false,
    Meydan_Dubai_2021: false,
    Meydan_Grandstand_Dubai_2021: true,
    PariserPlatz_Berlin_2020: false,
    Skyline_Dubai_2021: false,
    Universidad_Zaragoza_2021: false,
    WhiteRoom: true,
  };

  const etrongtEnvironments: EnvironmentsDisplayed = {
    ...defaultEnvironments,
    Convention_Centre_Dubai_2021: false,
    Meydan_Grandstand_Dubai_2021: false,
    Meydan_Dubai_2021: true,
    Skyline_Dubai_2021: true,
  };

  const q8etronEnvironments: EnvironmentsDisplayed = {
    TitusNarrow: true,
    Kellerei_Bozen: true,
    Kellerei_Bozen_Night: true,
  };

  const rs3Environments: EnvironmentsDisplayed = {
    ...defaultEnvironments,
    ChicagoNight: false,
    Convention_Centre_Dubai_2021: false,
    Meydan_Grandstand_Dubai_2021: false,
    Universidad_Zaragoza_2021: true,
    Metro_Dubai_2021: true,
  };

  const q6AndSq6etronSuvEnvironments: EnvironmentsDisplayed = {
    SculpturePark_Tasmania: true,
    Metro_Dubai_2021: true,
    MoMa_House_GenericLighting: true,
    WhiteRoom: true,
  };
  const a6AndS6etronSuvEnvironments: EnvironmentsDisplayed = {
    Villa_Alcobendas_Madrid: true,
    Metro_Dubai_2021: true,
    MoMa_House_GenericLighting: true,
    WhiteRoom: true,
  };
  const a3AndS3PaEnvironments: EnvironmentsDisplayed = {
    CongressCentre_Torun_Polen: true,
    Metro_Dubai_2021: true,
    MoMa_House_GenericLighting: true,
    WhiteRoom: true,
  };

  const a3SportbacktEnvironments: EnvironmentsDisplayed = {
    CongressCentre_Torun_Polen: true,
    Metro_Dubai_2021: true,
    MoMa_House_GenericLighting: true,
    WhiteRoom: true,
  };

  const a5AndS5Environments: EnvironmentsDisplayed = {
    ParkingSpot_CabotTrail_Canada: true,
    Metro_Dubai_2021: true,
    MoMa_House_GenericLighting: true,
    WhiteRoom: true,
  };
  const rs3limoEnvironments: EnvironmentsDisplayed = {
    Museum_Zory_Polen: true,
    Metro_Dubai_2021: true,
    MoMa_House_GenericLighting: true,
    WhiteRoom: true,
  };
  const q5andSq5Environments: EnvironmentsDisplayed = {
    Arken_Museum_Denmark: true,
    Metro_Dubai_2021: true,
    MoMa_House_GenericLighting: true,
    WhiteRoom: true,
  };
  const a3AllStreetEnvironments: EnvironmentsDisplayed = {
    FarmHouse_Zegocina_Polen: true,
    Metro_Dubai_2021: true,
    MoMa_House_GenericLighting: true,
    WhiteRoom: true,
  };

  // For some carlines not all environments should be shown: https://collaboration.msi.audi.com/jira/browse/ONEAUDI-4124
  const carlineEnvironmentsMap: Record<string, EnvironmentsDisplayed> = {
    etrongt: etrongtEnvironments,
    rsetrongt: etrongtEnvironments,
    q8etron: q8etronEnvironments,
    // @todo: How can the following numbers be a carline ID?
    // Q4 e-tron
    '51272': {
      ...defaultEnvironments,
      Meydan_Grandstand_Dubai_2021: false,
    },
    // Q4 Sportback e-tron
    '51282': {
      ...defaultEnvironments,
      Convention_Centre_Dubai_2021: false,
    },
    'audi-rs-3-limousine': rs3Environments,
    'audi-rs-3-sportback': rs3Environments,
    q6etron: q6AndSq6etronSuvEnvironments,
    sq6etron: q6AndSq6etronSuvEnvironments,
    a6avantetron: a6AndS6etronSuvEnvironments,
    s6avantetron: a6AndS6etronSuvEnvironments,
    a6sbetron: a6AndS6etronSuvEnvironments,
    s6sbetron: a6AndS6etronSuvEnvironments,
    a3sb: a3AndS3PaEnvironments,
    a3limo: a3AndS3PaEnvironments,
    s3sb: a3AndS3PaEnvironments,
    s3limo: a3AndS3PaEnvironments,
    rs3limo: rs3limoEnvironments,
    rs3sb: rs3limoEnvironments,
    a3allstreet: a3AllStreetEnvironments,
    a5limo: a5AndS5Environments,
    s5limo: a5AndS5Environments,
    a5avant: a5AndS5Environments,
    s5avant: a5AndS5Environments,
    q5: q5andSq5Environments,
    sq5: q5andSq5Environments,
    q5sb: q5andSq5Environments,
    sq5sb: q5andSq5Environments,
    a3allstreettfsie: a3AllStreetEnvironments,
    a3sbtfsie: a3SportbacktEnvironments,
    q6sbetron: q6AndSq6etronSuvEnvironments,
    sq6sbetron: q6AndSq6etronSuvEnvironments,
  };

  const lookupCarline = (carlineId ?? getCarlineId(ave)).toLowerCase();

  return carlineEnvironmentsMap[lookupCarline] ?? defaultEnvironments;
}

export async function fetchCarByWorkingMode(
  content: VisualizerContent,
  localeService: GfaLocaleServiceV1,
  vueFormatterService: VueFormatterServiceInterfaceV1,
  logger: Logger,
) {
  const { workingMode, market } = content;
  let car;

  if (workingMode.type === 'manual') {
    car = await fetchCarManual(workingMode, vueFormatterService, localeService, logger);
  } else if (workingMode.type === 'assetApi') {
    const { enableExclusiveMode } = workingMode;

    car = await fetchCarAssetApi(
      workingMode,
      market,
      enableExclusiveMode ? exclusiveCategories : defaultCategories,
      enableExclusiveMode,
      vueFormatterService,
      localeService,
      logger,
    );
  } else {
    car = fetchCarOneGraph(
      localeService,
      workingMode.carline,
      basePrNumbersToItems(workingMode.basePrNumbers),
    );
  }

  return car;
}

export function replaceShyCharacter(car: Car): Car {
  return {
    ...car,
    carlineName: car.carlineName.replace(shyCharacters, shyUniCode),
  };
}

export function enabledCategories(content: VisualizerContent, car: Car): OptionType[] {
  const { availableOptions } = car;
  const { workingMode } = content;

  const categories =
    workingMode.type === 'assetApi' && workingMode.enableExclusiveMode
      ? exclusiveCategories
      : defaultCategories;

  return [...categories].filter((category) => {
    if (!availableOptions?.[category]?.length) return false;
    if (workingMode.type === 'api' && category === 'interior' && !workingMode.showInterior)
      return false;
    return true;
  });
}

export function substitutePlaceholders(
  input: string,
  items: { placeholder: string; value: string }[],
) {
  return items.reduce((acc, { placeholder, value }) => {
    const regex = new RegExp(placeholder, 'g');

    return acc.replace(regex, value);
  }, input);
}

export function transformImage(
  imageUrl: string,
  size = 60,
  mimeType: 'jpeg' | 'webp' | 'png' = 'jpeg',
): string {
  return `${imageUrl}?wid=${size}&mimetype=image/${mimeType}`;
}

const regexpBreaks = /<br\s*\/?>/gi;
const regexpQuots = /&quot;/gi;
/**
 * Formats an HTML string by replacing line breaks with slashes and unescaping HTML entities.
 * @param html - The HTML string to format.
 * @returns The formatted string.
 */
export function formatHTML(html: string | null): string {
  return html?.replace(regexpBreaks, ' / ').replace(regexpQuots, '"') ?? '';
}
