import { Car, OptionTypeExclusiveInteriorOnly } from '../models';

const EXTERIOR_COLOR_PLACEHOLDER = 'Q0Q0';
const EXTERIOR_COLOR_PLACEHOLDER_MATTE = 'O0O0';

export function amendExclusiveAve(car: Car): Car {
  const { ave: aveRaw, selectedOptions } = car;
  const { exteriorColor, interiorSeatScheme } = selectedOptions;
  const { id: selectedExteriorColorId, subCategory: selectedExteriorColorSubCategory } =
    exteriorColor || {};

  if (!interiorSeatScheme || !selectedExteriorColorSubCategory) return car;

  const ave =
    selectedExteriorColorSubCategory === 'MATTE'
      ? aveRaw?.replace(EXTERIOR_COLOR_PLACEHOLDER, EXTERIOR_COLOR_PLACEHOLDER_MATTE)
      : aveRaw?.replace(EXTERIOR_COLOR_PLACEHOLDER_MATTE, EXTERIOR_COLOR_PLACEHOLDER);

  const { baseIds, prefixIds } = interiorSeatScheme;

  const prefixedOptions = Object.entries(prefixIds)
    .map(([key, value]) => {
      const optionId = selectedOptions[key as OptionTypeExclusiveInteriorOnly]?.id;
      if (!optionId) return [];

      return value.map((prefix) => `${prefix}${optionId}`);
    })
    .flat(1);

  const exclusiveOptions = [selectedExteriorColorId, ...baseIds, ...prefixedOptions].join(',');

  return {
    ...car,
    ave,
    exclusiveOptions,
  };
}
