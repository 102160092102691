import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button, Text } from '@audi/audi-ui-react';
import { i18nKeys, useI18n } from '../i18n/i18n';
import { SharedProps } from './BuildabilityConflictWarning';
import { ApplyIcon } from './Icons/ApplyIcon';
import { CancelIcon } from './Icons/CancelIcon';
import { NoseIcon } from './Icons/NoseIcon';

interface BBCPopoverProps extends SharedProps {
  isButtonGroupShown: boolean;
  setIsButtonGroupShown: (arg: boolean) => void;
  setIsPopoverLocked: (arg: boolean) => void;
  iconRef: HTMLButtonElement | null;
}

export const BBCWarningPopover: FC<BBCPopoverProps> = ({
  handleApplyClick,
  setIdOptionWithPopover,
  isButtonGroupShown,
  setIsButtonGroupShown,
  setIsPopoverLocked,
  iconRef,
}) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const applyButtonRef = useRef<HTMLButtonElement>(null);

  const cancel = useI18n(i18nKeys.conflict.cancel);
  const apply = useI18n(i18nKeys.conflict.apply);
  const notice = useI18n(i18nKeys.conflict.notice.generic);

  useEffect(() => {
    const { current: currentPopoverRef } = popoverRef;

    // click outside modal in order to close it
    const handleOutsideClick = (event: Event) => {
      if (
        !currentPopoverRef ||
        !(event.currentTarget instanceof Node) ||
        !(event.target instanceof Node)
      )
        return;
      if (currentPopoverRef === event.currentTarget) return;
      if (applyButtonRef.current?.contains(event.target)) return;

      // do not close popover when BBC is clicked
      if (iconRef?.contains(event.target)) return;

      setIdOptionWithPopover(null);
      setIsPopoverLocked(false);
      setIsButtonGroupShown(true);

      event.preventDefault();
      event.stopPropagation();
    };

    document.addEventListener('click', handleOutsideClick, true);

    // close notification when scrolled out of view
    // else click on a tile would be a "click outside to close" but user wouldn't realize ...
    // ... and think it's broken
    if (currentPopoverRef) {
      const observer = new IntersectionObserver((entries) => {
        const [{ isIntersecting }] = entries;

        if (!isIntersecting) setIdOptionWithPopover(null);
      });

      observer.observe(currentPopoverRef);

      try {
        currentPopoverRef?.scrollIntoView?.({ block: 'nearest' });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick, true);
    };
  }, [iconRef, setIdOptionWithPopover, setIsButtonGroupShown, setIsPopoverLocked]);

  return (
    <div ref={popoverRef} data-testid="bbt-warning-popover">
      <NoseIconPositioned />
      <StyledPopover>
        <NotificationText text={notice} />
        {isButtonGroupShown ? (
          <ButtonGroup>
            <PopoverSelectionButton
              variant="text"
              icon={<CancelIcon />}
              onClick={() => setIdOptionWithPopover(null)}
            >
              <NotificationText text={cancel} />
            </PopoverSelectionButton>
            <PopoverSelectionButton
              variant="text"
              icon={<ApplyIcon />}
              onClick={handleApplyClick}
              ref={applyButtonRef}
            >
              <NotificationText text={apply} />
            </PopoverSelectionButton>
          </ButtonGroup>
        ) : null}
      </StyledPopover>
    </div>
  );
};

const NotificationText: FC<{ text: string }> = ({ text }) => (
  <ColoredText variant="copy2">{text}</ColoredText>
);

const ColoredText = styled(Text)`
  color: var(${({ theme }) => theme.colors.base.grey[10]});
`;

const PopoverSelectionButton = styled(Button)`
  & span {
    color: var(${({ theme }) => theme.colors.base.grey[10]});
    margin-left: var(${({ theme }) => theme.responsive.spacing.xs});
  }
`;

const ButtonGroup = styled.div`
  position: relative;
  display: flex;
  margin-top: var(${({ theme }) => theme.responsive.spacing.s});
  right: 5px;
  gap: var(${({ theme }) => theme.responsive.spacing.l});
`;

const NoseIconPositioned = styled(NoseIcon)`
  z-index: 3;
  position: relative;
  transform: translateX(-50%);
  margin-left: var(${({ theme }) => theme.responsive.spacing.m});
  color: var(${({ theme }) => theme.colors.base.grey[80]});
`;

const StyledPopover = styled.div`
  position: relative;
  z-index: 2;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  bottom: 3px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  background-color: var(${({ theme }) => theme.colors.base.grey[80]});
  padding: var(${({ theme }) => theme.responsive.spacing.m});
  pointer-events: all;
`;
