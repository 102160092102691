export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DealerSearchSortId: { input: any; output: any };
  JSONObject: { input: any; output: any };
  StockCarSearchCriterionId: { input: any; output: any };
  StockCarSearchCriterionItemId: { input: any; output: any };
  StockCarSearchSortId: { input: any; output: any };
};

/** The input type for the acceptConflictSolutions query */
export type AcceptConflictSolutionsInput = {
  conflictTriggerId: Scalars['ID']['input'];
  /** Intermediate configured car identifier that is still in conflict. It is obtained from ConfiguredCarConflict.conflict.id */
  id: ConfiguredCarInput;
  /** Last conflict free configuration. This value should be taken from ConfiguredCarConflict.conflict.lastGoodConfiguredCarIdentifier */
  lastGoodConfiguredCarIdentifier: InputMaybe<ConfiguredCarInput>;
  /** An array of PR numbers choosing a pr number of all of the solution groups to solve and accept the conflict. */
  solutions: Array<Scalars['String']['input']>;
};

/** new type */
export type AcceptConflictSolutionsPayload = {
  __typename?: 'AcceptConflictSolutionsPayload';
  configuredCar: ConfiguredCar;
};

export type AudiCodeCar = AudiCodeError | ConfiguredCar | StockCar;

export type AudiCodeError = {
  __typename?: 'AudiCodeError';
  code: Maybe<AudiCodeErrorCarCode>;
  message: Maybe<Scalars['String']['output']>;
};

export type AudiCodeErrorCarCode = CarCode & {
  __typename?: 'AudiCodeErrorCarCode';
  country: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  language: Maybe<Scalars['String']['output']>;
};

export type AudicodeGraphqlInfo = OneGraphSubServiceInfo & {
  __typename?: 'AudicodeGraphqlInfo';
  endpoint: Scalars['String']['output'];
  health: OneGraphServiceHealth;
  id: Scalars['ID']['output'];
  meta: OneGraphServiceMetaInfo;
  name: Scalars['String']['output'];
  services: Maybe<Array<Maybe<OneGraphSubServiceDownStreamInfo>>>;
  version: Scalars['String']['output'];
};

/** information about stocks of a market */
export type AvailableStock = {
  __typename?: 'AvailableStock';
  /** information about the new car stock */
  newCars: Maybe<Stock>;
  /** information about the used car stock */
  usedCars: Maybe<Stock>;
};

/** Body type of a CarlineStructureCarline, has an ID and a name. */
export type BodyType = {
  __typename?: 'BodyType';
  /** Machine readable body type id, e.g. 'limo'. */
  id: Scalars['String']['output'];
  /** Localized human readable body type name, e.g. 'Limousine'. */
  name: Scalars['String']['output'];
};

/** Cache Control scope */
export enum CacheControlScope {
  /**
   * If a cached response has a PRIVATE scope, its value is accessible by only a single user.
   * To enforce this restriction, the cache needs to know how to identify that user.
   * @see: https://www.apollographql.com/docs/apollo-server/performance/caching/#identifying-users-for-private-responses
   */
  Private = 'PRIVATE',
  /**
   * By default, PUBLIC cached responses are accessible by all users.
   * @see https://www.apollographql.com/docs/apollo-server/performance/caching/#separating-responses-for-logged-in-and-logged-out-users
   */
  Public = 'PUBLIC',
}

/** Generic Car, implemented as ConfiguredCar or StockCar */
export type Car = {
  /** Carline information of a car. */
  carline: Maybe<CarlineBase>;
  /**
   * Unique identifier for a car.
   * Might contain AudiCode, MarketingCode etc.
   */
  code: Maybe<CarCode>;
  /** Model information of a car. */
  model: Maybe<CarModelBase>;
  /** Price information of a car. */
  prices: Maybe<CarPrices>;
};

/**
 * Unique identifier for a car.
 * Might contain AudiCode, MarketingCode etc.
 */
export type CarCode = {
  /** Country that the `id` is valid for. */
  country: Maybe<Scalars['String']['output']>;
  /**
   * Unique identifier for the stored car.
   * Might be AudiCode, MarketingCode etc.
   */
  id: Scalars['String']['output'];
  /** Language that the `id` is valid for. */
  language: Maybe<Scalars['String']['output']>;
};

export type CarCodeRepairInfo = {
  __typename?: 'CarCodeRepairInfo';
  status: Scalars['Boolean']['output'];
};

/** @deprecated data moved into `ConfiguredCar` */
export type CarFeature = {
  family: CarFeatureFamily;
  group: CarFeatureGroup;
  media: Maybe<Array<Maybe<ConfiguredCarMedia>>>;
  name: Scalars['String']['output'];
  pr3: Scalars['ID']['output'];
  pr7: Scalars['ID']['output'];
  price: Maybe<Price>;
};

/** Data object of the family of a feature */
export type CarFeatureFamily = {
  __typename?: 'CarFeatureFamily';
  /**
   * The family id is non translated and will be the same for every market
   * Use this to identify certain families
   * E.g. "Komfort.Spiegel"
   */
  id: Scalars['String']['output'];
  /**
   * The family name is market translated
   * This field is not to be used to identify families
   * E.g. "Mirrors"
   */
  name: Scalars['String']['output'];
};

/** Data object of the feature group of a feature */
export type CarFeatureGroup = {
  __typename?: 'CarFeatureGroup';
  /**
   * The group id is non translated and will be the same for every market
   * Use this to identify certain groups
   * E.g. 'Komfort'
   */
  id: Scalars['String']['output'];
  /**
   * The group name is market translated
   * This field is not to be used to identify groups
   * E.g. 'Comfort'
   */
  name: Scalars['String']['output'];
};

/**
 * Defines image types.
 *
 * Currently, all fields correspond to VSAPI image types. It is planned to replace them with more generic fields like
 * `xl` instead of `bigImage`.
 */
export type CarFeatureImage = {
  /** Size - width: 140, height: 63 */
  ak4DetailsL: Maybe<ImageSource>;
  /** Size - width: 79, height: 35 */
  ak4DetailsM: Maybe<ImageSource>;
  /** Size - width: 59, height: 26 */
  ak4DetailsS: Maybe<ImageSource>;
  /** Size - width: 159, height: 71 */
  ak4DetailsXL: Maybe<ImageSource>;
  /** Size - width: 20, height: 24 */
  ak4FoldingTile: Maybe<ImageSource>;
  /** Size - width: 40, height: 36 */
  ak4InnerTile: Maybe<ImageSource>;
  /** Size - width: 39, height: 24 */
  ak4OuterTile: Maybe<ImageSource>;
  /** Size - width: 259, height: 120 */
  akMotivL: Maybe<ImageSource>;
  /** Size - width: 119, height: 47 */
  akMotivM: Maybe<ImageSource>;
  /** Size - width: 59, height: 23 */
  akMotivS: Maybe<ImageSource>;
  /** Size - width: 704, height: 396 */
  bigImage: Maybe<ImageSource>;
  /** Size - width: 145, height: 80 */
  contentS: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  decoTileButton: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  decoTileHD: Maybe<ImageSource>;
  /** Size - width: 720, height: 1280 */
  decoTileSmallHD: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  decoTileSquareHD: Maybe<ImageSource>;
  /** Size - width: 1920, height: 814 */
  dimensionImageFrontHD: Maybe<ImageSource>;
  /** Size - width: 1300, height: 814 */
  dimensionImageFrontL: Maybe<ImageSource>;
  /** Size - width: 1920, height: 814 */
  dimensionImageSideHD: Maybe<ImageSource>;
  /** Size - width: 1300, height: 814 */
  dimensionImageSideL: Maybe<ImageSource>;
  /** Size - width: 1920, height: 814 */
  dimensionImageTopHD: Maybe<ImageSource>;
  /** Size - width: 1300, height: 814 */
  dimensionImageTopL: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  foldingTileButton: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  foldingTileHD: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  foldingTileSmallHD: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  foldingTileSquareHD: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  frontViewImage34HD: Maybe<ImageSource>;
  /** Size - width: 601, height: 1400 */
  frontViewImage34L: Maybe<ImageSource>;
  /** Size - width: 682, height: 512 */
  frontViewImage34M: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  frontViewImage34S: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  frontViewImage34XS: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  frontViewImageHD: Maybe<ImageSource>;
  /** Size - width: 1400, height: 601 */
  frontViewImageL: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  frontViewImageS: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  frontViewImageXS: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  headlightImage: Maybe<ImageSource>;
  /** Size - width: 400, height: 225 */
  headlightImageS: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  imageHD: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  imageSequenceEntryHD: Maybe<ImageSource>;
  /** Size - width: 704, height: 396 */
  imageSequenceEntryS: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  inlayTileButton: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  inlayTileHD: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  inlayTileSmallHD: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  inlayTileSquareHD: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  innerTileHD: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  interiorTileButton: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  interiorTileSmallHD: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  interiorTileSquareHD: Maybe<ImageSource>;
  /** Size - width: 2048, height: 2048 */
  interiorTileSquareL: Maybe<ImageSource>;
  /** Size - width: 159, height: 74 */
  nwbamMotiv: Maybe<ImageSource>;
  /** Size - width: 79, height: 47 */
  nwbsMotiv: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  outerTileButton: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  outerTileHD: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  outerTileSmallHD: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  outerTileSquareHD: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  seatImage: Maybe<ImageSource>;
  /** Size - width: 190, height: 250 */
  seatImageS: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  sideViewImageHD: Maybe<ImageSource>;
  /** Size - width: 1400, height: 601 */
  sideViewImageL: Maybe<ImageSource>;
  /** Size - width: 682, height: 512 */
  sideViewImageM: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  sideViewImageS: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  sideViewImageXS: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  smallImageHD: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  steeringWheelImage: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  steeringWheelImageS: Maybe<ImageSource>;
  /** Size - width: 400, height: 225 */
  teaserImage: Maybe<ImageSource>;
  /** Size - width: 249, height: 140 */
  thumbnailHD: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  wheelImage: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  wheelImageS: Maybe<ImageSource>;
};

/**
 * Defines text types.
 *
 * Currently, all fields correspond to VSAPI text types. It is planned to replace them with more generic fields like
 * `headline` instead of `akHeadline`.
 */
export type CarFeatureText = {
  akHeadline: Maybe<Scalars['String']['output']>;
  akRemarks: Maybe<Scalars['String']['output']>;
  akText: Maybe<Scalars['String']['output']>;
  benefits: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  carpetText: Maybe<Scalars['String']['output']>;
  carpetTitle: Maybe<Scalars['String']['output']>;
  dashboardText: Maybe<Scalars['String']['output']>;
  dashboardTitle: Maybe<Scalars['String']['output']>;
  engineDescription: Maybe<Scalars['String']['output']>;
  engineGear: Maybe<Scalars['String']['output']>;
  engineName: Maybe<Scalars['String']['output']>;
  engineNamesText: Maybe<Scalars['String']['output']>;
  exteriorColorText: Maybe<Scalars['String']['output']>;
  exteriorColorTitle: Maybe<Scalars['String']['output']>;
  exteriorContrastColorText: Maybe<Scalars['String']['output']>;
  exteriorContrastColorTitle: Maybe<Scalars['String']['output']>;
  headliningText: Maybe<Scalars['String']['output']>;
  headliningTitle: Maybe<Scalars['String']['output']>;
  intro: Maybe<Scalars['String']['output']>;
  seatsText: Maybe<Scalars['String']['output']>;
  seatsTitle: Maybe<Scalars['String']['output']>;
  teaserText: Maybe<Scalars['String']['output']>;
};

/**
 * Defines video types.
 *
 * Currently, all fields correspond to VSAPI video types. It is planned to replace them with more generic fields like
 * `mp4XL` instead of `bigVideoMp4`.
 */
export type CarFeatureVideo = {
  /** Size - width: 888, height: 500 */
  bigVideoMp4: Maybe<VideoSource>;
  /** Size - width: 704, height: 396 */
  videoMp4: Maybe<VideoSource>;
  /** Size - width: 888, height: 500 */
  videoThumb: Maybe<VideoSource>;
};

/** Identifier of a car. Can be used as Input value for specific queries. */
export type CarIdentifier = {
  /**
   * KVPS ID:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars['String']['output'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars['String']['output'];
  /**
   * User selected features/options contained in the ConfiguredCar
   * 7-digit or 3-digit pr numbers
   */
  equipmentOptions: Maybe<Array<Scalars['String']['output']>>;
  /** 4-digit pr number of the exterior color */
  exteriorColor: Maybe<Scalars['String']['output']>;
  /** 2-digit pr number of the interior color */
  interiorColor: Maybe<Scalars['String']['output']>;
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars['String']['output'];
  /** The model input separated in an own input */
  model: CarModelIdentifier;
  /** 5-digit sales group */
  salesGroup: Scalars['String']['output'];
};

/** Images for a car. */
export type CarImages = {
  /** Size - width: 1920, height: 1080 */
  sideViewImageHD: Maybe<ImageSource>;
  /** Size - width: 1400, height: 601 */
  sideViewImageL: Maybe<ImageSource>;
  /** Size - width: 682, height: 512 */
  sideViewImageM: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  sideViewImageS: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  sideViewImageXS: Maybe<ImageSource>;
};

/** Model information */
export type CarModelBase = {
  /** Identifier of the model */
  id: Maybe<CarModelIdentifier>;
  /** Name of the model */
  name: Maybe<Scalars['String']['output']>;
};

/**
 * Interface for a model of a car.
 * The values has to be handled as 'one' - they can't be modified or changed in an authoring environment.
 * The fo
 */
export type CarModelIdentifier = {
  /** 6-digit model base code. Describes the model/engine used in a configuration e.g. GENBAE */
  code: Scalars['String']['output'];
  /**
   * 3-digit pr numbers.
   * The extensions are PrNumbers that are part of the model code.
   * These PrNumbers are treated as standard options for this model (e.g. set a specific package or equipment).
   * This extensions can't be deselected in the configuration process. Sometimes this PrNumbers are more like a marker. For example mark the trimline of the model.
   * The handling of extensions can differ in different markets.
   */
  extensions: Maybe<Array<Scalars['String']['output']>>;
  /** 1-digit model version */
  version: Scalars['Int']['output'];
  /** The year of the catalogue release */
  year: Scalars['Int']['output'];
};

/** A specific price. */
export type CarPrice = {
  /** Market specific currency for the price */
  currencyDetails: Maybe<Currency>;
  /** Formatted price with currency (e.g. 1.321,50 EUR) */
  formattedValue: Maybe<Scalars['String']['output']>;
  /** Raw value of the price (e.g. 1321.5) */
  value: Scalars['Float']['output'];
  /** Formatted price without currency (e.g. 1.321,50) */
  valueAsText: Maybe<Scalars['String']['output']>;
};

/** All available prices for a car. */
export type CarPrices = {
  /** Total price of the car */
  total: Maybe<CarPrice>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type Carline = CarlineBase & {
  __typename?: 'Carline';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** Carline custom attributes. */
export type CarlineAttribute = {
  __typename?: 'CarlineAttribute';
  /** Machine readable unique identifier for a single attribute, e.g. 'umweltbonus'. */
  id: Scalars['String']['output'];
  /** Localized human readable display name, e.g. 'Elektro Förderung'. */
  name: Scalars['String']['output'];
};

/** information about a carline in the stocks of a market */
export type CarlineAvailableStock = {
  __typename?: 'CarlineAvailableStock';
  /** information about the new car stock */
  newCars: Maybe<Stock>;
  /** information about the used car stock */
  usedCars: Maybe<Stock>;
};

/** Carline information */
export type CarlineBase = {
  /** Id of the carline (a1sb) */
  id: Scalars['String']['output'];
  /** Localized name of the carline (A1 Sportback) */
  name: Scalars['String']['output'];
};

/** Consumption and emission strings for this carline. */
export type CarlineConsumptionAndEmission = {
  __typename?: 'CarlineConsumptionAndEmission';
  /** Raw consumption and emission ranges per fuel. */
  consumptionAndEmissionPerFuel: Array<CarlineConsumptionAndEmissionRanges>;
  /** Mesaurements data splited by fuel type with min and max values */
  measurements: Maybe<CarlineMeasurementsEec>;
  /**
   * Raw consumption and emission ranges.
   * @deprecated Please use consumptionAndEmissionPerFuel
   */
  ranges: Maybe<CarlineConsumptionAndEmissionRanges>;
  /** Summary of formatted consumption and emission strings. */
  summary: Maybe<CarlineConsumptionAndEmissionSummary>;
};

/** Consumption and emission range data for a carline. */
export type CarlineConsumptionAndEmissionData = {
  __typename?: 'CarlineConsumptionAndEmissionData';
  /** Emission range data. */
  co2Emission: Maybe<CarlineMinMaxValue>;
  /** Consumption range data. */
  consumption: Maybe<CarlineMinMaxValue>;
  /** Power consumption range data. */
  powerConsumption: Maybe<CarlineMinMaxValue>;
};

/** Contains raw values for consumption and emission data ranges. */
export type CarlineConsumptionAndEmissionRanges = {
  __typename?: 'CarlineConsumptionAndEmissionRanges';
  /** Unit used for displaying consumption values, e.g. 'l/100km' */
  consumptionUnit: Scalars['String']['output'];
  /**
   * Emissions and efficiency class range.
   * @deprecated No longer supported
   */
  eec: Maybe<EecRange>;
  /** Unit used for displaying emission values, e.g. 'g/100km' */
  emissionUnit: Scalars['String']['output'];
  /** Uniquely identifies a fuel type, e.g. 'diesel' */
  fuelTypeId: Scalars['String']['output'];
  /** NEDC emission and consumption data. */
  nedc: Maybe<CarlineConsumptionAndEmissionData>;
  /** WLTP emission and consumption data. */
  wltp: Maybe<CarlineConsumptionAndEmissionData>;
};

/**
 * Collection of formatted consumption and emission strings for this carline.
 * Ths values will contain references to footnotes that are listed in a separate field.
 * This is a convenient type for handling consumption & emission in the front end. No formatting is required.
 * The type 'TechnicalDataRanges' can be used for detailed information of the particular ranges and values.
 */
export type CarlineConsumptionAndEmissionSummary = {
  __typename?: 'CarlineConsumptionAndEmissionSummary';
  /**
   * Consumption in the correct formatted values in respect to country, language.
   * eg. "Stromverbrauch kombiniert<sup>{{footnote.reference.cae_global_disclaimer}}</sup>: 26.1-21.7 kWh&#x2F;100 km"
   */
  consumption: Scalars['String']['output'];
  /**
   * Emission in the correct formatted values in respect to country, language.
   * eg.  "CO<sub>2</sub>-Emissionen<sup>{{footnote.reference.cae_global_disclaimer}}</sup>: 0-0 g&#x2F;km"
   */
  emissionCO2: Scalars['String']['output'];
  /** Summary Nitrogen Dioxide text */
  emissionN02: Maybe<Scalars['String']['output']>;
  /**
   * List of footnote reference within the fields "consumption" and "emission"
   * eg.["footnote.reference.cae_global_disclaimer"]
   * }
   */
  footnoteReference: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CarlineConsumptionsItem = {
  __typename?: 'CarlineConsumptionsItem';
  fuelTypeId: Maybe<Scalars['String']['output']>;
  fuelTypeName: Maybe<Scalars['String']['output']>;
  isWltp: Maybe<Scalars['Boolean']['output']>;
  max: Maybe<Scalars['Float']['output']>;
  maxWithUnit: Maybe<Scalars['String']['output']>;
  min: Maybe<Scalars['Float']['output']>;
  minWithUnit: Maybe<Scalars['String']['output']>;
  unit: Maybe<Scalars['String']['output']>;
};

export type CarlineEecItem = {
  __typename?: 'CarlineEecItem';
  eecCo2Value: Maybe<Scalars['String']['output']>;
  eecFuelValue: Maybe<Scalars['String']['output']>;
  isPureCNG: Maybe<Scalars['Boolean']['output']>;
  isPureElectric: Maybe<Scalars['Boolean']['output']>;
};

export type CarlineEmissionItem = {
  __typename?: 'CarlineEmissionItem';
  max: Maybe<Scalars['Float']['output']>;
  maxWithUnit: Maybe<Scalars['String']['output']>;
  min: Maybe<Scalars['Float']['output']>;
  minWithUnit: Maybe<Scalars['String']['output']>;
  unit: Maybe<Scalars['String']['output']>;
};

export type CarlineEmissionSummary = {
  __typename?: 'CarlineEmissionSummary';
  consumption: Maybe<Scalars['String']['output']>;
  emissionCO2: Maybe<Scalars['String']['output']>;
  footnoteReferences: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** Describes both, real (backed by real vehicle data) and fake (mocked in the carline management UI) carline groups. */
export type CarlineGroup = {
  __typename?: 'CarlineGroup';
  /** Ordered list of carlines. The exact order is determined in the carline management UI. */
  carlines: Array<CarlineStructureCarline>;
  /** Optional exterior views. */
  exteriorViews: Maybe<ExteriorViews>;
  /** @deprecated Use identifier.id */
  id: Scalars['String']['output'];
  /** Uniquely identifies a carline. */
  identifier: CarlineIdentifier;
  /** @deprecated Carline group will not be mocked in the future */
  isMocked: Scalars['Boolean']['output'];
  /**
   * Carline editor metadata.
   * @deprecated Not used anymore
   */
  metadata: CarlineGroupMetadata;
  /** Localized, human readable display name, e.g. 'A3' */
  name: Scalars['String']['output'];
};

/** Describes both, real (backed by real vehicle data) and fake (mocked in the carline management UI) carline groups. */
export type CarlineGroupCarlinesArgs = {
  carlinesInput: InputMaybe<CarlinesInput>;
};

/** Input type to uniquely identify a carline group. */
export type CarlineGroupIdentifierInput = {
  /**
   * KVPS brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: InputMaybe<Scalars['String']['input']>;
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars['String']['input'];
  /** Machine readable carline group id, e.g. 'a1' */
  id: Scalars['String']['input'];
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars['String']['input'];
  targetGroup: InputMaybe<Scalars['String']['input']>;
};

/** Describes carline group metadata for the carline editor. */
export type CarlineGroupMetadata = {
  __typename?: 'CarlineGroupMetadata';
  /**
   * Visibility flag of this carline group.
   * @deprecated Not used anymore
   */
  isActive: Scalars['Boolean']['output'];
  /**
   * List of previous carline group IDs.
   * @deprecated Not used anymore
   */
  previousIds: Array<Maybe<Scalars['String']['output']>>;
  /**
   * The carline group sort order.
   * @deprecated Not used anymore
   */
  sortOrder: Maybe<Scalars['Int']['output']>;
};

export type CarlineGroupsIdentifierInput = {
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars['String']['input'];
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars['String']['input'];
};

/** Contains Health Status */
export type CarlineHealth = {
  __typename?: 'CarlineHealth';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

/** Uniquely identifies a carline. */
export type CarlineIdentifier = {
  __typename?: 'CarlineIdentifier';
  /**
   * KVPS brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars['String']['output'];
  /** Carline ID */
  carlineId: Maybe<Scalars['String']['output']>;
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars['String']['output'];
  /** Unique, machine readable carline id, e.g. 'a3sb' */
  id: Scalars['String']['output'];
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars['String']['output'];
  /** VTP ID used on fake carlines */
  vtpId: Maybe<Scalars['String']['output']>;
};

/** Input type to uniquely identify a carline. */
export type CarlineIdentifierInput = {
  /**
   * KVPS brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: InputMaybe<Scalars['String']['input']>;
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars['String']['input'];
  /** Unique, machine readable carline id, e.g. 'a3sb' */
  id: Scalars['String']['input'];
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars['String']['input'];
  /** Unique, Target group identifier (currently not used) */
  targetGroup: InputMaybe<Scalars['String']['input']>;
};

/** Contains status for carline service */
export type CarlineInfoServiceInfo = OneGraphSubServiceInfo & {
  __typename?: 'CarlineInfoServiceInfo';
  endpoint: Scalars['String']['output'];
  health: OneGraphServiceHealth;
  id: Scalars['ID']['output'];
  meta: OneGraphServiceMetaInfo;
  name: Scalars['String']['output'];
  services: Maybe<Array<Maybe<OneGraphSubServiceDownStreamInfo>>>;
  version: Scalars['String']['output'];
};

export type CarlineLink = {
  __typename?: 'CarlineLink';
  /** Link url */
  url: Maybe<Scalars['String']['output']>;
};

export type CarlineMeasurementsEec = {
  __typename?: 'CarlineMeasurementsEEC';
  consumptions: Maybe<Array<Maybe<CarlineConsumptionsItem>>>;
  eecModel: Maybe<CarlineEecItem>;
  emission: Maybe<CarlineEmissionItem>;
  summary: Maybe<CarlineEmissionSummary>;
};

/** Describes carline metadata for the carline editor. */
export type CarlineMetadata = {
  __typename?: 'CarlineMetadata';
  /**
   * Visibility flag of this carline.
   * @deprecated Not used anymore
   */
  isActive: Scalars['Boolean']['output'];
  /**
   * List of previous carline IDs.
   * @deprecated Not used anymore
   */
  previousIds: Array<Scalars['String']['output']>;
  /** @deprecated Not used anymore */
  releaseDate: Maybe<Scalars['String']['output']>;
  /**
   * The carline sort order.
   * @deprecated Not used anymore
   */
  sortOrder: Scalars['Int']['output'];
};

/** Upper and lower boundary of a value range. */
export type CarlineMinMaxValue = {
  __typename?: 'CarlineMinMaxValue';
  /** Upper range boundary. */
  max: Maybe<Scalars['Float']['output']>;
  /** Lower range boundary. */
  min: Maybe<Scalars['Float']['output']>;
};

/** Describes a generic carline price. */
export type CarlinePrice = {
  __typename?: 'CarlinePrice';
  /** Currency code according to ISO 4217. */
  code: Scalars['String']['output'];
  /** Currency symbol as string. */
  symbol: Maybe<Scalars['String']['output']>;
  /** Raw value. */
  value: Scalars['Float']['output'];
  /** Formatted value according to country / language. */
  valueAsText: Maybe<Scalars['String']['output']>;
};

/** Contains status for carline service */
export type CarlineStatus = {
  __typename?: 'CarlineStatus';
  endpoint: Scalars['String']['output'];
  health: CarlineHealth;
  id: Scalars['String']['output'];
  meta: CarlineStatusMeta;
  name: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

/** Carline status meta information */
export type CarlineStatusMeta = {
  __typename?: 'CarlineStatusMeta';
  channel: Scalars['String']['output'];
  homepage: Scalars['String']['output'];
  issueTracker: Scalars['String']['output'];
  team: Scalars['String']['output'];
};

/** Describes the carline structure for a specific brand, country, language and target group. */
export type CarlineStructure = {
  __typename?: 'CarlineStructure';
  /** Ordered list of carline groups. The exact order is determined in the carline management UI. */
  carlineGroups: Array<CarlineGroup>;
};

/** Describes both, real (backed by real vehicle data) and fake (mocked in the carline management UI) carlines. */
export type CarlineStructureCarline = {
  __typename?: 'CarlineStructureCarline';
  /**
   * information about the carline in the stocks of the market.
   * the market is selected by the information in the `CarlineIdentifier`
   */
  availableStock: Maybe<CarlineAvailableStock>;
  /** Body type of this carline. */
  bodyType: Maybe<BodyType>;
  /** Array of Body types of this carline. */
  bodyTypes: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Renderman delivers usageTypes this data is a mapped here. */
  categories: Array<Scalars['String']['output']>;
  /** Summary of consumption and emission ranges formatted as string according to country and language. */
  consumptionAndEmission: CarlineConsumptionAndEmission;
  /** Optional exterior views. */
  exteriorViews: Maybe<ExteriorViews>;
  fuelTypes: Array<FuelType>;
  /** Carline further carline group ids */
  furtherCarlineGroupIds: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** @deprecated Please use identifier.id */
  id: Scalars['String']['output'];
  /** Unique carline identifier */
  identifier: CarlineIdentifier;
  /** Fake carlines are maintained by content managers and are not configurable. Ex: end of secrecy (eos), promoted carlines */
  isFake: Scalars['Boolean']['output'];
  /** @deprecated Use isFake instead */
  isMocked: Scalars['Boolean']['output'];
  /** Carline links to specific pages */
  links: Maybe<CarlineStructureLink>;
  /**
   * Carline editor metadata.
   * @deprecated Not used anymore
   */
  metadata: CarlineMetadata;
  /**
   * Lowest possible price of this carline.
   * @deprecated use prices.minPrice instead
   */
  minPrice: Maybe<CarlinePrice>;
  /** The year of the catalogue release, e.g. '2021' */
  modelYear: Scalars['Int']['output'];
  /** Localized, human readable display name, e.g. 'A3 Sportback' */
  name: Scalars['String']['output'];
  /** Contains Financing Parameters and also the minPrice. */
  prices: CarlineTrimlineFinanceablePrices;
  releaseDate: Maybe<Scalars['String']['output']>;
  /** Trimlines of this carline. */
  trimlines: Array<Trimline>;
  vehicleType: Maybe<VehicleType>;
};

/** Describes both, real (backed by real vehicle data) and fake (mocked in the carline management UI) carlines. */
export type CarlineStructureCarlineAvailableStockArgs = {
  partnerInfo: InputMaybe<PartnerInfo>;
};

/** Describes both, real (backed by real vehicle data) and fake (mocked in the carline management UI) carlines. */
export type CarlineStructureCarlinePricesArgs = {
  financingInput: InputMaybe<FinancingInput>;
};

/** Input type to uniquely identify a carline for carline structure requests. */
export type CarlineStructureCarlineIdentifierInput = {
  /**
   * KVPS brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: InputMaybe<Scalars['String']['input']>;
  /** Unique, machine readable carline id, e.g. 'a3sb' */
  carlineId: InputMaybe<Scalars['String']['input']>;
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars['String']['input'];
  /** Unique, machine readable carline id, e.g. 'a3sb' */
  id: InputMaybe<Scalars['String']['input']>;
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars['String']['input'];
};

/** options for filter (reduce number of) carlines */
export type CarlineStructureForStockCarsCarlineFilterInput = {
  /**
   * hide all carlines, which are currently not available in selected stock
   *
   * NEW = "carlineGroups[].carlines[].availableStock.newCars.amount" is zero
   * USED = "carlineGroups[].carlines[].availableStock.usedCars.amount" is zero
   * NEW_AND_USED = "carlineGroups[].carlines[].availableStock.newCars.amount" and "carlineGroups[].carlines[].availableStock.usedCars.amount" are zero
   * NEW_OR_USED = "carlineGroups[].carlines[].availableStock.newCars.amount" or "carlineGroups[].carlines[].availableStock.usedCars.amount" are zero
   */
  hideNotAvailableInCarsStock: InputMaybe<StockCarsTypeCombinationInput>;
};

/** options for filter (reduce number of) carline groups */
export type CarlineStructureForStockCarsCarlineGroupsFilterInput = {
  /** hide all carline groups without carlines (e.g. all are filtered by other options) */
  hideWithNoCarlines: InputMaybe<Scalars['Boolean']['input']>;
};

/** options for filter (reduce number of) carline groups and/or carlines */
export type CarlineStructureForStockCarsFilterInput = {
  /** options for filter (reduce number of) carline groups */
  carlineGroups: InputMaybe<CarlineStructureForStockCarsCarlineGroupsFilterInput>;
  /** options for filter (reduce number of) carlines */
  carlines: InputMaybe<CarlineStructureForStockCarsCarlineFilterInput>;
};

export type CarlineStructureIdentifierInput = {
  /** KVPS brand id. e.g. A for Audi */
  brand: InputMaybe<Scalars['String']['input']>;
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars['String']['input'];
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars['String']['input'];
};

export type CarlineStructureLink = {
  __typename?: 'CarlineStructureLink';
  /** Link to configurator page */
  carlineStartPage: Maybe<CarlineLink>;
  /** Link to configurator page */
  configuratorPage: Maybe<CarlineLink>;
};

/** Determines the order of fetch and merge operations between API and database. */
export enum CarlineStructureMode {
  /** Fetch carline structure from the API and merge preview carlines and carline groups from the database. */
  Automatic = 'AUTOMATIC',
  /** Fetch carline structure from the database and fill in details from the API. */
  Manual = 'MANUAL',
}

export type CarlineTrimlineFinanceablePrices = {
  __typename?: 'CarlineTrimlineFinanceablePrices';
  financingParameters: Maybe<FinancingParameters>;
  /** Price of the cheapest model that belongs to the carline or trimline */
  minPrice: Maybe<Price>;
  monthlyRate: Maybe<MonthlyRateResult>;
};

/** Carlines configuration input if non configurable carlines should be included for this request */
export type CarlinesInput = {
  includeNonConfigurables: InputMaybe<Scalars['Boolean']['input']>;
};

/** @deprecated change mutation not implemented */
export type ChangeAction = {
  itemId: Scalars['String']['input'];
  resolveConflicts: InputMaybe<Scalars['Boolean']['input']>;
  type: ChangeType;
};

/** The input type for the query changeConfiguredCarFeature. */
export type ChangeConfiguredCarFeatureInput = {
  /** An action that defines if you want to remove or to add the given feature. */
  action: FeatureChangeAction;
  /**
   * Set to `true` if you want to automatically resolve all occuring conflicts by
   * their default solutions. In this case you can rely on that the returned payload
   * will always contain a `configuredCar`.
   * Set to `false`(the default) if you want to manually handle possible conflicts.
   */
  autoResolveConflicts: InputMaybe<Scalars['Boolean']['input']>;
  /** The feature id (pr3) that you want to add/remove to the configuration. */
  feature: Scalars['ID']['input'];
  /** The configured car you want to change */
  id: ConfiguredCarInput;
};

/**
 * The input type for the query changeConfiguredCarModel.
 *
 * In contrast to a feature change you can only set a new model and not remove a model in the configuration.
 */
export type ChangeConfiguredCarModelInput = {
  /**
   * Set to `true` if you want to automatically resolve all occuring conflicts by
   * their default solutions. In this case you can rely on that the returned payload
   * will always contain a `configuredCar`.
   * Set to `false`(the default) if you want to manually handle possible conflicts.
   */
  autoResolveConflicts: InputMaybe<Scalars['Boolean']['input']>;
  /** The configured car you want to change */
  id: ConfiguredCarInput;
  /** The model that you want to set to the configuration. */
  model: ConfiguredCarModelInput;
};

/** The return type for the query changeConfiguredCarModel and changeConfiguredCarFeature. */
export type ChangeConfiguredCarPayload = {
  __typename?: 'ChangeConfiguredCarPayload';
  /**
   * A configured car, if the configuration could be changed successfully. `null` if a
   * conflict occured while changing the configuration and `autoResolveConflict` was set to `false`.
   */
  configuredCar: Maybe<ConfiguredCar>;
  /**
   * Contains a conflict, if a conflict occured while changing the configuration. If `autoResolveConflict` was set to
   * `true` this field contains that conflict that was automatically resolved.
   */
  conflict: Maybe<ConfiguredCarConflict>;
  /**
   * A list of side effects (without user choice) that
   * 1. occured while changing the configuration, if configuredCar is not null.
   * 2. will occur, if configuredCar is null and when accepting the conflict in `conflict`.
   */
  sideEffects: Array<SolutionSideEffect>;
};

/** The input type for the query changeConfiguredCarTrimline. */
export type ChangeConfiguredCarTrimlineInput = {
  /** Set to true if you want to automatically resolve all occuring conflicts by their default solutions. In this case you can rely on that the returned payload will always contain a configuredCar. Set to false (the default) if you want to manually handle possible conflicts. */
  autoResolveConflicts: InputMaybe<Scalars['Boolean']['input']>;
  /** The configured car you want to change */
  id: ConfiguredCarInput;
  /** The trimline id that you want to set to the configuration. */
  trimline: Scalars['ID']['input'];
};

/** The input type for the changeConflictSolutions query */
export type ChangeConflictSolutionsInput = {
  /** Represents the feature/model/trimeline Id that originated the conflict. Take this value from `ConfiguredCarConflict.triggerId`. */
  conflictTriggerId: Scalars['ID']['input'];
  /** Intermediate configured car identifier that is still in conflict. It is obtained from ConfiguredCarConflict.conflict.id */
  id: ConfiguredCarInput;
  /** Last conflict free configuration. This value should be taken from ConfiguredCarConflict.conflict.lastGoodConfiguredCarIdentifier */
  lastGoodConfiguredCarIdentifier: InputMaybe<ConfiguredCarInput>;
  /**
   * The PR numbers of the solutions to be selected. Take these values from `Solution.feature.pr3`.
   * ⚠️  Changing several solutions in the same `changeConflictSolutions` query is not
   * supported yet. Every time a user selects a new solution a new `changeConflictSolutions`
   * query must be sent. This is required due to the selection of a solution
   * could affect the list of available solution groups and solutions available to the user.
   *
   * Example:
   *
   * 1. A conflict is triggered and there are 3 solution groups X, Y, Z.
   *
   * X -> *a*, b, c
   * Y -> *d*, e, f
   * Z -> *g*, h, i
   *
   * By default the first solution is selected for every solution group.
   *
   * 2. The user decides to select *e* for solution group *Y* and *i* for *Z*
   *
   * 3. The frontend should send two `changeConflictSolutions`
   * - with solutions ["a", "e", "g"]
   * - with solutions ["a", "e", "i"]
   */
  solutions: Array<Scalars['String']['input']>;
};

/** The return type of the changeConflictSolutions query */
export type ChangeConflictSolutionsPayload = {
  __typename?: 'ChangeConflictSolutionsPayload';
  conflict: ConfiguredCarConflict;
};

/** @deprecated change mutation not implemented */
export enum ChangeType {
  Add = 'ADD',
  Remove = 'REMOVE',
}

/** Returned payload containing ConfiguredCar and conflict resolution information as a result of a configuration change. */
export type ChangedConfiguredCar = {
  __typename?: 'ChangedConfiguredCar';
  autoResolvedConflict: Scalars['Boolean']['output'];
  configuredCar: ConfiguredCar;
};

/** Implementation of the subgraph service info object. Returns status info for the cola subgraph */
export type ColaServiceInfo = OneGraphSubServiceInfo & {
  __typename?: 'ColaServiceInfo';
  endpoint: Scalars['String']['output'];
  health: OneGraphServiceHealth;
  id: Scalars['ID']['output'];
  meta: OneGraphServiceMetaInfo;
  name: Scalars['String']['output'];
  services: Maybe<Array<Maybe<OneGraphSubServiceDownStreamInfo>>>;
  version: Scalars['String']['output'];
};

/** @deprecated data moved into `ConfiguredCar` */
export type Configuration = {
  __typename?: 'Configuration';
  availableOptions: Maybe<Array<Option>>;
  ave: Scalars['String']['output'];
  carline: Carline;
  emissionAndConsumption: Maybe<EmissionAndConsumption>;
  engine: Engine;
  prString: Scalars['String']['output'];
  priceDate: PriceDate;
  prices: Prices;
  renderImages: Maybe<RenderImages>;
  selectedOptions: Maybe<Array<Option>>;
  /** @deprecated No longer supported */
  techData: TechData;
};

/** @deprecated data moved into `ConfiguredCar` */
export type ConfigurationAvailableOptionsArgs = {
  financingInput: InputMaybe<FinancingInput>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type ConfigurationPricesArgs = {
  financingInput: InputMaybe<FinancingInput>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type ConfigurationSelectedOptionsArgs = {
  financingInput: InputMaybe<FinancingInput>;
};

/** @deprecated data moved into `ConfiguredCar.catalog.features` */
export type ConfigurationFeature = CarFeature & {
  __typename?: 'ConfigurationFeature';
  family: CarFeatureFamily;
  group: CarFeatureGroup;
  media: Maybe<Array<Maybe<ConfiguredCarMedia>>>;
  name: Scalars['String']['output'];
  pr3: Scalars['ID']['output'];
  pr7: Scalars['ID']['output'];
  price: Maybe<Price>;
};

/** @deprecated queries deprecated */
export type ConfigurationIdentifier = {
  identifier: Scalars['String']['input'];
  type: IdentifierType;
};

/** @deprecated data moved into `ConfiguredCar.catalog.packages` */
export type ConfigurationPackage = CarFeature & {
  __typename?: 'ConfigurationPackage';
  content: Array<ConfigurationFeature>;
  family: CarFeatureFamily;
  group: CarFeatureGroup;
  media: Maybe<Array<Maybe<ConfiguredCarMedia>>>;
  name: Scalars['String']['output'];
  pr3: Scalars['ID']['output'];
  pr7: Scalars['ID']['output'];
  price: Maybe<Price>;
};

/** A fully configured car */
export type ConfiguredCar = Car & {
  __typename?: 'ConfiguredCar';
  /**
   * The id and name of the carline of the configured car
   * data source: DPU https://pre.web-api.audi.com/dpu/v1/[country]/[language]/stateless-configuration?mode=price&ids=[prstring]
   */
  carline: Maybe<ConfiguredCarCarline>;
  /** The carline object of the configured car */
  carlineStructureCarline: Maybe<CarlineStructureCarline>;
  /** The complete catalog with all available features, model entries and package details. */
  catalog: Maybe<ConfiguredCarCatalog>;
  /** configured cars are not saved cars, so the code is always empty. */
  code: ConfiguredCarCarCode;
  /**
   * Contains data about the outside color of the car and hints for the brightness of overlapping text
   * data source: DPU https://pre.web-api.audi.com/dpu/v1/[country]/[language]/stateless-configuration?mode=price&ids=[prstring]
   */
  exteriorColor: ConfiguredCarColor;
  /** The "id" attribute is still experimental. Do not use it in production. */
  id: ConfiguredCarIdentifier;
  /**
   * A list of incentives
   * data source: DPU https://pre.web-api.audi.com/dpu/v1/[country]/[language]/stateless-configuration?mode=price&ids=[prstring]
   */
  incentives: Array<Incentive>;
  /** Contains EEC labels and other media resources of the configured car */
  media: Maybe<ConfiguredCarMediaResources>;
  /**
   *   Object which describes the car
   * data source: DPU https://pre.web-api.audi.com/dpu/v1/[country]/[language]/stateless-configuration?mode=price&ids=[prstring]
   */
  model: Maybe<ConfiguredCarModelBase>;
  prices: Maybe<ConfiguredCarPrices>;
  /** similar cars in new car stock and used car stock with this configured car as reference */
  similarCars: Maybe<SimilarCarsStock>;
  technicalData: Maybe<ConfiguredCarTechnicalData>;
};

/** media type Audi Visualization Engine (AVE 3d renderengine) */
export type ConfiguredCarAve = {
  __typename?: 'ConfiguredCarAve';
  /** special AVE string containig all prStrings in a special form */
  renderString: Maybe<Scalars['String']['output']>;
};

/** Input type to uniquely identify a carline. */
export type ConfiguredCarByCarlineInput = {
  /**
   * KVPS brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars['String']['input'];
  /** Unique, machine readable carline id, e.g. `a3sb` */
  carlineId: Scalars['String']['input'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars['String']['input'];
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars['String']['input'];
};

export type ConfiguredCarCarCode = CarCode & {
  __typename?: 'ConfiguredCarCarCode';
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Maybe<Scalars['String']['output']>;
  repairInfo: Maybe<CarCodeRepairInfo>;
  urls: Maybe<ConfiguredCarCarCodeUrls>;
};

export type ConfiguredCarCarCodeUrls = {
  __typename?: 'ConfiguredCarCarCodeUrls';
  entry: Maybe<Scalars['String']['output']>;
  pdf: Maybe<Scalars['String']['output']>;
};

/** The carline of the configured car */
export type ConfiguredCarCarline = CarlineBase & {
  __typename?: 'ConfiguredCarCarline';
  /** e.g. 'a4limo' */
  id: Scalars['String']['output'];
  /** e.g. 'A4 Limousine' */
  name: Scalars['String']['output'];
};

export type ConfiguredCarCatalog = {
  __typename?: 'ConfiguredCarCatalog';
  /** All features available for the configured car. */
  features: ConfiguredCarFeatures;
  /** All engine specific model entries of the catalog. */
  models: Maybe<ConfiguredCarCatalogModels>;
  /** @deprecated ADR: prefer object return types over arrays. */
  packages: Maybe<Array<ConfigurationPackage>>;
  /** All packages available for the configured car. A package may contain several features. */
  packagesDetails: ConfiguredCarPackages;
};

export type ConfiguredCarCatalogModel = {
  __typename?: 'ConfiguredCarCatalogModel';
  /** The driveName of this model. e.g. "Front-wheel drive" */
  driveName: Maybe<Scalars['String']['output']>;
  /** EcoLabel available for BEVs in select markets like ES and IT, e.g. "ZERO" */
  ecoLabel: Maybe<EcoLabel>;
  /** The engineName of this model. e.g. "35 TFSI" */
  engineName: Maybe<Scalars['String']['output']>;
  /** The fullName of this model. e.g. "Sport 35 TFSI 6-speed" */
  fullName: Maybe<Scalars['String']['output']>;
  /** The gearName of this model. e.g. "6-speed" */
  gearName: Maybe<Scalars['String']['output']>;
  /** The gearType of this model. e.g. "Manual" */
  gearType: Maybe<Scalars['String']['output']>;
  /** id of the model in the format "${audicode - 6 digits}${version - 1 digit}_${modelyear}". e.G. "8WCA7G0_2023" */
  id: Maybe<Scalars['String']['output']>;
  identifier: Maybe<ConfiguredCarModelIdentifier>;
  /** The price for the selected model */
  price: Maybe<ConfiguredCarCatalogModelPrice>;
  /** The price for the selected model with additional fees. May not be available in every market */
  priceWithFees: Maybe<ConfiguredCarCatalogModelPrice>;
  /** The status flags for this model */
  status: Maybe<Status>;
  /** The trimline id of the model. e.g. "trimline_default" */
  trimlineId: Maybe<Scalars['String']['output']>;
};

export type ConfiguredCarCatalogModelPrice = CarPrice & {
  __typename?: 'ConfiguredCarCatalogModelPrice';
  /** Details of the currency of the price */
  currencyDetails: Maybe<Currency>;
  /** The formatted value as text. e.g. '39.100,00 EUR' */
  formattedValue: Maybe<Scalars['String']['output']>;
  /** The value. e.g. 39100 */
  value: Scalars['Float']['output'];
  /** The value as text. e.g. '39.100,00' */
  valueAsText: Maybe<Scalars['String']['output']>;
};

/** Wrapper class for ConfiguredCarCatalogModel */
export type ConfiguredCarCatalogModels = {
  __typename?: 'ConfiguredCarCatalogModels';
  data: Maybe<Array<Maybe<ConfiguredCarCatalogModel>>>;
};

/**
 * Contains data about a color of the car and hints about the brightness of overlapping texts
 * data source: DPU https://pre.web-api.audi.com/dpu/v1/[country]/[language]/stateless-configuration?mode=price&ids=[prstring]
 */
export type ConfiguredCarColor = {
  __typename?: 'ConfiguredCarColor';
  /** The audi code of the color */
  code: Scalars['String']['output'];
  /** A hint about which color texts must have if they overlap the cars color. (possible values: 'LIGHT', 'DARK') */
  contrastThemeHint: ContrastThemeHint;
  /** The hex representation of the color. */
  hexColor: Scalars['String']['output'];
};

/** The type to present a conflict while changinging the configuration. */
export type ConfiguredCarConflict = {
  __typename?: 'ConfiguredCarConflict';
  /** Identifier to be used as input of the acceptConflictSolution query to resolve the conflict */
  id: ConflictConfiguredCarIdentifier;
  /** Identifier that represents the last valid configuration before the conflict occurred. It must be used as input of the acceptConflictSolution query to resolve the conflict */
  lastGoodConfiguredCarIdentifier: ConflictConfiguredCarIdentifier;
  /** how much does it cost to solve the conflict with the pre-selected solutions */
  price: Price;
  /** groups of solutions containing alternative features that should be added or removed to solve the conflict */
  solutionGroups: Array<SolutionGroups>;
  /** A reference to a feature or model that triggered the configuration conflict. It can be used to show the name and the prices of the item that triggered this conflict. */
  trigger: ConfiguredCarFeature;
  /** A reference to the original trigger. This value has to be supplied to conflict change and accept queries. */
  triggerId: Scalars['ID']['output'];
};

/** (PDF-) documents related to a configured car. */
export type ConfiguredCarDocuments = {
  __typename?: 'ConfiguredCarDocuments';
  /** Link to PDF-document with ENVKV Label */
  envkvLabelPdfUrl: Maybe<Scalars['String']['output']>;
  /** Link to PDF-document with car-configuration-summary. */
  summaryPdfUrl: Maybe<Scalars['String']['output']>;
};

/** Contains media urls related to EEC (Energy Efficiency Certificate) labels. */
export type ConfiguredCarEecLabelImageUrls = {
  __typename?: 'ConfiguredCarEECLabelImageUrls';
  /** An url pointing to the original sized EEC label for this configuration. */
  eecLabelImageUrl: Maybe<Scalars['String']['output']>;
  /**
   * An url pointing to a smaller sized EEC label for this configuration, if available.
   * E.g. SVG typed images do not need a smaller scaled version.
   */
  eecLabelImageUrlSmall: Maybe<Scalars['String']['output']>;
};

export type ConfiguredCarFeature = ConfiguredCarFeatureInterface & {
  __typename?: 'ConfiguredCarFeature';
  /**
   * Family of the package
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  family: CarFeatureFamily;
  /**
   * Feature group mapping
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  featureGroup: Maybe<ConfiguredCarFeatureGroup>;
  /**
   * The type of the feature
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  featureType: ConfiguredCarFeatureType;
  financingParameters: Maybe<FinancingParameters>;
  /**
   * The feature group of the package
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  group: CarFeatureGroup;
  images: Maybe<ConfiguredCarFeatureImage>;
  /**
   * List of feature ids in which the current feature is contained
   * @deprecated A feature can only be contained in a package. Use the ConfiguredCarCatalog.packagesDetails resolver if you would like to acccess package's features.
   */
  isContainedInFeatures: Array<Scalars['String']['output']>;
  /** e.g. 'Innenspiegel abblendbar' */
  name: Scalars['String']['output'];
  /** e.g. '4L2' */
  pr3: Scalars['ID']['output'];
  /** e.g. 'MIRS4L2' */
  pr7: Scalars['ID']['output'];
  /**
   * The price of the feature
   * data source: DPU https://pre.web-api.audi.com/dpu/v1/[country]/[language]/stateless-configuration?mode=price&ids=[prstring]
   */
  price: Maybe<Price>;
  rate: Maybe<PartRateResult>;
  /** The status of the feature */
  status: Status;
  /**
   * Additional categorization. Optional. (e.g. family: 'Sitze.Sitze', subFamily: 'sport')
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  subFamily: Maybe<CarFeatureFamily>;
  /** Marketing text blocks */
  texts: Maybe<ConfiguredCarFeatureText>;
  /**
   * Links to Labels and PDF infos with data if the feature is from type tyre. Feature group 'RIMS'
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  tyreLabels: Maybe<Array<Maybe<ConfiguredCarFeatureTyreLabel>>>;
  /**
   * This indicates the vendor of the feature. This field is only used for rims and will be null for other feature types.
   * For rims this option will always be set to AUDI except for when the rim is a Audi Sport rim, then it will be set to AUDI_SPORT.
   */
  vendor: Maybe<ConfiguredCarFeatureVendor>;
  videos: Maybe<ConfiguredCarFeatureVideo>;
};

/** Possible groups or categories to which a catalog feature could belong to */
export enum ConfiguredCarFeatureGroup {
  Accessories = 'ACCESSORIES',
  ExclusiveExteriorColors = 'EXCLUSIVE_EXTERIOR_COLORS',
  ExteriorColors = 'EXTERIOR_COLORS',
  InteriorTiles = 'INTERIOR_TILES',
  Lights = 'LIGHTS',
  Rims = 'RIMS',
  Seats = 'SEATS',
  SteeringWheels = 'STEERING_WHEELS',
}

/**
 * Defines image types.
 *
 * Currently, all fields correspond to VSAPI image types. It is planned to replace them with more generic fields like
 * `xl` instead of `bigImage`.
 */
export type ConfiguredCarFeatureImage = CarFeatureImage & {
  __typename?: 'ConfiguredCarFeatureImage';
  /** Size - width: 140, height: 63 */
  ak4DetailsL: Maybe<ImageSource>;
  /** Size - width: 79, height: 35 */
  ak4DetailsM: Maybe<ImageSource>;
  /** Size - width: 59, height: 26 */
  ak4DetailsS: Maybe<ImageSource>;
  /** Size - width: 159, height: 71 */
  ak4DetailsXL: Maybe<ImageSource>;
  /** Size - width: 20, height: 24 */
  ak4FoldingTile: Maybe<ImageSource>;
  /** Size - width: 40, height: 36 */
  ak4InnerTile: Maybe<ImageSource>;
  /** Size - width: 39, height: 24 */
  ak4OuterTile: Maybe<ImageSource>;
  /** Size - width: 259, height: 120 */
  akMotivL: Maybe<ImageSource>;
  /** Size - width: 119, height: 47 */
  akMotivM: Maybe<ImageSource>;
  /** Size - width: 59, height: 23 */
  akMotivS: Maybe<ImageSource>;
  /** Size - width: 704, height: 396 */
  bigImage: Maybe<ImageSource>;
  /** Size - width: 145, height: 80 */
  contentS: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  decoTileButton: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  decoTileHD: Maybe<ImageSource>;
  /** Size - width: 720, height: 1280 */
  decoTileSmallHD: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  decoTileSquareHD: Maybe<ImageSource>;
  /** Size - width: 1920, height: 814 */
  dimensionImageFrontHD: Maybe<ImageSource>;
  /** Size - width: 1300, height: 814 */
  dimensionImageFrontL: Maybe<ImageSource>;
  /** Size - width: 1920, height: 814 */
  dimensionImageSideHD: Maybe<ImageSource>;
  /** Size - width: 1300, height: 814 */
  dimensionImageSideL: Maybe<ImageSource>;
  /** Size - width: 1920, height: 814 */
  dimensionImageTopHD: Maybe<ImageSource>;
  /** Size - width: 1300, height: 814 */
  dimensionImageTopL: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  foldingTileButton: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  foldingTileHD: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  foldingTileSmallHD: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  foldingTileSquareHD: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  frontViewImage34HD: Maybe<ImageSource>;
  /** Size - width: 601, height: 1400 */
  frontViewImage34L: Maybe<ImageSource>;
  /** Size - width: 682, height: 512 */
  frontViewImage34M: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  frontViewImage34S: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  frontViewImage34XS: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  frontViewImageHD: Maybe<ImageSource>;
  /** Size - width: 1400, height: 601 */
  frontViewImageL: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  frontViewImageS: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  frontViewImageXS: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  headlightImage: Maybe<ImageSource>;
  /** Size - width: 400, height: 225 */
  headlightImageS: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  imageHD: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  imageSequenceEntryHD: Maybe<ImageSource>;
  /** Size - width: 704, height: 396 */
  imageSequenceEntryS: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  inlayTileButton: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  inlayTileHD: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  inlayTileSmallHD: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  inlayTileSquareHD: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  innerTileHD: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  interiorTileButton: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  interiorTileSmallHD: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  interiorTileSquareHD: Maybe<ImageSource>;
  /** Size - width: 2048, height: 2048 */
  interiorTileSquareL: Maybe<ImageSource>;
  /** Size - width: 159, height: 74 */
  nwbamMotiv: Maybe<ImageSource>;
  /** Size - width: 79, height: 47 */
  nwbsMotiv: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  outerTileButton: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  outerTileHD: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  outerTileSmallHD: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  outerTileSquareHD: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  seatImage: Maybe<ImageSource>;
  /** Size - width: 190, height: 250 */
  seatImageS: Maybe<ImageSource>;
  /** Size - width: 1920, height: 1080 */
  sideViewImageHD: Maybe<ImageSource>;
  /** Size - width: 1400, height: 601 */
  sideViewImageL: Maybe<ImageSource>;
  /** Size - width: 682, height: 512 */
  sideViewImageM: Maybe<ImageSource>;
  /** Size - width: 590, height: 332 */
  sideViewImageS: Maybe<ImageSource>;
  /** Size - width: 256, height: 144 */
  sideViewImageXS: Maybe<ImageSource>;
  /** Size - width: 1280, height: 720 */
  smallImageHD: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  steeringWheelImage: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  steeringWheelImageS: Maybe<ImageSource>;
  /** Size - width: 400, height: 225 */
  teaserImage: Maybe<ImageSource>;
  /** Size - width: 249, height: 140 */
  thumbnailHD: Maybe<ImageSource>;
  /** Size - width: 1080, height: 1080 */
  wheelImage: Maybe<ImageSource>;
  /** Size - width: 200, height: 200 */
  wheelImageS: Maybe<ImageSource>;
};

/** Data of a single feature of a configured car */
export type ConfiguredCarFeatureInterface = {
  /** The family of a feature */
  family: CarFeatureFamily;
  /** Feature group mapping */
  featureGroup: Maybe<ConfiguredCarFeatureGroup>;
  /** The type of the feature */
  featureType: ConfiguredCarFeatureType;
  financingParameters: Maybe<FinancingParameters>;
  /** The feature group of the feature */
  group: CarFeatureGroup;
  images: Maybe<ConfiguredCarFeatureImage>;
  /**
   * List of feature ids in which the current feature is contained
   * @deprecated A feature can only be contained in a package. Use the ConfiguredCarCatalog.packagesDetails resolver if you would like to acccess package's features.
   */
  isContainedInFeatures: Array<Scalars['String']['output']>;
  /** E.g. 'Auto-dimming rearview mirror' */
  name: Scalars['String']['output'];
  /** E.g. '4L2' */
  pr3: Scalars['ID']['output'];
  /** E.g. 'MIRS4L2' */
  pr7: Scalars['ID']['output'];
  /** The price of the feature */
  price: Maybe<Price>;
  /** The status of the feature */
  status: Status;
  /** Additional categorization. Optional. (e.g. family: 'Sitze.Sitze', subFamily: 'sport') */
  subFamily: Maybe<CarFeatureFamily>;
  texts: Maybe<ConfiguredCarFeatureText>;
  /** Links to Labels and PDF infos with data if the feature is from type tyre. Only available for feature group 'RIMS'. */
  tyreLabels: Maybe<Array<Maybe<ConfiguredCarFeatureTyreLabel>>>;
  videos: Maybe<ConfiguredCarFeatureVideo>;
};

/** configuration states to the corresponding bitflags from the DPU (00000=> */
export enum ConfiguredCarFeatureState {
  Available = 'available',
  Changeable = 'changeable',
  InConflict = 'inConflict',
  Selected = 'selected',
  Standard = 'standard',
}

/**
 * Defines text types.
 *
 * Currently, all fields correspond to VSAPI text types. It is planned to replace them with more generic fields like
 * `headline` instead of `akHeadline`.
 */
export type ConfiguredCarFeatureText = CarFeatureText & {
  __typename?: 'ConfiguredCarFeatureText';
  akHeadline: Maybe<Scalars['String']['output']>;
  akRemarks: Maybe<Scalars['String']['output']>;
  akText: Maybe<Scalars['String']['output']>;
  benefits: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  carpetText: Maybe<Scalars['String']['output']>;
  carpetTitle: Maybe<Scalars['String']['output']>;
  dashboardText: Maybe<Scalars['String']['output']>;
  dashboardTitle: Maybe<Scalars['String']['output']>;
  engineDescription: Maybe<Scalars['String']['output']>;
  engineGear: Maybe<Scalars['String']['output']>;
  engineName: Maybe<Scalars['String']['output']>;
  engineNamesText: Maybe<Scalars['String']['output']>;
  exteriorColorText: Maybe<Scalars['String']['output']>;
  exteriorColorTitle: Maybe<Scalars['String']['output']>;
  exteriorContrastColorText: Maybe<Scalars['String']['output']>;
  exteriorContrastColorTitle: Maybe<Scalars['String']['output']>;
  headliningText: Maybe<Scalars['String']['output']>;
  headliningTitle: Maybe<Scalars['String']['output']>;
  intro: Maybe<Scalars['String']['output']>;
  seatsText: Maybe<Scalars['String']['output']>;
  seatsTitle: Maybe<Scalars['String']['output']>;
  teaserText: Maybe<Scalars['String']['output']>;
};

/** Any possible kind type which a feature can get */
export enum ConfiguredCarFeatureType {
  Accessory = 'ACCESSORY',
  BodyColor = 'BODY_COLOR',
  Equipment = 'EQUIPMENT',
  EquipmentContent = 'EQUIPMENT_CONTENT',
  ExclusiveExteriorColor = 'EXCLUSIVE_EXTERIOR_COLOR',
  ExteriorColor = 'EXTERIOR_COLOR',
  InteriorTile = 'INTERIOR_TILE',
  Model = 'MODEL',
  RawInteriorColor = 'RAW_INTERIOR_COLOR',
  RoofColor = 'ROOF_COLOR',
  Stealth = 'STEALTH',
  Trimline = 'TRIMLINE',
}

/** Links to some additional information data for tyre features */
export type ConfiguredCarFeatureTyreLabel = {
  __typename?: 'ConfiguredCarFeatureTyreLabel';
  /** Link to a product information sheet pdf */
  productSheetUrl: Maybe<Scalars['String']['output']>;
  /** Link to an image with environmental informations for the tyre */
  tyreLabelUrl: Maybe<Scalars['String']['output']>;
};

/** Shows who the vendor of the feature is */
export enum ConfiguredCarFeatureVendor {
  Audi = 'AUDI',
  AudiSport = 'AUDI_SPORT',
}

/**
 * Defines video types.
 *
 * Currently, all fields correspond to VSAPI video types. It is planned to replace them with more generic fields like
 * `mp4XL` instead of `bigVideoMp4`.
 */
export type ConfiguredCarFeatureVideo = CarFeatureVideo & {
  __typename?: 'ConfiguredCarFeatureVideo';
  /** Size - width: 888, height: 500 */
  bigVideoMp4: Maybe<VideoSource>;
  /** Size - width: 704, height: 396 */
  videoMp4: Maybe<VideoSource>;
  /** Size - width: 888, height: 500 */
  videoThumb: Maybe<VideoSource>;
};

export type ConfiguredCarFeatures = {
  __typename?: 'ConfiguredCarFeatures';
  data: Array<ConfiguredCarFeature>;
};

export type ConfiguredCarFeaturesDataArgs = {
  featuresFilterInput: InputMaybe<FeaturesFilterInput>;
};

/** Identifier to query a configured car */
export type ConfiguredCarIdentifier = {
  __typename?: 'ConfiguredCarIdentifier';
  /**
   * KVPS (means: 'Konzern Vertriebs Partner Stammdaten') brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars['String']['output'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars['String']['output'];
  /** 3-digit pr numbers */
  equipmentOptions: Maybe<Array<Scalars['String']['output']>>;
  /** 4-digit pr number of the exterior color */
  exteriorColor: Maybe<Scalars['String']['output']>;
  /** 2-digit pr number of the interior color */
  interiorColor: Maybe<Scalars['String']['output']>;
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars['String']['output'];
  model: ConfiguredCarModelIdentifier;
  /** 5-digit sales group */
  salesGroup: Scalars['String']['output'];
};

/**
 * Object representation of a prString
 *
 * MBV prString:
 *
 * 51901\\2021|GENBAE\\1|F14 S1S1|F56     FZ
 * -----       ------        ----
 * |      ---- |       -     |            --
 * |      |    |       |     |            |
 * |      |    |       |     |            interiorColor
 * |      |    |       |     |
 * |      |    |       |     exteriorColor
 * |      |    |       |
 * |      |    |       modelVersion
 * |      |    |
 * |      |    modelCode
 * |      |
 * |      modelYear
 * |
 * salesgroup
 *
 * NEMO prString:
 *
 * GENBAE1_2021|S1S1|FZ
 * ------  ----      --
 * |     - |    ---- |
 * |     | |    |    |
 * |     | |    |    interiorColor
 * |     | |    |
 * |     | |    exteriorColor
 * |     | |
 * |     | modelYear
 * |     |
 * |     modelVersion
 * |
 * modelCode
 */
export type ConfiguredCarInput = {
  /**
   * KVPS (means: 'Konzern Vertriebs Partner Stammdaten') ID:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars['String']['input'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars['String']['input'];
  /**
   * TODO: Combine equipmentOptions, exteriorColor, interiorColor in a separate input???
   * 7-digit pr numbers
   */
  equipmentOptions: InputMaybe<Array<Scalars['String']['input']>>;
  /** 4-digit pr number of the exterior color */
  exteriorColor: InputMaybe<Scalars['String']['input']>;
  /** 2-digit pr number of the interior color */
  interiorColor: InputMaybe<Scalars['String']['input']>;
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars['String']['input'];
  /** the model input separte in an own input */
  model: ConfiguredCarModelInput;
  /**
   * 5-digit sales group
   * TODO: for now, we use string because of leading zeros.
   */
  salesGroup: InputMaybe<Scalars['String']['input']>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type ConfiguredCarMedia =
  | ConfiguredCarFeatureImage
  | ConfiguredCarFeatureText
  | ConfiguredCarFeatureVideo;

/** All available media resources for a configured car */
export type ConfiguredCarMediaResources = {
  __typename?: 'ConfiguredCarMediaResources';
  ave: Maybe<ConfiguredCarAve>;
  /** (PDF-) documents related to the configured car. */
  documents: Maybe<ConfiguredCarDocuments>;
  /** Contains media urls related to EEC labels. */
  eec: Maybe<ConfiguredCarEecLabelImageUrls>;
  /**
   * Images related to the configured car.
   *
   * Provide either "width" or "resolution". Providing nothing will fallback to providing the default resolution of the image. Providing both will ignore the width parameter.
   * Providing width only will always result in 4:3 images
   */
  images: Array<RenderedCarImage>;
  /** @deprecated use `images` */
  renderImages: Maybe<ConfiguredCarRenderImages>;
};

/** All available media resources for a configured car */
export type ConfiguredCarMediaResourcesImagesArgs = {
  client?: InputMaybe<Scalars['String']['input']>;
  filterInput: InputMaybe<RenderedCarImagesFilterInput>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  resolution?: InputMaybe<Array<InputImageResolution>>;
  width?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type ConfiguredCarModelBase = CarModelBase & {
  __typename?: 'ConfiguredCarModelBase';
  id: Maybe<ConfiguredCarModelIdentifier>;
  name: Maybe<Scalars['String']['output']>;
};

/** Model identifier */
export type ConfiguredCarModelIdentifier = CarModelIdentifier & {
  __typename?: 'ConfiguredCarModelIdentifier';
  /** 6-digit model base code. Describes the model/engine used in a configuration e.g. 8WCAYG */
  code: Scalars['String']['output'];
  /** 3-digit pr numbers */
  extensions: Maybe<Array<Scalars['String']['output']>>;
  /**
   * 7-digit pr numbers
   * The PrNumbers in this field are identical to the ones in the `extensions` field but in PR7 instead of PR3 format
   */
  extensionsPR7: Maybe<Array<Scalars['String']['output']>>;
  /** 1-digit model version */
  version: Scalars['Int']['output'];
  /** The year of the catalogue release */
  year: Scalars['Int']['output'];
};

export type ConfiguredCarModelInput = {
  /** 6-digit model base code. Describes the model/engine used in a configuration e.g. GENBAE */
  code: Scalars['String']['input'];
  /** 3-digit pr numbers */
  extensions: InputMaybe<Array<Scalars['String']['input']>>;
  /** 7-digit pr numbers */
  extensionsPR7: InputMaybe<Array<Scalars['String']['input']>>;
  /** 1-digit model version */
  version: Scalars['Int']['input'];
  /** The year of the catalogue release/models release */
  year: Scalars['Int']['input'];
};

export type ConfiguredCarPackage = ConfiguredCarFeatureInterface & {
  __typename?: 'ConfiguredCarPackage';
  /** All items which are part of this package */
  content: Maybe<Array<ConfiguredCarPackageFeature>>;
  /**
   * Family of the package
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  family: CarFeatureFamily;
  /**
   * Feature group mapping
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  featureGroup: Maybe<ConfiguredCarFeatureGroup>;
  /**
   * The type of the feature
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  featureType: ConfiguredCarFeatureType;
  /**
   * Features contained in the package
   * @deprecated Use content instead
   */
  features: ConfiguredCarFeatures;
  financingParameters: Maybe<FinancingParameters>;
  /**
   * The feature group of the package
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  group: CarFeatureGroup;
  images: Maybe<ConfiguredCarFeatureImage>;
  /**
   * List of feature ids in which the current feature is contained
   * @deprecated A feature can only be contained in a package. Use the ConfiguredCarCatalog.packagesDetails resolver if you would like to acccess package's features.
   */
  isContainedInFeatures: Array<Scalars['String']['output']>;
  /** e.g. 'Comfort & Sound pack' */
  name: Scalars['String']['output'];
  /** e.g. 'PG2' */
  pr3: Scalars['ID']['output'];
  /** e.g. 'GPG2PG2' */
  pr7: Scalars['ID']['output'];
  /**
   * Price for this feature
   * data source: DPU https://pre.web-api.audi.com/dpu/v1/[country]/[language]/stateless-configuration?mode=price&ids=[prstring]
   */
  price: Maybe<Price>;
  rate: Maybe<PartRateResult>;
  /** Status flags of the package */
  status: Status;
  /**
   * Additional categorization. Optional. (e.g. family: 'Sitze.Sitze', subFamily: 'sport')
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  subFamily: Maybe<CarFeatureFamily>;
  /** Marketing text blocks */
  texts: Maybe<ConfiguredCarFeatureText>;
  /**
   * Links to Labels and PDF infos with data if the feature is from type tyre. Only available for feature group 'RIMS'.
   * data source: VSAPI https://pre.web-api.audi.com/vsapi/v1/[country]/[lamguage]/CatalogForModel?model=[model]
   */
  tyreLabels: Maybe<Array<Maybe<ConfiguredCarFeatureTyreLabel>>>;
  videos: Maybe<ConfiguredCarFeatureVideo>;
};

export type ConfiguredCarPackageFeature = {
  __typename?: 'ConfiguredCarPackageFeature';
  /** This is the id of the item which is part of the package. This is not necessarily the pr7 number - e.g. '6I6_in_WPX' */
  contentId: Scalars['String']['output'];
  images: Maybe<ConfiguredCarFeatureImage>;
  /** e.g. 'Audi active lane assist' */
  name: Scalars['String']['output'];
  /** e.g. '6I6' */
  pr3: Scalars['ID']['output'];
  /** The status of the feature */
  status: Status;
  /** Marketing text blocks */
  texts: Maybe<ConfiguredCarFeatureText>;
};

export type ConfiguredCarPackages = {
  __typename?: 'ConfiguredCarPackages';
  data: Maybe<Array<ConfiguredCarPackage>>;
};

export type ConfiguredCarPackagesDataArgs = {
  featuresFilterInput: InputMaybe<FeaturesFilterInput>;
};

export type ConfiguredCarPrices = CarPrices & {
  __typename?: 'ConfiguredCarPrices';
  /**
   * special taxes (Canada market only)
   * @deprecated Use priceParts instead
   */
  airConditioningLevy: Maybe<CarPrice>;
  /**
   * bpm (first-time-registration) tax (Belastingdienst - NL only)
   * @deprecated Use priceParts instead
   */
  bpm: Maybe<CarPrice>;
  /**
   * lowest price of the configured car´s carline
   * @deprecated Use priceParts instead
   */
  carlineBase: Maybe<CarPrice>;
  /**
   * delivery cost excluded from total
   * @deprecated Use priceParts instead
   */
  charges: Maybe<CarPrice>;
  /**
   * special fee (Canada market only)
   * @deprecated Use priceParts instead
   */
  dealerAdminFee: Maybe<CarPrice>;
  /**
   * special fee (Canada market only)
   * @deprecated Use priceParts instead
   */
  estimatedMotorVehicleCounselFee: Maybe<CarPrice>;
  /**
   * special taxes (Canada market only)
   * @deprecated Use priceParts instead
   */
  estimatedTireLevy: Maybe<CarPrice>;
  /**
   * total price excluding options which are not financeable
   * @deprecated Use priceParts instead
   */
  financeableTotal: Maybe<CarPrice>;
  financingParameters: Maybe<FinancingParameters>;
  /**
   * special taxes (Canada market only)
   * @deprecated Use priceParts instead
   */
  greenLevy: Maybe<CarPrice>;
  /**
   * special taxes (Canada market only)
   * @deprecated Use priceParts instead
   */
  luxuryTax: Maybe<CarPrice>;
  /**
   * base price of model without extra options
   * @deprecated Use priceParts instead
   */
  model: Maybe<CarPrice>;
  monthlyRate: Maybe<MonthlyRateResult>;
  /**
   * netto price of the model (FI only)
   * @deprecated Use priceParts instead
   */
  nettoBase: Maybe<CarPrice>;
  /**
   * netto price of the model´s options (FI only)
   * @deprecated Use priceParts instead
   */
  nettoOptions: Maybe<CarPrice>;
  /**
   * total price excluding financeable total
   * @deprecated Use priceParts instead
   */
  nonFinanceableTotal: Maybe<CarPrice>;
  /**
   * prices of all options contained in the configured car
   * @deprecated Use priceParts instead
   */
  options: Maybe<CarPrice>;
  /** creation date for the delivered prices */
  priceDate: Maybe<PriceDate>;
  /** this array contains all available price parts of this configured car for this market */
  priceParts: Array<TypedPrice>;
  /**
   * On The Road Price (UK market), without additional Servicepackgages (= total + otrOffset)
   * @deprecated Use priceParts instead
   */
  rotr: Maybe<CarPrice>;
  /**
   * On The Road Price offset (UK market)
   * @deprecated Use priceParts instead
   */
  rotrRate: Maybe<CarPrice>;
  /**
   * price for selected service packages
   * @deprecated Use priceParts instead
   */
  servicePackage: Maybe<CarPrice>;
  /**
   * total tax (FI and NO markets)
   * @deprecated Use priceParts instead
   */
  taxTotal: Maybe<CarPrice>;
  /**
   * total tax depending on CO2 consumptions (NO market only)
   * @deprecated Use priceParts instead
   */
  taxTotalCO2: Maybe<CarPrice>;
  /**
   * total tax price excluding  scrap fee (NO market only)
   * @deprecated Use priceParts instead
   */
  taxTotalExclScrapFree: Maybe<CarPrice>;
  /**
   * total tax depending on NO2 consumptions (NO market only)
   * @deprecated Use priceParts instead
   */
  taxTotalNOx: Maybe<CarPrice>;
  /**
   * total tax depending on weight (NO market only)
   * @deprecated Use priceParts instead
   */
  taxTotalWeight: Maybe<CarPrice>;
  /**
   * taxable benefits (FI only)
   * @deprecated Use priceParts instead
   */
  taxableBenefit1: Maybe<CarPrice>;
  /**
   * taxable benefits (FI only)
   * @deprecated Use priceParts instead
   */
  taxableBenefit2: Maybe<CarPrice>;
  /**
   * total price of the configured car
   * @deprecated Use priceParts instead
   */
  total: Maybe<CarPrice>;
  /**
   * total price with additional charges
   * @deprecated Use priceParts instead
   */
  totalWithCharges: Maybe<CarPrice>;
  /**
   * value added taxes (vat) for this model
   * @deprecated Use priceParts instead
   */
  vat: Maybe<CarPrice>;
};

/**
 * Render images for a configured car
 *
 * mapping for dpu based render images
 * n2c01: ["exterior_front", "layerstage.new"],
 * n2c02: ["closeup_lights", "layerstage.new"],
 * n2c12: ["exterior_back", "layerstage.new"],
 * n2c14: ["exterior", "layerstage.new"],
 * n2c15: ["closeup_wheels", "layerstage.new"],
 * n2n05: ["interior", "layerstage.new"],
 * n2n06: ["dashboard", "layerstage.new"],
 * n2n10: ["rearseat", "layerstage.new"],
 * n3c01: ["exterior_front", "stage.new"],
 * n3c02: ["closeup_lights", "stage.new"],
 * n3c12: ["exterior_back", "stage.new"],
 * n3c14: ["exterior", "stage.new"],
 * n3c15: ["closeup_wheels", "stage.new"],
 * n3n05: ["interior", "stage.new"],
 * n3n06: ["dashboard", "stage.new"],
 * n3n10: ["rearseat", "stage.new"],
 * n4c01: ["exterior_front", "fullscreen.new"],
 * n4c02: ["closeup_lights", "fullscreen.new"],
 * n4c12: ["exterior_back", "fullscreen.new"],
 * n4c14: ["exterior", "fullscreen.new"],
 * n4c15: ["closeup_wheels", "fullscreen.new"],
 * n4n05: ["interior", "fullscreen.new"],
 * n4n06: ["parent.dashboard", "fullscreen.new"],
 * n4n10: ["rearseat", "fullscreen.new"],
 * n5c01: ["exterior_front", "carstore.new"],
 * n8c01: ["exterior_front", "compare.new"],
 * n9c14: ["exterior", "modelfinder.new"],
 * nmfc: ["exterior", "flyout"],
 */
export type ConfiguredCarRenderImages = {
  __typename?: 'ConfiguredCarRenderImages';
  n2c01: Maybe<Scalars['String']['output']>;
  n2c02: Maybe<Scalars['String']['output']>;
  n2c12: Maybe<Scalars['String']['output']>;
  n2c14: Maybe<Scalars['String']['output']>;
  n2c15: Maybe<Scalars['String']['output']>;
  n2n05: Maybe<Scalars['String']['output']>;
  n2n06: Maybe<Scalars['String']['output']>;
  n2n10: Maybe<Scalars['String']['output']>;
  n3c01: Maybe<Scalars['String']['output']>;
  n3c02: Maybe<Scalars['String']['output']>;
  n3c12: Maybe<Scalars['String']['output']>;
  n3c14: Maybe<Scalars['String']['output']>;
  n3c15: Maybe<Scalars['String']['output']>;
  n3n05: Maybe<Scalars['String']['output']>;
  n3n06: Maybe<Scalars['String']['output']>;
  n3n10: Maybe<Scalars['String']['output']>;
  n4c01: Maybe<Scalars['String']['output']>;
  n4c02: Maybe<Scalars['String']['output']>;
  n4c12: Maybe<Scalars['String']['output']>;
  n4c14: Maybe<Scalars['String']['output']>;
  n4c15: Maybe<Scalars['String']['output']>;
  n4n05: Maybe<Scalars['String']['output']>;
  n4n06: Maybe<Scalars['String']['output']>;
  n4n10: Maybe<Scalars['String']['output']>;
  n5c01: Maybe<Scalars['String']['output']>;
  n8c01: Maybe<Scalars['String']['output']>;
  n9c14: Maybe<Scalars['String']['output']>;
  nmfc: Maybe<Scalars['String']['output']>;
};

/** TechnicalData for configurations. */
export type ConfiguredCarTechnicalData = {
  __typename?: 'ConfiguredCarTechnicalData';
  /**
   * The time needed to accelerate the vehicle from a given start velocity to a given target velocity.
   * (https://auto.schema.org/accelerationTime)
   * f.ex.: 9,5 s
   * TODO: question: How to handle the name: Beschleunigung 0-100 km/
   */
  accelerationTime: Maybe<TechnicalDataFloatItem>;
  /**
   * The time needed to accelerate the vehicle from a given start velocity to a given target velocity when using launch control.
   * For example: 5.9 s
   */
  accelerationTimeLaunchControl: Maybe<TechnicalDataFloatItem>;
  /**
   * Indicates the design and body style of the vehicle (e.g. station wagon, hatchback, etc.).
   * (https://auto.schema.org/bodyType)
   */
  bodyType: Maybe<TechnicalDataTextItem>;
  /**
   * Brakes og the vehicle
   * f.ex. "Diagonal-2-Kreis Bremssystem mit ESC/ABS/EBV, Bremskraftverstärker, hydraulischer Bremsassistent; vorn: Faustsattel, Bremsscheibe innenbelüftet"
   */
  brakes: Maybe<TechnicalDataTextItem>;
  /**
   * The available volume for cargo or luggage. For automobiles, this is usually the trunk volume.
   * https://schema.org/cargoVolume
   */
  cargoVolume: Maybe<TechnicalDataRangeItem>;
  /**
   * Clutch of the vehicle
   * f.ex.: Doppelkupplung
   */
  clutch: Maybe<TechnicalDataTextItem>;
  /** Consumptions and emissions. */
  consumptionAndEmission: Maybe<TechnicalDataConsumptionAndEmission>;
  /**
   * Energy Efficiency Certificate.
   * @deprecated use envkv.eec instead
   */
  eec: Maybe<TechnicalDataTextItem>;
  /**
   * Combined electric range. Set minValue and maxValue accordingly.
   * TODO: Move attribute to Engine type?
   * f.ex. 284,0 - 341,0 km
   * Deprecated: --> engine.electricRangeCombined
   * @deprecated No longer supported
   */
  electricRangeCombined: Maybe<TechnicalDataRangeItem>;
  /**
   * Array of the engines for this vehicle. Minimal one entry
   * (https://auto.schema.org/EngineSpecification)
   */
  engine: TechnicalDataEngineBase;
  /** Energieverbrauchskennzeichnungsverordnung (Energy consumption labeling regulation) properties */
  envkv: Maybe<TechnicalDataEnvkv>;
  /** A list of additional technical data fields that are not part of the schema due to being market specific. */
  extendedTechnicalData: Maybe<Array<TechnicalDataExtendedField>>;
  /**
   * Front axle of the vehicle
   * f.ex. "McPherson-Federbeinachse vorne"
   */
  frontAxle: Maybe<TechnicalDataTextItem>;
  /**
   * Gearbox of the vehicle
   * f.ex.: 7-Gang S tronic
   */
  gearbox: Maybe<TechnicalDataTextItem>;
  /** Class of several insurance related attributes */
  insuranceClass: Maybe<TechnicalDataInsuranceClass>;
  /**
   * Indicates that the vehicle meets the respective emission standard.
   * https://schema.org/meetsEmissionStandard
   * f.ex. Euro 6d-TEMP
   */
  meetsEmissionStandard: Maybe<TechnicalDataTextItem>;
  /**
   * Rear axle of the vehicle
   * f.ex. "Verbundlenker-Hinterachse "
   */
  rearAxle: Maybe<TechnicalDataTextItem>;
  /**
   * Rims of the vehicle
   * f.ex. "Räder, 5-Arm-Design, 6 J x 15, Reifen 185/65 R 15"
   */
  rims: Maybe<TechnicalDataTextItem>;
  /**
   * The permitted total weight of cargo and installations (e.g. a roof rack) on top of the vehicle.
   * https://auto.schema.org/roofLoad
   */
  roofLoad: Maybe<TechnicalDataFloatItem>;
  /**
   * The speed range of the vehicle. If the vehicle is powered by an engine, the upper limit of the speed range (indicated by maxValue should be the maximum speed achievable under regular conditions.
   * (https://auto.schema.org/speed)
   * f.ex. 250 km/h
   */
  speed: Maybe<TechnicalDataFloatItem>;
  /**
   * Steering system of the vehicle
   * f.ex. "Elektromechanische Lenkung mit geschwindigkeitsabhängiger Servounterstützung; Wendekreis ca. 10,6 m"
   */
  steeringSystem: Maybe<TechnicalDataTextItem>;
  /** Debug Value */
  tenant: Maybe<Scalars['String']['output']>;
  /**
   * The permitted vertical load (TWR) of a trailer attached to the vehicle. Also referred to as Tongue Load Rating (TLR) or Vertical Load Rating (VLR)
   * https://auto.schema.org/tongueWeight
   */
  tongueWeight: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight at a gradient of 8 percent.
   * f.ex.: 2700 kg
   */
  trailerWeight8: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight at a gradient of 12 percent.
   * f.ex. 2800 kg / in Verbindung mit adaptive air suspension oder adaptive air suspension sport 3500
   */
  trailerWeight12: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight for trailers without separate brakes.
   * f.ex.: 750 kg
   */
  trailerWeightUnbraked: Maybe<TechnicalDataFloatItem>;
  /**
   * Tyres of the vehicle
   * f.ex. "Reifen 185/65 R15 92 V xl"
   */
  tyres: Maybe<TechnicalDataTextItem>;
  /**
   * Vehicle weight without load.
   * f.ex.: 2505 kg
   */
  unladenWeight: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of component used for transmitting the power from a rotating power source to the wheels or other relevant component(s) ("gearbox" for cars)
   * (https://schema.org/vehicleTransmission)
   * f.ex.: Allradantrieb quattro
   */
  vehicleTransmission: Maybe<TechnicalDataTextItem>;
  /**
   * The permitted total weight of the loaded vehicle, including passengers and cargo and the weight of the empty vehicle.
   * https://auto.schema.org/weightTotal
   * f.ex.: 3045 kg
   */
  weightTotal: Maybe<TechnicalDataFloatItem>;
};

/** Identifier which is return from a change configured car conflict. Should be used in the accept solution query as input */
export type ConflictConfiguredCarIdentifier = {
  __typename?: 'ConflictConfiguredCarIdentifier';
  /**
   * KVPS (means: 'Konzern Vertriebs Partner Stammdaten') brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars['String']['output'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars['String']['output'];
  /** 3-digit pr numbers */
  equipmentOptions: Maybe<Array<Scalars['String']['output']>>;
  /** 4-digit pr number of the exterior color */
  exteriorColor: Maybe<Scalars['String']['output']>;
  /** 2-digit pr number of the interior color */
  interiorColor: Maybe<Scalars['String']['output']>;
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars['String']['output'];
  model: ConflictConfiguredCarModelIdentifier;
  /** 5-digit sales group */
  salesGroup: Maybe<Scalars['String']['output']>;
};

/** Conflict Model identifier */
export type ConflictConfiguredCarModelIdentifier = CarModelIdentifier & {
  __typename?: 'ConflictConfiguredCarModelIdentifier';
  /** 6-digit model base code. Describes the model/engine used in a configuration e.g. 8WCAYG */
  code: Scalars['String']['output'];
  /** 3-digit pr numbers */
  extensions: Maybe<Array<Scalars['String']['output']>>;
  /** 1-digit model version */
  version: Scalars['Int']['output'];
  /** The year of the catalogue release */
  year: Scalars['Int']['output'];
};

/** Summary of consumption and emission ranges formatted as string according to country and language. */
export type ConsumptionAndEmission = {
  __typename?: 'ConsumptionAndEmission';
  /**
   * Array of disctinct consumption and emission values for fuelType
   * @deprecated use measurements instead
   */
  consumptionAndEmissionPerFuel: ConsumptionAndEmissionPerFuel;
  /** Array containing consumption and emission values for each fuel measured by WLTP/NEDC */
  measurements: Array<Maybe<ConsumptionAndEmissionPerFuel>>;
  /** Summary values for footnotes etc. */
  summary: ConsumptionAndEmissionSummary;
};

/** Per fuel consumption and emission values; separated by testing method */
export type ConsumptionAndEmissionPerFuel = {
  __typename?: 'ConsumptionAndEmissionPerFuel';
  fuelName: Maybe<Scalars['String']['output']>;
  fuelType: FuelType;
  /** Consumption and emission ranges per fuel; entries for different energy management testing cycles */
  testMethods: ConsumptionAndEmissionTestMethods;
};

/** Summary values for footnotes etc. */
export type ConsumptionAndEmissionSummary = {
  __typename?: 'ConsumptionAndEmissionSummary';
  /**
   * Summary consumption text for footnotes etc.
   * @deprecated use consumptionSummary instead
   */
  consumption: Scalars['String']['output'];
  /**
   * Array containing consumption values for each fuel
   * @deprecated use consumptionsSummary instead
   */
  consumptionSummary: Array<Maybe<FuelFloatValue>>;
  /** Array containing consumption values for each fuel and energy management */
  consumptionsSummary: Maybe<Array<FuelValues>>;
  /** Array containing energy efficicency classes for each energy management */
  eecsSummary: Maybe<Array<EecValuePerEnergyManagementWltp>>;
  /**
   * Summary emission text for footnotes etc.
   * @deprecated use emissionCO2Summary instead
   */
  emissionCO2: Scalars['String']['output'];
  /**
   * Object containing information about the emission values
   * @deprecated use emissionsCO2Summary instead
   */
  emissionCO2Summary: FuelFloatValue;
  /** Summary Nitrogen Dioxide text */
  emissionN02: Maybe<Scalars['String']['output']>;
  /** Object containing information about the emission values for each fuel and energy management */
  emissionsCO2Summary: Maybe<Array<FuelValues>>;
  /** Array of footnote references */
  footnoteReferences: Array<Maybe<Scalars['String']['output']>>;
};

export type ConsumptionAndEmissionTestMethods = {
  __typename?: 'ConsumptionAndEmissionTestMethods';
  nedc: Maybe<NedcTestMethod>;
  nonWltp: Maybe<NonWltpTestMethod>;
  /** @deprecated use wltpPerEnergyManagement */
  wltp: Maybe<WltpTestMethod>;
  /** Consumption and emission ranges per fuel; entries for different energy management testing cycles */
  wltpPerEnergyManagement: Maybe<WltpTestMethodsPerEnergyManagement>;
};

/** Node containing consumption and emission values */
export type ConsumptionAndEmissionValuePerEnergyManagementWltp = {
  __typename?: 'ConsumptionAndEmissionValuePerEnergyManagementWltp';
  /** we have different values for different energy managements, example: pure or sustaining for hybrids */
  energyManagement: Maybe<WltpEnergyManagement>;
  /** formatted value */
  formattedValue: Maybe<Scalars['String']['output']>;
  /** may or may not be present depending on a factual decision; true if preferred to be shown */
  isDefault: Maybe<Scalars['Boolean']['output']>;
  /** label, if present */
  label: Maybe<Scalars['String']['output']>;
  /** remarks, if present */
  remarks: Maybe<Array<Maybe<Remark>>>;
  /** unit */
  unit: Maybe<Scalars['String']['output']>;
  /** raw value */
  value: Maybe<Scalars['Float']['output']>;
};

/** An indication about if a color should be chosen from a brighter or darker color scheme */
export enum ContrastThemeHint {
  Dark = 'DARK',
  Light = 'LIGHT',
}

/** Currency information */
export type Currency = {
  __typename?: 'Currency';
  /** ISO 4217 currency code. For example, "USD" */
  code: Scalars['ID']['output'];
  /** Symbol of the currency. For example, "$" */
  symbol: Maybe<Scalars['String']['output']>;
};

/** All possible customer group classifications */
export enum CustomerGroupClassification {
  Private = 'PRIVATE',
  SmallCommercial = 'SMALL_COMMERCIAL',
}

/** @deprecated data moved into `ConfiguredCar` */
export type DataEntry = {
  __typename?: 'DataEntry';
  disclaimer: Maybe<Scalars['String']['output']>;
  formattedValue: Scalars['String']['output'];
  id: Scalars['String']['output'];
  rawValue: Maybe<Scalars['String']['output']>;
  unit: Maybe<Scalars['String']['output']>;
};

/** information about a dealer of a car */
export type DealerInfo = {
  __typename?: 'DealerInfo';
  /** city of dealer address */
  city: Scalars['String']['output'];
  /** ISO Alpha3 country code / KVPS country */
  country: Scalars['String']['output'];
  /** e mail address of dealer */
  email: Maybe<Scalars['String']['output']>;
  /** fax number of dealer */
  fax: Maybe<Scalars['String']['output']>;
  /** KVPS number of dealer */
  hid: Scalars['String']['output'];
  /** unique id of a dealer in a market */
  id: Scalars['String']['output'];
  /** imprint of dealer */
  imprint: Maybe<Scalars['String']['output']>;
  /** name of dealer */
  name: Scalars['String']['output'];
  /** phone number of dealer */
  phone: Maybe<Scalars['String']['output']>;
  /** geo location of dealer */
  position: Maybe<GeoLocation>;
  /** region of dealer address (e.g. state) */
  region: Maybe<Scalars['String']['output']>;
  /** street of dealer address */
  street: Maybe<Scalars['String']['output']>;
  /** link to dealers page */
  website: Maybe<Scalars['String']['output']>;
  /** zip of dealer address */
  zip: Scalars['String']['output'];
};

/**
 * "new type"
 *
 * result of a dealer search
 */
export type DealerSearch = {
  __typename?: 'DealerSearch';
  /** number of found dealers */
  resultNumber: Scalars['Int']['output'];
  /** subset of found dealers */
  results: DealerSearchResults;
};

/**
 * "new input"
 *
 * parameter for geo search of dealers
 */
export type DealerSearchParameterGeoCriterionInput = {
  /** latitude of geo position (center of search circle) */
  latitude: Scalars['Float']['input'];
  /** longitude of geo position (center of search circle) */
  longitude: Scalars['Float']['input'];
  /** maximum distance between passed geo position (latitude and longitude) and geo position of a found dealer (radius of search circle) */
  maxDistance: Scalars['Float']['input'];
};

/**
 * "new input"
 *
 * additional parameter for dealer search: search criteria, sorting and paging
 */
export type DealerSearchParameterInput = {
  /** parameter for geographic proximity search */
  geo: InputMaybe<DealerSearchParameterGeoCriterionInput>;
  /**
   * parameter for search by dealer name
   * the search value could be only a part the name
   */
  name: InputMaybe<Scalars['String']['input']>;
  /** parameter for paging */
  paging: InputMaybe<DealerSearchParameterPagingInput>;
  /** parameter for sorting */
  sort: InputMaybe<DealerSearchParameterSortInput>;
};

/** new input */
export type DealerSearchParameterPagingInput = {
  /** limitation of results */
  limit: Scalars['Int']['input'];
  /** offset set (first element) */
  offset: Scalars['Int']['input'];
};

/** new input */
export type DealerSearchParameterSortInput = {
  /** selected sort direction */
  direction: DealerSearchSortDirection;
  /** Unique identifier for a sort option */
  id: Scalars['DealerSearchSortId']['input'];
};

/**
 * "new type"
 *
 * list of found dealers
 */
export type DealerSearchResults = {
  __typename?: 'DealerSearchResults';
  /** found dealers */
  dealers: Array<DealerSearchResultsDealer>;
  /** applied paging parameter */
  paging: Maybe<DealerSearchResultsPaging>;
  /** applied sort parameter */
  sort: Maybe<DealerSearchResultsSort>;
};

/**
 * "new type"
 *
 * data of a single found dealer
 */
export type DealerSearchResultsDealer = {
  __typename?: 'DealerSearchResultsDealer';
  /** common dealer data */
  dealer: DealerInfo;
};

/** new type */
export type DealerSearchResultsPaging = {
  __typename?: 'DealerSearchResultsPaging';
  /** limit set */
  limit: Scalars['Int']['output'];
  /** offset set (first element) */
  offset: Scalars['Int']['output'];
};

/** new type */
export type DealerSearchResultsSort = {
  __typename?: 'DealerSearchResultsSort';
  /** selected sort direction */
  direction: DealerSearchSortDirection;
  /** Unique identifier for a sort option */
  id: Scalars['DealerSearchSortId']['output'];
};

/** new enum */
export enum DealerSearchSortDirection {
  /** ascending sort direction */
  Asc = 'ASC',
  /** descending sort direction */
  Desc = 'DESC',
}

/** @deprecated data moved into `ConfiguredCar` */
export type EacConsolidated = {
  __typename?: 'EACConsolidated';
  unit: Maybe<Scalars['String']['output']>;
  value: Maybe<EacValue>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacConsumption = {
  __typename?: 'EACConsumption';
  consolidated: Maybe<EacConsolidated>;
  nedc: Maybe<EacEntry>;
  wltp: Maybe<EacEntry>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacDataSet = {
  __typename?: 'EACDataSet';
  em: Maybe<Scalars['String']['output']>;
  isDefault: Maybe<Scalars['Boolean']['output']>;
  values: Maybe<EacDataSetValues>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacDataSetValues = {
  __typename?: 'EACDataSetValues';
  combined: Maybe<EacValue>;
  extraHigh: Maybe<EacValue>;
  high: Maybe<EacValue>;
  low: Maybe<EacValue>;
  medium: Maybe<EacValue>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacEmission = {
  __typename?: 'EACEmission';
  co2: Maybe<EacEmissionType>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacEmissionType = {
  __typename?: 'EACEmissionType';
  consolidated: Maybe<EacConsolidated>;
  nedc: Maybe<EacEntry>;
  wltp: Maybe<EacEntry>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacEntry = {
  __typename?: 'EACEntry';
  consolidated: Maybe<EacConsolidated>;
  dataSets: Maybe<Array<Maybe<EacDataSet>>>;
  /** "datasets" is deprecated. Use "dataSets" (mixedCase) instead. */
  datasets: Maybe<Array<Maybe<EacDataSet>>>;
  unit: Maybe<Scalars['String']['output']>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacFootnote = {
  __typename?: 'EACFootnote';
  reference: Maybe<Scalars['String']['output']>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacFuel = {
  __typename?: 'EACFuel';
  consumption: Maybe<EacConsumption>;
  emission: Maybe<EacEmission>;
  fuel: Maybe<Scalars['String']['output']>;
  range: Maybe<EacRange>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacRange = {
  __typename?: 'EACRange';
  consolidated: Maybe<EacConsolidated>;
  nedc: Maybe<EacEntry>;
  wltp: Maybe<EacEntry>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EacValue = {
  __typename?: 'EACValue';
  formatted: Maybe<Scalars['String']['output']>;
  label: Maybe<Scalars['String']['output']>;
  number: Maybe<Scalars['Float']['output']>;
  text: Maybe<Scalars['String']['output']>;
  unit: Maybe<Scalars['String']['output']>;
};

/** Contains EEC range data. */
export type EecRange = {
  __typename?: 'EECRange';
  /** The worst EEC value, e.g. 'F' */
  max: Scalars['String']['output'];
  /** The best EEC value, e.g. 'A' */
  min: Scalars['String']['output'];
};

export enum EcoLabel {
  Eco = 'ECO',
  EcoIt = 'ECO_IT',
  NoEco = 'NO_ECO',
  Zero = 'ZERO',
}

/** Node for energy efficiency class */
export type EecPerEnergyManagementWltp = {
  __typename?: 'EecPerEnergyManagementWltp';
  /** Energy Efficiency Class. */
  eec: Maybe<TechnicalDataTextItem>;
  /** applied measurement cycle */
  energyManagement: Maybe<WltpEnergyManagement>;
};

/** summary node for eec (energy efficiency classes) */
export type EecValuePerEnergyManagementWltp = {
  __typename?: 'EecValuePerEnergyManagementWltp';
  /** we have different values for different energy management types, example: pure or sustaining for hybrids */
  energyManagement: Maybe<WltpEnergyManagement>;
  /** formatted value with explanatory text translated to the language of the market */
  formattedValue: Maybe<Scalars['String']['output']>;
  /** very broad description translated to the language of the market */
  label: Maybe<Scalars['String']['output']>;
  /** manually added remarks, if any needed */
  remarks: Maybe<Array<Maybe<Remark>>>;
  /** raw value, represented via string, very often not just one letter */
  value: Maybe<Scalars['String']['output']>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type EmissionAndConsumption = {
  __typename?: 'EmissionAndConsumption';
  eec: Maybe<Scalars['String']['output']>;
  emissionClass: Maybe<Scalars['String']['output']>;
  footnote: Maybe<EacFootnote>;
  formattedConsumption: Maybe<Scalars['String']['output']>;
  formattedEmission: Maybe<Scalars['String']['output']>;
  formattedFuels: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fuels: Maybe<Array<Maybe<EacFuel>>>;
  hasNedc: Maybe<Scalars['Boolean']['output']>;
  hasWltp: Maybe<Scalars['Boolean']['output']>;
  vehicleType: Maybe<Scalars['String']['output']>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type Engine = {
  __typename?: 'Engine';
  code: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** The power of the vehicle's engine in different formats: PS, KW or combined */
export type EnginePower = {
  __typename?: 'EnginePower';
  /**
   * The combine max power of the vehicle's engine.
   * f.ex. 250 kW (340 PS) bei 5000-6400 min -1
   */
  maxPower: Maybe<TechnicalDataFloatItem>;
  /** The power of the vehicle's engine in KW. */
  maxPowerKW: Maybe<TechnicalDataFloatItem>;
  /** The power of the vehicle's engine in KW when using launch control. */
  maxPowerLaunchControlKW: Maybe<TechnicalDataFloatItem>;
  /** The max power of the vehicle's engine in PS. */
  maxPowerPS: Maybe<TechnicalDataFloatItem>;
};

/** Collection of named exterior views. Each key maps to a render image URL. */
export type ExteriorViews = {
  __typename?: 'ExteriorViews';
  /** The front view, slightly angled. */
  beautyFront: Scalars['String']['output'];
  /** The rear view, slightly angled. */
  beautyRear: Scalars['String']['output'];
  /** The front view. */
  front: Scalars['String']['output'];
  /** The side view. */
  side: Scalars['String']['output'];
};

/** @deprecated data moved into `ConfiguredCar` */
export type Family = {
  __typename?: 'Family';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** filter features by one or multiple family IDs (family IDs are market specific and can differ for each market!)(multiple family IDs in the array behave like a logic OR) */
export type FamilyIdsFilterInput = {
  familyIds: Array<Scalars['String']['input']>;
  mode: InputMaybe<FilterMode>;
};

/**
 * Feature change request input required to modify a given ConfiguredCar. If autoResolveConflicts is enabled
 * eventual conflicting results will be solved automatically with the best effort approach.
 */
export type FeatureChange = {
  /** action to be performed with this feature on the ConfiguredCar: "ADD", "REMOVE", etc. */
  action: FeatureChangeAction;
  /** indicates if an eventual conflicting configuration result after adding/removing this feature should be automatically resolved */
  autoResolveConflicts: Scalars['Boolean']['input'];
  /** ConfiguredCarFeature's pr3 or pr7 value */
  id: Scalars['ID']['input'];
};

/** Possible actions that can be taken with a feature in relation to a ConfiguredCar */
export enum FeatureChangeAction {
  Add = 'ADD',
  Remove = 'REMOVE',
}

/** Contains a list of features as Strings for this specific Trimline Model */
export type FeatureGroup = {
  __typename?: 'FeatureGroup';
  features: Array<Scalars['String']['output']>;
  headline: Scalars['String']['output'];
};

/** filter features by one or more feature groups (feature groups are derived from groupIds and familyIds and not market dependent)(feature groups in the array behave like a logic OR) */
export type FeatureGroupsFilterInput = {
  featureGroups: Array<ConfiguredCarFeatureGroup>;
  mode: InputMaybe<FilterMode>;
};

/** filter features by one or multiple feature types (multiple feature types in the array behave like a logic OR) */
export type FeatureTypesFilterInput = {
  featureTypes: Array<Scalars['String']['input']>;
  mode: InputMaybe<FilterMode>;
};

/** features can be filtered by their attributes (e.g. configuration state, family id, group id or feature group) */
export type FeaturesFilterInput = {
  /** filter features by family Ids (when providing multiple filters (filterByFamilyIds, filterByGroupIds or filterByFeatureGroups) they get applied one after the other in a logical AND manner) */
  filterByFamilyIds: InputMaybe<FamilyIdsFilterInput>;
  /** filter feature by feature groups (when providing multiple filters (filterByFamilyIds, filterByGroupIds or filterByFeatureGroups) they get applied one after the other in a logical AND manner) */
  filterByFeatureGroups: InputMaybe<FeatureGroupsFilterInput>;
  /**
   * filter features by one ore more conditions (array) based on their configuration state (available, changeable,
   * inConflict, selected,  standard)
   */
  filterByFeatureState: InputMaybe<Array<ConfiguredCarFeatureState>>;
  /** filter features by feature types (when providing multiple filters they get applied one after the other in a logical AND manner) */
  filterByFeatureTypes: InputMaybe<FeatureTypesFilterInput>;
  /** filter feature by group Ids (when providing multiple filters (filterByFamilyIds, filterByGroupIds or filterByFeatureGroups) they get applied one after the other in a logical AND manner) */
  filterByGroupIds: InputMaybe<GroupIdsFilterInput>;
};

/** options on how to apply filters (e.g. in FeaturesFilterInput) */
export enum FilterMode {
  Exclusive = 'EXCLUSIVE',
  Inclusive = 'INCLUSIVE',
}

/** An configuration option for which a rate should be calculated by Financing-GraphQl. */
export type FinanceablePart = {
  __typename?: 'FinanceablePart';
  pr: Scalars['String']['output'];
  price: Maybe<Scalars['Float']['output']>;
};

export type FinanceablePartRate = {
  __typename?: 'FinanceablePartRate';
  currency: Maybe<FinancingCurrency>;
  formattedValue: Maybe<Scalars['String']['output']>;
  rawValue: Maybe<Scalars['Float']['output']>;
  valueAsText: Maybe<Scalars['String']['output']>;
};

export type FinanceableTypedPrice = TypedPrice & {
  __typename?: 'FinanceableTypedPrice';
  monthlyRate: Maybe<MonthlyRateResult>;
  price: Price;
  type: Scalars['String']['output'];
};

/** @deprecated calculation moved into financing-graphql subgraph */
export type Financing = {
  __typename?: 'Financing';
  globalDisclaimer: Maybe<Scalars['String']['output']>;
  product: Maybe<Scalars['String']['output']>;
  productDisclaimer: Maybe<Scalars['String']['output']>;
  rate: Maybe<Price>;
  summaryDisclaimer: Maybe<Scalars['String']['output']>;
};

export type FinancingCalculationParameters = {
  __typename?: 'FinancingCalculationParameters';
  country: Scalars['String']['output'];
  language: Scalars['String']['output'];
  model: FinancingModelParameters;
  modelPrice: Maybe<Scalars['Float']['output']>;
  /** Okapi data as described in webcalc */
  okapiData: Maybe<OkapiData>;
  part: Maybe<Array<Maybe<FinanceablePart>>>;
  /** array of price details objects. */
  priceDetails: Maybe<Array<KeyValuePair>>;
  product: Maybe<FinancingProduct>;
  salesGroup: Scalars['String']['output'];
  /** Selected not default options for configuration */
  selectedOptions: Maybe<Array<SelectedFinanceablePart>>;
  totalPrice: Maybe<Scalars['Float']['output']>;
};

export type FinancingCurrency = {
  __typename?: 'FinancingCurrency';
  code: Maybe<Scalars['String']['output']>;
  symbol: Maybe<Scalars['String']['output']>;
};

export type FinancingDisclaimers = {
  __typename?: 'FinancingDisclaimers';
  calculationDisclaimer: Maybe<Scalars['String']['output']>;
  globalDisclaimer: Maybe<Scalars['String']['output']>;
  productDisclaimer: Maybe<Scalars['String']['output']>;
  rateDetails: Maybe<Scalars['String']['output']>;
};

export type FinancingError = {
  __typename?: 'FinancingError';
  code: Scalars['String']['output'];
  message: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type FinancingGraphqlInfo = OneGraphSubServiceInfo & {
  __typename?: 'FinancingGraphqlInfo';
  endpoint: Scalars['String']['output'];
  health: OneGraphServiceHealth;
  id: Scalars['ID']['output'];
  meta: OneGraphServiceMetaInfo;
  name: Scalars['String']['output'];
  services: Maybe<Array<Maybe<OneGraphSubServiceDownStreamInfo>>>;
  version: Scalars['String']['output'];
};

/** Financing Product information */
export type FinancingInput = {
  /** e.g. VC for VarioCredit */
  product: Scalars['String']['input'];
  productParameters: InputMaybe<Array<InputMaybe<FinancingProductParameterInput>>>;
};

/** Model information used for financing calculations */
export type FinancingModelParameters = {
  __typename?: 'FinancingModelParameters';
  /**
   * Model code
   *
   * example: "GEGBUB"
   */
  code: Scalars['String']['output'];
  /**
   * Model extensions in pr3 format
   *
   * example: ["GA2", "GB1", "IU1"]
   */
  extensions: Maybe<Array<Scalars['String']['output']>>;
  /**
   * Model extensions in pr7 format
   *
   * example: ["MVOKGA2", "MKOMGB1", "MCPBIU1"]
   */
  extensionsPR7: Maybe<Array<Scalars['String']['output']>>;
  /**
   * Model version
   *
   * example: 2
   */
  version: Scalars['Int']['output'];
  /**
   * Model year
   *
   * example: 2024
   */
  year: Scalars['Int']['output'];
};

/** All required data for financing calculation. Additional data is provided by Financing-GraphQl through federation. */
export type FinancingParameters = {
  __typename?: 'FinancingParameters';
  appliedProduct: Maybe<FinancingProduct>;
  /** @deprecated Use appliedProduct.financingParameters instead. Will be populated with the financing product that was used for the rate calculation. If no financing input was specified in the query variables, this will be a default product. In contrast, financingParameters.product will only contain the product that was specified in the query. */
  calculationParameters: Maybe<Array<Maybe<FinancingProductParameter>>>;
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars['String']['output'];
  disclaimers: Maybe<FinancingDisclaimers>;
  /** Request parameters in the format expected by WebCalc. */
  externalRequestOptions: Maybe<Scalars['JSONObject']['output']>;
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars['String']['output'];
  model: FinancingModelParameters;
  modelPrice: Maybe<Scalars['Float']['output']>;
  part: Maybe<FinanceablePart>;
  product: Maybe<FinancingProduct>;
  productName: Maybe<Scalars['String']['output']>;
  /** 5-digit sales group */
  salesGroup: Scalars['String']['output'];
  totalPrice: Maybe<Scalars['Float']['output']>;
};

/** Financing Product information */
export type FinancingProduct = {
  __typename?: 'FinancingProduct';
  id: Scalars['String']['output'];
  productParameters: Maybe<Array<Maybe<FinancingProductParameter>>>;
};

/** Financing Product parameter e.g. DownPayment */
export type FinancingProductParameter = {
  __typename?: 'FinancingProductParameter';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** Financing Product parameter e.g. DownPayment */
export type FinancingProductParameterInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type FuelFloatValue = {
  __typename?: 'FuelFloatValue';
  formattedValue: Maybe<Scalars['String']['output']>;
  fuelName: Maybe<Scalars['String']['output']>;
  fuelType: Maybe<FuelType>;
  label: Maybe<Scalars['String']['output']>;
  remarks: Maybe<Array<Maybe<Remark>>>;
  unit: Maybe<Scalars['String']['output']>;
  value: Maybe<Scalars['Float']['output']>;
};

export enum FuelType {
  Cng = 'CNG',
  Diesel = 'DIESEL',
  Electrical = 'ELECTRICAL',
  /** @deprecated use: ELECTRICAL */
  Electricity = 'ELECTRICITY',
  Hybrid = 'HYBRID',
  Petrol = 'PETROL',
}

/** node containing consumption and emission values for fuel type */
export type FuelValues = {
  __typename?: 'FuelValues';
  /** Localized name of the fuel type, i.e., 'Diesel' or 'Benzin' */
  fuelName: Maybe<Scalars['String']['output']>;
  /** Type of fuel, i.e., 'DIESEL' or 'PETROL' */
  fuelType: Maybe<FuelType>;
  /** multiple values, one per Energy Management */
  values: Maybe<Array<ConsumptionAndEmissionValuePerEnergyManagementWltp>>;
};

/** geo location with latitude and longitude */
export type GeoLocation = {
  __typename?: 'GeoLocation';
  /** latitude of geo location */
  latitude: Scalars['Float']['output'];
  /** longitude of geo location */
  longitude: Scalars['Float']['output'];
};

/** @deprecated data moved into `ConfiguredCar` */
export type Group = {
  __typename?: 'Group';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** filter features by one or multiple group IDs (group IDs are market specific and can differ for each market!)(multiple group IDs in the array behave like a logic OR) */
export type GroupIdsFilterInput = {
  groupIds: Array<Scalars['String']['input']>;
  mode: InputMaybe<FilterMode>;
};

/** @deprecated queries deprecated */
export enum IdentifierType {
  Audicode = 'AUDICODE',
  Carline = 'CARLINE',
  Prstring = 'PRSTRING',
}

/** @deprecated data moved into `ConfiguredCar` */
export type Image = {
  __typename?: 'Image';
  url: Maybe<Scalars['String']['output']>;
};

export type ImageResolution = {
  __typename?: 'ImageResolution';
  height: Scalars['Int']['output'];
  width: Scalars['Int']['output'];
};

/** Defines an image by size, MIME type and source URL. */
export type ImageSource = {
  __typename?: 'ImageSource';
  /** The height of the image in pixels, e.g. 600. */
  height: Scalars['Int']['output'];
  /** The MIME type of the image, e.g. "image/png". */
  mimeType: Scalars['String']['output'];
  /** The source of the image, e.g. "https://www.mydomain.com/myimage.png" */
  url: Scalars['String']['output'];
  /** The width of the image in pixels, e.g. 800. */
  width: Scalars['Int']['output'];
};

/**
 * Incentive for certain customers or groups
 * data source: DPU https://pre.web-api.audi.com/dpu/v1/[country]/[language]/stateless-configuration?mode=price&ids=[prstring]
 */
export type Incentive = {
  __typename?: 'Incentive';
  /** What this incentive is for */
  action: IncentiveAction;
  /** Which customer groups this incentive is available for and descriptions */
  customerGroup: IncentiveCustomerGroup;
  id: Scalars['String']['output'];
  /** Disclaimer or legal text */
  limitation: Maybe<Scalars['String']['output']>;
  /**
   * How much this incentive is worth
   * data source: DPU https://pre.web-api.audi.com/dpu/v1/[country]/[language]/stateless-configuration?mode=price&ids=[prstring]
   */
  price: Price;
  /** The status of the incentive */
  status: Status;
  /** When the incentive starts and ends */
  validityPeriod: IncentiveValidityPeriod;
  /** Requirements to qualify for the incentive */
  vehicleEligibilityCheck: Maybe<Scalars['String']['output']>;
};

/** The incentive action */
export type IncentiveAction = {
  __typename?: 'IncentiveAction';
  /** A description of what the incentive is for */
  description: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['String']['output']>;
  /** A title for the incentive */
  title: Maybe<Scalars['String']['output']>;
};

/** The incentive customer group */
export type IncentiveCustomerGroup = {
  __typename?: 'IncentiveCustomerGroup';
  /** An array of which customer groups that may use this incentive */
  classification: Array<CustomerGroupClassification>;
  /** A text description of who the incentive is for */
  description: Maybe<Scalars['String']['output']>;
  /** What is required to qualify for the incentive */
  eligibility: Maybe<Scalars['String']['output']>;
};

/** Incentive validity period */
export type IncentiveValidityPeriod = {
  __typename?: 'IncentiveValidityPeriod';
  /** Incentive starting date */
  from: Maybe<Scalars['String']['output']>;
  /** Incentive end date */
  to: Maybe<Scalars['String']['output']>;
};

export type InputImageResolution = {
  height: Scalars['Int']['input'];
  width: Scalars['Int']['input'];
};

/** @deprecated renamed to `ConfiguredCarFeatureGroup` */
export enum ItemGroup {
  Accessories = 'ACCESSORIES',
  ExclusiveExteriorColors = 'EXCLUSIVE_EXTERIOR_COLORS',
  ExteriorColors = 'EXTERIOR_COLORS',
  InteriorTiles = 'INTERIOR_TILES',
  Lights = 'LIGHTS',
  Rims = 'RIMS',
  Seats = 'SEATS',
  SteeringWheels = 'STEERING_WHEELS',
}

/** A Key-Value-Pair with a float payload */
export type KeyValueFloat = {
  __typename?: 'KeyValueFloat';
  /** The records identifier */
  key: Scalars['String']['output'];
  /** The records payload */
  value: Scalars['Float']['output'];
};

/** A union type for a generic key-value-pair with either a number or string value */
export type KeyValuePair = KeyValueFloat | KeyValueString;

/** A Key-Value-Pair with a string payload */
export type KeyValueString = {
  __typename?: 'KeyValueString';
  /** The records identifier */
  key: Scalars['String']['output'];
  /** The records payload */
  value: Scalars['String']['output'];
};

/** Used as a container to provide price information. */
export type LabeledTypedPrice = {
  __typename?: 'LabeledTypedPrice';
  /** text information about the price, like 'Price' */
  label: Maybe<Scalars['String']['output']>;
  /** price information with values and currency details */
  price: Price;
  /** type of price, like 'Sale', 'List' */
  type: Scalars['String']['output'];
};

/** @deprecated queries deprecated */
export type MarketContext = {
  country: Scalars['String']['input'];
  language: Scalars['String']['input'];
  previewContext: InputMaybe<Scalars['String']['input']>;
};

export type MarketIdentifier = {
  __typename?: 'MarketIdentifier';
  /**
   * KVPS (means: 'Konzern Vertriebs Partner Stammdaten') brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars['String']['output'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars['String']['output'];
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars['String']['output'];
  /** 5-digit sales group */
  salesGroup: Scalars['String']['output'];
};

/** information to specify a market */
export type MarketIdentifierInput = {
  /** KVPS brand id. For example `A` for Audi. */
  brand: Scalars['String']['input'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars['String']['input'];
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars['String']['input'];
};

/** Trimline Model Data */
export type Model = {
  __typename?: 'Model';
  /**
   * Default configuration of the model
   *
   * Disclaimer:
   * Including this in your query can have a great impact on latency!
   * This field will only be populated for markets also supporting the "configuredCarByCarline" query
   */
  defaultConfiguredCar: Maybe<ConfiguredCar>;
  driveName: Scalars['String']['output'];
  ecoLabel: Maybe<EcoLabel>;
  engineName: Scalars['String']['output'];
  featureGroups: Array<FeatureGroup>;
  gearType: Scalars['String']['output'];
  marketId: MarketIdentifier;
  modelId: ModelIdentifier;
  name: Scalars['String']['output'];
  /** @deprecated use prices.minPrice instead */
  price: Price;
  prices: ModelFinanceablePrices;
  technicalData: ModelTechnicalData;
};

/** Trimline Model Data */
export type ModelPricesArgs = {
  financingInput: InputMaybe<FinancingInput>;
};

/** Node for energy efficiency class on model level */
export type ModelEecPerEnergyManagementWltp = {
  __typename?: 'ModelEecPerEnergyManagementWltp';
  /** Energy Efficiency Class. */
  eec: Maybe<RangeLevelInfo>;
  /** applied measurement cycle */
  energyManagement: Maybe<WltpEnergyManagement>;
};

export type ModelFinanceablePrices = {
  __typename?: 'ModelFinanceablePrices';
  financingParameters: Maybe<FinancingParameters>;
  monthlyRate: Maybe<MonthlyRateResult>;
  /** Model's base price */
  price: Maybe<Price>;
  /** Model's base price with fees */
  priceWithFees: Maybe<Price>;
};

/** This identifier contains all information required to identifier a Model */
export type ModelIdentifier = {
  __typename?: 'ModelIdentifier';
  code: Scalars['String']['output'];
  extensions: Maybe<Array<Scalars['String']['output']>>;
  extensionsPR7: Maybe<Array<Scalars['String']['output']>>;
  version: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type ModelIdentifierInput = {
  code: Scalars['String']['input'];
  /** 7-digit pr numbers */
  extensionsPR7: InputMaybe<Array<Scalars['String']['input']>>;
  version: Scalars['Int']['input'];
  year: Scalars['Int']['input'];
};

export type ModelTechnicalData = {
  __typename?: 'ModelTechnicalData';
  /**
   * The time needed to accelerate the vehicle from a given start velocity to a given target velocity.
   * (https://auto.schema.org/accelerationTime)
   * f.ex.: 9,5 s
   * TODO: question: How to handle the name: Beschleunigung 0-100 km/
   */
  accelerationTime: Maybe<TechnicalDataFloatItem>;
  /**
   * The time needed to accelerate the vehicle from a given start velocity to a given target velocity when using launch control.
   * For example: 5.9 s
   */
  accelerationTimeLaunchControl: Maybe<TechnicalDataFloatItem>;
  /**
   * Indicates the design and body style of the vehicle (e.g. station wagon, hatchback, etc.).
   * (https://auto.schema.org/bodyType)
   */
  bodyType: Maybe<TechnicalDataTextItem>;
  /**
   * Brakes og the vehicle
   * f.ex. "Diagonal-2-Kreis Bremssystem mit ESC/ABS/EBV, Bremskraftverstärker, hydraulischer Bremsassistent; vorn: Faustsattel, Bremsscheibe innenbelüftet"
   */
  brakes: Maybe<TechnicalDataTextItem>;
  /**
   * The available volume for cargo or luggage. For automobiles, this is usually the trunk volume.
   * https://schema.org/cargoVolume
   */
  cargoVolume: Maybe<TechnicalDataRangeItem>;
  /** Consumptions and emissions. */
  consumptionAndEmission: Maybe<ConsumptionAndEmission>;
  /**
   * Energy Efficiency Certificate.
   * @deprecated use envkv.eec instead
   */
  eec: Maybe<TechnicalDataTextItem>;
  /**
   * Combined electric range. Set minValue and maxValue accordingly.
   * TODO: Move attribute to Engine type?
   * f.ex. 284,0 - 341,0 km
   * Deprecated: --> engine.electricRangeCombined
   * @deprecated No longer supported
   */
  electricRangeCombined: Maybe<TechnicalDataRangeItem>;
  /**
   * Array of the engines for this vehicle. Minimal one entry
   * (https://auto.schema.org/EngineSpecification)
   */
  engine: Maybe<TechnicalDataEngineBase>;
  /** Energieverbrauchskennzeichnungsverordnung (Energy consumption labeling regulation) properties for models */
  envkv: Maybe<ModelTechnicalDataEnvkv>;
  /** A list of additional technical data fields that are not part of the schema due to being market specific. */
  extendedTechnicalData: Maybe<Array<TechnicalDataExtendedField>>;
  /**
   * Front axle of the vehicle
   * f.ex. "McPherson-Federbeinachse vorne"
   */
  frontAxle: Maybe<TechnicalDataTextItem>;
  /**
   * Gearbox of the vehicle
   * f.ex.: 7-Gang S tronic
   */
  gearbox: Maybe<TechnicalDataTextItem>;
  /** Class of several insurance related attributes */
  insuranceClass: Maybe<TechnicalDataInsuranceClass>;
  /**
   * Indicates that the vehicle meets the respective emission standard.
   * https://schema.org/meetsEmissionStandard
   * f.ex. Euro 6d-TEMP
   */
  meetsEmissionStandard: Maybe<TechnicalDataTextItem>;
  /**
   * Rear axle of the vehicle
   * f.ex. "Verbundlenker-Hinterachse "
   */
  rearAxle: Maybe<TechnicalDataTextItem>;
  /**
   * The permitted total weight of cargo and installations (e.g. a roof rack) on top of the vehicle.
   * https://auto.schema.org/roofLoad
   */
  roofLoad: Maybe<TechnicalDataFloatItem>;
  /**
   * The speed range of the vehicle. If the vehicle is powered by an engine, the upper limit of the speed range (indicated by maxValue should be the maximum speed achievable under regular conditions.
   * (https://auto.schema.org/speed)
   * f.ex. 250 km/h
   */
  speed: Maybe<TechnicalDataFloatItem>;
  /**
   * Steering system of the vehicle
   * f.ex. "Elektromechanische Lenkung mit geschwindigkeitsabhängiger Servounterstützung; Wendekreis ca. 10,6 m"
   */
  steeringSystem: Maybe<TechnicalDataTextItem>;
  /** Debug Value */
  tenant: Maybe<Scalars['String']['output']>;
  /**
   * The permitted vertical load (TWR) of a trailer attached to the vehicle. Also referred to as Tongue Load Rating (TLR) or Vertical Load Rating (VLR)
   * https://auto.schema.org/tongueWeight
   */
  tongueWeight: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight at a gradient of 8 percent.
   * f.ex.: 2700 kg
   */
  trailerWeight8: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight at a gradient of 12 percent.
   * f.ex. 2800 kg / in Verbindung mit adaptive air suspension oder adaptive air suspension sport 3500
   */
  trailerWeight12: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight for trailers without separate brakes.
   * f.ex.: 750 kg
   */
  trailerWeightUnbraked: Maybe<TechnicalDataFloatItem>;
  /**
   * Vehicle weight without load.
   * f.ex.: 2505 kg
   */
  unladenWeight: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of component used for transmitting the power from a rotating power source to the wheels or other relevant component(s) ("gearbox" for cars)
   * (https://schema.org/vehicleTransmission)
   * f.ex.: Allradantrieb quattro
   */
  vehicleTransmission: Maybe<TechnicalDataTextItem>;
  /**
   * The permitted total weight of the loaded vehicle, including passengers and cargo and the weight of the empty vehicle.
   * https://auto.schema.org/weightTotal
   * f.ex.: 3045 kg
   */
  weightTotal: Maybe<TechnicalDataFloatItem>;
};

/** Node for all data regarding energy consumption labeling regulation on model level */
export type ModelTechnicalDataEnvkv = {
  __typename?: 'ModelTechnicalDataEnvkv';
  /** Energy Efficiency Class per wltp phase method on model level */
  eecModelPerEnergyManagementWltp: Maybe<Array<ModelEecPerEnergyManagementWltp>>;
};

export type MonthlyRate = {
  __typename?: 'MonthlyRate';
  currency: Maybe<FinancingCurrency>;
  formattedValue: Maybe<Scalars['String']['output']>;
  rawValue: Maybe<Scalars['Float']['output']>;
  valueAsText: Maybe<Scalars['String']['output']>;
};

export type MonthlyRateResult = FinancingError | MonthlyRate;

export type Mutation = {
  __typename?: 'Mutation';
  /** @deprecated not implemented */
  configurationChange: Configuration;
};

export type MutationConfigurationChangeArgs = {
  change: ChangeAction;
  configIdentifier: ConfigurationIdentifier;
  marketContext: MarketContext;
};

/** Node containing consumption and emission values for NEDC testing method */
export type NedcTestMethod = {
  __typename?: 'NedcTestMethod';
  /** Set of values for fuel consumption */
  consumption: Maybe<NedcValues>;
  /** Set of values for CO2 emissions */
  emissionCO2: Maybe<NedcValues>;
  /** Value for NO2 emissions */
  emissionNO2: Maybe<TechnicalDataFloatItem>;
};

/** consumption or emission values for NEDC testing method */
export type NedcValues = {
  __typename?: 'NedcValues';
  /** combined range value for consumption or emission profile */
  combined: Maybe<RangeInfo>;
  /** consolidated range value may or may not be present depending on a factual decision about value to be shown */
  consolidated: Maybe<RangeInfo>;
  /** consumption or emission range value for extraUrban profile */
  extraUrban: Maybe<RangeInfo>;
  /** consumption or emission range value for urban profile */
  urban: Maybe<RangeInfo>;
};

export type NonFinanceableTypedPrice = TypedPrice & {
  __typename?: 'NonFinanceableTypedPrice';
  price: Price;
  type: Scalars['String']['output'];
};

/** consumption or emission values for non WLTP Markets (Canada, US, Singapore) */
export type NonWltpTestMethod = {
  __typename?: 'NonWltpTestMethod';
  consumption: NonWltpValues;
  emissionCO2: Maybe<TechnicalDataFloatItem>;
  emissionNO2: Maybe<TechnicalDataFloatItem>;
};

/** consumption or emission values for non WLTP Markets (Canada, US, Singapore) */
export type NonWltpValues = {
  __typename?: 'NonWltpValues';
  /** combined value for consumption or emission profile */
  combined: Maybe<TechnicalDataFloatItem>;
  /** consolidated value may or may not be present depending on a factual decision about value to be shown */
  consolidated: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for extraUrban profile */
  extraUrban: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for urban profile */
  urban: Maybe<TechnicalDataFloatItem>;
};

/** Describes the optional data sent to Okapi */
export type OkapiData = {
  __typename?: 'OkapiData';
  /**
   * code for the vehicles base type
   * example: BASETYPE:8WDA7G
   */
  baseTypeCode: Maybe<Scalars['String']['output']>;
  /**
   * code for the entity
   * example: TYPE:8WDA7G
   */
  code: Maybe<Scalars['String']['output']>;
  /** free text describing the vehicle */
  description: Maybe<Scalars['String']['output']>;
  /**
   * Identifier
   * example: 9a98a6cc-090e-5737-bf07-97faf72f12c6
   */
  id: Maybe<Scalars['String']['output']>;
  /**
   * The model year
   * example: MODELYEAR:2024
   */
  modelYearCode: Maybe<Scalars['String']['output']>;
  /**
   * Shortcode
   * example: SHORTENER:E00
   */
  shortCode: Maybe<Scalars['String']['output']>;
  /**
   * model version
   * example: 2
   */
  version: Maybe<Scalars['String']['output']>;
};

/**
 * %DELETE TYPE%
 * Informations about the OneGraph, the OneGraphSubServices and additional DownStreamServices
 * The services can/should deliver informations about
 *  - health
 *  - endpoint
 *  - meta (such as contact, issueTracker, Channel,...)
 *  - down stream services of the sub services (important for checking, visualising the state of OneGraph)
 *
 * Infos about SubServices can be federated in this type
 * f.ex
 *
 * extend type OneGraphInfo @key(fields: id) @shareable {
 *     id: ID! @external
 *     mySubService: MySubServiceInfo!
 * }
 *
 * type MySubServiceInfo implements OneGraphSubServiceInfo @shareable {
 * ...
 * }
 */
export type OneGraphInfo = {
  __typename?: 'OneGraphInfo';
  audicodeGraphqlInfo: AudicodeGraphqlInfo;
  carlineInfoServiceInfo: CarlineInfoServiceInfo;
  colaServiceInfo: ColaServiceInfo;
  /** GraphQL endpoint of the OneGraph */
  endpoint: Scalars['String']['output'];
  financingGraphqlInfo: FinancingGraphqlInfo;
  /**
   * ID of the OneGraph (derived from the version)
   * @deprecated Use `version`.
   */
  id: Scalars['ID']['output'];
  /** GraphQL endpoint of the OneGraph */
  meta: OneGraphServiceMetaInfo;
  /** Name of the OneGraph: 'OneGraph' */
  name: Scalars['String']['output'];
  /** information and health status about the Stock-Car subgraph */
  stockcarGraphqlInfo: StockcarGraphqlInfo;
  tedasServiceInfo: TedasServiceInfo;
  /** Software Version of the OneGraph-Gateway */
  version: Scalars['String']['output'];
};

/**
 * MetaInfo of the service
 * The service might be the OneGraph itself, the sub services or the downstream service
 */
export type OneGraphServiceContactInfo = {
  __typename?: 'OneGraphServiceContactInfo';
  /** Email of the contact */
  email: Maybe<Scalars['String']['output']>;
  /**
   * Name of the contact.
   * Provide title, fistname and lastname in one field
   */
  name: Scalars['String']['output'];
  /** Role of the contact */
  role: OneGraphServiceContactRoleEnum;
};

/**
 * TODO; Verify the roles (Complete?)
 * The role of a contact
 */
export enum OneGraphServiceContactRoleEnum {
  /** Role Developer */
  Developer = 'DEVELOPER',
  /**
   * Role Product Owner
   * In an Audi Context an Audi reponsibility
   */
  ProductOwner = 'PRODUCT_OWNER',
  /** Role Proxy Product Owner */
  ProxyProductOwner = 'PROXY_PRODUCT_OWNER',
  /**
   * Support
   * Might be a support contact whithin the service or an exteranl support supplier
   */
  Support = 'SUPPORT',
}

/**
 * There are currently two locations in the info graph where service health might be indicated
 * - OneGraphSubService: Should indicate health of one (or more) downstreamservice
 * - OneGraphDownStreamService: Should health of downstream service. This should be requested by the SubService
 */
export type OneGraphServiceHealth = {
  __typename?: 'OneGraphServiceHealth';
  /**
   * URL of the check endpoint (might be important for troubleshooting and support)
   * @deprecated Removed without replacement, so that check urls are not exposed.
   */
  checkEndpoint: Maybe<Scalars['String']['output']>;
  /** Code (might be an error code of the Sub/DownStream-Service or http error code) */
  code: Maybe<Scalars['String']['output']>;
  /** Message related to the code */
  message: Maybe<Scalars['String']['output']>;
  /** Status of the Service */
  status: OneGraphServiceStatusEnum;
};

/**
 * Meta information about the Service
 * (Might be the OneGraph, the Subservices or the DownstreamServices)
 */
export type OneGraphServiceMetaInfo = {
  __typename?: 'OneGraphServiceMetaInfo';
  /**
   * URL of an direct communication channel.
   * In an Audi context a link to a rocket chat channel
   */
  channel: Maybe<Scalars['String']['output']>;
  /** Contact info for the service. */
  contact: Maybe<Array<OneGraphServiceContactInfo>>;
  /**
   * Homepage of the service to get more detailled information.
   * In an Audi context a link to a confluence page.
   * Might be also an link to the specification/support of this service
   */
  homepage: Scalars['String']['output'];
  /**
   * URL to the issue tracker.
   * In an Audi context a link to a jira project
   */
  issueTracker: Scalars['String']['output'];
  /** Repository for the source code of the service */
  repo: Maybe<Scalars['String']['output']>;
  /** Name of the team which is responsible for the service */
  team: Scalars['String']['output'];
};

/**
 * Indicates the service status
 * Derived from https://datatracker.ietf.org/doc/html/draft-inadarei-api-health-check-05#section-3.1
 */
export enum OneGraphServiceStatusEnum {
  /** Failure in Service */
  Fail = 'FAIL',
  /** Service is ok */
  Pass = 'PASS',
  /** Warning in Service */
  Warn = 'WARN',
}

/**
 * TODO: Separate types for different kinds of downstream services?
 * Info about a downstream service.
 * A donwstream service is an external ressource which will be used by a aub service.
 * This can be a Rest/GraphQL/SOAP-Endppoint or a database or something else
 */
export type OneGraphSubServiceDownStreamInfo = {
  __typename?: 'OneGraphSubServiceDownStreamInfo';
  /** Endpoint of the downstream service */
  endpoint: Maybe<Scalars['String']['output']>;
  /** Healthinfo about the downstream service */
  health: Maybe<OneGraphServiceHealth>;
  /**
   * ID of the downstream service.
   * Fill something that makews sense in the context of the downstream service
   * f.ex. a REST-ressource might deliver the version of the API
   */
  id: Scalars['ID']['output'];
  /** MetaInfo about the downstream service */
  meta: Maybe<OneGraphServiceMetaInfo>;
  /** Name of the downstream service */
  name: Scalars['String']['output'];
};

/**
 * Interface thas has to implemented by each subservice opf the OneGraph
 * TODO: Information about the running plattform (f.ex AWS, SPR, WebCentzer, Cocos,...)?
 * TODO: Programming language and frameworks?
 */
export type OneGraphSubServiceInfo = {
  /** Endpoint of the sub service */
  endpoint: Scalars['String']['output'];
  /** Health of the sub service */
  health: OneGraphServiceHealth;
  /**
   * Identifier of the sub service
   * Might be the version, git commit hash, ....
   * Should be human readable
   */
  id: Scalars['ID']['output'];
  /** MetaInfo of the sub service */
  meta: OneGraphServiceMetaInfo;
  /** Name of the sub service */
  name: Scalars['String']['output'];
  /** External downstream services of the sub service */
  services: Maybe<Array<Maybe<OneGraphSubServiceDownStreamInfo>>>;
};

export type OneGraphSubServiceStatus = {
  __typename?: 'OneGraphSubServiceStatus';
  /** Health status of the Subgraph service */
  health: OneGraphServiceHealth;
  /** The Name of the Subgraph service */
  name: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

/** @deprecated data moved into `ConfiguredCar.catalog` */
export type Option = {
  __typename?: 'Option';
  containsOptions: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  family: Family;
  financingParameters: Maybe<FinancingParameters>;
  group: Group;
  hasDetails: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  images: Maybe<Array<Image>>;
  isContainedInOptions: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  itemGroup: Maybe<ItemGroup>;
  itemType: Scalars['String']['output'];
  mbvId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  price: Maybe<OptionPrice>;
  rate: Maybe<PartRateResult>;
  status: Status;
  tyreLabels: Maybe<Array<Maybe<TyreLabel>>>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type OptionPrice = {
  __typename?: 'OptionPrice';
  currency: Maybe<Scalars['String']['output']>;
  formattedValue: Maybe<Scalars['String']['output']>;
  priceMode: Maybe<PriceMode>;
  rawValue: Maybe<Scalars['Float']['output']>;
};

/**
 * Pagination - we're 'blocking' this type to make sure that it isn't be used outside core definition.
 * TODO: check original type
 */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** Indicates whether there's a next page */
  hasNextPage: Scalars['Boolean']['output'];
  /** Indicates whether there's a previous page */
  hasPreviousPage: Scalars['Boolean']['output'];
};

export type PartRateResult = FinanceablePartRate | FinancingError;

/** information to specify a dealer */
export type PartnerInfo = {
  /** use data of the dealer group instead of a single dealer */
  includeGroupStock: InputMaybe<Scalars['Boolean']['input']>;
  /** KVPS id of the dealer */
  partnerId: Scalars['String']['input'];
};

/**
 * A type to represent a price and currency.
 * Add @shareable directive when carline-info
 * service has become Fed 2 compliant.
 */
export type Price = CarPrice & {
  __typename?: 'Price';
  /** @deprecated No longer supported */
  currency: Maybe<Scalars['String']['output']>;
  /** Currency code ("EUR", "USD") & symbol ("€", "$") */
  currencyDetails: Maybe<Currency>;
  /** Formatted display value. Example: "9,99 EUR" */
  formattedValue: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer supported */
  rawValue: Maybe<Scalars['String']['output']>;
  /** The actual price as a number. Example: 9,99 */
  value: Scalars['Float']['output'];
  /** Only the number as text. Example: "9,99" */
  valueAsText: Maybe<Scalars['String']['output']>;
};

export type PriceDate = {
  __typename?: 'PriceDate';
  date: Maybe<Scalars['String']['output']>;
  text: Maybe<Scalars['String']['output']>;
};

/** @deprecated queries deprecated */
export enum PriceMode {
  Price = 'PRICE',
  Rate = 'RATE',
}

/** @deprecated data moved into `ConfiguredCar` */
export type Prices = {
  __typename?: 'Prices';
  base: Maybe<Price>;
  bpm: Maybe<Price>;
  carlineBase: Maybe<Price>;
  charges: Maybe<Price>;
  financeableTotal: Maybe<Price>;
  /** @deprecated financing information moved into financingParameters */
  financing: Maybe<Financing>;
  financingParameters: Maybe<FinancingParameters>;
  monthlyRate: Maybe<MonthlyRateResult>;
  nettoBase: Maybe<Price>;
  nettoOptions: Maybe<Price>;
  nonFinanceableTotal: Maybe<Price>;
  options: Maybe<Price>;
  rotr: Maybe<Price>;
  rotrRate: Maybe<Price>;
  servicePackage: Maybe<Price>;
  taxTotal: Maybe<Price>;
  taxTotalCO2: Maybe<Price>;
  taxTotalExclScrapFree: Maybe<Price>;
  taxTotalNOx: Maybe<Price>;
  taxTotalWeight: Maybe<Price>;
  taxableBenefit1: Maybe<Price>;
  taxableBenefit2: Maybe<Price>;
  total: Maybe<Price>;
  /** @deprecated No longer supported */
  totalRate: Maybe<Price>;
  totalWithCharges: Maybe<Price>;
  vat: Maybe<Price>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type Query = {
  __typename?: 'Query';
  /** A query to accept the current selected conflict solutions */
  acceptConflictSolutions: AcceptConflictSolutionsPayload;
  /** information about the stocks of a market */
  availableStock: AvailableStock;
  carByCode: Maybe<AudiCodeCar>;
  carCodeTechnicalData: ConfiguredCarTechnicalData;
  carline: CarlineStructureCarline;
  carlineGroup: CarlineGroup;
  carlineStructure: CarlineStructure;
  carlineStructureForStockCars: CarlineStructure;
  /** A query to change a configured car by adding or removing a feature. */
  changeConfiguredCarFeature: ChangeConfiguredCarPayload;
  /** A query to change a configured car's model. */
  changeConfiguredCarModel: ChangeConfiguredCarPayload;
  /** A query to change a configured car's trimline. */
  changeConfiguredCarTrimline: ChangeConfiguredCarPayload;
  /** A query to change a conflict solution. */
  changeConflictSolutions: ChangeConflictSolutionsPayload;
  /** @deprecated Use query configuredCar. */
  configuration: Configuration;
  /** @deprecated Use query configuredCarByCarlineWithFeatures. */
  configurationInitWithItems: Configuration;
  /** Returns the configured car defined by the input object */
  configuredCar: ConfiguredCar;
  /** Resolve the default configuration for the given carline. */
  configuredCarByCarline: ConfiguredCar;
  /** Resolve the lowest priced ConfiguredCar for the given carline including the required features. */
  configuredCarByCarlineWithFeatures: ConfiguredCar;
  /**
   * Changes the given configuration by adding or removing a new feature. It additionally can auto-resolve buildability
   * conflicts and return status information about it.
   * @deprecated Please use the new query chnageConfigurationFeature
   */
  configuredCarChange: ChangedConfiguredCar;
  configuredCarPackages: Maybe<Array<ConfigurationPackage>>;
  /** Query for technical data for configured car */
  configuredCarTechnicalData: ConfiguredCarTechnicalData;
  /**
   * new query
   *
   * search dealers in a stock
   */
  dealerSearch: DealerSearch;
  /** @deprecated Use configuredCar instead */
  getConfiguredCar: ConfiguredCar;
  /** @deprecated Use configuredCarPackages instead */
  getConfiguredCarPackages: Maybe<Array<ConfigurationPackage>>;
  /**
   * Query for technical data for configured car
   * @deprecated use `configuredCarTechnicalData` instead
   */
  getTechnicalDataForConfiguredCar: ConfiguredCarTechnicalData;
  /** @deprecated Use query configuredCar and corresponding catalog fields with optional filters. */
  itemsByGroup: Array<Option>;
  /** @deprecated use technicalDataByModel instead */
  modelTechnicalData: Maybe<ModelTechnicalData>;
  /**
   * Get information about the OneGraph itself
   * @deprecated use subgraph service related status queries instead
   */
  oneGraphInfo: Maybe<OneGraphInfo>;
  /**
   * New query to get information for a single stock car
   * See RFC62 for more details
   */
  stockCar: Maybe<StockCar>;
  /** query to get extended information for a single stock car */
  stockCarExtended: Maybe<StockCarExtended>;
  /** new query */
  stockCarSearch: StockCarSearch;
  /** new query */
  stockCarSearchConfiguration: StockCarSearchConfiguration;
  /**
   * New query for checking the health status of the subgraph
   * See RFC005 for more details
   */
  stockcarServiceStatus: OneGraphSubServiceStatus;
  /** Query for retrieving technical data for a specific model */
  technicalDataByModel: Maybe<ModelTechnicalData>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryAcceptConflictSolutionsArgs = {
  financingInput: InputMaybe<FinancingInput>;
  input: AcceptConflictSolutionsInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryAvailableStockArgs = {
  marketIdentifier: MarketIdentifierInput;
  partnerInfo: InputMaybe<PartnerInfo>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryCarByCodeArgs = {
  code: Scalars['String']['input'];
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryCarCodeTechnicalDataArgs = {
  code: TedasCarCode;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryCarlineArgs = {
  identifier: CarlineIdentifierInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryCarlineGroupArgs = {
  identifier: CarlineGroupIdentifierInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryCarlineStructureArgs = {
  identifier: CarlineStructureIdentifierInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryCarlineStructureForStockCarsArgs = {
  filter: InputMaybe<CarlineStructureForStockCarsFilterInput>;
  stockIdentifier: StockIdentifierInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryChangeConfiguredCarFeatureArgs = {
  financingInput: InputMaybe<FinancingInput>;
  input: ChangeConfiguredCarFeatureInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryChangeConfiguredCarModelArgs = {
  financingInput: InputMaybe<FinancingInput>;
  input: ChangeConfiguredCarModelInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryChangeConfiguredCarTrimlineArgs = {
  financingInput: InputMaybe<FinancingInput>;
  input: ChangeConfiguredCarTrimlineInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryChangeConflictSolutionsArgs = {
  financingInput: InputMaybe<FinancingInput>;
  input: ChangeConflictSolutionsInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryConfigurationArgs = {
  configIdentifier: ConfigurationIdentifier;
  marketContext: MarketContext;
  priceMode?: InputMaybe<PriceMode>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryConfigurationInitWithItemsArgs = {
  carline: Scalars['String']['input'];
  items: Array<Scalars['String']['input']>;
  marketContext: MarketContext;
  priceMode?: InputMaybe<PriceMode>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryConfiguredCarArgs = {
  configuredCarIdentifier: ConfiguredCarInput;
  financingInput: InputMaybe<FinancingInput>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryConfiguredCarByCarlineArgs = {
  carlineInput: ConfiguredCarByCarlineInput;
  financingInput: InputMaybe<FinancingInput>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryConfiguredCarByCarlineWithFeaturesArgs = {
  carlineInput: ConfiguredCarByCarlineInput;
  features: Array<Scalars['String']['input']>;
  financingInput: InputMaybe<FinancingInput>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryConfiguredCarChangeArgs = {
  configuredCarIdentifier: ConfiguredCarInput;
  featureChange: FeatureChange;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryConfiguredCarPackagesArgs = {
  configuredCarIdentifier: ConfiguredCarInput;
  financingInput: InputMaybe<FinancingInput>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryConfiguredCarTechnicalDataArgs = {
  identifier: TechnicalDataConfiguredCarInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryDealerSearchArgs = {
  searchParameter: InputMaybe<DealerSearchParameterInput>;
  stockIdentifier: StockIdentifierInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryGetConfiguredCarArgs = {
  configuredCarIdentifier: ConfiguredCarInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryGetConfiguredCarPackagesArgs = {
  configuredCarIdentifier: ConfiguredCarInput;
  financingInput: InputMaybe<FinancingInput>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryGetTechnicalDataForConfiguredCarArgs = {
  identifier: TechnicalDataConfiguredCarInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryItemsByGroupArgs = {
  configIdentifier: ConfigurationIdentifier;
  itemGroup: ItemGroup;
  marketContext: MarketContext;
  priceMode?: InputMaybe<PriceMode>;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryModelTechnicalDataArgs = {
  marketId: MarketIdentifierInput;
  modelId: ModelIdentifierInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryStockCarArgs = {
  stockCarIdentifier: StockCarIdentifierInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryStockCarExtendedArgs = {
  stockCarIdentifier: StockCarIdentifierInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryStockCarSearchArgs = {
  searchParameter: InputMaybe<StockCarSearchParameterInput>;
  stockIdentifier: StockIdentifierInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryStockCarSearchConfigurationArgs = {
  input: StockIdentifierInput;
};

/**
 * %DELETE TYPE%
 * Root queries of the CoreSchema
 * TODO: Should this separated in an own service?
 */
export type QueryTechnicalDataByModelArgs = {
  technicalDataByModelInput: TechnicalDataByModelInput;
};

export type RangeInfo = {
  __typename?: 'RangeInfo';
  label: Scalars['String']['output'];
  max: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
  remarks: Maybe<Array<Maybe<Remark>>>;
  unit: Scalars['String']['output'];
};

/** ranges which have to be expressed via literals and not numbers */
export type RangeLevelInfo = {
  __typename?: 'RangeLevelInfo';
  /** extra info */
  label: Scalars['String']['output'];
  /** max of the range as it can literally be defined */
  max: Scalars['String']['output'];
  /** min of the range as it can literally be defined */
  min: Scalars['String']['output'];
  /** remarks, if present */
  remarks: Maybe<Array<Maybe<Remark>>>;
  /** Debug: Source where it is resolved */
  source: Maybe<Scalars['String']['output']>;
  /** unit of the range */
  unit: Scalars['String']['output'];
};

export type Remark = {
  __typename?: 'Remark';
  /** Unique ID of the remark */
  id: Scalars['String']['output'];
  /** Text of the remark */
  value: Maybe<Scalars['String']['output']>;
};

/** @deprecated data moved into `ConfiguredCar` */
export type RenderImages = {
  __typename?: 'RenderImages';
  closeupLightsFullscreen: Maybe<Scalars['String']['output']>;
  closeupLightsLayerstage: Maybe<Scalars['String']['output']>;
  closeupLightsStage: Maybe<Scalars['String']['output']>;
  closeupWheelsFullscreen: Maybe<Scalars['String']['output']>;
  closeupWheelsLayerstage: Maybe<Scalars['String']['output']>;
  closeupWheelsStage: Maybe<Scalars['String']['output']>;
  dashboardFullscreen: Maybe<Scalars['String']['output']>;
  dashboardLayerstage: Maybe<Scalars['String']['output']>;
  dashboardStage: Maybe<Scalars['String']['output']>;
  exteriorBackFullscreen: Maybe<Scalars['String']['output']>;
  exteriorBackLayerstage: Maybe<Scalars['String']['output']>;
  exteriorBackStage: Maybe<Scalars['String']['output']>;
  exteriorFrontCarstore: Maybe<Scalars['String']['output']>;
  exteriorFrontCompare: Maybe<Scalars['String']['output']>;
  exteriorFrontFullscreen: Maybe<Scalars['String']['output']>;
  exteriorFrontLayerstage: Maybe<Scalars['String']['output']>;
  exteriorFrontStage: Maybe<Scalars['String']['output']>;
  exteriorFullscreen: Maybe<Scalars['String']['output']>;
  exteriorLayerstage: Maybe<Scalars['String']['output']>;
  exteriorModelfinder: Maybe<Scalars['String']['output']>;
  exteriorStage: Maybe<Scalars['String']['output']>;
  interiorFullscreen: Maybe<Scalars['String']['output']>;
  interiorLayerstage: Maybe<Scalars['String']['output']>;
  interiorStage: Maybe<Scalars['String']['output']>;
  n2c01: Maybe<Scalars['String']['output']>;
  n2c02: Maybe<Scalars['String']['output']>;
  n2c12: Maybe<Scalars['String']['output']>;
  n2c14: Maybe<Scalars['String']['output']>;
  n2c15: Maybe<Scalars['String']['output']>;
  n2n05: Maybe<Scalars['String']['output']>;
  n2n06: Maybe<Scalars['String']['output']>;
  n2n10: Maybe<Scalars['String']['output']>;
  n3c01: Maybe<Scalars['String']['output']>;
  n3c02: Maybe<Scalars['String']['output']>;
  n3c12: Maybe<Scalars['String']['output']>;
  n3c14: Maybe<Scalars['String']['output']>;
  n3c15: Maybe<Scalars['String']['output']>;
  n3n05: Maybe<Scalars['String']['output']>;
  n3n06: Maybe<Scalars['String']['output']>;
  n3n10: Maybe<Scalars['String']['output']>;
  n4c01: Maybe<Scalars['String']['output']>;
  n4c02: Maybe<Scalars['String']['output']>;
  n4c12: Maybe<Scalars['String']['output']>;
  n4c14: Maybe<Scalars['String']['output']>;
  n4c15: Maybe<Scalars['String']['output']>;
  n4n05: Maybe<Scalars['String']['output']>;
  n4n06: Maybe<Scalars['String']['output']>;
  n4n10: Maybe<Scalars['String']['output']>;
  n5c01: Maybe<Scalars['String']['output']>;
  n8c01: Maybe<Scalars['String']['output']>;
  n9c14: Maybe<Scalars['String']['output']>;
  rearseatFullscreen: Maybe<Scalars['String']['output']>;
  rearseatLayerstage: Maybe<Scalars['String']['output']>;
  rearseatStage: Maybe<Scalars['String']['output']>;
};

/** Represents a rendered image of a car inclduing metadata */
export type RenderedCarImage = {
  __typename?: 'RenderedCarImage';
  /** Category, e.g. EXTERIOR. */
  category: RenderedImagesFilterCategory;
  /**
   * Convertible Roof: OPEN or CLOSED.
   * Will always be CLOSED
   * @deprecated No longer differentiated in backend.
   */
  convertibleRoof: RenderedImagesConvertibleRoof;
  /**
   * Device type, e.g. MOBILE or DESKTOP.
   * Will aways be DESKTOP
   * @deprecated No longer differentiated in backend.
   */
  deviceType: RenderedImagesDeviceType;
  /** True when the fallbackUrl was provided in the url field. This may be the case when RenderService cannot provide the real image. */
  fallbackUrlUsed: Scalars['Boolean']['output'];
  /** MIME type of the image. */
  mimeType: Scalars['String']['output'];
  /**
   * profileId identifies a profile which describes an associated image including its metadata like view, and category.
   * A profile may also have variants for different device types like MOBILE and DESKTOP as well as options for having a convertible's roof open or closed.
   * The profileId follows the format <CATEGORY>-<VIEW>-<human readable name of the image>
   * profileId, e.g. EXTERIOR-FRONT-3-4
   */
  profileId: Scalars['String']['output'];
  /** Id of the image known to renderservice, e.g. ngccfront34closedbg. */
  renderServiceId: Scalars['String']['output'];
  /** Resolution of the rendered image */
  resolution: ImageResolution;
  /**
   * URL for the image.
   * If the real url of the image cannot be provided the fallbackUrl will be provided here and `fallbackUrlUsed` is set to true. The fallback image will have the same geometry as the original image.
   */
  url: Scalars['String']['output'];
  /** View, e.g. FRONT. */
  view: RenderedImagesFilterView;
  /** Image width in pixels. */
  width: Scalars['Int']['output'];
};

/** Filter options for retrieval of rendered car images */
export type RenderedCarImagesFilterInput = {
  /**
   * Filter profiles by category, e.g. INTERIOR, LIGHTS, EXTERIOR.
   * Multiple filters in the array behave like a logic ORwhen filtering with filterByView and filterByCategory the filters behave like a logic AND.
   * If filterByProfileId is set, this filter is ignored.
   */
  filterByCategory: InputMaybe<Array<RenderedImagesFilterCategory>>;
  /**
   * Select one or multiple profiles directly by their profileId e.g. "EXTERIOR-FRONT-3-4". When using this filter, filterByView and filterByCategory will be ignored, since their properties are already defined in the profile.
   * The order of porfileIds in the array will be reflected in the output.
   */
  filterByProfileId: InputMaybe<Array<Scalars['String']['input']>>;
  /**
   * Filter profiles by view, e.g. FRONT, REAR, PARALLEL.
   * Multiple filters in the array behave like a logic OR, when filtering with filterByView and filterByCategory the filters behave like a logic AND.
   * If filterByProfileId is set, this filter is ignored.
   */
  filterByView: InputMaybe<Array<RenderedImagesFilterView>>;
};

/** Indicates whether the rendered convertible's roof is open or closed */
export enum RenderedImagesConvertibleRoof {
  Closed = 'CLOSED',
  Open = 'OPEN',
}

/** Device type used in RenderedCarImage */
export enum RenderedImagesDeviceType {
  Desktop = 'DESKTOP',
  Mobile = 'MOBILE',
}

/** Used as filter option to retrieve rendered car images. Filters the iamge's category */
export enum RenderedImagesFilterCategory {
  Exterior = 'EXTERIOR',
  Interior = 'INTERIOR',
  Lights = 'LIGHTS',
  Unknown = 'UNKNOWN',
}

/** Used as filter option to retrieve rendered car iamges. Filters the view of the car */
export enum RenderedImagesFilterView {
  Dashboard = 'DASHBOARD',
  Front = 'FRONT',
  Front3_4 = 'FRONT3_4',
  Frontseats = 'FRONTSEATS',
  Headlight = 'HEADLIGHT',
  Lights = 'LIGHTS',
  Parallel = 'PARALLEL',
  Rear = 'REAR',
  Rear3_4 = 'REAR3_4',
  Rearseats = 'REARSEATS',
  Rim = 'RIM',
  Seats = 'SEATS',
  Side = 'SIDE',
  Unknown = 'UNKNOWN',
  Wheels = 'WHEELS',
}

/** Describes a selected option of the current configuration for the finance parameters */
export type SelectedFinanceablePart = {
  __typename?: 'SelectedFinanceablePart';
  /** E.g. '4L2' */
  pr3: Scalars['ID']['output'];
  /** E.g. 'MIRS4L2' */
  pr7: Scalars['ID']['output'];
  /** Always contains a price >= 0.0 */
  price: Scalars['Float']['output'];
  /** Mapping of the featureType */
  type: SelectedFinanceablePartType;
};

/**
 * Possible type for a SelectedFinanceablePart based on enum from the finance API
 *
 * API support more values, but we only provide these
 */
export enum SelectedFinanceablePartType {
  Accessory = 'ACCESSORY',
  Equipment = 'EQUIPMENT',
  Exterior = 'EXTERIOR',
  Interior = 'INTERIOR',
  Package = 'PACKAGE',
}

/** single found similar car */
export type SimilarCar = {
  __typename?: 'SimilarCar';
  /**
   * "new field"
   *
   * differences between similar car and reference configured car
   */
  compareCriteria: Array<SimilarCarCompareCriterion>;
  /** distance between geo position of the car and passed geo position from geo search */
  geoDistance: Maybe<StockCarFloatValueWithUnitItem>;
  /** score of matching of similar car with reference in percent */
  score: Scalars['Float']['output'];
  /** information about the similar car */
  stockCar: StockCar;
};

/** single found similar car */
export type SimilarCarCompareCriteriaArgs = {
  status: Array<SimilarCarCompareCriterionStatus>;
};

/**
 * "new type"
 *
 * single difference between similar car and reference configured car
 */
export type SimilarCarCompareCriterion = {
  __typename?: 'SimilarCarCompareCriterion';
  /** category of difference */
  category: SimilarCarCompareCriterionCategory;
  /** language independent code of difference */
  code: Scalars['String']['output'];
  /** status of difference in relation to reference configured car */
  status: SimilarCarCompareCriterionStatus;
  /** language dependent display text of difference */
  text: Scalars['String']['output'];
};

/**
 * "new type"
 *
 * category of difference between similar car and reference configured car
 */
export type SimilarCarCompareCriterionCategory = {
  __typename?: 'SimilarCarCompareCriterionCategory';
  /** language independent code of category */
  code: Scalars['String']['output'];
  /** language dependent display text of category */
  text: Scalars['String']['output'];
};

/**
 * "new type"
 *
 * status of difference between similar car and reference configured car
 * (in relation to reference configured car)
 */
export enum SimilarCarCompareCriterionStatus {
  Additional = 'additional',
  Missing = 'missing',
}

/** result of similar cars search */
export type SimilarCars = {
  __typename?: 'SimilarCars';
  /** found similar cars */
  cars: Array<SimilarCar>;
};

/** settings for the precision of the model (code and year) for seeking similar cars */
export type SimilarCarsCarModelPrecisionInput = {
  /**
   * precision of the mbv model code of the reference configured car.
   * The value must be between 0 and 6. 6 is default.
   * It defines the number of the digits of the mbv model code of the reference configured car,
   * that must be matched in the similar cars.
   */
  codePrecision: InputMaybe<Scalars['Int']['input']>;
  /**
   * range of model years for similar cars (based on the model year of the reference configured car).
   * By default the model year of the reference configured car is set.
   */
  yearsOffset: InputMaybe<SimilarCarsCarModelYearRangeByOffsetInput>;
};

/**
 * range of model years for similar cars
 * the values are offsets based on the model year of the reference configured car
 * e.g. for the reference year 2023
 * 2 = 2025
 * 1 = 2024
 * 0 = 2023
 * -1 = 2022
 * -2 = 2021
 */
export type SimilarCarsCarModelYearRangeByOffsetInput = {
  end: Scalars['Int']['input'];
  start: Scalars['Int']['input'];
};

/**
 * weights for calculate the score of a similar car.
 * Each weight is defined for one feature type (based on a KeyTool equipment category).
 * So each feature with this feature type gets the defined weight.
 * The value must be a positive integer. Default is 100.
 */
export type SimilarCarsFeatureTypeWeightsInput = {
  engineAndTransmission: InputMaybe<Scalars['Int']['input']>;
  exterior: InputMaybe<Scalars['Int']['input']>;
  infotainment: InputMaybe<Scalars['Int']['input']>;
  interior: InputMaybe<Scalars['Int']['input']>;
  other: InputMaybe<Scalars['Int']['input']>;
  seats: InputMaybe<Scalars['Int']['input']>;
  steeringWheels: InputMaybe<Scalars['Int']['input']>;
  technologyAndSafety: InputMaybe<Scalars['Int']['input']>;
  wheelsAndTyres: InputMaybe<Scalars['Int']['input']>;
};

/** parameter for getting similar cars */
export type SimilarCarsInput = {
  /** prefiltering criteria for similar cars */
  additionalParameters: InputMaybe<Array<StockCarSearchParameterCriterionInput>>;
  /**
   * "new field"
   *
   * parameter for geo search of stock cars
   */
  geoSearch: InputMaybe<StockCarSearchParameterGeoCriterionInput>;
  /** maximal number of returned similar cars. Default is 10. Maximum is 50. */
  maxNumberOfCars: InputMaybe<Scalars['Int']['input']>;
  /**
   * By default the similar car should have the same model (code and year) like the reference configured car.
   * With this settings the precision of matching can be adjust.
   */
  modelPrecision: InputMaybe<SimilarCarsCarModelPrecisionInput>;
  /**
   * "new field"
   *
   * define the order of the returned similar cars
   * default is SCORE
   */
  sortCriterion: InputMaybe<SimilarCarsSortCriterion>;
  /**
   * by default all parameter have the same relevance by calculate the score
   * with this settings the weights can be changed
   */
  weights: InputMaybe<SimilarCarsWeightsInput>;
};

/**
 * "new enum"
 *
 * define the order of the returned similar cars
 */
export enum SimilarCarsSortCriterion {
  /**
   * Distance from closest.
   * If vehicles have same distance - first with highest score.
   * If score same - show cheapest.
   */
  Distance = 'DISTANCE',
  /**
   * Price from low to high.
   * If vehicles have same price - first with highest score.
   * If score same - first closest to user by distance.
   */
  Price = 'PRICE',
  /**
   * Score from high to low.
   * If vehicles have same score - first closest to user by distance.
   * If distance is same - show cheapest.
   */
  Score = 'SCORE',
}

/** similar cars in new car stock and used car stock */
export type SimilarCarsStock = {
  __typename?: 'SimilarCarsStock';
  /** similar cars in the new car stock */
  newCars: Maybe<SimilarCars>;
  /** similar cars in the used car stock */
  usedCars: Maybe<SimilarCars>;
};

/** similar cars in new car stock and used car stock */
export type SimilarCarsStockNewCarsArgs = {
  input: InputMaybe<SimilarCarsInput>;
};

/** similar cars in new car stock and used car stock */
export type SimilarCarsStockUsedCarsArgs = {
  input: InputMaybe<SimilarCarsInput>;
};

/** weights for calculate the score of a similar car */
export type SimilarCarsWeightsInput = {
  /** weights by feature type (based on KeyTool equipment categories) */
  featureTypeWeights: InputMaybe<SimilarCarsFeatureTypeWeightsInput>;
};

/** A Solution represents a solution or alternative to solve a conflict within one SolutionGroup. */
export type Solution = {
  __typename?: 'Solution';
  /** Defines whether the `feature` has to be added or removed to form a solution. */
  action: FeatureChangeAction;
  /** Defines which feature has to be added or removed to form a solution. */
  feature: ConfiguredCarFeature;
  /** the usage of the solution may come with additional side effects (e.g. would remove other features) */
  sideEffects: Array<SolutionSideEffect>;
};

/** A SolutionGroup represents a list of alternatives/solutions to solve a conflict. */
export type SolutionGroups = {
  __typename?: 'SolutionGroups';
  solutions: Array<Solution>;
};

/**
 * A Solution side effect represents a solution that
 * 1. will be automatically applied if the containing solution will be selected
 * OR
 * 2. has already occured while changing a configuration.
 */
export type SolutionSideEffect = {
  __typename?: 'SolutionSideEffect';
  /** Defines if the side effect is an addition or removal. */
  action: FeatureChangeAction;
  /** Defines the feature of the side effect. */
  feature: ConfiguredCarFeature;
};

/** The status of a configured model, package or feature */
export type Status = {
  __typename?: 'Status';
  available: Scalars['Boolean']['output'];
  changeable: Scalars['Boolean']['output'];
  inConflict: Scalars['Boolean']['output'];
  selected: Scalars['Boolean']['output'];
  standard: Scalars['Boolean']['output'];
};

/** information about one stock */
export type Stock = {
  __typename?: 'Stock';
  /** number of cars */
  amount: Scalars['Int']['output'];
  /** url for stock frontend */
  url: Scalars['String']['output'];
};

/** information about a Stock-Car */
export type StockCar = Car & {
  __typename?: 'StockCar';
  /** list of active campaigns which includes this stock car */
  campaigns: Array<StockCarCampaign>;
  /** Information about different prices types of a car */
  carPrices: Maybe<Array<LabeledTypedPrice>>;
  /** Carline information of a car. */
  carline: Maybe<StockCarCarline>;
  /** Kind of car. New car or used car. */
  cartypeText: Maybe<Scalars['String']['output']>;
  /** information about the AudiCode of a car */
  code: StockCarCarCode;
  /**
   * "new field"
   *
   * Information about colors of a car, structured by interior-, exterior- and top color
   */
  colorInfo: Maybe<StockCarColorInfo>;
  /** commission number of car(unique for a dealer in a stock) */
  commissionNumber: Scalars['String']['output'];
  /** information about a dealer of a car */
  dealer: DealerInfo;
  /** Free text provided by dealer, can contain HTML. */
  descriptionByDealer: Maybe<Scalars['String']['output']>;
  /** Number of doors */
  doors: Maybe<Scalars['Int']['output']>;
  /** Kind of drive. For example Frontwheeldrive. */
  driveText: Maybe<Scalars['String']['output']>;
  /** ELVIS key for emission decal */
  emissionDecalCode: Maybe<Scalars['String']['output']>;
  /** Engine information of a car. */
  engineInfo: Maybe<StockCarEngine>;
  /** StockCar equipment features provided from Carport/VehicleLifecycleService */
  features: Array<StockCarFeature>;
  /** Kind of emis badge like "D" */
  gearText: Maybe<Scalars['String']['output']>;
  /** Information about general inspection date; like value:"neu", type:"text" */
  generalInspectionDateInfo: Maybe<StockCarDateInfo>;
  /** list of categories grouping mapped generic equipments */
  genericEquipmentCategories: Array<StockCarGenericEquipmentCategory>;
  /** list of generic equipment items mapped to features or assigned manually */
  genericEquipments: Array<StockCarGenericEquipment>;
  /** unique id of a car in a stock */
  id: Scalars['String']['output'];
  /** images of car */
  images: Maybe<Array<StockCarImage>>;
  /** Information about initial registration date; like value:"2023-06-24T16:11:17+02:00", type:"timestamp" */
  initialRegistrationDateInfo: Maybe<StockCarDateInfo>;
  /** information to describe manufacturer specific attributes of a car */
  manufacturerSpecificItems: StockCarManufacturer;
  /** Mileage of a car described by unit, value and formatted value. */
  mileage: Maybe<StockCarFloatValueWithUnitItem>;
  /** Model information of a car. */
  model: Maybe<StockCarModel>;
  /** Kind of padding. For example "Leather seating surfaces". */
  paddingTypeText: Maybe<Scalars['String']['output']>;
  /** Key and translated text for pre used information, like "School bus". */
  preUse: Maybe<StockcarCodedTextItem>;
  /** Number of previous owners of a car. */
  preowners: Maybe<Scalars['Int']['output']>;
  /**
   * Price information of a car.
   * @deprecated Use carPrices
   */
  prices: Maybe<StockCarCarPrices>;
  /** StockCar quality labels, like 'GWPLUS' */
  qualityLabel: Array<StockCarQualityLabel>;
  /** Sales information of a car */
  salesInfo: Maybe<StockCarSalesInfo>;
  /** Text used as subtitle. For example "2.0 TFSI quattro Progressiv 200 HP Tiptronic". */
  subtitleText: Maybe<Scalars['String']['output']>;
  /** information to describe technical attributes of a car */
  techDataGroups: Array<StockCarTechDataGroup>;
  /** Text used as title. For example "2016 Audi Q3". */
  titleText: Maybe<Scalars['String']['output']>;
  /** Vehicle identification number of a car. */
  vin: Maybe<Scalars['String']['output']>;
  /** StockCar warranties with conditions assigned to the car, like 'Neuwagengarantie' until '40000 km' */
  warranties: Array<StockCarWarranty>;
  /** link to the cars detail page */
  weblink: Maybe<Scalars['String']['output']>;
};

/** information about a Stock-Car */
export type StockCarImagesArgs = {
  groupIds: InputMaybe<Array<Scalars['String']['input']>>;
  imageIds: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Used as container to provide StockCarFeature elements grouped by assigned categories from CDB */
export type StockCarCdbCategory = {
  __typename?: 'StockCarCDBCategory';
  /** list of StockCarFeature elements assigned to this category */
  features: Array<StockCarFeature>;
  /** identifier of CDB category, like 'safety_security_shell.safety_security' */
  id: Scalars['String']['output'];
  /** description of category to be displayed in frontends, like 'Safety and Security' */
  label: Scalars['String']['output'];
};

/** Used as container to group StockCarCDBCategory elements by corresponding parent categories from CDB */
export type StockCarCdbCategoryGroup = {
  __typename?: 'StockCarCDBCategoryGroup';
  /** list of child elements represented as StockCarCDBCategory elements */
  categories: Array<StockCarCdbCategory>;
  /** identifier of CDB category, like 'safety_security_shell' */
  id: Scalars['String']['output'];
  /** description of category to be displayed in frontends, like 'Safety and Security' */
  label: Scalars['String']['output'];
};

/** Element containing an additional CDB attribute with corresponding texts */
export type StockCarCdbItem = {
  __typename?: 'StockCarCDBItem';
  /** identifier of data, like 'body-type' */
  id: Scalars['String']['output'];
  /** list of texts, identified by IDs */
  textInfos: Array<StockCarStringItemWithId>;
  /** corresponding text, like 'sportback' */
  value: Scalars['String']['output'];
};

/** information of a single campaign for stock cars */
export type StockCarCampaign = {
  __typename?: 'StockCarCampaign';
  benefit: Maybe<Scalars['Float']['output']>;
  end: Maybe<StockCarDateInfo>;
  frontends: Array<StockCarCampaignFrontend>;
  id: Scalars['String']['output'];
  kind: Maybe<Scalars['String']['output']>;
  legalEntity: Maybe<Scalars['String']['output']>;
  market: Maybe<Scalars['String']['output']>;
  shortName: Maybe<Scalars['String']['output']>;
  start: Maybe<StockCarDateInfo>;
  type: Maybe<Scalars['String']['output']>;
  typeDetail: Maybe<Scalars['String']['output']>;
};

/** footer for campaign for stock cars */
export type StockCarCampaignFooter = {
  __typename?: 'StockCarCampaignFooter';
  footerText: Maybe<Scalars['String']['output']>;
  index: Scalars['String']['output'];
};

/** localized frontend information of a single campaign for stock cars */
export type StockCarCampaignFrontend = {
  __typename?: 'StockCarCampaignFrontend';
  bannerBig: Maybe<Scalars['String']['output']>;
  bannerSmall: Maybe<Scalars['String']['output']>;
  benefitList: Maybe<Scalars['String']['output']>;
  benefitText: Maybe<Scalars['String']['output']>;
  buttonLabel: Maybe<Scalars['String']['output']>;
  campaignDescription: Maybe<Scalars['String']['output']>;
  campaignName: Maybe<Scalars['String']['output']>;
  color: Maybe<Scalars['String']['output']>;
  description: Maybe<Scalars['String']['output']>;
  disclaimer: Maybe<Scalars['String']['output']>;
  externalInfoLabel: Maybe<Scalars['String']['output']>;
  externalInfoUrl: Maybe<Scalars['String']['output']>;
  filterText: Maybe<Scalars['String']['output']>;
  footers: Array<StockCarCampaignFooter>;
  headline: Maybe<Scalars['String']['output']>;
  icon: Maybe<Scalars['String']['output']>;
  iconText: Maybe<Scalars['String']['output']>;
  image: Maybe<Scalars['String']['output']>;
  infoLayerIcon: Maybe<Scalars['String']['output']>;
  infoLayerText: Maybe<Scalars['String']['output']>;
  infoLayerUrl: Maybe<Scalars['String']['output']>;
  infoLayerUrlText: Maybe<Scalars['String']['output']>;
  infoText: Maybe<Scalars['String']['output']>;
  lead: Maybe<Scalars['String']['output']>;
  leadInfoTextCustomer: Maybe<Scalars['String']['output']>;
  leadInfoTextDealer: Maybe<Scalars['String']['output']>;
  locale: Maybe<Scalars['String']['output']>;
  micrositeUrl: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

/** information about the AudiCode of a car */
export type StockCarCarCode = CarCode & {
  __typename?: 'StockCarCarCode';
  /** Country that the `id` is valid for. */
  country: Maybe<Scalars['String']['output']>;
  /**
   * Unique identifier for the car.
   * Might be AudiCode, MarketingCode etc.
   */
  id: Scalars['String']['output'];
  /** Language that the `id` is valid for. */
  language: Maybe<Scalars['String']['output']>;
  /** URL behind the Code (AudiCode). Can be used to open the car in a browser. */
  url: Scalars['String']['output'];
};

/** Identifier for a model of a car. Contains necessary Information about the car model */
export type StockCarCarModelIdentifier = CarModelIdentifier & {
  __typename?: 'StockCarCarModelIdentifier';
  /** model base code. Describes the model of the car. */
  code: Scalars['String']['output'];
  /** pr numbers of the car. Sometimes this PrNumbers are more like a marker. For example mark the trimline of the model. */
  extensions: Maybe<Array<Scalars['String']['output']>>;
  /** model version of the car. */
  version: Scalars['Int']['output'];
  /** The year of the catalogue release. */
  year: Scalars['Int']['output'];
};

/** A specific price. */
export type StockCarCarPrice = CarPrice & {
  __typename?: 'StockCarCarPrice';
  /** Information about the currency */
  currencyDetails: Maybe<Currency>;
  /** not supported yet */
  formattedValue: Maybe<Scalars['String']['output']>;
  /** Price as float */
  value: Scalars['Float']['output'];
  /** price as string */
  valueAsText: Maybe<Scalars['String']['output']>;
};

/** All available prices for a car. */
export type StockCarCarPrices = CarPrices & {
  __typename?: 'StockCarCarPrices';
  /** The total price of the car. */
  total: Maybe<StockCarCarPrice>;
};

/** Carline information */
export type StockCarCarline = CarlineBase & {
  __typename?: 'StockCarCarline';
  /** ID of the carline. F.ex. a4avant, a3sb, etc. */
  id: Scalars['String']['output'];
  /** Localized name of the carline. F.ex. A4 Avant, A3 Sportback, etc. */
  name: Scalars['String']['output'];
};

/** new type */
export type StockCarColorInfo = {
  __typename?: 'StockCarColorInfo';
  /** exterior color information */
  exteriorColor: Maybe<StockCarExteriorColor>;
  /** interior color information */
  interiorColor: Maybe<StockCarInteriorColor>;
  /** top color information */
  topColor: Maybe<StockCarTopColor>;
};

/** Used as a container to provide parseable date information or plain texts, identified by type. */
export type StockCarDateInfo = {
  __typename?: 'StockCarDateInfo';
  /** type of value, can be timestamp or text */
  type: Maybe<Scalars['String']['output']>;
  /** text representation of a value, outcome defined by type */
  value: Maybe<Scalars['String']['output']>;
};

/** Used as a data structure to provide engine related information of a car. */
export type StockCarEngine = {
  __typename?: 'StockCarEngine';
  /** Use of unit, value, formattedValue. For example "ccm", "1994", "1994 ccm". */
  engineDisplacement: Maybe<StockCarFloatValueWithUnitItem>;
  /** Use of code and text. For example "B" and "Benzin". */
  fuel: Maybe<StockcarCodedTextItem>;
  /** provide engine max power in kW with formattedValue, unit and float value */
  maxPowerKW: Maybe<StockCarFloatValueWithUnitItem>;
  /** provide engine max power in PS with formattedValue, unit and float value */
  maxPowerPS: Maybe<StockCarFloatValueWithUnitItem>;
};

/** extended information about a stockcar */
export type StockCarExtended = {
  __typename?: 'StockCarExtended';
  /** similar cars in new car stock and used car stock with this stockcar as reference */
  similarCars: Maybe<SimilarCarsStock>;
  /** basic data for a stockcar */
  stockCar: StockCar;
};

/** new type */
export type StockCarExteriorColor = {
  __typename?: 'StockCarExteriorColor';
  /** generic color information for all cars, like code:"07", text:"Black" */
  baseColorInfo: Maybe<StockcarCodedTextItem>;
  /** description of specific color, like code:"A2", text:"Brilliant Black" */
  colorInfo: Maybe<StockcarCodedTextItem>;
  /** description of color type, like 'Exterior' */
  label: Maybe<Scalars['String']['output']>;
};

/** Interface describing equipment features in StockCar context. */
export type StockCarFeature = {
  /** enum of equipment feature; either series or special equipment */
  featureType: StockCarFeatureType;
  /** list of categories grouping mapped generic equipments */
  genericEquipmentCategories: Array<StockCarGenericEquipmentCategory>;
  /** list of generic equipments mapped to current feature */
  genericEquipments: Array<StockCarGenericEquipment>;
  /** list of weblinks to image resources, identified by IDs */
  imageResources: Array<StockCarStringItemWithId>;
  /** PrNumber codes */
  prNumber: StockCarPrNumber;
  /** text describing feature, like 'Anschlussgarantie 3 Jahre, max. 100.000 km' */
  text: Scalars['String']['output'];
  /** all texts from different sources assigned to current feature, identified by unique fields */
  textInfos: StockCarFeatureTextInfo;
};

/** Implementation of StockCarFeature. Used to describe PrNumbers which are not Packages. Extends interface by field 'packages'. */
export type StockCarFeatureEquipment = StockCarFeature & {
  __typename?: 'StockCarFeatureEquipment';
  /** type of equipment feature; either series or special equipment */
  featureType: StockCarFeatureType;
  /** list of categories grouping mapped generic equipments */
  genericEquipmentCategories: Array<StockCarGenericEquipmentCategory>;
  /** list of generic equipments mapped to current feature */
  genericEquipments: Array<StockCarGenericEquipment>;
  /** list of weblinks to image resources, identified by IDs */
  imageResources: Array<StockCarStringItemWithId>;
  /** list of StockCarFeaturePackage containing this feature */
  packages: Array<StockCarFeaturePackage>;
  /** PrNumber codes */
  prNumber: StockCarPrNumber;
  /** text describing feature, like 'Anschlussgarantie 3 Jahre, max. 100.000 km' */
  text: Scalars['String']['output'];
  /** all texts from different sources assigned to current feature, identified by unique fields */
  textInfos: StockCarFeatureTextInfo;
};

/** Implementation of StockCarFeature. Used to describe PrNumbers which are Packages. Extends interface by field 'features'. */
export type StockCarFeaturePackage = StockCarFeature & {
  __typename?: 'StockCarFeaturePackage';
  /** list of StockCarFeatureEquipment which are part of this package */
  equipments: Array<StockCarFeatureEquipment>;
  /** type of equipment feature; either series or special equipment */
  featureType: StockCarFeatureType;
  /** list of categories grouping mapped generic equipments */
  genericEquipmentCategories: Array<StockCarGenericEquipmentCategory>;
  /** list of generic equipments mapped to current feature */
  genericEquipments: Array<StockCarGenericEquipment>;
  /** list of weblinks to image resources, identified by IDs */
  imageResources: Array<StockCarStringItemWithId>;
  /** PrNumber codes */
  prNumber: StockCarPrNumber;
  /** text describing feature, like 'Anschlussgarantie 3 Jahre, max. 100.000 km' */
  text: Scalars['String']['output'];
  /** all texts from different sources assigned to current feature, identified by unique fields */
  textInfos: StockCarFeatureTextInfo;
};

/** Used as a container to provide texts from different sources assigned to a feature, identified by their original IDs. */
export type StockCarFeatureTextInfo = {
  __typename?: 'StockCarFeatureTextInfo';
  /** list of benefit texts without order from source multimedia DB (only Audi) */
  benefits: Array<Scalars['String']['output']>;
  /** details text from source multimedia DB (only Audi) */
  details: Maybe<Scalars['String']['output']>;
  /** intro text from source multimedia DB (only Audi) */
  intro: Maybe<Scalars['String']['output']>;
  /** text from source multimedia DB (only Audi) */
  name: Maybe<Scalars['String']['output']>;
  /** list of remark texts without order from source multimedia DB (only Audi) */
  remarks: Array<Scalars['String']['output']>;
  /** teaser text from source multimedia DB (only Audi) */
  teaser: Maybe<Scalars['String']['output']>;
};

/** Enum describing type of equipment feature, like 'SPECIAL' or 'SERIES' */
export enum StockCarFeatureType {
  Series = 'SERIES',
  Special = 'SPECIAL',
}

/** Used as a container to provide data in float format as a raw and formatted value */
export type StockCarFloatValueItem = {
  __typename?: 'StockCarFloatValueItem';
  /** formatted value for display */
  formatted: Scalars['String']['output'];
  /** float value */
  number: Scalars['Float']['output'];
};

/** Used as a container to provide data in float format as a raw and formatted value with a unit. */
export type StockCarFloatValueWithUnitItem = {
  __typename?: 'StockCarFloatValueWithUnitItem';
  /** formatted unit display text */
  unitText: Maybe<Scalars['String']['output']>;
  /** formatted value for display */
  value: StockCarFloatValueItem;
};

/** Used as a container to provide generic equipment items. Can also be used for 3rd party vehicles. */
export type StockCarGenericEquipment = {
  __typename?: 'StockCarGenericEquipment';
  /** list of mapped generic equipment categories for grouping purposes */
  categories: Array<StockCarGenericEquipmentCategory>;
  /** coded identifier of an equipment item, like 'AL' */
  code: Scalars['String']['output'];
  /** list of mapped StockCarFeatures, extending this generic information */
  features: Array<StockCarFeature>;
  /** textual description of an equipment item, like 'Lederausstattung' */
  text: Scalars['String']['output'];
};

/** Used as a container to provide categories for generic equipment items. */
export type StockCarGenericEquipmentCategory = {
  __typename?: 'StockCarGenericEquipmentCategory';
  /** coded identifier of an equipment item, like '6' */
  code: Scalars['String']['output'];
  /** list of generic equipments belonging to this category */
  equipments: Array<StockCarGenericEquipment>;
  /** textual description of an equipment item, like 'Seats' */
  text: Scalars['String']['output'];
};

export type StockCarIdentifierInput = {
  /** specify the id of one single vehicle */
  id: Scalars['String']['input'];
  /** specify the stock which contains the vehicle */
  stockIdentifier: StockIdentifierInput;
};

/** single image of a stock car */
export type StockCarImage = {
  __typename?: 'StockCarImage';
  /** height of image in pixels */
  height: Maybe<Scalars['Int']['output']>;
  /** unique id of image in a stock car */
  id: StockCarImageIdentifier;
  /** MIME type of image e.g. 'image/png' */
  mimeType: Maybe<Scalars['String']['output']>;
  /** kind of image: e.g. 'render', 'photo' */
  type: Maybe<Scalars['String']['output']>;
  /** url of image in a stock car */
  url: Scalars['String']['output'];
  /** width of image in pixels */
  width: Maybe<Scalars['Int']['output']>;
};

/** unique id of image in a stock car */
export type StockCarImageIdentifier = {
  __typename?: 'StockCarImageIdentifier';
  /** group id of image in a stock car */
  group: Scalars['String']['output'];
  /** image id inside a group in a stock car */
  image: Scalars['String']['output'];
};

/** new type */
export type StockCarInteriorColor = {
  __typename?: 'StockCarInteriorColor';
  /** generic color information for all cars, like code:"07", text:"Black" */
  baseColorInfo: Maybe<StockcarCodedTextItem>;
  /** description of specific color, like code:"YA", text:"Myth-Black" */
  colorInfo: Maybe<StockcarCodedTextItem>;
  /** description of color type, like 'Innenfarbe' */
  label: Maybe<Scalars['String']['output']>;
};

/** union of different implementations for manufacturer specific items */
export type StockCarManufacturer = StockCarManufacturer3rdParty | StockCarManufacturerAudi;

/** implementations of manufacturer specific for not group vehicles, like 'BMW', 'Ford' */
export type StockCarManufacturer3rdParty = {
  __typename?: 'StockCarManufacturer3rdParty';
  /** Name of 3rd party manufacturer */
  name: Scalars['String']['output'];
};

/** implementations of manufacturer specific items for brand Audi */
export type StockCarManufacturerAudi = {
  __typename?: 'StockCarManufacturerAudi';
  /** list of StockCarFeature elements, grouped by CDB information */
  cdbCategories: Array<StockCarCdbCategoryGroup>;
  /** list of additional attributes of a car provided by CDB */
  cdbItems: Array<StockCarCdbItem>;
};

/** implementations of manufacturer specific items for brand Audi */
export type StockCarManufacturerAudiCdbItemsArgs = {
  ids: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Model information */
export type StockCarModel = CarModelBase & {
  __typename?: 'StockCarModel';
  /** Identifier of the model. */
  id: StockCarCarModelIdentifier;
  /** Name of the model */
  name: Maybe<Scalars['String']['output']>;
  /** year model actually sold */
  salesModelyear: Maybe<Scalars['Int']['output']>;
};

/** Used as a container to describe the 7 digit PrCode known from Carport. */
export type StockCarPrNumber = {
  __typename?: 'StockCarPrNumber';
  /** 1digit equipment class origin code, like 'L' */
  class: Scalars['String']['output'];
  /** 3digit option code, like 'AV1' */
  code: Scalars['String']['output'];
  /** 3digit family code, like 'LRV', can be empty in case of package */
  family: Scalars['String']['output'];
};

export type StockCarQualityLabel = {
  __typename?: 'StockCarQualityLabel';
  /** hyperlink to image resource, like 'https://vtpapi.audi.de/vtp_base/img/audi/highlights-plus-warranty_60x45px.png' */
  imageURL: Maybe<Scalars['String']['output']>;
  /** corresponding display text, like 'Audi Gebrauchtwagen :plus' */
  label: Scalars['String']['output'];
  /** additional legal information, like 'Dealers' */
  legalEntity: Maybe<Scalars['String']['output']>;
  /** shorthand identifier of quality label, like 'GWPLUS' */
  name: Scalars['String']['output'];
};

/** Used as a data structure to provide sales info for different markets at one place */
export type StockCarSalesInfo = {
  __typename?: 'StockCarSalesInfo';
  /** Information about available status of a car; like value:"Immediately", type:"text" */
  availableFromDateInfo: Maybe<StockCarDateInfo>;
  /** Information about e-commerce status of a car. */
  isBuyOnline: Maybe<Scalars['Boolean']['output']>;
  /** Information about offer status of a car; like value:"2023-06-24T16:11:17+02:00", type:"timestamp" */
  offerDateInfo: Maybe<StockCarDateInfo>;
  /** Information about order status like "Intransit From Factory". */
  orderStatusText: Maybe<Scalars['String']['output']>;
  /** Information about the order status like "30". */
  physicalOrderStatus: Maybe<Scalars['String']['output']>;
  /** Information about order type like "SHIP - Deliverable Inventory Order". */
  saleOrderTypeText: Maybe<Scalars['String']['output']>;
};

/** new type */
export type StockCarSearch = {
  __typename?: 'StockCarSearch';
  /** number of found cars */
  resultNumber: Scalars['Int']['output'];
  /** found cars */
  results: StockCarSearchResults;
  /** data for further search actions */
  search: StockCarSearchSearch;
};

/** new type */
export type StockCarSearchConfiguration = {
  __typename?: 'StockCarSearchConfiguration';
  /** meta-data for all available search options */
  criteria: Array<StockCarSearchCriterionConfiguration>;
  /** meta-data for all available sort options */
  sortableItems: Array<StockCarSearchSortableItemsConfiguration>;
};

/** new type */
export type StockCarSearchCriterion = {
  __typename?: 'StockCarSearchCriterion';
  /** Unique identifier for a search option */
  id: Scalars['StockCarSearchCriterionId']['output'];
  /** still available items of search criteria */
  possibleItems: Array<StockCarSearchCriterionItem>;
  /** selected items of search criteria */
  selectedItems: Array<StockCarSearchCriterionItem>;
};

/** new type */
export type StockCarSearchCriterionConfiguration = {
  __typename?: 'StockCarSearchCriterionConfiguration';
  /** Unique identifier for a search option */
  id: Scalars['StockCarSearchCriterionId']['output'];
  /** meta-data of possible items inside search criteria */
  items: Array<StockCarSearchCriterionItemConfiguration>;
  /** display text for search critera */
  text: Scalars['String']['output'];
  /** display text for unit of search criteria if set */
  unitText: Maybe<Scalars['String']['output']>;
};

/** new type */
export type StockCarSearchCriterionItem = {
  __typename?: 'StockCarSearchCriterionItem';
  /** Unique identifier for an item in a search criteria */
  id: Scalars['StockCarSearchCriterionItemId']['output'];
  /** number of found cars matching item */
  number: Scalars['Int']['output'];
};

/** new type */
export type StockCarSearchCriterionItemConfiguration = {
  __typename?: 'StockCarSearchCriterionItemConfiguration';
  /** Unique identifier for an item in a search criteria */
  id: Scalars['StockCarSearchCriterionItemId']['output'];
  /** display text of item */
  text: Scalars['String']['output'];
};

/** new input */
export type StockCarSearchParameterCriterionInput = {
  /** Unique identifier for a search option */
  id: Scalars['StockCarSearchCriterionId']['input'];
  /** Unique identifier for an item in a search criteria */
  items: Array<Scalars['StockCarSearchCriterionItemId']['input']>;
};

/**
 * "new input"
 *
 * parameter for geo search of stock cars
 */
export type StockCarSearchParameterGeoCriterionInput = {
  /** latitude of geo position (center of search circle) */
  latitude: Scalars['Float']['input'];
  /** longitude of geo position (center of search circle) */
  longitude: Scalars['Float']['input'];
  /** maximum distance between passed geo position (latitude and longitude) and geo position of a found car (radius of search circle) */
  maxDistance: Scalars['Float']['input'];
};

/** new input */
export type StockCarSearchParameterInput = {
  /** selected search criteria */
  criteria: Array<StockCarSearchParameterCriterionInput>;
  /** parameter for geographic proximity search */
  geo: InputMaybe<StockCarSearchParameterGeoCriterionInput>;
  /** parameter for paging */
  paging: InputMaybe<StockCarSearchParameterPagingInput>;
  /** parameter for sorting */
  sort: InputMaybe<StockCarSearchParameterSortInput>;
};

/** new input */
export type StockCarSearchParameterPagingInput = {
  /** limitation of results */
  limit: Scalars['Int']['input'];
  /** offset set (first element) */
  offset: Scalars['Int']['input'];
};

/** new input */
export type StockCarSearchParameterSortInput = {
  /** selected sort direction */
  direction: StockCarSearchSortDirection;
  /** Unique identifier for a sort option */
  id: Scalars['StockCarSearchSortId']['input'];
};

/** new type */
export type StockCarSearchResults = {
  __typename?: 'StockCarSearchResults';
  /** found cars matching configured search */
  cars: Array<StockCarSearchResultsCar>;
  /** applied paging parameter */
  paging: StockCarSearchResultsPaging;
  /** applied sort parameter */
  sort: StockCarSearchResultsSort;
};

/** new type */
export type StockCarSearchResultsCar = {
  __typename?: 'StockCarSearchResultsCar';
  /** used distance to location of car (if proximity search applied) */
  geoDistance: Maybe<StockCarFloatValueWithUnitItem>;
  /** data of found car */
  stockCar: StockCar;
};

/** new type */
export type StockCarSearchResultsPaging = {
  __typename?: 'StockCarSearchResultsPaging';
  /** limit set */
  limit: Scalars['Int']['output'];
  /** offset set (first element) */
  offset: Scalars['Int']['output'];
};

/** new type */
export type StockCarSearchResultsSort = {
  __typename?: 'StockCarSearchResultsSort';
  /** selected sort direction */
  direction: StockCarSearchSortDirection;
  /** Unique identifier for a sort option */
  id: Scalars['StockCarSearchSortId']['output'];
};

/** new type */
export type StockCarSearchSearch = {
  __typename?: 'StockCarSearchSearch';
  /** list of search criteria */
  criteria: Array<StockCarSearchCriterion>;
};

/** new enum */
export enum StockCarSearchSortDirection {
  /** ascending sort direction */
  Asc = 'ASC',
  /** descending sort direction */
  Desc = 'DESC',
}

/** new type */
export type StockCarSearchSortableItemsConfiguration = {
  __typename?: 'StockCarSearchSortableItemsConfiguration';
  /** display text on ascending sort */
  ascText: Scalars['String']['output'];
  /** display text on descending sort */
  descText: Scalars['String']['output'];
  /** Unique identifier for a sort option */
  id: Scalars['StockCarSearchSortId']['output'];
};

/** Basic type to display corresponding id/value strings in StockCar */
export type StockCarStringItemWithId = {
  __typename?: 'StockCarStringItemWithId';
  /** identifier of data */
  id: Scalars['String']['output'];
  /** corresponding text */
  value: Scalars['String']['output'];
};

export type StockCarSubServiceStatusDependency = {
  __typename?: 'StockCarSubServiceStatusDependency';
  health: OneGraphServiceHealth;
  name: Scalars['String']['output'];
  version: Scalars['String']['output'];
};

/** Used as a container to provide text based technical information identified by an ID. */
export type StockCarTechData = {
  __typename?: 'StockCarTechData';
  /** identifier of data, like 'acceleration' */
  id: Scalars['String']['output'];
  /** text to display as description, like 'Acceleration (0 - 60 mph)' */
  label: Scalars['String']['output'];
  /** text based information about technical data, like text:'6.6 sec seconds' */
  text: Scalars['String']['output'];
};

/** Used as a container to provide technical information as StockCarTechData grouped by IDs. */
export type StockCarTechDataGroup = {
  __typename?: 'StockCarTechDataGroup';
  /** identifier of data set, like 'engine' */
  id: Scalars['String']['output'];
  /** text to display as description of id, like 'Engineering | Performance' */
  label: Scalars['String']['output'];
  /** grouped StockCarTechData */
  techDataList: Array<StockCarTechData>;
};

/** new type */
export type StockCarTopColor = {
  __typename?: 'StockCarTopColor';
  /** description of specific color, like code:"A2", text:"Brilliant Black" */
  colorInfo: Maybe<StockcarCodedTextItem>;
  /** description of color type, like 'Contrasting (e.g. Roof)' */
  label: Maybe<Scalars['String']['output']>;
};

export type StockCarWarranty = {
  __typename?: 'StockCarWarranty';
  /** date string with pattern 'YYYY-MM-DD', like '2025-08-06' */
  dateEnd: Maybe<Scalars['String']['output']>;
  /** date string with pattern 'YYYY-MM-DD', like '2022-08-07' */
  dateStart: Maybe<Scalars['String']['output']>;
  /** additional information to be displayed, like 'Garantie vorhanden. Für weitere Informationen wenden Sie sich bitte an den Anbieter des Fahrzeugs' */
  dealerCondition: Maybe<Scalars['String']['output']>;
  /** unit code for distanceValue, like 'Kilometer' */
  distanceUnitCode: Maybe<Scalars['String']['output']>;
  /** unit display text for distanceValue, like 'km' */
  distanceUnitText: Maybe<Scalars['String']['output']>;
  /** number, like '40000' */
  distanceValue: Maybe<Scalars['Int']['output']>;
  /** hyperlink to document resource, like 'https://www.audi.de/dam/nemo/customer-area/warranty-guarantees/pdf/audi_garantie.pdf' */
  documentURL: Maybe<Scalars['String']['output']>;
  /** unit code for durationValue, like 'Month' */
  durationUnitCode: Maybe<Scalars['String']['output']>;
  /** unit display text for durationValue, like 'Monate' */
  durationUnitText: Maybe<Scalars['String']['output']>;
  /** number, like '24' */
  durationValue: Maybe<Scalars['Int']['output']>;
  /** corresponding display text, like 'Anschlussgarantie' */
  label: Scalars['String']['output'];
  /** additional legal information, like 'volkswagenAG' */
  legalEntity: Maybe<Scalars['String']['output']>;
  /** shorthand identifier of quality label, like 'asg' */
  name: Scalars['String']['output'];
  /** free text, set by dealer, like 'Marderschutz' */
  productName: Maybe<Scalars['String']['output']>;
  /** display order priority, like '15' */
  sortOrder: Scalars['Int']['output'];
};

export enum StockCarsType {
  /** Cars in stock that are new */
  New = 'NEW',
  /** Cars in stock that are used */
  Used = 'USED',
}

/** combinations for select "StockCarsType" */
export enum StockCarsTypeCombinationInput {
  /** only new cars stock is used / affected */
  New = 'NEW',
  /** new and used cars stock are used / affected */
  NewAndUsed = 'NEW_AND_USED',
  /** new or used cars stock are used / affected */
  NewOrUsed = 'NEW_OR_USED',
  /** only used cars stock is used / affected */
  Used = 'USED',
}

export type StockIdentifierInput = {
  /** specify the market of the stocks */
  marketIdentifier: MarketIdentifierInput;
  /** The stock type where the structure should be taken from */
  stockCarsType: StockCarsType;
};

/** Used as a container to provide coded data with a text and vise versa */
export type StockcarCodedTextItem = {
  __typename?: 'StockcarCodedTextItem';
  /** Code representation of a value, used and provided by different systems. */
  code: Scalars['String']['output'];
  /** Text value */
  text: Scalars['String']['output'];
};

/** Info Object of the Stock-Car subgraph */
export type StockcarGraphqlInfo = OneGraphSubServiceInfo & {
  __typename?: 'StockcarGraphqlInfo';
  /** Endpoint of the Stock-Car subgraph */
  endpoint: Scalars['String']['output'];
  /** Health status of the Stock-Car subgraph */
  health: OneGraphServiceHealth;
  /** id of the Stock-Car subgraph */
  id: Scalars['ID']['output'];
  /** contact information of the Stock-Car subgraph */
  meta: OneGraphServiceMetaInfo;
  /** Name of the Stock-Car subgraph */
  name: Scalars['String']['output'];
  /** Health status and information about the services which are used by Stock-Car subgraph */
  services: Maybe<Array<Maybe<OneGraphSubServiceDownStreamInfo>>>;
  /** Version of the Stock-Car subgraph */
  version: Scalars['String']['output'];
};

/** @deprecated data moved into `ConfiguredCar.techData`. Data provided by techdata-graphql subgraph. */
export type TechData = {
  __typename?: 'TechData';
  acceleration: Maybe<DataEntry>;
  displacement: Maybe<DataEntry>;
  eec: Maybe<Scalars['String']['output']>;
  electricRange: Maybe<DataEntry>;
  fuelType: Maybe<DataEntry>;
  gearbox: Maybe<DataEntry>;
  hybridElectricalPower: Maybe<DataEntry>;
  maxOutput: Maybe<DataEntry>;
  maxTorque: Maybe<DataEntry>;
  topSpeed: Maybe<DataEntry>;
};

/** Complete technical data available for a given car configuration. */
export type TechnicalData = {
  /**
   * The time needed to accelerate the vehicle from a given start velocity to a given target velocity.
   * (https://auto.schema.org/accelerationTime)
   * f.ex.: "9,5 s"
   */
  accelerationTime: Maybe<TechnicalDataFloatItem>;
  /**
   * Indicates the design and body style of the vehicle (e.g. station wagon, hatchback, etc.).
   * (https://auto.schema.org/bodyType)
   */
  bodyType: Maybe<TechnicalDataTextItem>;
  /**
   * Brakes og the vehicle
   * f.ex. "Diagonal-2-Kreis Bremssystem mit ESC/ABS/EBV, Bremskraftverstärker, hydraulischer Bremsassistent; vorn: Faustsattel, Bremsscheibe innenbelüftet"
   */
  brakes: Maybe<TechnicalDataTextItem>;
  /**
   * The available volume for cargo or luggage. For automobiles, this is usually the trunk volume.
   * https://schema.org/cargoVolume
   */
  cargoVolume: Maybe<TechnicalDataRangeItem>;
  /**
   * Clutch of the vehicle
   * f.ex.: Doppelkupplung
   */
  clutch: Maybe<TechnicalDataTextItem>;
  /** Energy Efficiency Certificate. */
  eec: Maybe<TechnicalDataTextItem>;
  /**
   * Combined electric range. Set minValue and maxValue accordingly.
   * TODO: Move attribute to Engine type?
   * TODO: Should this be handled by a separate consumption & emission object. There might be more information regarding WLTP & NEFC
   * f.ex. 284,0 - 341,0 km
   */
  electricRangeCombined: Maybe<TechnicalDataRangeItem>;
  /**
   * The engine for this vehicle
   * (https://auto.schema.org/EngineSpecification)
   */
  engine: TechnicalDataEngineBase;
  /**
   * Front axle of the vehicle
   * f.ex. "McPherson-Federbeinachse vorne"
   */
  frontAxle: Maybe<TechnicalDataTextItem>;
  /**
   * Gearbox of the vehicle
   * f.ex.: "7-Gang S tronic"
   */
  gearbox: Maybe<TechnicalDataTextItem>;
  /**
   * Indicates that the vehicle meets the respective emission standard.
   * https://schema.org/meetsEmissionStandard
   * f.ex. Euro 6d-TEMP
   */
  meetsEmissionStandard: Maybe<TechnicalDataTextItem>;
  /**
   * Rear axle of the vehicle
   * f.ex. "Verbundlenker-Hinterachse "
   */
  rearAxle: Maybe<TechnicalDataTextItem>;
  /**
   * Rims of the vehicle
   * f.ex. "Räder, 5-Arm-Design, 6 J x 15, Reifen 185/65 R 15"
   */
  rims: Maybe<TechnicalDataTextItem>;
  /**
   * The permitted total weight of cargo and installations (e.g. a roof rack) on top of the vehicle.
   * https://auto.schema.org/roofLoad
   */
  roofLoad: Maybe<TechnicalDataFloatItem>;
  /**
   * The speed range of the vehicle. If the vehicle is powered by an engine, the upper limit of the speed range (indicated by maxValue should be the maximum speed achievable under regular conditions.
   * https://auto.schema.org/speed
   * f.ex. 250 km/h
   */
  speed: Maybe<TechnicalDataFloatItem>;
  /**
   * Steering system of the vehicle
   * f.ex. "Elektromechanische Lenkung mit geschwindigkeitsabhängiger Servounterstützung; Wendekreis ca. 10,6 m"
   */
  steeringSystem: Maybe<TechnicalDataTextItem>;
  /**
   * The permitted vertical load (TWR) of a trailer attached to the vehicle. Also referred to as Tongue Load Rating (TLR) or Vertical Load Rating (VLR)
   * https://auto.schema.org/tongueWeight
   */
  tongueWeight: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight at a gradient of 8 percent.
   * f.ex.: 2700 kg
   */
  trailerWeight8: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight at a gradient of 12 percent.
   * f.ex. 2800 kg / in Verbindung mit adaptive air suspension oder adaptive air suspension sport 3500
   */
  trailerWeight12: Maybe<TechnicalDataFloatItem>;
  /**
   * Max. trailer weight for trailers without separate brakes.
   * f.ex.: 750 kg
   */
  trailerWeightUnbraked: Maybe<TechnicalDataFloatItem>;
  /**
   * Tyres of the vehicle
   * f.ex. "Reifen 185/65 R15 92 V xl"
   */
  tyres: Maybe<TechnicalDataTextItem>;
  /**
   * Vehicle weight without load.
   * f.ex.: 2505 kg
   */
  unladenWeight: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of component used for transmitting the power from a rotating power source to the wheels or other relevant component(s) ("gearbox" for cars)
   * (https://schema.org/vehicleTransmission)
   * f.ex.: Allradantrieb quattro
   */
  vehicleTransmission: Maybe<TechnicalDataTextItem>;
  /**
   * The permitted total weight of the loaded vehicle, including passengers and cargo and the weight of the empty vehicle.
   * https://auto.schema.org/weightTotal
   * f.ex.: 3045 kg
   */
  weightTotal: Maybe<TechnicalDataFloatItem>;
};

/** Information about the battery for electro vehicles (also for hybrid with electro) */
export type TechnicalDataBattery = {
  __typename?: 'TechnicalDataBattery';
  /** TODO name of battery capacity and its value need to be clearified */
  grossCapacity: Maybe<TechnicalDataFloatItem>;
  /** TODO name of battery capacity and its value need to be clearified */
  netCapacity: Maybe<TechnicalDataFloatItem>;
  /** TODO */
  type: Maybe<TechnicalDataTextItem>;
};

/** Parameters for retrieving technical data for a specific model. */
export type TechnicalDataByModelInput = {
  /**
   * KVPS (means: 'Konzern Vertriebs Partner Stammdaten') brand id. Use one of the following:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars['String']['input'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example 'de' */
  country: Scalars['String']['input'];
  /** Language code following the ISO-639-1 standard. For example 'de' */
  language: Scalars['String']['input'];
  /** Model identifier */
  model: ModelIdentifierInput;
  /** 5-digit sales group */
  salesGroup: Scalars['String']['input'];
};

/**
 * Object representation of a prString
 *
 * MBV prString:
 *
 * 51901\\2021|GENBAE\\1|F14 S1S1|F56     FZ
 * -----       ------        ----
 * |      ---- |       -     |            --
 * |      |    |       |     |            |
 * |      |    |       |     |            interiorColor
 * |      |    |       |     |
 * |      |    |       |     exteriorColor
 * |      |    |       |
 * |      |    |       modelVersion
 * |      |    |
 * |      |    modelCode
 * |      |
 * |      modelYear
 * |
 * salesgroup
 *
 * NEMO prString:
 *
 * GENBAE1_2021|S1S1|FZ
 * ------  ----      --
 * |     - |    ---- |
 * |     | |    |    |
 * |     | |    |    interiorColor
 * |     | |    |
 * |     | |    exteriorColor
 * |     | |
 * |     | modelYear
 * |     |
 * |     modelVersion
 * |
 * modelCode
 */
export type TechnicalDataConfiguredCarInput = {
  /**
   * KVPS ID:
   *
   * A AUDI
   * C SKODA
   * E Bentley
   * F Ford
   * L Lamborghini
   * N VW Nutzfahrzeuge
   * M MAN
   * P Porsche
   * S SEAT
   * V VW Pkw
   */
  brand: Scalars['String']['input'];
  /** Country code following the ISO-3166-1 alpha-2 standard. For example `de` */
  country: Scalars['String']['input'];
  /**
   * TODO: Combine equipmentOptions, exteriorColor, interiorColor in a separate input???
   * 7-digit pr numbers
   */
  equipmentOptions: InputMaybe<Array<Scalars['String']['input']>>;
  /** 4-digit pr number of the exterior color */
  exteriorColor: InputMaybe<Scalars['String']['input']>;
  /** 2-digit pr number of the interior color */
  interiorColor: InputMaybe<Scalars['String']['input']>;
  /** Language code following the ISO-639-1 standard. For example `de` */
  language: Scalars['String']['input'];
  /** the model input separte in an own input */
  model: TechnicalDataConfiguredCarModelInput;
  /**
   * 5-digit sales group
   * TODO: for now, we use string because of leading zeros. Check!
   */
  salesGroup: InputMaybe<Scalars['String']['input']>;
};

export type TechnicalDataConfiguredCarModelInput = {
  /** 6-digit model base code. Describes the model/engine used in a configuration e.g. GENBAE */
  code: Scalars['String']['input'];
  /** 7-digit pr numbers */
  extensionsPR7: InputMaybe<Array<Scalars['String']['input']>>;
  /** 1-digit model version */
  version: Scalars['Int']['input'];
  /** The year of the catalogue release */
  year: Scalars['Int']['input'];
};

/** Node for all data regarding consumption and emission values */
export type TechnicalDataConsumptionAndEmission = {
  __typename?: 'TechnicalDataConsumptionAndEmission';
  /**
   * Array of disctinct consumption and emission values for fuelType
   * @deprecated use measurements instead
   */
  consumptionAndEmissionPerFuel: Maybe<Array<Maybe<TechnicalDataConsumptionAndEmissionPerFuel>>>;
  /** Array containing consumption and emission values for each fuel measured by WLTP/NEDC */
  measurements: Array<Maybe<TechnicalDataConsumptionAndEmissionPerFuel>>;
  /** e.g. 40.5 mg/km */
  noxCombined: Maybe<TechnicalDataFloatItem>;
  /** Summary values for footnotes etc. */
  summary: Maybe<TechnicalDataConsumptionAndEmissionSummary>;
};

/** Per fuel consumption and emission values; separated by testing method */
export type TechnicalDataConsumptionAndEmissionPerFuel = {
  __typename?: 'TechnicalDataConsumptionAndEmissionPerFuel';
  /** Localized name of the fuel type, i.e., 'Diesel' or 'Benzin' */
  fuelName: Maybe<Scalars['String']['output']>;
  /** Type of fuel, i.e., 'DIESEL' or 'PETROL' */
  fuelType: Maybe<Scalars['String']['output']>;
  /** Per fuel consumption and emission values for NEDC testing method */
  nedc: Maybe<TechnicalDataConsumptionAndEmissionValuesNedc>;
  /**
   * Array of consumption and emission values per fuel for WLTP testing method; entries for different
   * energy management testing cycles
   */
  wltp: Maybe<TechnicalDataConsumptionAndEmissionValuesWltp>;
};

/** Summary values for footnotes etc. */
export type TechnicalDataConsumptionAndEmissionSummary = {
  __typename?: 'TechnicalDataConsumptionAndEmissionSummary';
  /**
   * Summary consumption text for footnotes etc.
   * @deprecated use consumptionSummary instead
   */
  consumption: Maybe<Scalars['String']['output']>;
  /**
   * Array containing consumption values for each fuel
   * @deprecated use consumptionsSummary instead
   */
  consumptionSummary: Maybe<Array<Maybe<FuelFloatValue>>>;
  /** Array containing consumption values for each fuel and energy management */
  consumptionsSummary: Maybe<Array<FuelValues>>;
  /** Array containing energy efficicency classes for each energy management */
  eecsSummary: Maybe<Array<EecValuePerEnergyManagementWltp>>;
  /**
   * Summary emission text for footnotes etc.
   * @deprecated use emissionCO2Summary instead
   */
  emissionCO2: Maybe<Scalars['String']['output']>;
  /**
   * Object containing information about the emission values
   * @deprecated use emissionsCO2Summary instead
   */
  emissionCO2Summary: Maybe<FuelFloatValue>;
  /** Object containing information about the emission values for each fuel and energy management */
  emissionsCO2Summary: Maybe<Array<FuelValues>>;
  /** Array of footnote references */
  footnoteReference: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** Node containing consumption and emission values for NEDC testing method */
export type TechnicalDataConsumptionAndEmissionValuesNedc = {
  __typename?: 'TechnicalDataConsumptionAndEmissionValuesNedc';
  /** Set of values for fuel consumption */
  consumption: Maybe<ValuesNedc>;
  /** Set of values for CO2 emissions */
  emissionCO2: Maybe<ValuesNedc>;
};

export type TechnicalDataConsumptionAndEmissionValuesPerEnergyManagementWltp = {
  __typename?: 'TechnicalDataConsumptionAndEmissionValuesPerEnergyManagementWltp';
  /** Set of values for fuel consumption */
  consumption: Maybe<ValuesWltp>;
  /** Set of values for CO2 emissions */
  emissionCO2: Maybe<ValuesWltp>;
  /** applied measurement cycle */
  energyManagement: Maybe<WltpEnergyManagement>;
  /** may or may not be present depending on a factual decision; true if preferred to be shown */
  isDefault: Maybe<Scalars['Boolean']['output']>;
};

export type TechnicalDataConsumptionAndEmissionValuesWltp = {
  __typename?: 'TechnicalDataConsumptionAndEmissionValuesWltp';
  consolidated: Maybe<TechnicalDataConsumptionAndEmissionValuesPerEnergyManagementWltp>;
  perEnergyManagement: Maybe<
    Array<Maybe<TechnicalDataConsumptionAndEmissionValuesPerEnergyManagementWltp>>
  >;
};

/** Electro engine */
export type TechnicalDataElectroEngine = TechnicalDataEngineBase & {
  __typename?: 'TechnicalDataElectroEngine';
  /** Information about the battery */
  battery: Maybe<TechnicalDataBattery>;
  /**
   * Charging durance 0 - 100 % with maximal charging power (AC).
   * f.ex. 11 kW
   */
  chargingDuranceAc: Maybe<TechnicalDataFloatItem>;
  /**
   * Combined electric range. Set minValue and maxValue accordingly.
   * TODO: Move attribute to Engine type?
   * f.ex. 284,0 - 341,0 km
   * Deprecated: --> engine.electricRangeCombined
   */
  electricRangeCombined: Maybe<TechnicalDataFloatItem>;
  /** electric range value for electro engine */
  electricRanges: Maybe<RangeInfo>;
  /**
   * The power of the vehicle's engine.
   * https://auto.schema.org/enginePower
   * TODO: a) How to handle the requirement to deliver kw and ps,
   * -> Argument for field?
   * TODO: b) how to deliver Umdrehungen (revolutions per minute)
   * TODO: f.ex. power.max- 250 kW (340 PS) bei 5000-6400 min -1
   * @deprecated use power.maxPower
   */
  enginePower: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of engine or engines powering the vehicle.
   * https://auto.schema.org/engineType
   * f.ex V6-Zylinder-Ottomotor mit Direkteinspritzung, Lambda-Regelung, Klopfregelung, Turboaufladung und Abgasturboaufladung
   */
  engineType: Maybe<TechnicalDataTextItem>;
  /**
   * Maximal chargeable energy content.
   * f.ex. 19,7 kWh / XXX km
   */
  maxRangeChargingDc10: Maybe<TechnicalDataFloatItem>;
  /** The maxinal hourly output of an electro engine */
  maximalHourlyPower: Maybe<TechnicalDataFloatItem>;
  /**
   * Maximal loading power AC.
   * f.ex. 11 kW
   */
  maximalLoadingPower: Maybe<TechnicalDataFloatItem>;
  /**
   * Minimal charging durance 10 - 80 % with maximal charging power (HPC).
   * f.ex. 36 min
   */
  minimalChargingDurance: Maybe<TechnicalDataFloatItem>;
  /** The power of the vehicle's engine */
  power: Maybe<EnginePower>;
  /**
   * The torque (turning force) of the vehicle's engine.
   * https://auto.schema.org/torque
   */
  torque: Maybe<TechnicalDataFloatItem>;
  /** Max. Torque front back */
  torqueFrontBack: Maybe<TechnicalDataFloatItem>;
};

/**
 * The engine(s) for this vehicle.
 * (https://auto.schema.org/EngineSpecification)
 */
export type TechnicalDataEngineBase = {
  /**
   * The power of the vehicle's engine.
   * (https://auto.schema.org/enginePower)
   * TODO: a) How to handle the requirement to deliver kw and ps,
   * -> Argument for field?
   * TODO: b) how to deliver Umdrehungen (revolutions per minute)
   * TODO: f.ex. power.max- 250 kW (340 PS) bei 5000-6400 min -1
   * @deprecated use power.maxPower
   */
  enginePower: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of engine or engines powering the vehicle.
   * https://auto.schema.org/engineType
   * f.ex V6-Zylinder-Ottomotor mit Direkteinspritzung, Lambda-Regelung, Klopfregelung, Turboaufladung und Abgasturboaufladung
   */
  engineType: Maybe<TechnicalDataTextItem>;
  /** The power of the vehicle's engine */
  power: Maybe<EnginePower>;
  /**
   * The torque (turning force) of the vehicle's engine.
   * https://auto.schema.org/torque
   */
  torque: Maybe<TechnicalDataFloatItem>;
};

/** Node for all data regarding energy consumption labeling regulation */
export type TechnicalDataEnvkv = {
  __typename?: 'TechnicalDataEnvkv';
  /** Assumed yearly distance */
  assumedYearlyDistance: Maybe<TechnicalDataFloatItem>;
  /** CO2 Costs */
  co2costs: Maybe<TechnicalDataFloatLevels>;
  /** Consumption Levels */
  consumptionLevels: Maybe<TechnicalDataFloatLevels>;
  /**
   * Energy Efficiency Certificate.
   * @deprecated use eecPerEnergyManagementWltp instead
   */
  eec: Maybe<TechnicalDataTextItem>;
  /** Energy Efficiency Class per wltp phase method */
  eecPerEnergyManagementWltp: Maybe<Array<EecPerEnergyManagementWltp>>;
  /** Electricity cost per year */
  electricityCostPerYear: Maybe<TechnicalDataFloatItem>;
  /** Electricity price */
  electricityPrice: Maybe<TechnicalDataFloatItem>;
  /** Fuel cost per year */
  fuelCostPerYear: Maybe<TechnicalDataFloatItem>;
  /** Fuel price */
  fuelPrice: Maybe<TechnicalDataFloatItem>;
  /** Taxes per year */
  taxPerYear: Maybe<TechnicalDataFloatItem>;
};

/** Technical attribute that is not part of the schema due to being market specific. */
export type TechnicalDataExtendedField = {
  __typename?: 'TechnicalDataExtendedField';
  /** Unique identifier for this field. For example: gasoline-equivalent */
  id: Scalars['String']['output'];
  /** The value object for this field (union type). */
  item: TechnicalDataItem;
};

/**
 * Describes a technical data attribute with a single float `value`.
 * Remark: Due to missing data in the downstream services we'll define the field `value` as optional.
 * The data will currently be delivered as a preformatted locale string. Therefore we will use the field `valueAsText`
 */
export type TechnicalDataFloatItem = {
  __typename?: 'TechnicalDataFloatItem';
  /**
   * The formattedValue of the technical data to display.
   * In case of simple values it contains the value directly f.ex. "Verbundlenker-Hinterachse"
   * In case unit with values it contains the formatted value and the unit f.ex "193 km/h"
   */
  formattedValue: Scalars['String']['output'];
  /**
   * The label of the value
   * f.ex. "Höchstgeschwindigkeit"
   */
  label: Maybe<Scalars['String']['output']>;
  remarks: Maybe<Array<Maybe<Remark>>>;
  /** Debug: Source where it is resolved */
  source: Maybe<Scalars['String']['output']>;
  /**
   * Unit of the value
   * f.ex "km/h"
   */
  unit: Maybe<Scalars['String']['output']>;
  /**
   * Value as float
   * f.ex. 22134.12
   */
  value: Maybe<Scalars['Float']['output']>;
  /**
   * Value as text (Sometimes the value is only available as text - in respect to the language)
   * f.ex "22134,12"
   */
  valueAsText: Maybe<Scalars['String']['output']>;
};

/** Node for all data regarding co2costs */
export type TechnicalDataFloatLevels = {
  __typename?: 'TechnicalDataFloatLevels';
  /** high value */
  high: Maybe<TechnicalDataFloatItem>;
  /** low value */
  low: Maybe<TechnicalDataFloatItem>;
  /** medium value */
  medium: Maybe<TechnicalDataFloatItem>;
};

/** type for engines with fuel: petrol or diesel */
export type TechnicalDataFuelEngine = TechnicalDataEngineBase & {
  __typename?: 'TechnicalDataFuelEngine';
  /**
   * The emission control system.
   * f.ex. Katalysator, Lambdasonde, Ottopartikelfilter
   */
  emissionControlSystem: Maybe<TechnicalDataTextItem>;
  /**
   * The volume swept by all of the pistons inside the cylinders of an internal combustion engine in a single movement.
   * (https://auto.schema.org/engineDisplacement)
   * f.ex.: 1968 cm3
   */
  engineDisplacement: Maybe<TechnicalDataFloatItem>;
  /**
   * The engine management.
   * f.ex. Vollelektronisch Benzin
   */
  engineManagement: Maybe<TechnicalDataTextItem>;
  /**
   * The power of the vehicle's engine.
   * (https://auto.schema.org/enginePower)
   * TODO: a) How to handle the requirement to deliver kw and ps,
   * -> Argument for field?
   * TODO: b) how to deliver Umdrehungen (revolutions per minute)
   * TODO: f.ex. power.max- 250 kW (340 PS) bei 5000-6400 min -1
   * @deprecated use power.maxPower
   */
  enginePower: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of engine or engines powering the vehicle.
   * (https://auto.schema.org/engineType)
   * f.ex V6-Zylinder-Ottomotor mit Direkteinspritzung, Lambda-Regelung, Klopfregelung, Turboaufladung und Abgasturboaufladung
   */
  engineType: Maybe<TechnicalDataTextItem>;
  /**
   * The capacity of the fuel tank or in the case of electric cars, the battery. If there are multiple components for storage, this should indicate the total of all storage of the same type.
   * (https://auto.schema.org/fuelCapacity)
   * f.ex. 63 l
   */
  fuelCapacity: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of fuel suitable for the engine or engines of the vehicle. If the vehicle has only one engine, this property can be attached directly to the vehicle.
   * (https://schema.org/fuelType)
   * f.ex. Super schwefelfrei ROZ 95
   */
  fuelType: Maybe<TechnicalDataTextItem>;
  /** The power of the vehicle's engine */
  power: Maybe<EnginePower>;
  /**
   * The torque (turning force) of the vehicle's engine.
   * (https://auto.schema.org/torque)
   */
  torque: Maybe<TechnicalDataFloatItem>;
};

/** type for engines with gas */
export type TechnicalDataGasEngine = TechnicalDataEngineBase & {
  __typename?: 'TechnicalDataGasEngine';
  /**
   * The emission control system.
   * f.ex. Katalysator, Lambdasonde, Ottopartikelfilter
   */
  emissionControlSystem: Maybe<TechnicalDataTextItem>;
  /**
   * The volume swept by all of the pistons inside the cylinders of an internal combustion engine in a single movement.
   * (https://auto.schema.org/engineDisplacement)
   * f.ex.: 1968 cm3
   */
  engineDisplacement: Maybe<TechnicalDataFloatItem>;
  /**
   * The engine management.
   * f.ex. Vollelektronisch Benzin
   */
  engineManagement: Maybe<TechnicalDataTextItem>;
  /**
   * The power of the vehicle's engine.
   * (https://auto.schema.org/enginePower)
   * TODO: a) How to handle the requirement to deliver kw and ps,
   * -> Argument for field?
   * TODO: b) how to deliver Umdrehungen (revolutions per minute)
   * TODO: f.ex. power.max- 250 kW (340 PS) bei 5000-6400 min -1
   * @deprecated use power.maxPower
   */
  enginePower: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of engine or engines powering the vehicle.
   * (https://auto.schema.org/engineType)
   * f.ex V6-Zylinder-Ottomotor mit Direkteinspritzung, Lambda-Regelung, Klopfregelung, Turboaufladung und Abgasturboaufladung
   */
  engineType: Maybe<TechnicalDataTextItem>;
  /**
   * The capacity of the fuel tank or in the case of electric cars, the battery. If there are multiple components for storage, this should indicate the total of all storage of the same type.
   * (https://auto.schema.org/fuelCapacity)
   * f.ex. 63 l
   */
  fuelCapacity: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of fuel suitable for the engine or engines of the vehicle. If the vehicle has only one engine, this property can be attached directly to the vehicle.
   * (https://schema.org/fuelType)
   * TODO: Would we handle the speed in rangesHow to handle mutliple fuelTypes
   * f.ex. Super schwefelfrei ROZ 95
   */
  fuelType: Maybe<TechnicalDataTextItem>;
  /** The power of the vehicle's engine */
  power: Maybe<EnginePower>;
  /**
   * The torque (turning force) of the vehicle's engine.
   * (https://auto.schema.org/torque)
   */
  torque: Maybe<TechnicalDataFloatItem>;
};

/** Electro engine */
export type TechnicalDataHybridEngine = TechnicalDataEngineBase & {
  __typename?: 'TechnicalDataHybridEngine';
  /** Information about the battery */
  battery: Maybe<TechnicalDataBattery>;
  /** City electric range */
  electricRangeCity: Maybe<TechnicalDataFloatItem>;
  /**
   * Combined electric range. Set minValue and maxValue accordingly.
   * f.ex. 284,0 - 341,0 km
   */
  electricRangeCombined: Maybe<TechnicalDataFloatItem>;
  /**
   * Electrical Power
   * f.ex 94 kW
   */
  electricalPower: Maybe<TechnicalDataFloatItem>;
  /**
   * The electrical torque (turning force) of the vehicle's engine.
   * https://auto.schema.org/torque
   * f.ex. 600 Nm,
   */
  electricalTorque: Maybe<TechnicalDataFloatItem>;
  /**
   * The emission control system.
   * f.ex. Katalysator, Lambdasonde, Ottopartikelfilter
   */
  emissionControlSystem: Maybe<TechnicalDataTextItem>;
  /**
   * The volume swept by all of the pistons inside the cylinders of an internal combustion engine in a single movement.
   * (https://auto.schema.org/engineDisplacement)
   * f.ex.: 1968 cm3
   */
  engineDisplacement: Maybe<TechnicalDataFloatItem>;
  /**
   * The engine management.
   * f.ex. Vollelektronisch Benzin
   */
  engineManagement: Maybe<TechnicalDataTextItem>;
  /**
   * The fuel power of the vehicle's hybrid engine.
   * https://auto.schema.org/enginePower
   * TODO: a) How to handle the requirement to deliver kw and ps,
   * -> Argument for field?
   * TODO: b) how to deliver Umdrehungen (revolutions per minute)
   * TODO: f.ex. power.max- 250 kW (340 PS) bei 5000-6400 min -1
   * @deprecated use power.maxPower
   */
  enginePower: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of engine or engines powering the vehicle.
   * https://auto.schema.org/engineType
   * f.ex V6-Zylinder-Ottomotor mit Direkteinspritzung, Lambda-Regelung, Klopfregelung, Turboaufladung und Abgasturboaufladung
   */
  engineType: Maybe<TechnicalDataTextItem>;
  /**
   * The capacity of the fuel tank or in the case of electric cars, the battery. If there are multiple components for storage, this should indicate the total of all storage of the same type.
   * (https://auto.schema.org/fuelCapacity)
   * f.ex. 63 l
   */
  fuelCapacity: Maybe<TechnicalDataFloatItem>;
  /**
   * The type of fuel suitable for the engine or engines of the vehicle. If the vehicle has only one engine, this property can be attached directly to the vehicle.
   * (https://schema.org/fuelType)
   * f.ex. Super schwefelfrei ROZ 95
   */
  fuelType: Maybe<TechnicalDataTextItem>;
  /**
   * Momentary overall power
   * f.ex 280 kW
   */
  momentaryOverallPower: Maybe<TechnicalDataFloatItem>;
  /** The power of the vehicle's engine */
  power: Maybe<EnginePower>;
  /**
   * The torque (turning force) of the vehicle's engine.
   * https://auto.schema.org/torque
   * f.ex. 600 Nm,
   */
  torque: Maybe<TechnicalDataFloatItem>;
  /**
   * The total system torque (turning force) of the vehicle's engine (hybrid: fuel and electro combined)
   * https://auto.schema.org/torque
   * f.ex. 600 Nm,
   */
  totalSystemTorque: Maybe<TechnicalDataFloatItem>;
};

/** All technical data belongs to insurance */
export type TechnicalDataInsuranceClass = {
  __typename?: 'TechnicalDataInsuranceClass';
  /** Insurance class for fully comprehensive coverage. */
  fullyComprehensive: Maybe<TechnicalDataFloatItem>;
  /** Insurance class for basic liability. */
  liability: Maybe<TechnicalDataFloatItem>;
  /** Insurance class for partially comprehensive coverage. */
  partiallyComprehensive: Maybe<TechnicalDataFloatItem>;
};

/** Represents all possible types of a generic technical attribute. */
export type TechnicalDataItem =
  | TechnicalDataFloatItem
  | TechnicalDataRangeItem
  | TechnicalDataTextItem;

/**
 * Describes a technical data attribute with `min` and `max` value.
 * Remark: Due to missing data in the downstream services we'll define the fields `min/max` as optional.
 * The data will currently be delivered as a preformatted locale string. Therefore we"ll use the field `minAsText/maxAsText`
 */
export type TechnicalDataRangeItem = {
  __typename?: 'TechnicalDataRangeItem';
  /**
   * The formattedValue of the technical data to display.
   * In case of simple values it contains the value directly f.ex. "Verbundlenker-Hinterachse"
   * In case unit with values it contains the formatted value and the unit f.ex "335/1090 l"
   */
  formattedValue: Scalars['String']['output'];
  /**
   * The label of the value
   * f.ex. "Gepäckraumvolumen"
   */
  label: Maybe<Scalars['String']['output']>;
  /**
   * Maximum value of the range
   * 120.44
   */
  max: Maybe<Scalars['Float']['output']>;
  /**
   * Maximum value of the range as text.
   * f.ex. "120,44"
   */
  maxAsText: Maybe<Scalars['String']['output']>;
  /**
   * Minimum value of the range
   * f.ex. 77.89
   */
  min: Maybe<Scalars['Float']['output']>;
  /**
   * Minimum value of the range as text.
   * f.ex. "77,89"
   */
  minAsText: Maybe<Scalars['String']['output']>;
  remarks: Maybe<Array<Maybe<Remark>>>;
  /** Debug: Source where it is resolved */
  source: Maybe<Scalars['String']['output']>;
  /**
   * Unit of the value
   * f.ex. "l"
   */
  unit: Maybe<Scalars['String']['output']>;
};

/** Describes a technical data attribute with a single `text`. */
export type TechnicalDataTextItem = {
  __typename?: 'TechnicalDataTextItem';
  /**
   * The formattedValue of the technical data to display.
   * It contains the value directly f.ex. "Hydraulisch betätigte Trockenkupplung, asbest-/bleifreie Beläge"
   */
  formattedValue: Scalars['String']['output'];
  /**
   * The label of the value
   * f.ex. "Kupplung"
   */
  label: Maybe<Scalars['String']['output']>;
  remarks: Maybe<Array<Maybe<Remark>>>;
  /** Debug: Source where it is resolved */
  source: Maybe<Scalars['String']['output']>;
};

/** TODO */
export type TedasCarCode = {
  country: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  language: InputMaybe<Scalars['String']['input']>;
};

export type TedasServiceInfo = OneGraphSubServiceInfo & {
  __typename?: 'TedasServiceInfo';
  endpoint: Scalars['String']['output'];
  health: OneGraphServiceHealth;
  id: Scalars['ID']['output'];
  meta: OneGraphServiceMetaInfo;
  name: Scalars['String']['output'];
  services: Maybe<Array<Maybe<OneGraphSubServiceDownStreamInfo>>>;
  version: Scalars['String']['output'];
};

/** All Data regarding the trimline */
export type Trimline = {
  __typename?: 'Trimline';
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  images: Array<ImageSource>;
  models: Array<Model>;
  name: Scalars['String']['output'];
  prices: CarlineTrimlineFinanceablePrices;
  remarks: Maybe<Scalars['String']['output']>;
  teaserText: Maybe<Scalars['String']['output']>;
};

/** All Data regarding the trimline */
export type TrimlinePricesArgs = {
  financingInput: InputMaybe<FinancingInput>;
};

/** A price of the type and its type, e.g. 'total', 'base', 'rotr', 'model'.... */
export type TypedPrice = {
  price: Price;
  type: Scalars['String']['output'];
};

/** @deprecated data moved into `ConfiguredCar` */
export type TyreLabel = {
  __typename?: 'TyreLabel';
  productSheetUrl: Maybe<Scalars['String']['output']>;
  tyreLabelUrl: Maybe<Scalars['String']['output']>;
};

/** consumption or emission values for NEDC testing method */
export type ValuesNedc = {
  __typename?: 'ValuesNedc';
  /** combined value for consumption or emission profile */
  combined: Maybe<TechnicalDataFloatItem>;
  /** consolidated value may or may not be present depending on a factual decision about value to be shown */
  consolidated: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for extraUrban profile */
  extraUrban: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for urban profile */
  urban: Maybe<TechnicalDataFloatItem>;
};

/** consumption or emission values for WLTP testing method */
export type ValuesWltp = {
  __typename?: 'ValuesWltp';
  /** consumption or emission value for extraHigh profile */
  city: Maybe<TechnicalDataFloatItem>;
  /** combined value for consumption or emission */
  combined: Maybe<TechnicalDataFloatItem>;
  /** consolidated value may or may not be present depending on a factual decision about value to be shown */
  consolidated: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for extraHigh profile */
  extraHigh: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for high profile */
  high: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for low profile */
  low: Maybe<TechnicalDataFloatItem>;
  /** consumption or emission value for medium profile */
  medium: Maybe<TechnicalDataFloatItem>;
};

export enum VehicleType {
  Bev = 'BEV',
  Icev = 'ICEV',
  Phev = 'PHEV',
}

/** Defines a video by size, MIME type and source URL. */
export type VideoSource = {
  __typename?: 'VideoSource';
  /** The height of the video in pixels, e.g. 1920. */
  height: Scalars['Int']['output'];
  /** The MIME type of the video, e.g. "video/mp4". */
  mimeType: Scalars['String']['output'];
  /** The source of the video, e.g. "https://www.mydomain.com/myvideo.mp4". */
  url: Scalars['String']['output'];
  /** The width of the video in pixels, e.g. 1080. */
  width: Scalars['Int']['output'];
};

/** Energy Management for WLTP measurement cycle */
export enum WltpEnergyManagement {
  /** PHEV with with depleted battery measurement */
  Depleting = 'DEPLETING',
  /** Pure fuel measurement, applies for PEV or ICE */
  Pure = 'PURE',
  /** MHEV or PHEV with with full battery measurement */
  Sustaining = 'SUSTAINING',
  /** PHEV (unclear ?) measurement */
  Weighted = 'WEIGHTED',
}

export type WltpTestMethod = {
  __typename?: 'WltpTestMethod';
  consumption: WltpValues;
  emissionCO2: WltpValues;
  emissionNO2: Maybe<TechnicalDataFloatItem>;
};

/** Node containing consumption and emission values for WLTP testing method */
export type WltpTestMethodPerEnergyManagement = {
  __typename?: 'WltpTestMethodPerEnergyManagement';
  /** Set of ranges for fuel consumption */
  consumption: WltpValues;
  /** Set of ranges for CO2 emissions */
  emissionCO2: WltpValues;
  /** Single value for NOX emissions */
  emissionNO2: Maybe<TechnicalDataFloatItem>;
  /** applied measurement cycle */
  energyManagement: WltpEnergyManagement;
  /** may or may not be present depending on a factual decision; true if preferred to be shown */
  isDefault: Maybe<Scalars['Boolean']['output']>;
};

/** Node containing consumption and emission values per energy management for WLTP testing method */
export type WltpTestMethodsPerEnergyManagement = {
  __typename?: 'WltpTestMethodsPerEnergyManagement';
  /** consolidated values */
  consolidated: Maybe<WltpTestMethodPerEnergyManagement>;
  /** entries for different energy management testing cycles */
  perEnergyManagement: Maybe<Array<WltpTestMethodPerEnergyManagement>>;
};

/** consumption or emission values for WLTP testing method */
export type WltpValues = {
  __typename?: 'WltpValues';
  /** consumption or emission range value for extraHigh profile */
  city: Maybe<RangeInfo>;
  /** combined range value for consumption or emission */
  combined: Maybe<RangeInfo>;
  /** consolidated range value may or may not be present depending on a factual decision about value to be shown */
  consolidated: Maybe<RangeInfo>;
  /** consumption or emission range value for extraHigh profile */
  extraHigh: Maybe<RangeInfo>;
  /** consumption or emission range value for high profile */
  high: Maybe<RangeInfo>;
  /** consumption or emission range value for low profile */
  low: Maybe<RangeInfo>;
  /** consumption or emission range value for medium profile */
  medium: Maybe<RangeInfo>;
};

export type ConfiguredCarFragment = {
  catalog:
    | ({
        features: {
          exteriorColors: Array<
            {
              name: string;
              featureGroup: ConfiguredCarFeatureGroup | null;
              pr3: string;
              images:
                | ({
                    outerTileSmallHD:
                      | ({ height: number; mimeType: string; url: string; width: number } & {
                          __typename?: 'ImageSource';
                        })
                      | null;
                  } & { __typename?: 'ConfiguredCarFeatureImage' })
                | null;
              family: { id: string; name: string } & { __typename?: 'CarFeatureFamily' };
              status: {
                available: boolean;
                changeable: boolean;
                inConflict: boolean;
                selected: boolean;
                standard: boolean;
              } & { __typename?: 'Status' };
              group: { id: string; name: string } & { __typename?: 'CarFeatureGroup' };
            } & { __typename?: 'ConfiguredCarFeature' }
          >;
          rims: Array<
            {
              name: string;
              featureGroup: ConfiguredCarFeatureGroup | null;
              pr3: string;
              images:
                | ({
                    wheelImageS:
                      | ({ height: number; mimeType: string; url: string; width: number } & {
                          __typename?: 'ImageSource';
                        })
                      | null;
                  } & { __typename?: 'ConfiguredCarFeatureImage' })
                | null;
              family: { id: string; name: string } & { __typename?: 'CarFeatureFamily' };
              status: {
                available: boolean;
                changeable: boolean;
                inConflict: boolean;
                selected: boolean;
                standard: boolean;
              } & { __typename?: 'Status' };
              group: { id: string; name: string } & { __typename?: 'CarFeatureGroup' };
            } & { __typename?: 'ConfiguredCarFeature' }
          >;
        } & { __typename?: 'ConfiguredCarFeatures' };
        packagesDetails: {
          interiors: Array<
            {
              name: string;
              featureGroup: ConfiguredCarFeatureGroup | null;
              pr3: string;
              images:
                | ({
                    interiorTileButton:
                      | ({ height: number; mimeType: string; url: string; width: number } & {
                          __typename?: 'ImageSource';
                        })
                      | null;
                  } & { __typename?: 'ConfiguredCarFeatureImage' })
                | null;
              family: { id: string; name: string } & { __typename?: 'CarFeatureFamily' };
              status: {
                available: boolean;
                changeable: boolean;
                inConflict: boolean;
                selected: boolean;
                standard: boolean;
              } & { __typename?: 'Status' };
              group: { id: string; name: string } & { __typename?: 'CarFeatureGroup' };
            } & { __typename?: 'ConfiguredCarPackage' }
          > | null;
        } & { __typename?: 'ConfiguredCarPackages' };
      } & { __typename?: 'ConfiguredCarCatalog' })
    | null;
  media:
    | ({ ave: ({ renderString: string | null } & { __typename?: 'ConfiguredCarAve' }) | null } & {
        __typename?: 'ConfiguredCarMediaResources';
      })
    | null;
  prices:
    | ({
        total:
          | ({ value: number; formattedValue: string | null } & {
              __typename?: 'ConfiguredCarCatalogModelPrice' | 'Price' | 'StockCarCarPrice';
            })
          | null;
        rotr:
          | ({ value: number; formattedValue: string | null } & {
              __typename?: 'ConfiguredCarCatalogModelPrice' | 'Price' | 'StockCarCarPrice';
            })
          | null;
      } & { __typename?: 'ConfiguredCarPrices' })
    | null;
  technicalData:
    | ({
        consumptionAndEmission:
          | ({
              summary:
                | ({
                    consumptionsSummary: Array<
                      {
                        fuelType: FuelType | null;
                        fuelName: string | null;
                        values: Array<
                          { formattedValue: string | null } & {
                            __typename?: 'ConsumptionAndEmissionValuePerEnergyManagementWltp';
                          }
                        > | null;
                      } & { __typename?: 'FuelValues' }
                    > | null;
                    eecsSummary: Array<
                      { formattedValue: string | null } & {
                        __typename?: 'EecValuePerEnergyManagementWltp';
                      }
                    > | null;
                    emissionsCO2Summary: Array<
                      {
                        values: Array<
                          { formattedValue: string | null } & {
                            __typename?: 'ConsumptionAndEmissionValuePerEnergyManagementWltp';
                          }
                        > | null;
                      } & { __typename?: 'FuelValues' }
                    > | null;
                  } & { __typename?: 'TechnicalDataConsumptionAndEmissionSummary' })
                | null;
            } & { __typename?: 'TechnicalDataConsumptionAndEmission' })
          | null;
      } & { __typename?: 'ConfiguredCarTechnicalData' })
    | null;
} & { __typename?: 'ConfiguredCar' };

export type ExteriorColorConfiguredCarFeatureFragment = {
  name: string;
  featureGroup: ConfiguredCarFeatureGroup | null;
  pr3: string;
  images:
    | ({
        outerTileSmallHD:
          | ({ height: number; mimeType: string; url: string; width: number } & {
              __typename?: 'ImageSource';
            })
          | null;
      } & { __typename?: 'ConfiguredCarFeatureImage' })
    | null;
  family: { id: string; name: string } & { __typename?: 'CarFeatureFamily' };
  status: {
    available: boolean;
    changeable: boolean;
    inConflict: boolean;
    selected: boolean;
    standard: boolean;
  } & { __typename?: 'Status' };
  group: { id: string; name: string } & { __typename?: 'CarFeatureGroup' };
} & { __typename?: 'ConfiguredCarFeature' };

export type ConfiguredCarFeatureWithoutImages = {
  name: string;
  featureGroup: ConfiguredCarFeatureGroup | null;
  pr3: string;
  family: { id: string; name: string } & { __typename?: 'CarFeatureFamily' };
  status: {
    available: boolean;
    changeable: boolean;
    inConflict: boolean;
    selected: boolean;
    standard: boolean;
  } & { __typename?: 'Status' };
  group: { id: string; name: string } & { __typename?: 'CarFeatureGroup' };
} & { __typename?: 'ConfiguredCarFeature' };

export type ExteriorColorConfiguredCarFeatureImageFragment = {
  outerTileSmallHD:
    | ({ height: number; mimeType: string; url: string; width: number } & {
        __typename?: 'ImageSource';
      })
    | null;
} & { __typename?: 'ConfiguredCarFeatureImage' };

export type ImageSourceFragment = {
  height: number;
  mimeType: string;
  url: string;
  width: number;
} & { __typename?: 'ImageSource' };

export type RimsConfiguredCarFeatureFragment = {
  name: string;
  featureGroup: ConfiguredCarFeatureGroup | null;
  pr3: string;
  images:
    | ({
        wheelImageS:
          | ({ height: number; mimeType: string; url: string; width: number } & {
              __typename?: 'ImageSource';
            })
          | null;
      } & { __typename?: 'ConfiguredCarFeatureImage' })
    | null;
  family: { id: string; name: string } & { __typename?: 'CarFeatureFamily' };
  status: {
    available: boolean;
    changeable: boolean;
    inConflict: boolean;
    selected: boolean;
    standard: boolean;
  } & { __typename?: 'Status' };
  group: { id: string; name: string } & { __typename?: 'CarFeatureGroup' };
} & { __typename?: 'ConfiguredCarFeature' };

export type RimsConfiguredCarFeatureImageFragment = {
  wheelImageS:
    | ({ height: number; mimeType: string; url: string; width: number } & {
        __typename?: 'ImageSource';
      })
    | null;
} & { __typename?: 'ConfiguredCarFeatureImage' };

export type InteriorConfiguredCarPackageFragment = {
  name: string;
  featureGroup: ConfiguredCarFeatureGroup | null;
  pr3: string;
  images:
    | ({
        interiorTileButton:
          | ({ height: number; mimeType: string; url: string; width: number } & {
              __typename?: 'ImageSource';
            })
          | null;
      } & { __typename?: 'ConfiguredCarFeatureImage' })
    | null;
  family: { id: string; name: string } & { __typename?: 'CarFeatureFamily' };
  status: {
    available: boolean;
    changeable: boolean;
    inConflict: boolean;
    selected: boolean;
    standard: boolean;
  } & { __typename?: 'Status' };
  group: { id: string; name: string } & { __typename?: 'CarFeatureGroup' };
} & { __typename?: 'ConfiguredCarPackage' };

export type ConfiguredCarPackageWithoutImages = {
  name: string;
  featureGroup: ConfiguredCarFeatureGroup | null;
  pr3: string;
  family: { id: string; name: string } & { __typename?: 'CarFeatureFamily' };
  status: {
    available: boolean;
    changeable: boolean;
    inConflict: boolean;
    selected: boolean;
    standard: boolean;
  } & { __typename?: 'Status' };
  group: { id: string; name: string } & { __typename?: 'CarFeatureGroup' };
} & { __typename?: 'ConfiguredCarPackage' };

export type InteriorConfiguredCarFeatureImageFragment = {
  interiorTileButton:
    | ({ height: number; mimeType: string; url: string; width: number } & {
        __typename?: 'ImageSource';
      })
    | null;
} & { __typename?: 'ConfiguredCarFeatureImage' };

export type TechnicalDataConsumptionAndEmissionSummaryFragment = {
  consumptionsSummary: Array<
    {
      fuelType: FuelType | null;
      fuelName: string | null;
      values: Array<
        { formattedValue: string | null } & {
          __typename?: 'ConsumptionAndEmissionValuePerEnergyManagementWltp';
        }
      > | null;
    } & { __typename?: 'FuelValues' }
  > | null;
  eecsSummary: Array<
    { formattedValue: string | null } & { __typename?: 'EecValuePerEnergyManagementWltp' }
  > | null;
  emissionsCO2Summary: Array<
    {
      values: Array<
        { formattedValue: string | null } & {
          __typename?: 'ConsumptionAndEmissionValuePerEnergyManagementWltp';
        }
      > | null;
    } & { __typename?: 'FuelValues' }
  > | null;
} & { __typename?: 'TechnicalDataConsumptionAndEmissionSummary' };

export type ChangeConfiguredCarFeatureVariables = Exact<{
  changeConfiguredCarFeatureInput: ChangeConfiguredCarFeatureInput;
}>;

export type ChangeConfiguredCarFeature = {
  changeConfiguredCarFeature: {
    configuredCar:
      | ({
          catalog:
            | ({
                features: {
                  exteriorColors: Array<
                    {
                      name: string;
                      featureGroup: ConfiguredCarFeatureGroup | null;
                      pr3: string;
                      images:
                        | ({
                            outerTileSmallHD:
                              | ({
                                  height: number;
                                  mimeType: string;
                                  url: string;
                                  width: number;
                                } & { __typename?: 'ImageSource' })
                              | null;
                          } & { __typename?: 'ConfiguredCarFeatureImage' })
                        | null;
                      family: { id: string; name: string } & { __typename?: 'CarFeatureFamily' };
                      status: {
                        available: boolean;
                        changeable: boolean;
                        inConflict: boolean;
                        selected: boolean;
                        standard: boolean;
                      } & { __typename?: 'Status' };
                      group: { id: string; name: string } & { __typename?: 'CarFeatureGroup' };
                    } & { __typename?: 'ConfiguredCarFeature' }
                  >;
                  rims: Array<
                    {
                      name: string;
                      featureGroup: ConfiguredCarFeatureGroup | null;
                      pr3: string;
                      images:
                        | ({
                            wheelImageS:
                              | ({
                                  height: number;
                                  mimeType: string;
                                  url: string;
                                  width: number;
                                } & { __typename?: 'ImageSource' })
                              | null;
                          } & { __typename?: 'ConfiguredCarFeatureImage' })
                        | null;
                      family: { id: string; name: string } & { __typename?: 'CarFeatureFamily' };
                      status: {
                        available: boolean;
                        changeable: boolean;
                        inConflict: boolean;
                        selected: boolean;
                        standard: boolean;
                      } & { __typename?: 'Status' };
                      group: { id: string; name: string } & { __typename?: 'CarFeatureGroup' };
                    } & { __typename?: 'ConfiguredCarFeature' }
                  >;
                } & { __typename?: 'ConfiguredCarFeatures' };
                packagesDetails: {
                  interiors: Array<
                    {
                      name: string;
                      featureGroup: ConfiguredCarFeatureGroup | null;
                      pr3: string;
                      images:
                        | ({
                            interiorTileButton:
                              | ({
                                  height: number;
                                  mimeType: string;
                                  url: string;
                                  width: number;
                                } & { __typename?: 'ImageSource' })
                              | null;
                          } & { __typename?: 'ConfiguredCarFeatureImage' })
                        | null;
                      family: { id: string; name: string } & { __typename?: 'CarFeatureFamily' };
                      status: {
                        available: boolean;
                        changeable: boolean;
                        inConflict: boolean;
                        selected: boolean;
                        standard: boolean;
                      } & { __typename?: 'Status' };
                      group: { id: string; name: string } & { __typename?: 'CarFeatureGroup' };
                    } & { __typename?: 'ConfiguredCarPackage' }
                  > | null;
                } & { __typename?: 'ConfiguredCarPackages' };
              } & { __typename?: 'ConfiguredCarCatalog' })
            | null;
          media:
            | ({
                ave: ({ renderString: string | null } & { __typename?: 'ConfiguredCarAve' }) | null;
              } & { __typename?: 'ConfiguredCarMediaResources' })
            | null;
          prices:
            | ({
                total:
                  | ({ value: number; formattedValue: string | null } & {
                      __typename?: 'ConfiguredCarCatalogModelPrice' | 'Price' | 'StockCarCarPrice';
                    })
                  | null;
                rotr:
                  | ({ value: number; formattedValue: string | null } & {
                      __typename?: 'ConfiguredCarCatalogModelPrice' | 'Price' | 'StockCarCarPrice';
                    })
                  | null;
              } & { __typename?: 'ConfiguredCarPrices' })
            | null;
          technicalData:
            | ({
                consumptionAndEmission:
                  | ({
                      summary:
                        | ({
                            consumptionsSummary: Array<
                              {
                                fuelType: FuelType | null;
                                fuelName: string | null;
                                values: Array<
                                  { formattedValue: string | null } & {
                                    __typename?: 'ConsumptionAndEmissionValuePerEnergyManagementWltp';
                                  }
                                > | null;
                              } & { __typename?: 'FuelValues' }
                            > | null;
                            eecsSummary: Array<
                              { formattedValue: string | null } & {
                                __typename?: 'EecValuePerEnergyManagementWltp';
                              }
                            > | null;
                            emissionsCO2Summary: Array<
                              {
                                values: Array<
                                  { formattedValue: string | null } & {
                                    __typename?: 'ConsumptionAndEmissionValuePerEnergyManagementWltp';
                                  }
                                > | null;
                              } & { __typename?: 'FuelValues' }
                            > | null;
                          } & { __typename?: 'TechnicalDataConsumptionAndEmissionSummary' })
                        | null;
                    } & { __typename?: 'TechnicalDataConsumptionAndEmission' })
                  | null;
              } & { __typename?: 'ConfiguredCarTechnicalData' })
            | null;
          id: {
            equipmentOptions: Array<string> | null;
            exteriorColor: string | null;
            interiorColor: string | null;
            salesGroup: string;
          } & { __typename?: 'ConfiguredCarIdentifier' };
          carline: ({ name: string } & { __typename?: 'ConfiguredCarCarline' }) | null;
        } & { __typename?: 'ConfiguredCar' })
      | null;
  } & { __typename?: 'ChangeConfiguredCarPayload' };
} & { __typename?: 'Query' };

export type ConfiguredCarByCarlineWithFeaturesVariables = Exact<{
  carlineInput: ConfiguredCarByCarlineInput;
  features: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type ConfiguredCarByCarlineWithFeatures = {
  configuredCarByCarlineWithFeatures: {
    catalog:
      | ({
          features: {
            exteriorColors: Array<
              {
                name: string;
                featureGroup: ConfiguredCarFeatureGroup | null;
                pr3: string;
                images:
                  | ({
                      outerTileSmallHD:
                        | ({ height: number; mimeType: string; url: string; width: number } & {
                            __typename?: 'ImageSource';
                          })
                        | null;
                    } & { __typename?: 'ConfiguredCarFeatureImage' })
                  | null;
                family: { id: string; name: string } & { __typename?: 'CarFeatureFamily' };
                status: {
                  available: boolean;
                  changeable: boolean;
                  inConflict: boolean;
                  selected: boolean;
                  standard: boolean;
                } & { __typename?: 'Status' };
                group: { id: string; name: string } & { __typename?: 'CarFeatureGroup' };
              } & { __typename?: 'ConfiguredCarFeature' }
            >;
            rims: Array<
              {
                name: string;
                featureGroup: ConfiguredCarFeatureGroup | null;
                pr3: string;
                images:
                  | ({
                      wheelImageS:
                        | ({ height: number; mimeType: string; url: string; width: number } & {
                            __typename?: 'ImageSource';
                          })
                        | null;
                    } & { __typename?: 'ConfiguredCarFeatureImage' })
                  | null;
                family: { id: string; name: string } & { __typename?: 'CarFeatureFamily' };
                status: {
                  available: boolean;
                  changeable: boolean;
                  inConflict: boolean;
                  selected: boolean;
                  standard: boolean;
                } & { __typename?: 'Status' };
                group: { id: string; name: string } & { __typename?: 'CarFeatureGroup' };
              } & { __typename?: 'ConfiguredCarFeature' }
            >;
          } & { __typename?: 'ConfiguredCarFeatures' };
          packagesDetails: {
            interiors: Array<
              {
                name: string;
                featureGroup: ConfiguredCarFeatureGroup | null;
                pr3: string;
                images:
                  | ({
                      interiorTileButton:
                        | ({ height: number; mimeType: string; url: string; width: number } & {
                            __typename?: 'ImageSource';
                          })
                        | null;
                    } & { __typename?: 'ConfiguredCarFeatureImage' })
                  | null;
                family: { id: string; name: string } & { __typename?: 'CarFeatureFamily' };
                status: {
                  available: boolean;
                  changeable: boolean;
                  inConflict: boolean;
                  selected: boolean;
                  standard: boolean;
                } & { __typename?: 'Status' };
                group: { id: string; name: string } & { __typename?: 'CarFeatureGroup' };
              } & { __typename?: 'ConfiguredCarPackage' }
            > | null;
          } & { __typename?: 'ConfiguredCarPackages' };
        } & { __typename?: 'ConfiguredCarCatalog' })
      | null;
    media:
      | ({ ave: ({ renderString: string | null } & { __typename?: 'ConfiguredCarAve' }) | null } & {
          __typename?: 'ConfiguredCarMediaResources';
        })
      | null;
    prices:
      | ({
          total:
            | ({ value: number; formattedValue: string | null } & {
                __typename?: 'ConfiguredCarCatalogModelPrice' | 'Price' | 'StockCarCarPrice';
              })
            | null;
          rotr:
            | ({ value: number; formattedValue: string | null } & {
                __typename?: 'ConfiguredCarCatalogModelPrice' | 'Price' | 'StockCarCarPrice';
              })
            | null;
        } & { __typename?: 'ConfiguredCarPrices' })
      | null;
    technicalData:
      | ({
          consumptionAndEmission:
            | ({
                summary:
                  | ({
                      consumptionsSummary: Array<
                        {
                          fuelType: FuelType | null;
                          fuelName: string | null;
                          values: Array<
                            { formattedValue: string | null } & {
                              __typename?: 'ConsumptionAndEmissionValuePerEnergyManagementWltp';
                            }
                          > | null;
                        } & { __typename?: 'FuelValues' }
                      > | null;
                      eecsSummary: Array<
                        { formattedValue: string | null } & {
                          __typename?: 'EecValuePerEnergyManagementWltp';
                        }
                      > | null;
                      emissionsCO2Summary: Array<
                        {
                          values: Array<
                            { formattedValue: string | null } & {
                              __typename?: 'ConsumptionAndEmissionValuePerEnergyManagementWltp';
                            }
                          > | null;
                        } & { __typename?: 'FuelValues' }
                      > | null;
                    } & { __typename?: 'TechnicalDataConsumptionAndEmissionSummary' })
                  | null;
              } & { __typename?: 'TechnicalDataConsumptionAndEmission' })
            | null;
        } & { __typename?: 'ConfiguredCarTechnicalData' })
      | null;
    id: {
      equipmentOptions: Array<string> | null;
      exteriorColor: string | null;
      interiorColor: string | null;
      salesGroup: string;
      model: { code: string; extensions: Array<string> | null; version: number; year: number } & {
        __typename?: 'ConfiguredCarModelIdentifier';
      };
    } & { __typename?: 'ConfiguredCarIdentifier' };
    carline: ({ name: string } & { __typename?: 'ConfiguredCarCarline' }) | null;
    carlineStructureCarline:
      | ({ vehicleType: VehicleType | null } & { __typename?: 'CarlineStructureCarline' })
      | null;
  } & { __typename?: 'ConfiguredCar' };
} & { __typename?: 'Query' };
