import React, { FC, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { OverlayItem } from './OverlayItem';
import { useAppDispatch, useAppState } from '../contexts/app-context';
import { NullableCategory } from '../contexts/reducers/app-reducer';
import { SEAT_SCHEME_CATEGORY } from '../utils/models';
import {
  DESKTOP_BACK_BUTTON_Z_INDEX,
  MOBILE_BACK_BUTTON_Z_INDEX,
  MOBILE_OVERLAY_Z_INDEX,
} from '../utils/utils';
import { TextButton } from '../components/TextButton';
import { i18nKeys, useI18n } from '../i18n/i18n';
import { CtaButton, CtaButtonProps } from '../components/CtaButton';

type OverlayProps = { ctaProps: CtaButtonProps };

export const Overlay: FC<OverlayProps> = ({ ctaProps }) => {
  const buttonText = useI18n(i18nKeys.backToOverview);

  const { selectedCategory, enabledCategories, tracking } = useAppState();

  const dispatch = useAppDispatch();
  const setCategory = useCallback(
    (category: NullableCategory) =>
      dispatch({
        type: 'SET_CATEGORY',
        payload: category,
      }),
    [dispatch],
  );

  const wrapperRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const overlayHighlightedTileRef = useRef<HTMLDivElement>(null);

  // Scroll to center the highlighted button when the Overlay mounts
  useEffect(() => {
    const { current: wrapper } = wrapperRef;
    const { current: overlayHighlightedButton } = overlayHighlightedTileRef;

    if (!wrapper || !overlayHighlightedButton) return;

    const { offsetHeight: wrapperOffsetHeight } = wrapper;
    const { offsetTop, offsetHeight } = overlayHighlightedButton;

    /**
     * Scroll isn`t always precise, especially when opening the Overlay for the first time
     * because some fonts are loaded and that can shift the layout
     */
    wrapper.scrollTo({
      behavior: 'smooth',
      top: offsetTop + offsetHeight / 2 - wrapperOffsetHeight / 2,
    });
  }, []);

  const allowViewChangeRef = useRef(false);
  const animationFrameRef = useRef<number | null>(null);

  // show coresponding view in Visa FA depending on category in view
  const onScroll = useCallback(() => {
    const fn = () => {
      if (!allowViewChangeRef.current) return;

      const { current: wrapper } = wrapperRef;
      const { current: content } = contentRef;
      if (!wrapper || !content) return;

      const { scrollTop, offsetHeight: wrapperOffsetHeight } = wrapper;
      const scrollBottom = scrollTop + wrapperOffsetHeight;

      let mostVisibleHeight = 0;
      let mostVisibleCategory: NullableCategory | null = null;

      // eslint-disable-next-line no-restricted-syntax,no-underscore-dangle
      for (const _child of content.children) {
        const child = _child as HTMLElement;

        const childCategory = (child.dataset.category || null) as NullableCategory;

        const { offsetTop, offsetHeight } = child;
        const offsetBottom = offsetTop + offsetHeight;

        const viewportOffsetTop = Math.max(offsetTop - scrollTop, 0);
        const viewportOffsetBottom = Math.max(scrollBottom - offsetBottom, 0);

        const visibleHeight = Math.max(
          wrapperOffsetHeight - viewportOffsetTop - viewportOffsetBottom,
          0,
        );

        // in the case of a category with few items(less than 3), visibleHeight is shorter than mostVisibleCategory.
        // However it has same length as offsetHeight.
        if (visibleHeight === offsetHeight) {
          mostVisibleCategory = childCategory;
          break;
        }

        if (visibleHeight > mostVisibleHeight) {
          mostVisibleHeight = visibleHeight;
          mostVisibleCategory = childCategory;
        }
      }

      if (
        !mostVisibleCategory ||
        selectedCategory === mostVisibleCategory ||
        !enabledCategories.includes(mostVisibleCategory)
      ) {
        return;
      }

      setCategory(mostVisibleCategory);
    };

    if (animationFrameRef.current) cancelAnimationFrame(animationFrameRef.current);
    animationFrameRef.current = requestAnimationFrame(fn);
  }, [enabledCategories, selectedCategory, setCategory]);

  const resetSelectedCategory = useCallback(() => {
    dispatch({
      type: 'SET_CATEGORY',
      payload: null,
    });
  }, [dispatch]);

  const handleClickBack = useCallback(() => {
    resetSelectedCategory();
    tracking?.goBackToOverview.trigger(buttonText);
  }, [buttonText, resetSelectedCategory, tracking]);

  useEffect(() => {
    if (!selectedCategory) {
      allowViewChangeRef.current = false;
    }

    const timeout = selectedCategory
      ? setTimeout(() => {
          allowViewChangeRef.current = true;
        }, 2000)
      : null;

    return () => {
      if (!timeout) return;
      clearTimeout(timeout);
    };
  }, [selectedCategory]);

  useEffect(
    () => () => {
      if (!animationFrameRef.current) return;
      cancelAnimationFrame(animationFrameRef.current);
    },
    [],
  );

  return (
    <OverlayBackground onScroll={onScroll} ref={wrapperRef} data-testid="overlay-background">
      <ButtonWrapper>
        <StickyTextButton onClick={handleClickBack}>{buttonText}</StickyTextButton>
      </ButtonWrapper>
      <OverlayContent ref={contentRef}>
        {enabledCategories.map((category) =>
          category === SEAT_SCHEME_CATEGORY ? null : (
            <OverlayItem
              ref={category === selectedCategory ? overlayHighlightedTileRef : undefined}
              category={category}
              key={category}
            />
          ),
        )}
        <StyledCtaButton {...ctaProps} />
      </OverlayContent>
    </OverlayBackground>
  );
};

const OverlayBackground = styled.div`
  /* position: fixed on mobile so that the overlay covers the entire screen and
  does not only start below the Visa FA */
  display: grid;
  grid-template-rows: auto 1fr;
  justify-content: stretch;
  row-gap: var(${({ theme }) => theme.responsive.spacing.m});
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(${({ theme }) => theme.colors.base.grey[0]});
  overflow-y: scroll;
  z-index: ${MOBILE_OVERLAY_Z_INDEX};

  /* Overlay is positioned above the Summary as opposed to not rendering the Summary
    so that the Overlay with position: absolute can place itself exactly where the Summary
    was shown (as the position of the SummaryAndSelectionWrapper in Visualizer.tsx is
    definded by the hidden Summary) */
  @media (min-width: ${({ theme }) => theme.breakpoints.l}px) {
    position: absolute;
    height: 100%;
    overflow-y: auto;
    justify-content: stretch;
    z-index: 1;
  }
`;

const OverlayContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-inline: var(${({ theme }) => theme.responsive.spacing.l});
  gap: var(${({ theme }) => theme.responsive.spacing.xxl});

  @media (min-width: ${({ theme }) => theme.breakpoints.l}px) {
    padding-inline: 0;
  }
`;

const ButtonWrapper = styled.div`
  position: sticky;
  top: 0;
  padding-inline: var(${({ theme }) => theme.responsive.spacing.l});
  z-index: ${MOBILE_BACK_BUTTON_Z_INDEX};

  @media (min-width: ${({ theme }) => theme.breakpoints.l}px) {
    padding-inline: 0;
    z-index: ${DESKTOP_BACK_BUTTON_Z_INDEX};
  }
`;

const StickyTextButton = styled(TextButton)`
  background-color: white;
  padding-block: var(${({ theme }) => theme.responsive.spacing.l})
    var(${({ theme }) => theme.responsive.spacing.xs});
  width: calc(100% + 6px);

  &:after {
    content: '';
    height: 1px;
    position: absolute;
    bottom: 0;
    background-color: var(${({ theme }) => theme.colors.base.grey[20]});
    left: 6px;
    right: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.l}px) {
    padding-block: var(${({ theme }) => theme.responsive.spacing.xs});
    z-index: ${DESKTOP_BACK_BUTTON_Z_INDEX};
  }
`;

const StyledCtaButton = styled(CtaButton)`
  display: grid;
  padding-inline: var(${({ theme }) => theme.responsive.spacing.l});
  margin-bottom: var(${({ theme }) => theme.responsive.spacing.l});
  align-self: stretch;

  @media (min-width: ${({ theme }) => theme.breakpoints.l}px) {
    display: none;
  }
`;
