import { GfaLocaleServiceV1 } from '@volkswagen-onehub/gfa-locale-service';
import { Logger } from '@feature-hub/core';
import axios from 'axios';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { AssetAPIConfig } from '../../editor.d';
import { components } from './definition.d';
import { transformImage } from '../utils';
import {
  Car,
  OptionType,
  Option,
  SEAT_SCHEME_CATEGORY,
  OptionTypeExcludingSeatScheme,
  OptionTypeExclusive,
  OptionTypeDefault,
  SeatSchemeOption,
  OptionTypeExclusiveInteriorOnly,
} from '../models';
import { amendExclusiveAve } from './ave';

interface AssetApiResponse {
  customizersResults: {
    customizerResults: {
      apis: {
        'customizerapi.json': string;
      };
    }[];
  }[];
}

type AssetApiData = components['schemas']['CustomizerApiData'];

type EquipmentTypeExclusiveInteriorOnly =
  | 'EXCLUSIVE_INTERIOR_BASE'
  | 'EXCLUSIVE_INTERIOR_CONTRAST'
  | 'EXCLUSIVE_INTERIOR_STITCHING';

export const NO_SEAT_SCHEME_ID_SUFFIX = 'No0';

const equipmentTypeByOptionTypeDefault = {
  exteriorColor: 'EXTERIOR_COLOR',
  interior: 'PACKAGE',
  rim: 'WHEEL',
} as const;

const equipmentTypeByOptionTypeExclusiveInterior = {
  interiorBaseColor: 'EXCLUSIVE_INTERIOR_BASE',
  interiorContrastColor: 'EXCLUSIVE_INTERIOR_CONTRAST',
  interiorStitchingColor: 'EXCLUSIVE_INTERIOR_STITCHING',
} as const;

const equipmentTypeByOptionTypeExclusive = {
  exteriorColor: 'EXCLUSIVE_EXTERIOR_COLOR',
  ...equipmentTypeByOptionTypeExclusiveInterior,
} as const;

const exclusiveCategoryByOptionTypeExclusive = {
  exteriorColor: 'exclusiveExterior',
  interiorBaseColor: 'exclusiveInterior',
  interiorContrastColor: 'exclusiveInterior',
  interiorStitchingColor: 'exclusiveInterior',
} as const;

function getAvailableOptions(
  input: AssetApiData,
  category: OptionTypeExcludingSeatScheme,
  isExclusive: boolean,
  language: string,
): Option[] {
  if (isExclusive) {
    const exclusiveCategoryKey = category as OptionTypeExcludingSeatScheme & OptionTypeExclusive;

    const exclusiveCategory = input[exclusiveCategoryByOptionTypeExclusive[exclusiveCategoryKey]];
    const equipmentType = equipmentTypeByOptionTypeExclusive[exclusiveCategoryKey];

    return (
      exclusiveCategory?.equipmentHierarchy
        .find(({ type }) => type === equipmentType)
        ?.equipments.filter(({ imageUrl }) => Boolean(imageUrl))
        .map(({ imageUrl, prCode, texts, filters }) => ({
          id: prCode,
          tile: { url: transformImage(imageUrl!) },
          name: texts[language] || texts.en || '',
          subCategory:
            category === 'exteriorColor'
              ? filters.find(({ key }) => key === 'EXCLUSIVE_COLOR_TYPE')?.value
              : undefined,
        })) || []
    );
  }

  const defaultCategoryKey = category as OptionTypeExcludingSeatScheme & OptionTypeDefault;

  const equipmentType = equipmentTypeByOptionTypeDefault[defaultCategoryKey];

  return (
    input.equipmentHierarchy
      ?.find(({ type }) => type === equipmentType)
      ?.equipments.filter(({ imageUrl }) => Boolean(imageUrl))
      .map(({ imageUrl, prCode, texts }) => ({
        id: prCode,
        tile: { url: transformImage(imageUrl!) },
        name: texts[language] || texts.en || '',
      })) || []
  );
}

function getAvailableSeatSchemes(input: AssetApiData, language: string): SeatSchemeOption[] {
  return (
    input.exclusiveInterior?.seatSchemes
      .filter(({ prCode }) => !prCode.endsWith(NO_SEAT_SCHEME_ID_SUFFIX))
      .map(({ baseParts, partPrefixesByType, prCode, texts }) => ({
        baseIds: baseParts,
        id: prCode,
        name: texts[language] || texts.en || '',
        // remap `prefixIds` from asset api to our internal keys from `OptionType`
        prefixIds: Object.fromEntries(
          Object.entries(equipmentTypeByOptionTypeExclusiveInterior).map(([key, value]) => [
            key,
            partPrefixesByType[value as EquipmentTypeExclusiveInteriorOnly] || [],
          ]),
        ) as Record<OptionTypeExclusiveInteriorOnly, string[]>,
      })) || []
  );
}

function getSelectedOption(
  input: AssetApiData,
  category: OptionTypeExcludingSeatScheme,
  isExclusive: boolean,
  options: Option[],
): Option {
  if (isExclusive) {
    const exclusiveCategoryKey = category as OptionTypeExcludingSeatScheme & OptionTypeExclusive;

    const exclusiveCategory = input[exclusiveCategoryByOptionTypeExclusive[exclusiveCategoryKey]];
    const equipmentType = equipmentTypeByOptionTypeExclusive[exclusiveCategoryKey];

    const selectedId = exclusiveCategory?.equipmentHierarchy.find(
      ({ type }) => type === equipmentType,
    )?.default;

    return (selectedId && options.find(({ id }) => id === selectedId)) || options[0];
  }

  const defaultCategoryKey = category as OptionTypeExcludingSeatScheme & OptionTypeDefault;

  const equipmentType = equipmentTypeByOptionTypeDefault[defaultCategoryKey];

  const selectedId = input.equipmentHierarchy?.find(({ type }) => type === equipmentType)?.default;

  return (selectedId && options.find(({ id }) => id === selectedId)) || options[0];
}

function getSelectedSeatScheme(
  input: AssetApiData,
  options: SeatSchemeOption[],
): SeatSchemeOption | undefined {
  const selectedId = input.exclusiveInterior?.seatSchemes.find(
    ({ default: isDefault }) => isDefault,
  )?.prCode;

  return (selectedId && options.find(({ id }) => id === selectedId)) || options[0];
}

export async function createCar(
  { modelCode, modelYear, vehicleType }: AssetAPIConfig,
  marketCode: string,
  emission: string,
  powerConsumption: string,
  classFb: string,
  consumption: string,
  fuelConsumptionBatteryDischarged: string,
  classBatteryDischarged: string,
  enabledCategories: readonly OptionType[],
  isExclusive: boolean,
  { language }: GfaLocaleServiceV1,
): Promise<Car> {
  const { data } = await axios.post<AssetApiResponse>(
    'https://mediaservice.audi.com/renderapi/v2/customizerapi',
    {
      client: 'zwHXvYR6',
      live: true,
      brand: marketCode,
      configs: [
        {
          name: 'cuaps5',
        },
      ],
      names: ['customizerapi.json'],
      customizerRequests: [
        {
          modelCode,
          modelYear,
        },
      ],
    },
  );

  const result: AssetApiData = JSON.parse(
    data?.customizersResults?.[0]?.customizerResults?.[0]?.apis?.['customizerapi.json'] || '{}',
  );

  const { basePrString: ave, carlineTexts } = result;
  const longName = carlineTexts[language] || carlineTexts.en || '';
  const availableOptions = Object.fromEntries(
    enabledCategories.map(
      (category) =>
        [
          category,
          category === SEAT_SCHEME_CATEGORY
            ? getAvailableSeatSchemes(result, language)
            : getAvailableOptions(result, category, isExclusive, language),
        ] as const,
    ),
  ) as Record<OptionTypeExcludingSeatScheme, Option[]> &
    Record<typeof SEAT_SCHEME_CATEGORY, SeatSchemeOption[]>;

  const selectedOptions = Object.fromEntries(
    Object.entries(availableOptions).map(
      ([category, options]) =>
        [
          category,
          category === SEAT_SCHEME_CATEGORY
            ? getSelectedSeatScheme(result, options as SeatSchemeOption[])
            : getSelectedOption(
                result,
                category as OptionTypeExcludingSeatScheme,
                isExclusive,
                options as Option[],
              ),
        ] as const,
    ),
  ) as Record<OptionTypeExcludingSeatScheme, Option> &
    Record<typeof SEAT_SCHEME_CATEGORY, SeatSchemeOption>;

  return amendExclusiveAve({
    carlineName: longName,
    ave,
    availableOptions,
    selectedOptions,
    emission,
    powerConsumption,
    classFb,
    consumption,
    fuelConsumptionBatteryDischarged,
    classBatteryDischarged,
    vehicleType,
  });
}

export async function fetchCar(
  config: AssetAPIConfig,
  marketCode: string,
  enabledCategories: readonly OptionType[],
  isExclusive: boolean,
  ecFormatterService: VueFormatterServiceInterfaceV1,
  localeService: GfaLocaleServiceV1,
  logger: Logger,
): Promise<Car> {
  // eslint-disable-next-line prefer-const
  let {
    emissionFallback: emission,
    consumptionFallback: consumption,
    classFallback: classFb,
    fuelConsumptionBatteryDischargedFallback: fuelConsumptionBatteryDischarged,
    classBatteryDischargedFallback: classBatteryDischarged,
    powerConsumptionFallback: powerConsumption,
  } = config;

  const { carline } = config;

  try {
    const res = await ecFormatterService.vueRangeForCarline(
      localeService.countryCode,
      localeService.language,
      carline,
    );

    const {
      formattedCo2Class,
      formattedDischargedCo2Class,
      formattedEmission,
      formattedConsumption,
      formattedDischargedConsumption,
    } = res;
    if (formattedEmission) emission = formattedEmission;
    if (formattedConsumption) consumption = formattedConsumption;

    const fuelAndPowerConsumption = consumption.split('; ');
    const [consumptionFromVue, powerConsumptionFromVue] = fuelAndPowerConsumption;

    powerConsumption = powerConsumptionFromVue ?? powerConsumption;
    consumption = consumptionFromVue ?? consumption;

    if (formattedCo2Class) classFb = formattedCo2Class;
    if (formattedDischargedCo2Class) classBatteryDischarged = formattedDischargedCo2Class;
    if (formattedDischargedConsumption)
      fuelConsumptionBatteryDischarged = formattedDischargedConsumption;
  } catch (err) {
    const msg = `ecFormatterService.vueRangeForCarline request failed for ${carline}. Using fallbacks.`;
    logger.warn(msg);
  }

  return createCar(
    config,
    marketCode,
    emission,
    powerConsumption,
    classFb,
    consumption,
    fuelConsumptionBatteryDischarged,
    classBatteryDischarged,
    enabledCategories,
    isExclusive,
    localeService,
  );
}
